import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../button/Button";
import { Layout } from "../layout/Layout";
import { Modal } from "../modal/Modal";
import { Panel, PanelWidth } from "../panel/Panel";

import styles from "./product-terms-consent-modal.module.scss";

export interface ProductTermsConsentModalProps {
  isTermAccepted: boolean;
  isVisible: boolean;
  isLoading: boolean;
  children?: ReactNode;
  handleAccess: (granted: boolean) => void;
  onAccept: (codes: any) => Promise<void>;
  onReject: (codes: any) => Promise<void>;
  close: () => void;
}

export const ProductTermsConsentModal: FC<ProductTermsConsentModalProps> = ({
  children,
  isTermAccepted,
  isVisible,
  isLoading,
  handleAccess,
  close,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isVisible} close={close} hideCloseButton>
      <Panel width={PanelWidth.SMALL} contentClassName={styles.content}>
        {children}

        <Layout wrap="MOBILE">
          <Button
            onClick={() => handleAccess(false)}
            isLoading={isLoading}
            color="LIGHT_RED"
            fontSize={16}
            weight="MEDIUM"
            borderRadius="SMALL"
            stretch="MOBILE"
          >
            {t("Reject and log out")}
          </Button>
          <Button
            className={styles.button}
            color="BLUE"
            fontSize={16}
            weight="MEDIUM"
            borderRadius="SMALL"
            stretch="MOBILE"
            disabled={!isTermAccepted}
            onClick={() => handleAccess(true)}
            isLoading={isLoading}
          >
            {t("Accept")}
          </Button>
        </Layout>
      </Panel>
    </Modal>
  );
};
