import classNames from "classnames";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { ApolloError } from "@apollo/client";

import { Button } from "../../components/button/Button";
import { Container, ContainerWidth } from "../../components/container/Container";
import Field, { FieldGutter } from "../../components/field/Field";
import { Form } from "../../components/form/Form";
import { Heading, HeadingGutter } from "../../components/heading/Heading";
import { IconCheckmarkThin } from "../../components/icon/IconCheckmarkThin";
import { IconEmailError } from "../../components/icon/IconEmailError";
import { Loader } from "../../components/loader/Loader";
import { useCreateEmailChangeMutation, useViewerQuery } from "../../graphql/schema";
import { handleFormErrors } from "../../services/handleFormErrors";

import styles from "./emailConfirmationView.module.scss";

type ChangeEmailFormValues = {
  email: string;
  repeatEmail: string;
  password: string;
};

export const EmailConfirmationView: React.FC = () => {
  const [t] = useTranslation();
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [createEmailChange, { loading: createEmailChangeLoading }] = useCreateEmailChangeMutation({
    refetchQueries: ["Viewer"],
  });
  const { data, loading } = useViewerQuery({ fetchPolicy: "network-only" });

  const changeEmailSchema = yup.object().shape({
    email: yup
      .string()
      .notOneOf([data?.viewer?.email], t("New email should be different from current email"))
      .required()
      .email(),
    repeatEmail: yup
      .string()
      .oneOf([yup.ref("email")], t("Both Emails need to be the same"))
      .required(),
    password: yup.string().required(t("Please enter your password")),
  });

  const {
    register: changeEmail,
    handleSubmit,
    errors,
    setError,
  } = useForm<ChangeEmailFormValues>({
    resolver: yupResolver(changeEmailSchema),
  });

  if (loading) {
    return <Loader />;
  }

  const handleChangeEmail = handleSubmit(async ({ email, repeatEmail, password }) => {
    try {
      const result = await createEmailChange({ variables: { email, repeatEmail, password } });

      if (result) {
        setIsEmailConfirmed(true);
        setNewEmailAddress(email);
      }
    } catch (error) {
      setIsEmailConfirmed(false);
      setNewEmailAddress("");

      if (error instanceof ApolloError) {
        handleFormErrors({ error, t, setError });
      }

      return;
    }
  });

  // handle email confirmation status
  if (isEmailConfirmed) {
    return (
      <Container title={t("Check your email")} center size={ContainerWidth.SMALL} secondaryTitle>
        <p className={styles["title-caption"]}>
          {t(
            "Go check your e-mail. We have just sent you an email that contains a confirmation link. By clicking on that link we are able to verify that you have access to ",
          )}
          <span className={styles["user-email"]}>
            <strong>{newEmailAddress}</strong>
          </span>
        </p>
        <p className={classNames(styles["guideline-text"], styles.discalaimer)}>
          {t(
            "In case you are facing difficulties with the email change, please contact special customer support line for email change related issues at ",
          )}
        </p>
        <span className={styles["support-email"]}>emailchange@sfsuite.com</span>
        <Button
          className={styles.retry}
          kind="TEXT"
          height="AUTO"
          onClick={() => {
            setNewEmailAddress("");
            setIsEmailConfirmed(false);
          }}
        >
          {t("Didn’t receive the email? Try again")}…
        </Button>
      </Container>
    );
  }

  return (
    <Container title={t("Action required")} center size={ContainerWidth.SMALL} secondaryTitle>
      <p className={styles["title-caption"]}>
        {t(
          "There seems to be a mismatch with your registered e-mail address. We would like you to enter your valid personal email address.",
        )}
      </p>

      <div className={styles["error-message-wrap"]}>
        <IconEmailError />
        <div className={styles["error-message"]}>
          <strong>
            {data?.viewer?.firstName} {data?.viewer?.lastName}
          </strong>
          , {t("your current registered email is")}{" "}
          <span className={styles.email}>
            <strong>{data?.viewer?.email}</strong>
          </span>
        </div>
      </div>
      <Heading level={5} gutter={HeadingGutter.LARGE} center>
        {t("Set your valid personal e-mail")}
      </Heading>
      <Form onSubmit={handleChangeEmail} stretch>
        <Field
          defaultValue=""
          name="email"
          label={t("New email")}
          expand
          gutter={FieldGutter.MEDIUM}
          internalRef={changeEmail}
          error={errors.email}
        />
        <Field
          name="repeatEmail"
          label={t("Repeat your new email")}
          expand
          gutter={FieldGutter.MEDIUM}
          internalRef={changeEmail}
          error={errors.repeatEmail}
          defaultValue=""
        />
        <p className={styles["guideline-text"]}>{t("So that we know it is you, please validate this request")}</p>
        <Field
          name="password"
          type="password"
          label={t("Transaction password")}
          expand
          gutter={FieldGutter.LARGE}
          internalRef={changeEmail}
          error={errors.password}
          defaultValue=""
        />
        <Button
          center="WITH_MARGIN"
          shape="ROUND"
          color="BLUE"
          height="LARGE"
          fontSize={16}
          weight="MEDIUM"
          iconRight={<IconCheckmarkThin className={styles.icon} />}
          iconHeight={15}
          gutter="LARGE"
          disabled={createEmailChangeLoading}
        >
          {t("Confirm my valid e-mail")}
        </Button>
      </Form>
      <p className={classNames(styles["guideline-text"], styles.discalaimer)}>
        {t(
          "In case you are facing difficulties with changing the email, please contact special customer support line for email change related issues at",
        )}{" "}
        <span className={styles["support-email"]}>customercare@sfsuite.com</span>
      </p>
    </Container>
  );
};
