import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../button/Button";
import { Checkbox } from "../checkbox/Checkbox";

import styles from "./cookie-consent-option.module.scss";

export interface CookieConsentOptionProps {
  checked: boolean;
  isMandatory: boolean;
  title: string;
  value: string;
  cookiesCount: number;
  onChange(isChecked: boolean): unknown;
  onShowDetails(): unknown;
  children: React.ReactChild;
}

export const CookieConsentOption: FC<CookieConsentOptionProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles["container"]}>
      <label className={styles["header"]}>
        <Checkbox
          checked={props.checked}
          onChange={(e) => props.onChange(e.currentTarget.checked)}
          disabled={props.isMandatory}
        />
        <div className={styles["header-section"]}>
          {props.title} {props.cookiesCount > 0 && `(${props.cookiesCount})`}
          <Button
            className={styles["show-details"]}
            onClick={(e) => {
              e.preventDefault();
              props.onShowDetails();
            }}
            kind="TEXT"
            height="AUTO"
          >
            {t("Details")}
          </Button>
        </div>
      </label>
      {props.children}
    </div>
  );
};
