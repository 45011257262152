import React, { ReactNode } from "react";
import classNames from "classnames";
import { Carousel as ReactCarousel } from "react-responsive-carousel";

import { IconArrow } from "../icon/IconArrow";
import { Button } from "../button/Button";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./carousel.module.scss";

export interface CarouselProps {
  className?: string;
  children?: ReactNode;
}

export const Carousel: React.FC<CarouselProps> = ({ className, children }) => {
  const count = React.Children.count(children);

  // seems that carousel wont update correctly if it has multiple items and then 1 item after update
  const carouselKey = count;

  return (
    <ReactCarousel
      showIndicators={count > 1}
      key={carouselKey}
      className={classNames(styles.carousel, { [styles["carousel--single-item"]]: count < 2 }, className)}
      renderArrowPrev={(clickHandler: () => void, hasPrev: boolean) =>
        hasPrev && (
          <Button className={classNames(styles.navigate, styles["navigate--prev"])} kind="TEXT" onClick={clickHandler}>
            <IconArrow fill="#7789ad" width={8} height={16} />
          </Button>
        )
      }
      renderArrowNext={(clickHandler: () => void, hasNext: boolean) =>
        hasNext && (
          <Button className={classNames(styles.navigate, styles["navigate--next"])} kind="TEXT" onClick={clickHandler}>
            <IconArrow fill="#7789ad" width={8} height={16} />
          </Button>
        )
      }
      renderThumbs={() => []}
    >
      {children as React.ReactChild[] /* seems to be issue of bad typings */}
    </ReactCarousel>
  );
};
