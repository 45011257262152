import React, { ReactNode } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";

import styles from "./checkbox.module.scss";

export enum CheckboxStyle {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
}

export enum FieldGutter {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
  LARGE = "LARGE",
}

export type InternalRefType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null;

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError;
  checkboxStyle?: keyof typeof CheckboxStyle;
  gutter?: keyof typeof FieldGutter | number;
  label?: string | ReactNode;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
  internalRef?: (ref: InternalRefType) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  error,
  checkboxStyle = "PRIMARY",
  gutter,
  label,
  defaultValue,
  checked,
  disabled,
  className,
  labelClassName,
  internalRef,
  ...rest
}) => {
  const customGutter = typeof gutter === "number" ? gutter : undefined;

  return (
    <div style={{ marginBottom: customGutter }} className={classNames((styles as any)[`gutter--${gutter}`], className)}>
      <label
        className={classNames(styles.label, (styles as any)[`label--${checkboxStyle}`], {
          [styles["label--tertiary-checked"]]: checkboxStyle === "TERTIARY" && checked,
          [styles["label--disabled"]]: disabled,
        })}
      >
        <input
          {...rest}
          type="checkbox"
          ref={internalRef}
          disabled={disabled}
          checked={checked}
          className={classNames(styles.checkbox, (styles as any)[`checkbox--${checkboxStyle}`])}
        />
        <span className={classNames(styles.checkmark, (styles as any)[`checkmark--${checkboxStyle}`])} />
        <span className={labelClassName}>{label}</span>
      </label>
      {error && <div className={styles["error-message"]}>{error.message}</div>}
    </div>
  );
};
