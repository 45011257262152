import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { makeVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { StripeElementStyle } from "@stripe/stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";

import {
  useStripeCreditCardFormSetupIntentMutation,
  useStripeCreditCardFormRegisterSourceMutation,
  useStripeCreditCardFormRemoveSourceMutation,
} from "../../graphql/schema";
import { Columns } from "../columns/Columns";
import Field, { FieldGutter } from "../field/Field";
import { Section, SectionGutter, SectionProps } from "../section/Section";
import { Button } from "../button/Button";

import styles from "./stripe-credit-card-form.module.scss";
export interface StripeCreditCardFormProps extends Pick<SectionProps, "withSpace"> {
  onCreditCardHolderNameChange(name: string): void;
  className?: string;
  onSubmitButtonClicked?(): Promise<void>;
  fieldColumnWidth?: number;
}

const stripeCartStyle: StripeElementStyle = {
  base: {
    iconColor: "#0062ff",
    color: "rgb(181, 181, 190)",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "16px",
    lineHeight: "50px",

    ":-webkit-autofill": {
      color: "#b5b5be",
    },
  },
  invalid: {
    color: "rgb(252, 90, 90)",
  },
};

export const StripeCreditCardForm: React.FC<StripeCreditCardFormProps> = ({
  onCreditCardHolderNameChange,
  withSpace = true,
  className,
  onSubmitButtonClicked,
  fieldColumnWidth = 240,
}) => {
  const [t] = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // reset all form errors on un-mount
  useEffect(
    () => () => {
      stripeFormErrorsVar(stripeFormErrorsDefaultValues);
      stripeFormFieldCompleteVar(stripeFormFieldCompleteValues);
    },
    [],
  );

  const hasError = Object.values(stripeFormErrorsVar()).some((v) => !!v);

  return (
    <Section
      gutter={SectionGutter.SEMI_LARGE}
      withSpace={withSpace}
      className={classNames(styles["field-wrap"], className)}
    >
      {hasError && <p className={styles.error}>{t("Please review Your card info")}</p>}

      <Field
        label={t("Credit Card No.")}
        type="custom"
        gutter={FieldGutter.MEDIUM}
        error={getFieldError("cardNo") || getFieldComplete(t, "cardNo")}
      >
        {({ onBlur, onChange, onFocus }) => (
          <CardNumberElement
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={(e) => {
              setFieldComplete("cardNo", e.complete);
              setFieldError("cardNo", undefined);
              onChange(!e.empty);
            }}
            options={{ style: stripeCartStyle, placeholder: " " }}
          />
        )}
      </Field>

      <Field
        label={t("Card holder full name")}
        gutter={FieldGutter.MEDIUM}
        error={getFieldError("holderName")}
        onChange={(e) => {
          setFieldError("holderName", undefined);
          onCreditCardHolderNameChange(e);
        }}
      />

      <Columns minWidth={fieldColumnWidth} gap={20}>
        <Field
          label={t("Valid thru (MM/YY)")}
          type="custom"
          error={getFieldError("cardExpiryDate") || getFieldComplete(t, "cardExpiryDate")}
        >
          {({ onBlur, onChange, onFocus }) => (
            <CardExpiryElement
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={(e) => {
                setFieldComplete("cardExpiryDate", e.complete);
                setFieldError("cardExpiryDate", undefined);
                onChange(!e.empty);
              }}
              options={{ style: stripeCartStyle, placeholder: " " }}
            />
          )}
        </Field>
        <Field label={t("CVC No.")} type="custom" error={getFieldError("cardCvc") || getFieldComplete(t, "cardCvc")}>
          {({ onBlur, onChange, onFocus }) => (
            <CardCvcElement
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={(e) => {
                setFieldComplete("cardCvc", e.complete);
                setFieldError("cardCvc", undefined);
                onChange(!e.empty);
              }}
              options={{ style: stripeCartStyle, placeholder: " " }}
            />
          )}
        </Field>
      </Columns>
      {onSubmitButtonClicked && (
        <Button
          className={styles["submit-button"]}
          shape="ROUND"
          color="LIGHT_BLUE"
          weight="MEDIUM"
          center="BLOCK_AND_MARGIN"
          onClick={() => {
            setIsSubmitting(true);
            onSubmitButtonClicked().finally(() => {
              setIsSubmitting(false);
            });
          }}
          disabled={isSubmitting}
        >
          {t("Save changes")}
        </Button>
      )}
    </Section>
  );
};

/**
 * This will update credit card info in server and in Stripe
 *
 * 1. create stripe card setup intent
 * 2. if intent was made, unlink current credit card
 * 3. link new card to the user
 */
export function useUpdateStripeCreditCardInfo() {
  const stripe = useStripe();
  const elements = useElements();
  const [t] = useTranslation();

  const [createSetupIntent] = useStripeCreditCardFormSetupIntentMutation();
  const [registerSource] = useStripeCreditCardFormRegisterSourceMutation();
  const [removePaymentSource] = useStripeCreditCardFormRemoveSourceMutation();

  async function createNewSetupIntent(creditCardHolderName: string) {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardNumberElement. Elements knows how
    // to find CardNumberElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      throw new Error("Card number element was not found");
    }

    // let server know we want to update stripe credit card info
    const { data } = await createSetupIntent();

    if (!data?.createStripeSetupIntent) {
      throw new Error("createStripeSetupIntent did not return any value");
    }

    // Update credit card info in stripe
    const { error, setupIntent } = await stripe.confirmCardSetup(data.createStripeSetupIntent, {
      payment_method: {
        card: cardNumberElement,
        billing_details: creditCardHolderName
          ? {
              name: creditCardHolderName,
            }
          : undefined,
      },
    });

    if (error) {
      // handle field errors
      // https://stripe.com/docs/error-codes
      switch (error.code) {
        case "incorrect_number":
        case "incomplete_number":
        case "invalid_number":
        case "expired_card":
        case "card_declined":
        case "card_decline_rate_limit_exceeded":
          setFieldError("cardNo", error.message);

          break;

        case "invalid_expiry_year":
        case "invalid_expiry_year_past":
        case "invalid_expiry_month":
        case "invalid_expiry_month_past":
        case "incomplete_expiry":
          setFieldError("cardExpiryDate", error.message);
          break;

        case "incomplete_cvc":
        case "incorrect_cvc":
          setFieldError("cardCvc", error.message);
          break;

        default:
          // throw general error
          console.warn(error);
          throw new Error(error.message);
      }

      throw new Error(t("Please review Your card info"));
    }

    if (!setupIntent?.client_secret) {
      throw new Error("Card setup failed, stripe api did not send setupIntent.client_secret info");
    }

    return setupIntent;
  }

  async function linkNewPaymentSource(setupIntentClientSecret: string) {
    // store source to the server
    const { data: paymentSourceData } = await registerSource({
      variables: { setupIntentClientSecret },
    });

    if (!paymentSourceData?.registerStripePaymentSource) {
      throw new Error("Card save failed. server did not send PaymentSource info");
    }

    return paymentSourceData.registerStripePaymentSource;
  }

  async function unlinkExistingPaymentSource(paymentSourceId: string) {
    const { errors } = await removePaymentSource({ variables: { paymentSourceId } });

    if (errors) {
      console.error("Removing payment source was unsuccessful", errors);
      throw new Error("Removing payment source was unsuccessful");
    }
  }

  return async (props: { creditCardHolderName: string; existingCreditCardId?: string }) => {
    if (!props.creditCardHolderName) {
      setFieldError("holderName", t("Card holder name must be defined"));

      throw new Error(t("Please review Your card info"));
    }
    // create new Stripe setup intent. This stores credit card data to Stripe so that server can later confirm, if it exists.
    // Expect this to fail if user inserts incorrect data
    const intent = await createNewSetupIntent(props.creditCardHolderName);

    if (!intent?.client_secret) {
      throw new Error("Linking credit card info failed. Intent info was not received");
    }

    // we have to unlink existing credit card if it exists.
    // NB! never do this before stripe setup intent, since stripe can fail due to bad user input etc.
    if (props.existingCreditCardId) {
      try {
        await unlinkExistingPaymentSource(props.existingCreditCardId);
      } catch (e) {
        // if unlinking failed, still try to link new credit card
      }
    }

    // store source to the server
    const cardInfo = await linkNewPaymentSource(intent.client_secret);

    return cardInfo.id;
  };
}

export function useStripeConfirmCardPayment() {
  const stripe = useStripe();
  const elements = useElements();
  const [t] = useTranslation();

  // Make request to stripe to confirm card payment
  return async (props: { paymentIntentClientSecret: string; creditCardHolderName?: string }) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardNumberElement. Elements knows how
    // to find CardNumberElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(CardNumberElement);

    // Update credit card info in stripe
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      props.paymentIntentClientSecret,
      cardNumberElement
        ? {
            payment_method: {
              card: cardNumberElement,
              billing_details: props.creditCardHolderName
                ? {
                    name: props.creditCardHolderName,
                  }
                : undefined,
            },
            setup_future_usage: "off_session",
          }
        : undefined,
    );

    if (error) {
      // Log stripe error to console
      console.error("Stripe returned error, Error: ", error.message);
      // handle field errors
      // https://stripe.com/docs/error-codes
      switch (error.decline_code) {
        case "fraudulent":
        case "merchant_blacklist":
        case "lost_card":
        case "stolen_card":
        case "call_issuer":
        case "do_not_try_again":
        case "generic_decline":
        case "invalid_account":
        case "new_account_information_available":
        case "no_action_taken":
        case "not_permitted":
        case "transaction_not_allowed":
        case "do_not_honor":
          throw new Error(t("The payment was declined by the bank. Please contact your bank for more information."));

        // Error messages that will receive proper messages later
        case "authentication_required":
          throw new Error(
            t(
              "Your payment was declined. Please try again and authenticate your card when prompted during the payment process.",
            ),
          );
        case "approve_with_id":
        case "issuer_not_available":
        case "try_again_later":
          throw new Error(
            t("Your payment was declined. Please try again and if the problem persists contact your bank."),
          );
        case "card_not_supported":
          throw new Error(
            t("Your card does not support this type of purchase. Please contact your bank for more information."),
          );
        case "card_velocity_exceeded":
          throw new Error(
            t("Your card balance or credit limit is exceeded. Please contact your bank for more information."),
          );
        case "currency_not_supported":
          throw new Error(
            t("Your card does not support this currency. Please contact your bank for more information."),
          );
        case "duplicate_transaction":
          throw new Error(
            t(
              "Your payment was declined as a transaction with identical amount and credit card information was submitted very recently. Please check if a recent payment already exists.",
            ),
          );
        case "expired_card":
          throw new Error(t("It seems your card has expired. Please user another card."));
        case "incorrect_pin":
          throw new Error(t("The card number is incorrect. Please check your card number information and try again."));
        case "incorrect_zip":
          throw new Error(t("The ZIP/postal code is incorrect. Please check your ZIP/postal code and try again."));
        case "insufficient_funds":
          throw new Error(t("The card has insufficient funds to complete the purchase."));
        case "invalid_amount":
          throw new Error(
            t(
              "Looks like your card has a lower limit than required for this payment. You might want to check with your bank and then retry.",
            ),
          );
        case "invalid_pin":
          throw new Error(t("The PIN entered is incorrect. Please try again using the correct PIN."));
        case "offline_pin_required":
          throw new Error(
            t(
              "Your payment was declined as it requires a PIN. Please try again by inserting your card and entering a PIN.",
            ),
          );
        case "online_or_offline_pin_required":
          throw new Error(
            t(
              "Your payment was declined as it requires a PIN. Please try again by inserting your card and entering a PIN.",
            ),
          );
        case "pickup_card":
          throw new Error(t("The card was declined by the bank. Please contact your bank for more information."));
        case "pin_try_exceeded":
          throw new Error(
            t("The card was declined by the bank. Please use another card or a different payment method."),
          );
        case "processing_error":
          throw new Error(
            t(
              "An error occurred when processing your payment. Please try again and if the problem persists contact your bank.",
            ),
          );
        case "reenter_transaction":
          throw new Error(
            t(
              "An error occurred when processing your payment. Please try again and if the problem persists contact your bank.",
            ),
          );
        case "restricted_card":
        case "revocation_of_all_authorizations":
        case "revocation_of_authorization":
        case "security_violation":
        case "service_not_allowed":
        case "stop_payment_order":
          throw new Error(t("The card was declined by the bank. Please contact your bank for more information."));
        case "testmode_decline":
          throw new Error(t("Test card was used for the payment. Please use a genuine card."));
        case "withdrawal_count_limit_exceeded":
          throw new Error(
            "Your card balance or credit limit is exceeded. Please contact your bank for more information.",
          );

        default:
          break;
      }

      switch (error.code) {
        case "incorrect_number":
        case "incomplete_number":
        case "invalid_number":
        case "card_declined":
        case "card_decline_rate_limit_exceeded":
          setFieldError("cardNo", error.message);

          break;

        case "invalid_expiry_year":
        case "invalid_expiry_year_past":
        case "invalid_expiry_month":
        case "invalid_expiry_month_past":
        case "incomplete_expiry":
          setFieldError("cardExpiryDate", error.message);
          break;

        case "incomplete_cvc":
        case "incorrect_cvc":
        case "invalid_cvc":
          setFieldError("cardCvc", error.message);
          break;

        default:
          // throw general error
          console.warn(error);
          throw new Error(error.message);
      }

      throw new Error(t("Please review Your card info"));
    }

    return paymentIntent;
  };
}

interface StripeFormErrorsVar {
  cardNo?: string;
  cardExpiryDate?: string;
  cardCvc?: string;
  holderName?: string;
}
interface StripeFormFieldCompleteVar {
  cardNo?: boolean;
  cardExpiryDate?: boolean;
  cardCvc?: boolean;
}

const stripeFormErrorsDefaultValues: StripeFormErrorsVar = {
  cardNo: undefined,
  cardExpiryDate: undefined,
  cardCvc: undefined,
  holderName: undefined,
};

const stripeFormFieldCompleteValues: StripeFormFieldCompleteVar = {
  cardNo: undefined,
  cardExpiryDate: undefined,
  cardCvc: undefined,
};

const stripeFormErrorsVar = makeVar<StripeFormErrorsVar>(stripeFormErrorsDefaultValues);
const stripeFormFieldCompleteVar = makeVar<StripeFormFieldCompleteVar>(stripeFormFieldCompleteValues);

function getFieldError(fieldName: keyof NonNullable<StripeFormErrorsVar>) {
  const message = stripeFormErrorsVar()[fieldName];

  if (!message) {
    return undefined;
  }

  return { type: "error", message, isManual: true };
}

function setFieldError(fieldName: keyof NonNullable<StripeFormErrorsVar>, message?: string) {
  stripeFormErrorsVar({ ...stripeFormErrorsVar(), [fieldName]: message });
}

function getFieldComplete(t: (e: string) => string, fieldName: keyof NonNullable<StripeFormFieldCompleteVar>) {
  const res = stripeFormFieldCompleteVar()[fieldName];

  if (res === false) {
    return { type: "error", message: t("Field is incomplete or not correct"), isManual: true };
  }

  return undefined;
}

function setFieldComplete(fieldName: keyof NonNullable<StripeFormFieldCompleteVar>, value: boolean) {
  stripeFormFieldCompleteVar({ ...stripeFormFieldCompleteVar(), [fieldName]: value });
}

export function useAddNewCardInfo() {
  const stripe = useStripe();
  const elements = useElements();

  return async (props: { stripeSetupIntent?: string; creditCardHolderName?: string }) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (!props.stripeSetupIntent) {
      console.error("Stripe setup intent was missing");
      throw new Error("Could not add card");
    }

    // Get a reference to a mounted CardNumberElement. Elements knows how
    // to find CardNumberElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(CardNumberElement);

    try {
      await stripe.confirmCardSetup(
        props.stripeSetupIntent,
        cardNumberElement
          ? {
              payment_method: {
                card: cardNumberElement,
                billing_details: props.creditCardHolderName
                  ? {
                      name: props.creditCardHolderName,
                    }
                  : undefined,
              },
            }
          : undefined,
      );

      return true;
    } catch (err) {
      return false;
    }
  };
}
