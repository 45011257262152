import React, { ReactNode } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { IconLightbulb } from "../icon/IconLightbulb";
import { Carousel } from "../carousel/Carousel";

import styles from "./frequently-bought.module.scss";

export interface FrequentlyBoughtProps {
  productName?: string;
  gutter?: number;
  children?: ReactNode;
}

export const FrequentlyBought: React.FC<FrequentlyBoughtProps> = ({ productName, gutter = 70, children }) => {
  const [t] = useTranslation();

  // count how many children in carousel
  const count = React.Children.count(children);

  return (
    <div
      style={{ marginBottom: gutter }}
      className={classNames(styles.wrap, { [styles["wrap--single-child"]]: count < 2 })}
    >
      <IconLightbulb className={styles.icon} />
      <div className={styles.title}>
        {productName
          ? t("Frequently bought together with {{productName}}", { productName })
          : t("Frequently bought together")}
      </div>
      <Carousel className={styles.carousel}>{children}</Carousel>
    </div>
  );
};
