import React from "react";

export interface IconEmailErrorProps {
  hasTitle?: boolean;
  width?: number;
  height?: number;
  className?: string;
}

export const IconEmailError: React.FC<IconEmailErrorProps> = ({ hasTitle, width = 46, height = 40, className }) => {
  return (
    <svg
      className={className}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 46 40"
      width={width}
      height={height}
    >
      {hasTitle && <title>IconEmailError</title>}
      <path
        fill="#fc5a5a"
        id="email-error"
        d="M2.34,7.39Q2,11.32,2,16c0,3.21.12,6.15.36,8.84A5,5,0,0,0,7,29.38Q15,30,21,30a1,1,0,0,1,1,1,1,1,0,0,1-1,1c-4.06,0-8.78-.24-14.19-.62A7,7,0,0,1,.36,25C.12,22.27,0,19.26,0,16A95.63,95.63,0,0,1,.55,5.17,5,5,0,0,1,5.14.75Q15,0,22,0T38.86.75a5,5,0,0,1,4.59,4.42A95.63,95.63,0,0,1,44,16v1.1a1,1,0,0,1-1,1,1,1,0,0,1-1-1V16q0-4.68-.34-8.61a4,4,0,0,1-.81.58l-16.4,8.85a4.83,4.83,0,0,1-2.45.6,4.83,4.83,0,0,1-2.45-.6L3.15,8a4,4,0,0,1-.81-.58ZM3.49,3.52l-.08.12s-.07.11-.06.08A1.84,1.84,0,0,0,4.1,6.21l16.4,8.85a2.85,2.85,0,0,0,1.46.36H22a2.85,2.85,0,0,0,1.46-.36L39.9,6.21a1.84,1.84,0,0,0,.75-2.49s0,0-.06-.08l-.08-.12a3,3,0,0,0-1.8-.78Q28.9,2,22,2T5.29,2.74a3,3,0,0,0-1.8.78ZM26,29a9,9,0,1,0,9-9A9,9,0,0,0,26,29Zm-2,0A11,11,0,1,1,35,40,11,11,0,0,1,24,29Zm18.71-6.29-14,14a1,1,0,0,1-1.42-1.42l14-14A1,1,0,0,1,42.71,22.71Z"
      />
    </svg>
  );
};
