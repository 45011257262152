import React, { ReactNode, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import assertNever from "../../services/assertNever";
import { Routes } from "../../services/constants";
import { IconShoppingCart2 } from "../icon/IconShoppingCart2";
import { Button } from "../button/Button";
import { Link, LinkProps } from "../link/Link";
import { Loader, LoaderColor, LoaderSize } from "../loader/Loader";
import { Color, Shape } from "../../services/buttonLinkConstants";
import {
  ServiceOfferAvailability,
  ServiceProductCode,
  useAddToCartButtonQuery,
  UserConsentCode,
  useStoreUserConsentMutation,
} from "../../graphql/schema";
import { ForexConsentModal } from "../forex-consent-modal/ForexConsentModal";
import { TheViewConsent } from "../the-view-consent/TheViewConsent";

import styles from "./add-to-cart-button.module.scss";

export enum AddToCartButtonKind {
  ORANGE = "ORANGE",
  YELLOW = "YELLOW",
  DARK_BLUE = "DARK_BLUE",
  DARK_BLUE_2 = "DARK_BLUE_2",
  YELLOW_ROUND = "YELLOW_ROUND",
  BLUE = "BLUE",
}

export enum Breakpoint {
  MOBILE = "MOBILE",
  TABLET_PORTRAIT_UP = "TABLET_PORTRAIT_UP",
  TABLET_LANDSCAPE_UP = "TABLET_LANDSCAPE_UP",
  DESKTOP_UP = "DESKTOP_UP",
  TABLET_PORTRAIT_MAX = "TABLET_PORTRAIT_MAX",
  TABLET_LANDSCAPE_MAX = "TABLET_LANDSCAPE_MAX",
}

export enum AddToCartButtonIconGutter {
  SMALL = 10,
  SEMI_MEDIUM = 15,
  MEDIUM = 20,
}

export enum AddToCartButtonHeight {
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export interface AddToCartButtonStyleProps {
  iconGutter?: AddToCartButtonIconGutter;
  height?: keyof typeof AddToCartButtonHeight;
  stretch?: keyof typeof Breakpoint;
  hideCartIcon?: boolean;
  labelClassName?: string;
  iconSize?: number;
  width?: number;
  children?: ReactNode;
}

export interface AddToCartButtonProps extends AddToCartButtonStyleProps {
  productAccessPath?: string;
  externalUrl?: string | false;
  onAddToCartClick: () => Promise<any>;
  availability: keyof typeof ServiceOfferAvailability | null | undefined;
  kind?: keyof typeof AddToCartButtonKind;
  serviceCode?: keyof typeof ServiceProductCode;
  className?: string;
  onHandleUpgradeModal?: () => void;
}

export const AddToCartButton: React.FC<AddToCartButtonProps> = ({
  onAddToCartClick,
  kind = "ORANGE",
  serviceCode,
  availability,
  productAccessPath,
  className,
  iconGutter = AddToCartButtonIconGutter.MEDIUM,
  height = "MEDIUM",
  stretch,
  hideCartIcon,
  labelClassName,
  iconSize = 21,
  onHandleUpgradeModal,
  width,
  children,
  ...props
}) => {
  const [t] = useTranslation();
  const { push } = useHistory();

  // control Forex consent modal visibility
  const [isForexTermsVisible, setIsForexTermsVisible] = useState(false);

  // control The View consent modal visibility
  const [isTheViewTermsVisible, setIsTheViewTermsVisible] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const query = useAddToCartButtonQuery();

  // check consents for accessing product
  const consents = query.data?.me.consents;

  const detectProduct = (consentCode: UserConsentCode) => consents?.find((e) => e.code === consentCode);

  const isForexAccessGranted =
    detectProduct(UserConsentCode.FXPP)?.granted && detectProduct(UserConsentCode.FXTOU)?.granted;

  const isTheViewAccessGranted = query.data?.me.consents?.find((e) => e.code === UserConsentCode.TVTOUC)?.granted;

  const [storeUserConsent] = useStoreUserConsentMutation();

  // handle The View consents
  const handleUpdateTheViewConsents = async (code: UserConsentCode, granted: boolean) => {
    if (!query.data?.me.theiaAccess) {
      console.error(t("Theia Access missing"));
      toast.error(t("Could not open The view. Please try again later"));
      throw new Error(t("Could not open The view"));
    }

    await storeUserConsent({ variables: { code, granted } });

    if (granted) {
      window.open(query.data.me.theiaAccess, "_blank");
      await query.refetch();
      setIsTheViewTermsVisible(false);
    } else {
      push(Routes.LOGOUT);
    }
  };

  // handle Forex consents
  const handleUpdateForexConsents = async (code: UserConsentCode, granted: boolean) => {
    if (!query.data?.me.forexInsidersAccess) {
      console.error(t("Forex Insiders access missing"));
      toast.error(t("Could not open Forex. Please try again later"));
      throw new Error(t("Could not open Forex"));
    }

    await storeUserConsent({ variables: { code, granted } });

    if (granted) {
      window.open(query.data.me.forexInsidersAccess, "_blank");
      await query.refetch();
      setIsForexTermsVisible(false);
    } else {
      push(Routes.LOGOUT);
    }
  };

  // check if consent should be asked to access product
  const askConsent = (code: keyof typeof ServiceProductCode) => {
    switch (code) {
      case "FOREX":
      case "FOREX_PRO": {
        return !isForexAccessGranted;
      }

      case "VIEW_BASIC":
      case "VIEW_STANDARD":
      case "VIEW_SUPER":
      case "VIEW_ULTRA":
        return !isTheViewAccessGranted;

      default:
        return false;
    }
  };

  // control consent modal visibility
  const openConsentModal = (code: keyof typeof ServiceProductCode) => {
    switch (code) {
      case "FOREX":
      case "FOREX_PRO": {
        return setIsForexTermsVisible(!isForexAccessGranted);
      }

      case "VIEW_BASIC":
      case "VIEW_STANDARD":
      case "VIEW_SUPER":
      case "VIEW_ULTRA": {
        return setIsTheViewTermsVisible(!isTheViewTermsVisible);
      }

      default:
        return;
    }
  };

  const getAccessPathUrl = (code: string, defaultUrl: string) => {
    switch (code) {
      case "FOREX":
      case "FOREX_PRO": {
        return query.data?.me?.forexInsidersAccess ?? defaultUrl;
      }

      case "MEMBERSHIP_BASIC":
      case "MEMBERSHIP_PRO": {
        return query.data?.me?.membershipAccess ?? defaultUrl;
      }

      default:
        return defaultUrl;
    }
  };

  // button height classNames
  const heightClassNames = classNames({
    [styles["button--height-medium"]]: height === "MEDIUM",
    [styles["button--height-large"]]: height === "LARGE",
  });

  const combinedClassName = classNames(
    styles.button,
    heightClassNames,
    (styles as any)[`button--${kind}`],
    (styles as any)[`stretch--${stretch}`],
    {
      [styles["button--disabled"]]: isDisabled,
    },
    className,
  );

  // get button color and shape based on button kind
  const getButtonKind = (kind: keyof typeof AddToCartButtonKind) => {
    switch (kind) {
      case "BLUE":
        return { color: Color.BLUE };
      case "DARK_BLUE":
        return { color: Color.DARK_BLUE };
      case "DARK_BLUE_2":
        return { color: Color.DARK_BLUE_2, shape: Shape.ROUND };
      case "ORANGE":
        return { color: Color.ORANGE };
      case "YELLOW":
        return { color: Color.YELLOW };
      case "YELLOW_ROUND":
        return { color: Color.YELLOW, shape: Shape.ROUND };
      default:
        return assertNever(kind);
    }
  };

  const getLoaderColor = (kind: keyof typeof AddToCartButtonKind) => {
    switch (kind) {
      case "BLUE":
        return LoaderColor.WHITE;
      case "DARK_BLUE":
        return LoaderColor.ORANGE;
      case "DARK_BLUE_2":
        return LoaderColor.ORANGE;
      case "ORANGE":
        return LoaderColor.WHITE;
      case "YELLOW":
        return LoaderColor.WHITE;
      case "YELLOW_ROUND":
        return LoaderColor.WHITE;
      default:
        return assertNever(kind);
    }
  };

  switch (availability) {
    case "UNAVAILABLE_ALREADY_SUBSCRIBED":
      return productAccessPath ? (
        <>
          {serviceCode && askConsent(serviceCode) ? (
            <Button
              className={combinedClassName}
              borderRadius="SMALL"
              color={getButtonKind(kind).color}
              shape={getButtonKind(kind).shape}
              fontSize={18}
              onClick={() => openConsentModal(serviceCode)}
            >
              <span className={labelClassName}>{t("Access product")}</span>
            </Button>
          ) : props.externalUrl === false ? null : (
            <Link
              className={combinedClassName}
              to={getAccessPathUrl(serviceCode ?? "", productAccessPath)}
              color={getButtonKind(kind).color}
              shape={getButtonKind(kind).shape}
              borderRadius="SMALL"
              fontSize={18}
              externalUrl={getAccessPathUrl(serviceCode ?? "", props.externalUrl ?? "")}
            >
              <span className={labelClassName}>{t("Access product")}</span>
            </Link>
          )}

          {consents && (
            <>
              <ForexConsentModal
                consents={consents}
                isVisible={isForexTermsVisible}
                onAccept={(code) => handleUpdateForexConsents(code, true)}
                onReject={(code) => handleUpdateForexConsents(code, false)}
                close={() => setIsForexTermsVisible(false)}
              />
              <TheViewConsent
                consents={consents}
                isVisible={isTheViewTermsVisible}
                onAccept={(code) => handleUpdateTheViewConsents(code, true)}
                onReject={(code) => handleUpdateTheViewConsents(code, false)}
                close={() => setIsTheViewTermsVisible(false)}
              />
            </>
          )}
        </>
      ) : null;

    case "UNAVAILABLE_ALREADY_IN_CART":
      return null;
    //   return (
    //     <Link
    //       className={combinedClassName}
    //       to={Routes.CHECKOUT_CART}
    //       color={getButtonKind(kind).color}
    //       shape={getButtonKind(kind).shape}
    //       borderRadius="SMALL"
    //     >
    //       <span className={labelClassName}>{t("Go to cart")}</span>
    //     </Link>
    //   );

    case "AVAILABLE_FOR_PURCHASE":
      return null;
    //   return (
    //     <Link
    //       style={{ padding: iconGutter && `5px ${iconGutter}px`, width: width }}
    //       className={classNames(combinedClassName, styles["button--has-loader"])}
    //       to={Routes.CHECKOUT_CART}
    //       color={getButtonKind(kind).color}
    //       shape={getButtonKind(kind).shape}
    //       borderRadius="SMALL"
    //       onClick={async (e) => {
    //         if (isDisabled) {
    //           return;
    //         } else {
    //           setIsDisabled(true);
    //         }

    //         e.preventDefault();
    //         onAddToCartClick()
    //           .then(() => {
    //             push(Routes.CHECKOUT_CART);
    //           })
    //           .catch((e) => {
    //             console.error("adding to cart failed", e);
    //           })
    //           .finally(() => {
    //             setIsDisabled(false);
    //           });
    //       }}
    //     >
    //       <Loader size={LoaderSize.SMALL} color={getLoaderColor(kind)} visible={isDisabled} />

    //       {children ? (
    //         children
    //       ) : (
    //         <span
    //           style={{ padding: hideCartIcon ? 0 : `0 calc(${iconGutter}px + ${iconSize}px)` }}
    //           className={classNames(styles["button-label"], labelClassName)}
    //         >
    //           {t("Add to cart")}

    //           {!hideCartIcon && (
    //             <IconShoppingCart2
    //               width={iconSize}
    //               height={iconSize}
    //               className={classNames(styles["icon-cart"], (styles as any)[`icon-cart--${kind}`])}
    //             />
    //           )}
    //         </span>
    //       )}
    //     </Link>
    //   );

    case "AVAILABLE_FOR_UPGRADE":
      return null;
    //   return (
    //     <Button
    //       onClick={onHandleUpgradeModal}
    //       className={combinedClassName}
    //       color={getButtonKind(kind).color}
    //       borderRadius="SMALL"
    //     >
    //       <span className={labelClassName}>{t("Upgrade")}</span>
    //     </Button>
    //   );

    case "UNAVAILABLE_FOR_COUNTRY":
    case "UNAVAILABLE_FOR_UPGRADE":
      return props.externalUrl === false ? null : (
        <Button disabled className={combinedClassName} borderRadius="SMALL">
          <span className={labelClassName}>{t("Unavailable")}</span>
        </Button>
      );

    case null:
    case undefined:
      return null;

    default:
      return assertNever(availability);
  }
};
