import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../components/button/Button";
import { Heading } from "../../../components/heading/Heading";
import RegistrationForm from "../../../components/registration-form/RegistrationForm";
import { Section, SectionGutter } from "../../../components/section/Section";

import styles from "./register-account-section.module.scss";

interface RegisterAccountSectionProps {
  toggleShowLogin: () => void;
  affiliateId?: string;
}

export const RegisterAccountSection: React.FC<RegisterAccountSectionProps> = ({ toggleShowLogin, affiliateId }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.header}>
        <Heading className={styles.title} thin level={3} center>
          {t("Let’s set up an account for you")}
        </Heading>
        <div className={styles.label}>
          {t("Already have an account?")}
          <Button
            className={styles.link}
            kind="TEXT"
            height="AUTO"
            color="YELLOW"
            weight="SEMI_BOLD"
            onClick={() => toggleShowLogin()}
          >
            {t("Login")}
          </Button>
        </div>
      </div>

      <Section gutter={SectionGutter.EXTRA_LARGE} withSpace className={styles["field-wrap"]}>
        <RegistrationForm
          layout="SECONDARY"
          affiliateId={affiliateId}
          onAccountRegistrationComplete={toggleShowLogin}
        />
      </Section>
    </>
  );
};
