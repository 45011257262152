import classNames from "classnames";

import { InternalRefType } from "../field/Field";

import styles from "./quantity.module.scss";

export interface QuantityProps {
  name: string;
  internalRef?: (ref: InternalRefType) => void;
  amount: number;
  className?: string;
  onUpdate: (newQuantity: number) => void;
  isVertical?: boolean;
  disabled?: boolean;
}

const Quantity = ({ internalRef, name, amount, className, onUpdate, isVertical = false, disabled }: QuantityProps) => {
  const increment = (e: any) => {
    e.preventDefault();

    if (disabled) {
      return;
    }

    onUpdate(amount + 1);
  };

  const decrement = (e: any) => {
    e.preventDefault();
    if (disabled) {
      return;
    }

    onUpdate(amount - 1);
  };

  return (
    <>
      <div
        className={classNames(
          styles.quantity,
          {
            [styles["quantity--vertical"]]: isVertical,
            [styles["quantity--horizontal"]]: !isVertical,
            [styles["quantity--disabled"]]: disabled,
          },
          className,
        )}
      >
        {isVertical ? <button onClick={increment}>+</button> : <button onClick={decrement}>-</button>}
        <input
          className={classNames(styles.input, { [styles["input--vertical"]]: isVertical })}
          ref={internalRef}
          name={name}
          type="text"
          value={amount}
          readOnly
          size={3}
        />
        {isVertical ? <button onClick={decrement}>-</button> : <button onClick={increment}>+</button>}
      </div>
    </>
  );
};

export default Quantity;
