import React, { ReactNode, useRef, useState } from "react";
import { usePopper } from "react-popper";
import classNames from "classnames";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import styles from "./tooltip.module.scss";

export interface TooltipProps {
  reference?: React.ReactNode;
  openOnHover?: boolean;
  disabled?: boolean;
  className?: string;
  arrowClassName?: string;
  children?: ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  reference,
  openOnHover,
  className,
  arrowClassName,
  disabled,
}) => {
  // react-popper states and hooks
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles: reactPopperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
    placement: "top",
  });

  // ref to handle closing tooltip on click outside
  const wrapRef = useRef<HTMLDivElement>(null);

  // set tooltip visibility state
  const [isOpened, setIsOpened] = useState(false);

  // close tooltip when clicked outsite
  useOnClickOutside(wrapRef, () => {
    setIsOpened(false);
  });

  return (
    <>
      <div ref={wrapRef}>
        <div
          ref={setReferenceElement}
          onMouseEnter={!disabled && openOnHover ? () => setIsOpened(true) : undefined}
          onMouseLeave={!disabled && openOnHover ? () => setIsOpened(false) : undefined}
          onClick={!disabled && !openOnHover ? () => setIsOpened(true) : undefined}
        >
          {reference}
        </div>
      </div>

      <div
        ref={setPopperElement}
        style={reactPopperStyles.popper}
        className={classNames(styles.content, { [styles.opened]: isOpened }, className)}
        {...attributes.popper}
      >
        {children}
        <div
          style={reactPopperStyles.arrow}
          className={classNames(styles.arrow, arrowClassName)}
          ref={setArrowElement}
        />
      </div>
    </>
  );
};
