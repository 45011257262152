import React from "react";

export interface IconCloudProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconCloud: React.FC<IconCloudProps> = ({
  hasTitle,
  className,
  fill = "#fff",
  width = 24,
  height = 20,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 20"
    >
      {hasTitle && <title>IconCloud</title>}
      <path
        fill={fill}
        id="Cloud-Download"
        d="M12.5,18.81l0,0,3.62-3.61a.48.48,0,0,1,.7,0,.5.5,0,0,1,0,.71l-3.61,3.61a1.76,1.76,0,0,1-2.48,0L7.15,15.87a.5.5,0,0,1,0-.71.48.48,0,0,1,.7,0l3.62,3.62,0,0V9.51a.5.5,0,1,1,1,0v9.3ZM18.5,5A5.51,5.51,0,0,1,24,10.51v.33a6,6,0,0,1-5.44,6,.5.5,0,0,1-.55-.45.51.51,0,0,1,.45-.55,5,5,0,0,0,4.54-5v-.33A4.49,4.49,0,0,0,18.5,6a4.34,4.34,0,0,0-1,.1.5.5,0,0,1-.6-.42A5.5,5.5,0,0,0,6,6.53a.5.5,0,0,1-.55.5A3.39,3.39,0,0,0,5,7a4,4,0,0,0-4,4,4.82,4.82,0,0,0,4.58,4.82.49.49,0,0,1,.47.52.5.5,0,0,1-.52.48A5.83,5.83,0,0,1,0,11,5,5,0,0,1,5,6,6.51,6.51,0,0,1,17.84,5C18.05,5,18.28,5,18.5,5Z"
      />
    </svg>
  );
};
