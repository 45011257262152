import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ApolloError } from "@apollo/client";

import { Container } from "../container/Container";
import { Panel } from "../panel/Panel";
import {
  useCountryQuery,
  useRegisterShopUserMutation,
  useValidateAffiliateMutationMutation,
} from "../../graphql/schema";
import {
  digitsRegexp,
  luckyLoreRegistrationId,
  Routes,
  spaceRegexp,
  gsmRegexp,
  USCountryCodes,
} from "../../services/constants";
import { formatISODate } from "../../services/formatDate";
import { handleFormErrors } from "../../services/handleFormErrors";
import NotFoundView from "../../views/not-found-view/NotFoundView";
import RegistrationFormBase, { RegistrationFormBaseProps } from "../registrationFormBase/RegistrationFormBase";
import { tracker } from "../../libs/trackers";

export interface RegistrationFormProps extends RegistrationFormBaseProps {
  affiliateId?: string;
  onAccountRegistrationComplete?: () => void;
}

interface RouteParams {
  id?: string;
}

const maxBirthdayDate = new Date();
maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);

// const registerUserSchema = yup.object().shape({
//   email: yup
//     .string()
//     .required("This field is required")
//     .email("Not a valid email")
//     .test("is-gsm", "Should only contain latin chars", (value) => gsmRegexp.test(value)),
//   password: yup
//     .string()
//     .min(8, "Password should be at least 8 char long")
//     .max(60, "Password should be not longer than 60 chars")
//     .test("has-uppercase", "Password should have uppercase letters", (value) => value.toLowerCase() !== value)
//     .test("has-digits", "Password should have at least one digit", (value) => digitsRegexp.test(value))
//     .test("has-space", "Password should have no space", (value) => !spaceRegexp.test(value))
//     .required("This field is required"),
//   firstName: yup.string().required("This field is required"),
//   lastName: yup.string().required("This field is required"),
//   countryId: yup.string().required("This field is required"),
//   termsAndConditions: yup
//     .boolean()
//     .oneOf([true], "Please agree to the Privacy Policy")
//     .required("Please agree to the Privacy Policy"),
// });

export type RegisterUserFormValues = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  countryId: string;
  state: string;
  city: string;
  postcode: string;
  address: string;
  dateOfBirth: string;
  termsAndConditions: boolean;
};

export default function RegistrationForm(props: RegistrationFormProps) {
  const [t] = useTranslation();

  const registerUserSchema = yup.object().shape({
    email: yup
      .string()
      .required(t("This field is required"))
      .email(t("Not a valid email"))
      .test("is-gsm", t("Should only contain latin chars"), (value) => gsmRegexp.test(value || "")),
    password: yup
      .string()
      .min(8, t("Password should be at least 8 char long"))
      .max(60, t("Password should be not longer than 60 chars"))
      .test("has-uppercase", t("Password should have uppercase letters"), (value) => value?.toLowerCase() !== value)
      .test("has-digits", t("Password should have at least one digit"), (value) => digitsRegexp.test(value || ""))
      .test("has-space", t("Password should have no space"), (value) => !spaceRegexp.test(value || ""))
      .required(t("This field is required")),
    firstName: yup
      .string()
      .trim()
      .required(t("This field is required"))
      .test("is-name", t("Should only contain latin chars"), validateIsName),
    lastName: yup.string().trim().test("is-name", t("Should only contain latin chars"), validateIsName),
    countryId: yup.string().required(t("This field is required")),
    state: yup
      .string()
      .trim()
      .when("countryId", (countryId, schema) =>
        usCountryIds.includes(Number(countryId)) ? schema.required(t("State is missing")) : schema,
      ),
    city: yup
      .string()
      .trim()
      .when("countryId", (countryId, schema) =>
        usCountryIds.includes(Number(countryId)) ? schema.required(t("City is missing")).min(2).max(50) : schema,
      ),
    postcode: yup
      .string()
      .trim()
      .when("countryId", (countryId, schema) =>
        usCountryIds.includes(Number(countryId)) ? schema.required(t("Postcode is missing")).min(5).max(11) : schema,
      ),
    address: yup
      .string()
      .trim()
      .when("countryId", (countryId, schema) =>
        usCountryIds.includes(Number(countryId))
          ? schema.required(t("Address details are missing")).min(2).max(75)
          : schema,
      ),
    termsAndConditions: yup
      .boolean()
      .oneOf([true], t("Please agree to the Privacy Policy"))
      .required(t("Please agree to the Privacy Policy")),
  });

  const { params } = useRouteMatch<RouteParams>();
  const { push } = useHistory();

  // datepicker date
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [hasEightChars, setHasEightChars] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [userPasswd, setUserPasswd] = useState("");
  const {
    register,
    handleSubmit,
    errors: userInputErrors,
    setError,
  } = useForm<RegisterUserFormValues>({
    resolver: yupResolver(registerUserSchema),
    defaultValues: { termsAndConditions: false },
    reValidateMode: "onSubmit",
  });
  const [registerMutation, { loading: registerUserLoading }] = useRegisterShopUserMutation();
  const [formError] = React.useState<string>("");

  const affiliateId = params.id || props.affiliateId || "";

  const [validateAffiliate, { data: validateAffiliateData }] = useValidateAffiliateMutationMutation({
    fetchPolicy: "no-cache",
    variables: { affiliateId },
  });

  useEffect(() => {
    if (userPasswd.length >= 8) {
      setHasEightChars(true);
    } else {
      setHasEightChars(false);
    }

    if (/[a-z]+/.test(userPasswd)) {
      setHasLowerCase(true);
    } else {
      setHasLowerCase(false);
    }

    if (/[A-Z]+/.test(userPasswd)) {
      setHasUpperCase(true);
    } else {
      setHasUpperCase(false);
    }

    if (/\d+/.test(userPasswd)) {
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }
  }, [userPasswd]);

  const handleRegisterUser = handleSubmit(
    async ({ email, password, firstName, lastName, termsAndConditions, countryId, state, city, address, postcode }) => {
      if (!selectedDate) {
        setError("dateOfBirth", { type: "notMatch", message: "Date of birth is required" });

        return;
      }

      try {
        const result = await registerMutation({
          variables: {
            email,
            password,
            affiliateId,
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: selectedDate ? formatISODate(selectedDate, "YYYY-MM-DD") : "",
            countryId: parseInt(countryId.toString(), 10),
            state,
            city,
            address,
            postcode,
            termsAndConditions,
          },
          refetchQueries: "active",
          awaitRefetchQueries: true,
        });

        if (result) {
          tracker.trackEvent("registerUser", { firstName, lastName, affiliateId, email, country: countryId });

          if (props.onAccountRegistrationComplete) {
            props.onAccountRegistrationComplete();
          } else {
            push(Routes.LOGIN);
          }

          toast.success("Registration successful");
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          handleFormErrors({ setError, error, t });
        }
        return;
      }
    },
  );

  useEffect(() => {
    if (affiliateId) {
      validateAffiliate();
    }
  }, [affiliateId, validateAffiliate]);

  const { data: countryData } = useCountryQuery();

  if (affiliateId === luckyLoreRegistrationId) {
    return (
      <Container secondary>
        <Panel title={t("Welcome!")}>
          Download app and restart the app after downloading. If you are logged in then please log out.
        </Panel>
      </Container>
    );
  }

  if (validateAffiliateData?.validateAffiliate?.result.type === "VALIDATION_ERROR") {
    return <NotFoundView />;
  }

  const countries = countryData?.country.countries ?? [];
  const usCountryIds = countries.filter((c) => c.code && USCountryCodes.includes(c.code)).map((c) => c.id);

  return (
    <RegistrationFormBase
      countries={countries}
      formError={formError}
      handleRegisterUser={handleRegisterUser}
      passwordTipValues={{ hasEightChars, hasLowerCase, hasNumber, hasUpperCase }}
      register={register}
      registerUserLoading={registerUserLoading}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      setUserPasswd={setUserPasswd}
      userInputErrors={userInputErrors}
      className={props.className}
      agreementMessage={props.agreementMessage}
      agreementMessageClassName={props.agreementMessageClassName}
      submitButtonClassName={props.submitButtonClassName}
      submitButtonText={props.submitButtonText}
      onClick={props.onClick}
      buttonType={props.buttonType}
      layout={props.layout}
    />
  );
}
function validateIsName(value: unknown) {
  if (!value) {
    return true;
  }

  const isNameRegexp = /^[A-Za-z\-\s]+$/;
  return typeof value === "string" && isNameRegexp.test(value.trim());
}
