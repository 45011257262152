import { FC, ReactNode } from "react";

import { IconCheckAll } from "../icon/IconCheckAll";

import styles from "./checklist-item.module.scss";

export interface ChecklistItemProps {
  children?: ReactNode;
}

export const ChecklistItem: FC<ChecklistItemProps> = ({ children }) => {
  return (
    <li className={styles["list-item"]}>
      <IconCheckAll className={styles["checkmark-icon"]} />
      {children}
    </li>
  );
};
