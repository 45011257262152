import { TypedTypePolicies } from "../graphql/apolloHelpers";

export const typePolicies: TypedTypePolicies = {
  Me: {
    keyFields: [], // this is a singleton type
    fields: {
      info: {
        merge: true,
      },
      serviceOfferRecommendations: {
        merge: false,
      },
      personalServiceOffers: {
        merge: false,
      },
      serviceSubscriptionInvoices: {
        keyArgs: false,
      },
    },
  },
};
