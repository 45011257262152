import React from "react";

export interface IconLightbulbProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconLightbulb: React.FC<IconLightbulbProps> = ({
  hasTitle,
  className,
  fill = "#ff871c",
  width = 20,
  height = 30,
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 30"
  >
    {hasTitle && <title>IconLightbulb</title>}
    <path
      fill={fill}
      id="Lightbulb"
      d="M6,23.66c-1.09-.72-1.72-1.92-2.28-3.75-.14-.45-.15-.48-.2-.66a7.21,7.21,0,0,0-.89-2A13.3,13.3,0,0,1,0,10.06a10,10,0,1,1,20,0,13.41,13.41,0,0,1-2.63,7.24,6.92,6.92,0,0,0-.89,2c-.06.18-.07.21-.21.66-.56,1.82-1.19,3-2.27,3.75v2.88a3.38,3.38,0,0,1-2.92,3.38,8.37,8.37,0,0,1-2.16,0A3.38,3.38,0,0,1,6,26.55Zm4,.88a11,11,0,0,1-2.67-.33v2.34a2,2,0,0,0,1.75,2,7.08,7.08,0,0,0,1.84,0,2,2,0,0,0,1.75-2V24.21A11,11,0,0,1,10,24.54Zm0-1.36c3.29,0,4.12-.81,5-3.67.14-.46.15-.49.21-.67a8.28,8.28,0,0,1,1.07-2.33,12,12,0,0,0,2.39-6.45,8.67,8.67,0,1,0-17.34,0A12,12,0,0,0,3.72,16.5a8.15,8.15,0,0,1,1.06,2.33l.22.68C5.87,22.37,6.71,23.18,10,23.18Z"
    />
  </svg>
);
