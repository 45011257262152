import React, { ReactNode } from "react";
import classNames from "classnames";

import { Layout } from "../layout/Layout";

import styles from "./payment-method-panel.module.scss";

export enum PaymentMethodPanelKind {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export interface PaymentMethodPanelProps {
  kind?: keyof typeof PaymentMethodPanelKind;
  className?: string;
  children?: ReactNode;
}

export const PaymentMethodPanel: React.FC<PaymentMethodPanelProps> = ({ children, kind = "PRIMARY", className }) => {
  return (
    <Layout
      className={classNames(
        styles.panel,
        {
          [styles["panel--primary"]]: kind === "PRIMARY",
          [styles["panel--secondary"]]: kind === "SECONDARY",
        },
        className,
      )}
      wrap="TABLET_PORTRAIT_MAX"
    >
      {children}
    </Layout>
  );
};
