import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import NotFoundView from "../not-found-view/NotFoundView";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { ServiceProductCode, ServicePeriodCode, useBankLandingViewQueriesQuery } from "../../graphql/schema";
import { HeroSection } from "../../components/hero-section/HeroSection";
import { ContentSection } from "../../components/content-section/ContentSection";
import { Heading, HeadingGutter } from "../../components/heading/Heading";
import { AsyncImg } from "../../components/async-img/AsyncImg";
import { TextAccent } from "../../components/text-accent/TextAccent";
import { Separator } from "../../components/separator/Separator";
import { FrequentlyBought } from "../../components/frequently-bought/FrequentlyBought";
import { Suggestion } from "../../components/suggestion/Suggestion";
import { ServiceInfo } from "../../services/constants";
import Testimonial from "../../components/testimonial/Testimonial";
import { SignUpBenefits } from "./sign-up-benefits/SignUpBenefits";
import { Section } from "../../components/section/Section";
import { BuyingBehavior } from "./buying-behavior/BuyingBehavior";
import { findOfferByPeriod } from "../../services/findProductByPeriod";
import { notUndefinedOrNull } from "../../services/notUndefinedOrNull";
import { SignUpCta } from "./sign-up-cta/SignUpCta";
import bgImg from "./img/bank-background.png";
import illustrationImg from "./img/bankmacbook.png";
import testimonialLesBrown from "./img/les-brown.jpg";
import testimonialBernardWhite from "./img/bernard-white.jpg";
import testimonialFaces from "./img/faces.png";
import asSeenInImg from "./img/as-seen-in.png";
import { useMount } from "../../hooks/useMount";
import { hasAvailableOffers } from "../../services/hasAvailableOffers";
import { getServicesOffers } from "../../services/getServicesOffers";
import { tracker } from "../../libs/trackers";

import styles from "./product-bank-landing-view.module.scss";

export const ProductBankLandingView: React.FC = () => {
  const [t] = useTranslation();
  const { isMobile, isTabletLandscapeOrBigger } = useWindowDimensionsContext();

  const { data, loading, called } = useBankLandingViewQueriesQuery();

  const [selectedHeroProductCode, setSelectedHeroProductCode] = useState<ServiceProductCode>(
    ServiceProductCode.BANK_BASIC,
  );

  const bankBasicOffers = data?.bankBasic?.offers ?? [];
  const bankBasicOneMonth = findOfferByPeriod(ServicePeriodCode.ONE_MONTH, bankBasicOffers);

  const bankFullOffers = data?.bankFull?.offers ?? [];
  const bankFullOneMonth = findOfferByPeriod(ServicePeriodCode.ONE_MONTH, bankFullOffers);

  const products = [
    bankBasicOneMonth
      ? {
          ...bankBasicOneMonth,
          code: data?.bankBasic?.code,
          name: ServiceInfo.BANK_BASIC.title,
          value: "BANK_BASIC",
          price: bankBasicOneMonth.discountedPrice || bankBasicOneMonth.fullPrice,
          currencyCode: bankBasicOneMonth.currency.code,
        }
      : undefined,
    bankFullOneMonth
      ? {
          ...bankFullOneMonth,
          code: data?.bankFull?.code,
          name: ServiceInfo.BANK_FULL.title,
          value: "BANK_FULL",
          price: bankFullOneMonth.discountedPrice || bankFullOneMonth.fullPrice,
          currencyCode: bankFullOneMonth.currency.code,
        }
      : undefined,
  ].filter(notUndefinedOrNull);

  const selectedHeroProduct = products.find((p) => p.code === selectedHeroProductCode);

  useMount(() => {
    tracker.trackEvent("VIEW_PRODUCT", {
      productName: "Bank Connect & Beyond",
      type: "Subscription",
      url: window.location.href,
    });
  });

  if (!loading && called && !hasAvailableOffers(products)) {
    return (
      <NotFoundView text={t("Product not available, you will be redirected back to homepage after few seconds...")} />
    );
  }

  return (
    <>
      <HeroSection
        className={styles.hero}
        bgUrl={bgImg}
        illustrationUrl={illustrationImg}
        illustrationWidth="50%"
        illustrationBottomOffset="40px"
        illustrationClassName={styles["hero-illustration"]}
        options={products}
        selectedOptionValue={selectedHeroProductCode}
        onOptionChange={setSelectedHeroProductCode}
        productCode={selectedHeroProductCode}
        periodCode="ONE_MONTH"
        availability={selectedHeroProduct?.availability}
        reverseLayoutInMobile
        activeSubscriptionId={selectedHeroProduct?.upgradableSubscriptions[0]?.id}
        gap="MEDIUM"
      >
        <Heading white className={styles.heading} level={2} gutter={HeadingGutter.SMALL}>
          <TextAccent
            dangerouslySetInnerHTML={{
              __html: t("Predict buying behavior <strong>in less than 90 seconds.</strong>"),
            }}
          />
        </Heading>

        <p
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: t(
              "B.A.N.K.® is the world’s only sales methodology scientifically validated to accurately predict buying behavior in real time. Get ready to increase your sales up to 300%.",
            ),
          }}
        />
      </HeroSection>

      {/* credentials */}

      <Section className={styles["credentials-wrap"]} gutter={isTabletLandscapeOrBigger ? 50 : 0} center withSpace>
        <p className={styles["credentials-label"]}>{t("As seen in")}</p>
        <AsyncImg
          className={styles.credentials}
          src={asSeenInImg}
          alt="As seen in Harvard, Forbes and Etrepeneur and more"
        />
        <Separator className={styles["credentials-separator"]} type="SECONDARY" onBackground position="BOTTOM" />
      </Section>

      {/* sign up benefits */}

      <SignUpBenefits />

      {/* testimonial 1 */}

      <ContentSection className={styles["testimonial-section-content"]}>
        {isMobile && <Separator type="SECONDARY" onBackground />}
        <Testimonial
          description={t("World Renowned Motivational Speaker Coach & Best-Selling Author")}
          imagePath={testimonialLesBrown}
          author="Les Brown"
          quote={t(
            "<strong>B.A.N.K. is a game changer</strong> for every Entrepreneur and Sales Professional. This system will strengthen your confidence, expand your selling skills, and dramatically increase your income! ",
          )}
        />
      </ContentSection>

      {/* discover buying behavior */}

      <BuyingBehavior />

      {/* testimonial 2 */}

      <Section gutter={50} className={styles["testimonial-content"]} centerSelf withSpace>
        <AsyncImg src={testimonialFaces} className={styles["testimonial-background"]} />
        <Testimonial
          type="SECONDARY"
          description={t("CEO - White's Printing")}
          imagePath={testimonialBernardWhite}
          author="Bernard White"
          quote={t(
            "Since we started using <strong>CODEBREAKER AI</strong> to <strong>BANK</strong>ify proposals for prospects, our conversion rate has almost doubled from 44% to 87%.",
          )}
        />
      </Section>

      {/* sign-up cta */}

      <SignUpCta data={data} />

      {/* frequently bought together */}

      {hasAvailableOffers(getServicesOffers(data?.forex ?? [])) && (
        <FrequentlyBought productName={t("B.A.N.K Products")}>
          <Suggestion
            productCodes={["FOREX"]}
            periodCode="ONE_MONTH"
            name={ServiceInfo["FOREX"].title}
            to={ServiceInfo["FOREX"].publicUrl}
          />
        </FrequentlyBought>
      )}
    </>
  );
};
