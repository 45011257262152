import React from "react";

interface IconBell3Props {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconBell3: React.FC<IconBell3Props> = ({ hasTitle, className, width = 20, height = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 24" className={className}>
      {hasTitle && <title>IconBell3</title>}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" transform="translate(-210 -2829)">
          <g transform="translate(194 2644)">
            <g transform="translate(0 173)">
              <g transform="translate(.225)">
                <g transform="translate(16.179 13.57)">
                  <path d="M16.246 16.166l-1.136-1.781a1.24 1.24 0 01-.192-.59l-.392-5.984c-.21-3.171-2.693-5.806-5.867-5.992a6.246 6.246 0 00-6.605 5.822l-.405 6.154a1.24 1.24 0 01-.192.59L.321 16.166a1.254 1.254 0 001.061 1.926h13.807a1.254 1.254 0 001.057-1.926"></path>
                  <circle cx="13.778" cy="2.923" r="2.923" stroke="#FFC542" strokeWidth="1.253"></circle>
                  <path d="M9.728 19.344c.652 0 1.044.71.714 1.27a2.503 2.503 0 01-4.317 0c-.33-.56.063-1.27.714-1.27h2.89"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
