import React from "react";

export interface IconCalendarProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconCalendar: React.FC<IconCalendarProps> = ({
  hasTitle,
  className,
  fill = "#7789ad",
  width = 26,
  height = 23,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 23"
    >
      {hasTitle && <title>IconCalendar</title>}
      <path
        fill={fill}
        id="Calendar"
        d="M21.27,2.3h.59A4.18,4.18,0,0,1,24,2.85a4,4,0,0,1,1.48,1.44,3.91,3.91,0,0,1,.57,2q0,2.89,0,6.9Q26,16,25.74,19a4.08,4.08,0,0,1-3.84,3.65C18,22.87,15.08,23,13,23s-5-.13-8.92-.39A4.09,4.09,0,0,1,.27,19Q0,16,0,13.22V6.32a3.91,3.91,0,0,1,.57-2A4,4,0,0,1,2.05,2.85,4.18,4.18,0,0,1,4.14,2.3h.59V1.72a1.77,1.77,0,0,1,3.54,0V2.3h9.46V1.72a1.77,1.77,0,0,1,3.54,0Zm-.1,1.15a1.79,1.79,0,0,1-3.34,0H8.17a1.79,1.79,0,0,1-3.34,0H4.14A3,3,0,0,0,1.58,4.87a2.82,2.82,0,0,0-.4,1.45v6.9c0,1.8.09,3.68.27,5.63a2.92,2.92,0,0,0,2.74,2.62c3.88.25,6.82.38,8.84.38s5-.13,8.79-.38a2.92,2.92,0,0,0,2.74-2.61c.18-2,.27-3.85.26-5.63q0-4,0-6.91a2.74,2.74,0,0,0-.41-1.45,2.83,2.83,0,0,0-1.06-1,2.91,2.91,0,0,0-1.49-.39ZM19.5,1.15a.58.58,0,0,0-.59.57V2.87a.59.59,0,0,0,1.18,0V1.72A.58.58,0,0,0,19.5,1.15Zm-13,0a.58.58,0,0,0-.59.57V2.87a.59.59,0,0,0,1.18,0V1.72A.58.58,0,0,0,6.5,1.15ZM9.45,7.81a.6.6,0,0,1,1.19,0V18.57a.6.6,0,0,1-1.19,0Zm5.91,0A.58.58,0,0,1,16,7.24a.57.57,0,0,1,.59.57V18.57a.58.58,0,0,1-.59.58.59.59,0,0,1-.59-.58ZM5.32,8a.58.58,0,0,0-.59.58v9.19a.57.57,0,0,0,.59.57H20.68a.57.57,0,0,0,.59-.57V8.62A.58.58,0,0,0,20.68,8Zm0-1.15H20.68a1.75,1.75,0,0,1,1.77,1.73v9.19a1.74,1.74,0,0,1-1.77,1.72H5.32a1.74,1.74,0,0,1-1.77-1.72V8.62A1.75,1.75,0,0,1,5.32,6.89Zm-.59,6.9a.58.58,0,1,1,0-1.15H21.27a.58.58,0,1,1,0,1.15Z"
      />
    </svg>
  );
};
