import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { PaymentMethod } from "../../graphql/schema";
import { Modal, ModalKind, ModalProps } from "../modal/Modal";
import { PanelWidth } from "../panel/Panel";
import { OfferMembership, Offers, OfferSubmitMethod } from "./offers/Offers";
import { PurchaseConfirmation } from "./purchase-confirmation/PurchaseConfirmation";

import styles from "./the-view-exclusive-offer.module.scss";

export interface DigitalProductExclusiveOfferModalProps extends Pick<ModalProps, "close"> {
  label: string;
  description: string;
  onSubmit(method: OfferSubmitMethod): Promise<void>;
  onMembershipChecked?(id: string, isChecked: boolean): void;
  memberships?: OfferMembership[];
  availablePaymentMethods: PaymentMethod[];
  totalPriceInEur: number;
  totalPriceInDags: number;
  dagsBalance: number;
}

export enum PurchaseFlowStage {
  OFFERS = "OFFERS",
  PURCHASE_CONFIRMATION = "PURCHASE_CONFIRMATION",
}

export const DigitalProductExclusiveOfferModal: FC<DigitalProductExclusiveOfferModalProps> = ({
  label,
  description,
  onSubmit,
  close,
  onMembershipChecked,
  memberships,
  totalPriceInEur,
  totalPriceInDags,
  dagsBalance,
  availablePaymentMethods,
}) => {
  const [t] = useTranslation();

  const [purchaseFlowStage, setPurchaseFlowStage] = useState<keyof typeof PurchaseFlowStage>("OFFERS");

  async function handleSubmit(method: OfferSubmitMethod) {
    // TODO: implement client side form validation before calling submit

    if (method === PaymentMethod.COIN_ACCOUNT && purchaseFlowStage !== "PURCHASE_CONFIRMATION") {
      setPurchaseFlowStage("PURCHASE_CONFIRMATION");

      return Promise.resolve();
    }

    return onSubmit(method);
  }

  // fetch correct modal caption based on flow stage
  const modalFeatures: Record<PurchaseFlowStage, { caption: string; imageUrl: string; label?: string }> = {
    OFFERS: {
      caption: description,
      imageUrl: "/images/upsellIllustration-2.png",
      label,
    },
    PURCHASE_CONFIRMATION: {
      caption: t("By clicking confirm, your SF’s WebOffice coin account will be used for purchasing {{product}}", {
        product: label,
      }),
      imageUrl: "/images/topUpIllustration.png",
    },
  };

  return (
    <Modal
      isOpen
      close={close}
      kind={ModalKind.SECONDARY}
      panelProps={{
        imagePath: modalFeatures[purchaseFlowStage].imageUrl,
        width: PanelWidth.SMALL,
        label: modalFeatures[purchaseFlowStage].label,
        title: t("Checkout by using dagcoins"),
        caption: (
          <span
            className={styles.caption}
            dangerouslySetInnerHTML={{ __html: modalFeatures[purchaseFlowStage].caption }}
          />
        ),
      }}
    >
      {purchaseFlowStage === "OFFERS" && (
        <Offers
          memberships={memberships}
          onMembershipChecked={onMembershipChecked}
          totalPriceInEur={totalPriceInEur}
          totalPriceInDags={totalPriceInDags}
          availablePaymentMethods={availablePaymentMethods}
          onSubmit={handleSubmit}
        />
      )}

      {purchaseFlowStage === "PURCHASE_CONFIRMATION" && (
        <PurchaseConfirmation
          priceInDags={totalPriceInDags}
          amountAvailableInDags={dagsBalance}
          onClose={() => setPurchaseFlowStage("OFFERS")}
          onConfirm={() => handleSubmit(PaymentMethod.COIN_ACCOUNT)}
        />
      )}
    </Modal>
  );
};
