import React from "react";
import { useTranslation } from "react-i18next";

import { Columns } from "../../../components/columns/Columns";
import { ContentSection } from "../../../components/content-section/ContentSection";
import { Heading, HeadingGutter } from "../../../components/heading/Heading";
import { IconDotLineHorisontal } from "./icon-dot-line-horizontal/IconDotLineHorisontal";
import { MediaSection } from "../../../components/media-section/MediaSection";
import { TextAccent } from "../../../components/text-accent/TextAccent";
import { IconKey } from "../../../components/icon/IconKey";
import { IconDollarBill } from "../../../components/icon/IconDollarBill";
import { benefits } from "../bank-landing-content";
import { useWindowDimensionsContext } from "../../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { Separator } from "../../../components/separator/Separator";
import { IconUser2 } from "../../../components/icon/IconUser2";

import styles from "./sign-up-benefits.module.scss";

interface PanelIconProps {
  type: string;
}

export const SignUpBenefits: React.FC = () => {
  const [t] = useTranslation();

  const { isTabletLandscapeOrBigger } = useWindowDimensionsContext();

  return (
    <ContentSection className={styles.wrap}>
      <Separator className={styles.separator} type="SECONDARY" onBackground />
      <Heading level={3} className={styles.heading} center gutter={HeadingGutter.LARGE}>
        <TextAccent
          dangerouslySetInnerHTML={{
            __html: t("Sign up now and <strong>start increasing sales today!</strong>"),
          }}
          wrap
        />
      </Heading>

      <IconDotLineHorisontal className={styles["dotted-line"]} />

      <Columns minWidth="290px" gap={isTabletLandscapeOrBigger ? 100 : "100px 40px"}>
        {benefits.map((benefit) => (
          <MediaSection contentClassName={styles["content-wrap"]} src={benefit.imageUrl} key={benefit.type}>
            <PanelIcon type={benefit.type} />
            <Heading level={4} className={styles["panel-heading"]} center gutter={HeadingGutter.LARGE}>
              <TextAccent
                type={"YELLOW"}
                dangerouslySetInnerHTML={{
                  __html: t(benefit.title),
                }}
              />
            </Heading>
            <p className={styles.text}>{t(benefit.content)}</p>
          </MediaSection>
        ))}
      </Columns>
    </ContentSection>
  );
};

const PanelIcon: React.FC<PanelIconProps> = ({ type }) => {
  const renderIcon = () => {
    switch (type) {
      case "PROSPECTS":
        return <IconUser2 width={48} height={48} fill="#fff" />;
      case "LEADS":
        return <IconKey />;
      case "TIME":
        return <IconDollarBill />;
      default:
        return null;
    }
  };

  return <div className={styles["icon-wrap"]}>{renderIcon()}</div>;
};
