import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";

import { Section } from "../../components/section/Section";
import { HeroSection } from "./hero-section/HeroSection";
import { isLoggedInVar } from "../../cache/isLoggedIn";

import styles from "./home-view.module.scss";

// interface RouteParams {
//   id: string;
// }

// const periodCode = ServicePeriodCode.ONE_MONTH;
// const forexBankComboServiceCodes = [ServiceProductCode.FOREX, ServiceProductCode.BANK_FULL];

export default function HomeView() {
  //   const { isMobile, isTabletLandscapeOrBigger } = useWindowDimensionsContext();
  //   const { params } = useRouteMatch<RouteParams>();
  const [t] = useTranslation();
  const isAuthenticated = useReactiveVar(isLoggedInVar);
  //   const getPeriodTranslation = useServicePeriodTranslation();
  //   const [selectedBankProductCode, setSelectedBankProductCode] = useState<ServiceProductCode>(
  //     ServiceProductCode.BANK_BASIC,
  //   );
  //   const [selectedForexProductCode, setSelectedForexProductCode] = useState<ServiceProductCode>(
  //     ServiceProductCode.FOREX,
  //   );
  //   const [selectedMembershipProductCode, setSelectedMembershipProductCode] = useState<ServiceProductCode>(
  //     ServiceProductCode.MEMBERSHIP_BASIC,
  //   );
  //   const handleAddToCartClick = useAddServicesToCart();

  //   // fetch home view products data
  //   const { data } = useHomeViewQuery({
  //     variables: {
  //       periodCode,
  //       theViewProductCodes: TheViewProductCodes,
  //     },
  //   });

  //   const isUsCountry = isUserFromUs(data?.me);

  //   const forexBasicOffer = getOfferInfo(ServiceProductCode.FOREX, data?.forex);
  //   const forexProOffer = getOfferInfo(ServiceProductCode.FOREX_PRO, data?.forexPro);
  //   const forexOffers = [forexBasicOffer, forexProOffer].filter(notUndefinedOrNull);
  //   const selectedForexOffer = forexOffers.find((off) => off.productCode === selectedForexProductCode);

  //   const bankBasicOffer = getOfferInfo(ServiceProductCode.BANK_BASIC, data?.bankBasic);
  //   const bankFullOffer = getOfferInfo(ServiceProductCode.BANK_FULL, data?.bankFull);
  //   const promoOffer = data?.forexBankCombo ?? undefined;
  //   const bankOffers = [bankBasicOffer, bankFullOffer].filter(notUndefinedOrNull);
  //   const selectedBankOffer = bankOffers.find((off) => off.productCode === selectedBankProductCode);

  //   const membershipBasicOffer = getOfferInfo(ServiceProductCode.MEMBERSHIP_BASIC, data?.membershipBasic);
  //   const membershipProOffer = getOfferInfo(ServiceProductCode.MEMBERSHIP_PRO, data?.membershipPro);
  //   const membershipOffers = [membershipBasicOffer, membershipProOffer].filter(notUndefinedOrNull);
  //   const selectedMembershipOffer = membershipOffers.find((off) => off.productCode === selectedMembershipProductCode);

  //   const bankBasicOfferAvailability = bankBasicOffer?.availability;
  //   const bankFullOfferAvailability = bankFullOffer?.availability;

  // fetch affiliate data
  //   const { data: affiliateData } = useGetAffiliateQuery({
  //     fetchPolicy: "network-only",
  //   });

  //   const affiliate = affiliateData?.getAffiliate;

  //   const affiliateId = params.id;
  //   const [validateAffiliate] = useValidateAffiliateMutationMutation({
  //     fetchPolicy: "no-cache",
  //     variables: { affiliateId },
  //     refetchQueries: ["GetAffiliate"],
  //   });

  // label for browser tab title
  const titleLabel = t("Top network marketing company with a global community");

  //   useEffect(() => {
  //     if (affiliateId) {
  //       validateAffiliate();
  //     }

  //     // if B.A.N.K. Connect is in cart already, change the default checked product to B.A.N.K. Beyond
  //     if (
  //       bankBasicOfferAvailability === "UNAVAILABLE_ALREADY_IN_CART" ||
  //       (bankBasicOfferAvailability === "UNAVAILABLE_ALREADY_SUBSCRIBED" &&
  //         [
  //           ServiceOfferAvailability.AVAILABLE_FOR_PURCHASE,
  //           ServiceOfferAvailability.AVAILABLE_FOR_UPGRADE,
  //           ServiceOfferAvailability.UNAVAILABLE_ALREADY_IN_CART,
  //           undefined,
  //         ].includes(bankFullOfferAvailability))
  //     ) {
  //       setSelectedBankProductCode(ServiceProductCode.BANK_FULL);
  //     }
  //   }, [affiliateId, validateAffiliate, bankBasicOfferAvailability, bankFullOfferAvailability]);

  // const hasTheViewOffers = hasAvailableOffers(getServicesOffers(data?.theView ?? []));
  // const theViewOffers = (data?.theView ?? []).filter(notUndefinedOrNull);

  return (
    <div className={styles.home}>
      <Section gutter={0}>
        <HeroSection isAuthenticated={isAuthenticated} affiliateId={undefined} />
      </Section>

      {/* <SfSuiteSection isAuthenticated={isAuthenticated} affiliateId={affiliate?.affiliateId ?? undefined} />

      <MemborSection />

      <CommunitySection />

      <EventsSection />

      <MissionSection /> */}

      {/* The View Product Showcase */}
      {/* {hasTheViewOffers && (
        <ProductShowcase
          productCode={ServiceProductCode.VIEW_BASIC}
          periodCode={periodCode}
          availability={"AVAILABLE_FOR_PURCHASE"}
          title="The View"
          logo={<AsyncImg src="/images/TheViewLogo.svg" className={styles["view-logo"]} />}
          badgeColor="YELLOW"
          // badgeLabel={t("Newest")}
          image={<AsyncImg src={theViewLandingBanner} />}
          reverse="ALL_SCREENS"
          gap={isTabletLandscapeOrBigger ? "GAP_40" : "GAP_20"}
          gutter={isMobile ? 50 : 100}
          activeSubscriptionId={null}
          secondaryFlow={{
            packages: theViewOffers,
            modalPanelProps: {
              title: t("Select the packages"),
              caption: t(
                "The View has {{count}} different packages, select the one(s) that you want and click “Add to cart”. You can adjust the quantity of the packages inside the cart, on the next page.",
                { count: theViewOffers.length },
              ),
              label: "The View",
            },
            hasAccess: data?.me.theiaAccess,
          }}
        >
          <Section gutter={SectionGutter.MEDIUM}>
            <p className={styles["product-description-text"]}>
              {t(
                "The View is a new and groundbreaking virtual reality platform that brings together most innovative technology with the most extensive catalogue of experiences and contents.",
              )}
            </p>
            <p
              className={styles["product-description-text"]}
              dangerouslySetInnerHTML={{
                __html: t(
                  "You will have a window into a reality without limits. From leisure, education, communication to shopping and sales, all in a virtual, interactive, instantaneous and real space.",
                ),
              }}
            />
          </Section>
        </ProductShowcase>
      )} */}

      {/* FOREX SECTION */}
      {/* {hasAvailableOffers(forexOffers) && (
        <Section gutter={isTabletLandscapeOrBigger ? 125 : 50}>
          <ProductShowcase
            productCode={selectedForexProductCode}
            periodCode={periodCode}
            availability={
              selectedForexProductCode === ServiceProductCode.FOREX
                ? forexBasicOffer?.availability
                : forexProOffer?.availability
            }
            title={t("Forex products")}
            logo={<IconLogoForexInsiders height={51} width={76} />}
            image={<ForexLandingBanner />}
            reverse="SMALL_SCREEN"
            withBackground
            gap={isTabletLandscapeOrBigger ? "GAP_60" : "GAP_20"}
            badgeColor="YELLOW"
            badgeLabel={t("Most popular")}
            activeSubscriptionId={selectedForexOffer?.upgradableSubscriptions[0]?.id}
            serviceCode={selectedForexProductCode}
          >
            <>
              <p className={styles["product-description-text"]}>
                {t(
                  "With Forex Insiders memberships, it’s easy for beginners to start investing in the forex markets and make a profit. On average, once a day, you’ll get a notification that there is a new possible trading idea for you.",
                )}
                <br />
                {t(
                  "Log in to your Forex Insiders App, copy the trade idea to participate in the trading, and score a win. Easy as that!",
                )}
              </p>
              <p
                className={styles["product-description-text"]}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Forex Insiders and Forex Insiders Pro give you everything you need to become a forex expert. And the best part - <strong><i>it takes just 5 minutes a day!</i></strong>",
                  ),
                }}
              />
              <Section gutter={SectionGutter.MEDIUM}>
                {forexOffers.map((offer, index) => (
                  <div className={styles["product-radio-wrap"]} key={offer.productCode}>
                    <input
                      className={styles["product-radio"]}
                      type="radio"
                      id={`product-forex-${offer.productCode}`}
                      name="forexProductOption"
                      value={offer.productCode}
                      checked={offer.productCode === selectedForexProductCode}
                      onChange={() => setSelectedForexProductCode(offer.productCode)}
                    />

                    <label htmlFor={`product-forex-${offer.productCode}`} className={styles["product-radio-label"]}>
                      <strong className={styles["forex-cta"]}>
                        {index + 1}. {offer.title}{" "}
                      </strong>
                      {" - "}
                      {t("for only {{price}}{{sign}} {{period}}.", {
                        price: offer.discountedPrice || offer.fullPrice,
                        sign: CurrencySign[offer.currency.code],
                        period: getPeriodTranslation(offer.period.code, "LONG"),
                      })}

                      {isFirstPeriodDiscounted(offer) && (
                        <>
                          *
                          <span className={styles["price-disclaimer"]}>
                            {t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                              price: offer.discountedPriceNext || offer.fullPriceNext,
                              currencySign: CurrencySign[offer.currency.code],
                              period: getPeriodTranslation(offer.period.code, "SHORT"),
                            })}
                          </span>
                        </>
                      )}
                    </label>
                  </div>
                ))}
              </Section>
            </>
          </ProductShowcase>
        </Section>
      )} */}

      {/* B.A.N.K Products section */}

      {/* {hasAvailableOffers(bankOffers) && (
        <ProductShowcase
          productCode={selectedBankProductCode}
          periodCode={periodCode}
          availability={
            selectedBankProductCode === ServiceProductCode.BANK_BASIC
              ? bankBasicOffer?.availability
              : bankFullOffer?.availability
          }
          title={t("B.A.N.K Products")}
          reverse="ALL_SCREENS"
          gap={isTabletLandscapeOrBigger ? "GAP_40" : "GAP_20"}
          logo={<IconLogoBankProduct height={55} width={55} />}
          image={<BankLandingBanner />}
          gutter={isMobile ? 50 : 100}
          badgeColor="GREEN"
          badgeLabel={t("Trending")}
          externalUrl={bankExternalUrl()}
          activeSubscriptionId={selectedBankOffer?.upgradableSubscriptions[0]?.id}
        >
          <p
            className={styles["product-description-text"]}
            dangerouslySetInnerHTML={{
              __html: `${t(
                "B.A.N.K. products give you access to mind-blowing & little-known sales system that pinpoints anyone’s buying personality in nanoseconds. You’ll get the powerful B.A.N.K. tools, including access to elite training program, state of art technology that help you to communicate like a pro, improve your relationships, and most importantly - <strong>close more sales in less time!</strong>",
              )}`,
            }}
          />

          {bankOffers.length > 0 && (
            <p className={styles["product-description-text"]}>{t("B.A.N.K technology offers you two products:")}</p>
          )}

          <Section gutter={SectionGutter.MEDIUM}>
            {bankOffers.map((offer, index) => (
              <div className={styles["product-radio-wrap"]} key={offer.productCode}>
                <input
                  className={styles["product-radio"]}
                  type="radio"
                  id={`product-bank-${offer.productCode}`}
                  name="bankProductOption"
                  value={offer.productCode}
                  checked={offer.productCode === selectedBankProductCode}
                  onChange={() => setSelectedBankProductCode(offer.productCode)}
                />

                <label htmlFor={`product-bank-${offer.productCode}`} className={styles["product-radio-label"]}>
                  <strong className={styles["forex-cta"]}>
                    {index + 1}. {offer.title}{" "}
                  </strong>
                  {" - "}
                  {t("for only {{price}}{{sign}} {{period}}.", {
                    price: offer.discountedPrice || offer.fullPrice,
                    sign: CurrencySign[offer.currency.code],
                    period: getPeriodTranslation(offer.period.code, "LONG"),
                  })}

                  {isFirstPeriodDiscounted(offer) && (
                    <>
                      *
                      <span className={styles["price-disclaimer"]}>
                        {t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                          price: offer.discountedPriceNext || offer.fullPriceNext,
                          currencySign: CurrencySign[offer.currency.code],
                          period: getPeriodTranslation(offer.period.code, "SHORT"),
                        })}
                      </span>
                    </>
                  )}
                </label>
              </div>
            ))}
          </Section>
        </ProductShowcase>
      )} */}

      {/* MEMBERSHIP section */}
      {/* {hasAvailableOffers(membershipOffers) && (
        <ProductShowcase
          productCode={selectedMembershipProductCode}
          periodCode={periodCode}
          availability={
            selectedMembershipProductCode === ServiceProductCode.MEMBERSHIP_BASIC
              ? membershipBasicOffer?.availability
              : membershipProOffer?.availability
          }
          title="MEMBERSHIP"
          logo={<AsyncImg src="/images/LightBulb.png" className={styles["membership-logo"]} />}
          badgeColor="GREEN"
          badgeLabel={t("New")}
          image={<AsyncImg src={"/images/topUpIllustration.png"} />}
          reverse="ALL_SCREENS"
          gap={isTabletLandscapeOrBigger ? "GAP_40" : "GAP_20"}
          gutter={isMobile ? 50 : 100}
          activeSubscriptionId={selectedMembershipOffer?.upgradableSubscriptions[0]?.id}
        >
          <Section gutter={SectionGutter.MEDIUM}>
            <p className={styles["product-description-text"]}>
              {t(
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
              )}
            </p>

            <p
              className={styles["product-description-text"]}
              dangerouslySetInnerHTML={{
                __html: t(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
                ),
              }}
            />

            <Section gutter={SectionGutter.MEDIUM}>
              {membershipOffers.map((offer, index) => (
                <div className={styles["product-radio-wrap"]} key={offer.productCode}>
                  <input
                    className={styles["product-radio"]}
                    type="radio"
                    id={`product-membership-${offer.productCode}`}
                    name="membershipProductOption"
                    value={offer.productCode}
                    checked={offer.productCode === selectedMembershipProductCode}
                    onChange={() => setSelectedMembershipProductCode(offer.productCode)}
                  />

                  <label htmlFor={`product-membership-${offer.productCode}`} className={styles["product-radio-label"]}>
                    <strong className={styles["membership-cta"]}>
                      {index + 1}. {offer.title}{" "}
                    </strong>
                    {" - "}
                    {t("for only {{price}}{{sign}} {{period}}.", {
                      price: offer.discountedPrice || offer.fullPrice,
                      sign: CurrencySign[offer.currency.code],
                      period: getPeriodTranslation(offer.period.code, "LONG"),
                    })}

                    {isFirstPeriodDiscounted(offer) && (
                      <>
                        *
                        <span className={styles["price-disclaimer"]}>
                          {t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                            price: offer.discountedPriceNext || offer.fullPriceNext,
                            currencySign: CurrencySign[offer.currency.code],
                            period: getPeriodTranslation(offer.period.code, "SHORT"),
                          })}
                        </span>
                      </>
                    )}
                  </label>
                </div>
              ))}
            </Section>
          </Section>
        </ProductShowcase>
      )} */}

      {/* BECOME A PARTNER */}
      {/* <PartnerCtaSection affiliate={affiliate} isMobile={isMobile} isAuthenticated={isAuthenticated} /> */}

      {/*TESTIMONIALS - disabled due to dummy text*/}
      {/*<TestimonialsSection />*/}

      {/*PROMO OFFER*/}
      {/* {promoOffer && (
        <PromoSection
          currencyCode={promoOffer.currency.code}
          fullPrice={promoOffer.fullPrice}
          discountedPrice={promoOffer.discountedPrice}
          discount={promoOffer.discount}
          onAddToCartClick={() =>
            handleAddToCartClick(forexBankComboServiceCodes.map((productCode) => ({ productCode, periodCode })))
          }
          availability={promoOffer.availability}
        />
      )} */}

      {/* BENEFITS */}
      {/* <BenefitsSection /> */}

      {/* DISCOVER PRODUCTS */}

      {/* {!isAuthenticated ? (
        <div className={styles["sign-up-wrap"]}>
          <Separator type="SECONDARY" onBackground />
          <CallToActionPanel
            icon={<AsyncImg src={gemIcon} />}
            linkRoute={`/registration/${affiliate?.affiliateId}`}
            isDangerouslySetInnerHTML={true}
            title={
              affiliate !== null || undefined
                ? `<span>${t("Congratulations!")}</span> ${affiliate?.firstName} ${t(
                    "has sent you an invite to join us!",
                  )}`
                : t("Experience SF Suite!")
            }
            subTitle={t("A Wise Move Awaits")}
            linkTitle={
              <>
                {t("Sign up now")} <IconUser2 />
              </>
            }
          />
        </div>
      ) : null} */}

      <Helmet>
        <title>SF Suite | {titleLabel}</title>
        <meta property="og:title" content="SF Suite | Top network marketing company with a global community" />
        <meta
          property="og:description"
          content="SF Suite has hundreds of thousands members working towards their success. SF Suite helps people reach their true potential and financial goals."
        />
        <meta property="og:image" content={`${process.env.REACT_APP_HOST}/images/Banner-2.png`} />
      </Helmet>
    </div>
  );

  // get external bank product url
  //   function bankExternalUrl() {
  //     // bank connect url
  //     const bankFullUrl = data?.me.codebreakerAccess?.bankFullUrl;
  //     // bank beyond url
  //     const bankBasicUrl = data?.me.codebreakerAccess?.bankBasicUrl;

  //     if (bankFullUrl) {
  //       return bankFullUrl;
  //     } else if (bankBasicUrl) {
  //       return bankBasicUrl;
  //     } else return undefined;
  //   }
}

// function getOfferInfo<T extends object>(productCode: ServiceProductCode, data?: T | null) {
//   if (!data) {
//     return undefined;
//   }

//   return {
//     ...data,
//     productCode,
//     title: ServiceInfo[productCode].title,
//   };
// }
