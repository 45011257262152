import { FC, ReactNode } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { getBankCardNumber } from "../../services/getBankCardNumber";
import { Radio } from "../radio/Radio";
import { Layout } from "../layout/Layout";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";

import styles from "./bank-card-option.module.scss";

export interface BankCardOptionProps<T = string> {
  id: string;
  isActive: boolean;
  children?: ReactNode;
  onSelect: (id: T) => void;
  hasExpired?: boolean;
  brand?: string | null;
  type?: string | null;
  last4?: string | null;
  expYear?: number | null;
  expMonth?: number | null;
}

export const BankCardOption: FC<BankCardOptionProps> = ({
  id,
  hasExpired,
  brand,
  type,
  last4,
  expYear,
  expMonth,
  isActive,
  onSelect,
  children,
}) => {
  const { isTabletLandscapeOrBigger } = useWindowDimensionsContext();
  const { t } = useTranslation();

  // Handle type translation
  const handleType = (type: string | undefined | null) => {
    if (!type) {
      return undefined;
    }

    switch (type) {
      case "credit":
        return t("Card");
      case "debit":
        return t("debit");
      case "prepaid":
        return t("prepaid");
      case "unknown":
        return t("unknown");
      default:
        return type;
    }
  };

  return (
    <div className={styles.wrap}>
      <Radio
        kind="TERTIARY"
        gap={isTabletLandscapeOrBigger ? "LARGE" : "MEDIUM"}
        value={id}
        checked={isActive}
        onChange={() => onSelect(id)}
        label={
          <Layout className={styles["label-content"]} wrap="TABLET_PORTRAIT_MAX">
            <div className={styles.brand}>{brand}</div>
            <div>
              {handleType(type)}
              <Layout>
                <span>{last4 && <strong className={styles.nr}>{getBankCardNumber(last4)}</strong>}</span>
                {(expMonth || expYear) && (
                  <span className={classNames(styles.date, { [styles["date--expired"]]: hasExpired })}>
                    {("0" + expMonth).slice(-2)}/{expYear}
                  </span>
                )}
              </Layout>
            </div>
          </Layout>
        }
      />

      {isActive && children && <div className={styles.body}>{children}</div>}
    </div>
  );
};
