import React, { ReactNode } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Layout } from "../layout/Layout";
import Price, { CurrencyCode } from "../price/Price";

import styles from "./offer.module.scss";

export enum PriceFormatStyles {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
}

export type Receipt = {
  fee?: string | null;
  tax?: string | null;
  total?: string | null;
  subTotal?: string | null;
  currencyCode: keyof typeof CurrencyCode;
};

export enum OfferGutter {
  SMALL = "SMALL",
  MEDIUM = "MEDIIUM",
  SEMI_LARGE = "SEMI_LARGE",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export interface OfferProps {
  format: keyof typeof PriceFormatStyles;
  icon: React.ReactNode;
  title: string;
  receipt?: Receipt;
  gutter?: keyof typeof OfferGutter;
  thinTitle?: boolean;
  children?: ReactNode;
}

export const Offer: React.FC<OfferProps> = ({ children, format, icon, title, receipt, gutter, thinTitle }) => {
  // access translation keys
  const { t } = useTranslation();

  const gutterClassNames = classNames({
    [styles["gutter--small"]]: gutter === "SMALL",
    [styles["gutter--medium"]]: gutter === "MEDIUM",
    [styles["gutter--semi-large"]]: gutter === "SEMI_LARGE",
    [styles["gutter--large"]]: gutter === "LARGE",
    [styles["gutter--extra-large"]]: gutter === "EXTRA_LARGE",
  });

  return (
    <div className={classNames(styles.wrap, gutterClassNames)}>
      <div className={styles.panel}>
        <div className={styles["offer-section"]}>
          <div className={styles["icon-wrap"]}>{icon}</div>
          <p
            className={classNames(styles.title, { [styles["title--thin"]]: thinTitle })}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
        <div
          className={classNames(styles["formatted-text"], {
            [styles["formatted-text--primary"]]: format === "PRIMARY",
            [styles["formatted-text--secondary"]]: format === "SECONDARY",
            [styles["formatted-text--tertiary"]]: format === "TERTIARY",
          })}
        >
          {children}
        </div>
      </div>
      {receipt && (
        <div className={styles.receipt}>
          {receipt.subTotal && (
            <Layout className={styles["receipt-line"]}>
              <span>{t("Upgrade subtotal")}</span>
              <span>
                <Price price={receipt.subTotal} currency={receipt.currencyCode} />
              </span>
            </Layout>
          )}

          {receipt.fee && (
            <Layout className={styles["receipt-line"]}>
              <span>{t("Fee")}</span>
              <span>
                <Price price={receipt.fee} currency={receipt.currencyCode} />
              </span>
            </Layout>
          )}
          {receipt.tax && (
            <Layout className={styles["receipt-line"]}>
              <span>{t("Tax")}</span>
              <span>
                <Price price={receipt.tax} currency={receipt.currencyCode} />
              </span>
            </Layout>
          )}
          {receipt.total && (
            <Layout className={styles["receipt-line"]}>
              <span className={styles["receipt-total"]}>{t("Total")}</span>
              <span className={styles["total-value"]}>
                <Price price={receipt.total} currency={receipt.currencyCode} />
              </span>
            </Layout>
          )}
        </div>
      )}
    </div>
  );
};
