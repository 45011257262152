import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { useViewerQuery } from "../../../graphql/schema";
import { useGetLocales } from "../../../hooks/useGetLocales";
import { tracker } from "../../../libs/trackers";
import { RankNames, Routes } from "../../../services/constants";
import { getDocumentUrl } from "../../../services/getDocumentUrl";
import { isIntercomEnabled } from "../../../services/isIntercomEnabled";
import { isUserFromUs } from "../../../services/isUserFromUs";
import { FooterTermItem } from "../../footer/children/footer-term-item/FooterTermItem";
import { SocialMediaLinks } from "../social-media-links/SocialMediaLinks";

import styles from "./mobile-menu-footer.module.scss";

export interface MobileMenuFooterProps {
  handleMenuClose(): void;
}

export const MobileMenuFooter: React.FC<MobileMenuFooterProps> = ({ handleMenuClose }) => {
  const { t } = useTranslation();
  const { activeLocale, locales, setLocale } = useGetLocales();
  const { push } = useHistory();
  const { data } = useViewerQuery({ fetchPolicy: "network-only" });

  const rankName = RankNames.find((rank) => rank.id === data?.viewer?.rankId);
  const isUsCountry = isUserFromUs(data?.me);

  return (
    <>
      <div className={styles["link-wrap"]}>
        <FooterTermItem className={styles.link}>© {new Date().getFullYear()}</FooterTermItem>
        <FooterTermItem
          className={styles.link}
          isExternalLink
          url="https://www.sfsuite.com"
          label={"SF Suite"}
          onClick={handleMenuClose}
        />
        <div>
          <label htmlFor="language" className={styles["language-label"]}>
            {t("Language")}
          </label>
          <select
            name="language"
            id="language"
            onChange={(e) => setLocale(e.target.value)}
            defaultValue={activeLocale.code}
            className={styles["language-dropdown"]}
          >
            {locales.map((lang) => (
              <option value={lang.code} key={lang.code} className={styles.language}>
                {lang.name.toUpperCase()}
              </option>
            ))}
          </select>
        </div>
        <Link
          to={"#"}
          className={styles.link}
          onClick={() => {
            if (data?.viewer?.id) {
              tracker.identify(data?.viewer?.id, {
                userHash: data?.me.intercomHash || null,
                cocvalue: data?.me.info?.isCOC || false,
                referralCode: data?.viewer?.referralCode || null,
                email: data?.viewer?.email || null,
                username: data?.viewer?.username || null,
                country: data?.viewer?.country || null,
                firstName: data?.viewer?.firstName || null,
                lastName: data?.viewer?.lastName || null,
                phone: data?.viewer?.phone || null,
                rank: rankName?.name || null,
                registerDate: data?.viewer?.registerDate || null,
                birthdayDate: data?.viewer?.dateOfBirth || null,
              });
            }

            handleMenuClose();

            if (isIntercomEnabled()) {
              try {
                // @ts-ignore TODO: add intercom global types
                window.Intercom("show");
              } catch (e) {
                console.error('window.Intercom("show") failed', e);
              }
            } else {
              push(Routes.CONTACT);
            }
          }}
        >
          {t("Contact")}
        </Link>
        <FooterTermItem
          className={styles.link}
          label={t("Terms of Use")}
          fileName="SF Suite Terms of Use"
          url={getDocumentUrl("TERMS_OF_USE", isUsCountry)}
          onClick={handleMenuClose}
        />
        <FooterTermItem
          className={styles.link}
          label={t("Terms and Conditions")}
          fileName="SuccessFactory General Terms and Conditions"
          url={getDocumentUrl("GENERAL_TERMS_AND_CONDITIONS", isUsCountry)}
          onClick={handleMenuClose}
        />
        <FooterTermItem
          className={styles.link}
          label={t("Privacy Policy")}
          fileName="Privacy Policy"
          url={getDocumentUrl("PRIVACY_POLICY", isUsCountry)}
          onClick={handleMenuClose}
        />
        <FooterTermItem
          className={styles.link}
          label={t("Cookies Policy")}
          fileName="SF Cookie Policy"
          url={getDocumentUrl("COOKIE_POLICY", isUsCountry)}
          onClick={handleMenuClose}
        />
      </div>
      <SocialMediaLinks />
    </>
  );
};
