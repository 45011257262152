import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Button } from "../button/Button";
import {
  AddToCartButton,
  AddToCartButtonIconGutter,
  AddToCartButtonStyleProps,
} from "../add-to-cart-button/AddToCartButton";
import { Modal, ModalKind } from "../modal/Modal";
import { PanelProps, PanelWidth } from "../panel/Panel";
import { IconShoppingCart2 } from "../icon/IconShoppingCart2";
import { SERVICE_BUTTON_ICON_SIZE } from "../../services/constants";
import { ServicePeriodCode, ServiceProductCode } from "../../graphql/schema";
import { AddServicesToCartButton } from "../add-services-to-cart-button/AddServicesToCartButton";
import { Section } from "../section/Section";
import { Checkbox } from "../checkbox/Checkbox";
import Price, { CurrencyCode } from "../price/Price";
import { translateProductName } from "../../services/translateProductName";

import styles from "./add-to-cart-selector.module.scss";

export enum ButtonStyle {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export interface AddToCartSelectorProductPackage {
  code: ServiceProductCode;
  name?: string;
  offers: {
    fullPrice: string;
    discountedPrice?: string | null;
    currency: {
      code: CurrencyCode;
    };
  }[];
}

export interface AddToCartSelectorProps extends Pick<AddToCartButtonStyleProps, "labelClassName"> {
  packages?: AddToCartSelectorProductPackage[];
  modalPanelProps: PanelProps;
  buttonStyle?: keyof typeof ButtonStyle;
  hasAccess?: string | null;
  className?: string;
}

export const AddToCartSelector: FC<AddToCartSelectorProps> = ({
  packages,
  modalPanelProps,
  buttonStyle = "PRIMARY",
  hasAccess,
  className,
  ...props
}) => {
  // handle modal display
  const [isModalOpen, setIsModalOpen] = useState(false);

  // handle selected products
  const [selectedProducts, setSelectedProducts] = useState<ServiceProductCode[]>([]);

  // access translation keys
  const [t] = useTranslation();

  // handle product selection
  const handleChecked = (productCode: ServiceProductCode) => {
    if (selectedProducts.includes(productCode)) {
      setSelectedProducts(selectedProducts.filter((e) => e !== productCode));
      return;
    }

    setSelectedProducts([...selectedProducts, productCode]);
  };

  const handleActionButtonTitle = () => {
    switch (hasAccess) {
      case "":
        return t("Coming soon");
      case null:
        return t("Add to cart");
      case undefined:
        return t("Add to cart");
      default:
        return t("Access Product");
    }
  };

  // button padding
  const buttonPadding =
    buttonStyle === "PRIMARY"
      ? `5px ${AddToCartButtonIconGutter.SMALL}px`
      : `5px ${AddToCartButtonIconGutter.SEMI_MEDIUM}px`;

  // button label padding
  const labelPadding =
    buttonStyle === "PRIMARY"
      ? `0 calc(${AddToCartButtonIconGutter.SMALL}px + ${SERVICE_BUTTON_ICON_SIZE.PRIMARY}px`
      : `0 calc(${AddToCartButtonIconGutter.MEDIUM}px + ${SERVICE_BUTTON_ICON_SIZE.SECONDARY}px)`;

  // button icon size
  const iconSize = buttonStyle === "PRIMARY" ? SERVICE_BUTTON_ICON_SIZE.PRIMARY : SERVICE_BUTTON_ICON_SIZE.SECONDARY;

  return (
    <>
      {hasAccess ? (
        <AddToCartButton
          className={classNames(styles.button, (styles as any)[`button--${buttonStyle}`], styles.link, className)}
          labelClassName={classNames(styles["link-label"], props.labelClassName)}
          availability={"UNAVAILABLE_ALREADY_SUBSCRIBED"}
          onAddToCartClick={Promise.resolve}
          productAccessPath={hasAccess}
          externalUrl={hasAccess}
          serviceCode="VIEW_BASIC"
          kind="DARK_BLUE"
        />
      ) : (
        <Button
          inlineStyles={{ padding: buttonPadding }}
          className={classNames(styles.button, (styles as any)[`button--${buttonStyle}`])}
          color={buttonStyle === "PRIMARY" && hasAccess === null ? "ORANGE" : "DARK_BLUE"}
          borderRadius="SMALL"
          disabled={hasAccess === ""}
          onClick={() => setIsModalOpen(true)}
        >
          <span
            style={{
              padding: labelPadding,
            }}
            className={styles.label}
          >
            {handleActionButtonTitle()}
            <IconShoppingCart2
              className={classNames(styles.icon, (styles as any)[`icon--${buttonStyle}`])}
              width={iconSize}
              height={iconSize}
            />
          </span>
        </Button>
      )}
      <Modal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        kind={ModalKind.SECONDARY}
        panelProps={{
          imagePath: "/images/illustrationSelectPackage.png",
          width: PanelWidth.SMALL,
          title: modalPanelProps.title,
          caption: modalPanelProps.caption,
          label: modalPanelProps.label,
          titleClassName: styles["modal-title"],
          captionClassName: styles["modal-caption"],
        }}
      >
        <p className={styles["form-title"]}>{t("Select packages:")}</p>

        <Section gutter={50}>
          {(packages ?? []).map((p) => {
            const offer = p.offers[0];

            if (!offer) {
              return null;
            }

            return (
              <Checkbox
                key={p.code}
                className={styles.package}
                type="checkbox"
                name={p.code}
                checkboxStyle="TERTIARY"
                label={
                  <span className={classNames({ [styles["package-name"]]: selectedProducts.includes(p.code) })}>
                    {translateProductName(p, t) ?? ""}{" "}
                    <span className={styles["package-price"]}>
                      |{" "}
                      <Price
                        price={offer.discountedPrice ?? offer.fullPrice}
                        currency={offer.currency.code}
                        hideSubUnits
                      />
                    </span>
                  </span>
                }
                checked={selectedProducts.includes(p.code)}
                onChange={() => handleChecked(p.code)}
              />
            );
          })}
        </Section>
        <div className={styles["cart-button-wrap"]}>
          <AddServicesToCartButton
            productCodes={[...selectedProducts]}
            periodCode={ServicePeriodCode.LIFETIME}
            availability="AVAILABLE_FOR_PURCHASE"
            kind="BLUE"
            iconSize={SERVICE_BUTTON_ICON_SIZE.PRIMARY}
            activeSubscriptionId={null}
          />
        </div>
      </Modal>
    </>
  );
};
