import { TypedTypePolicies } from "../graphql/apolloHelpers";

export const typePolicies: TypedTypePolicies = {
  Payment: {
    keyFields: ["id"],
  },

  PaymentsQueries: {
    fields: {
      fetch: {
        keyArgs: ["id"],
      },
      get: {
        merge: false,
      },
      result: {
        merge: false,
      },
    },
  },
};
