export interface IconUserCardProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

function IconUserCard({ hasTitle, className, width = 48, height = 36 }: IconUserCardProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 36"
    >
      {hasTitle && <title>IconUserCard</title>}
      <path d="M7.12,2.65A5,5,0,0,0,2.46,7.22Q2,12.58,2,18c0,3.61.15,7.21.45,10.78a5,5,0,0,0,4.67,4.56c6.94.44,12.58.66,17,.66s10-.22,16.81-.65a5,5,0,0,0,4.67-4.57Q46,23.43,46,18c0-3.61-.15-7.21-.45-10.78a5,5,0,0,0-4.67-4.56Q30.5,2,24,2T7.12,2.65ZM7,.66C13.92.22,19.57,0,24,0S34.05.22,41,.66a7,7,0,0,1,6.53,6.39Q48,12.5,48,18t-.46,11A7,7,0,0,1,41,35.34Q30.66,36,24.07,36T7,35.34A7,7,0,0,1,.46,29C.15,25.31,0,21.65,0,18S.15,10.68.46,7.05A7,7,0,0,1,7,.66ZM15,28a21.81,21.81,0,0,0,5.87-.68,1.83,1.83,0,0,0,1.26-2.26l-.06-.18-.29-.73a3,3,0,0,0-2.29-1.85A27.7,27.7,0,0,0,15,22a27.7,27.7,0,0,0-4.49.3,3,3,0,0,0-2.29,1.85l-.29.73a1.82,1.82,0,0,0,1,2.38l.18.06A21.86,21.86,0,0,0,15,28Zm0,2a23.59,23.59,0,0,1-6.42-.76,3.83,3.83,0,0,1-2.64-4.72c0-.13.08-.26.13-.38l.29-.73a5,5,0,0,1,3.82-3.08A29.5,29.5,0,0,1,15,20a29.5,29.5,0,0,1,4.82.33,5,5,0,0,1,3.82,3.08l.29.73a3.84,3.84,0,0,1-2.14,5,2.65,2.65,0,0,1-.37.12A23.54,23.54,0,0,1,15,30ZM12,13a3,3,0,1,0,3-3A3,3,0,0,0,12,13Zm-2,0a5,5,0,1,1,5,5A5,5,0,0,1,10,13Zm18,1a1,1,0,0,1,0-2H40a1,1,0,0,1,0,2Zm0,4a1,1,0,0,1,0-2H40a1,1,0,0,1,0,2Zm0,4a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Z" />
    </svg>
  );
}

export default IconUserCard;
