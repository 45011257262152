import classNames from "classnames";
import React, { useRef } from "react";
import ReactModal, { Props } from "react-modal";
import { Link } from "react-router-dom";

import useOnClickOutside from "../../hooks/useOnClickOutside";
import { Button } from "../button/Button";
import { IconClose } from "../icon/IconClose";
import { Panel, PanelProps } from "../panel/Panel";
import { IconArrow } from "../icon/IconArrow";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";
import { Breakpoints } from "../../services/constants";

import styles from "./modal.module.scss";

export enum ModalKind {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
}

export enum CenterContent {
  ALL = "ALL",
}

export interface ModalProps extends Props {
  kind?: ModalKind;
  centerContent?: keyof typeof CenterContent | keyof typeof Breakpoints;
  panelProps?: PanelProps;
  titleIcon?: React.ReactNode;
  loading?: boolean;
  title?: string;
  close?(): void;
  hideCloseButton?: boolean;
  to?: string;
  className?: string;
  onReturn?(): void;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  kind = ModalKind.PRIMARY,
  centerContent,
  close,
  panelProps,
  titleIcon,
  loading,
  hideCloseButton,
  title,
  to,
  className,
  onReturn,
  ...rest
}) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapRef, () => close && close());

  const { isMobile } = useWindowDimensionsContext();

  if (kind === ModalKind.SECONDARY) {
    return (
      <ReactModal
        className={classNames(styles.modal, styles["modal-wrap"], styles["modal-wrap--secondary"])}
        overlayClassName={classNames(styles["modal-overlay"], styles["modal-overlay--secondary"], {
          [styles["modal-overlay--loading"]]: loading,
        })}
        bodyOpenClassName={styles["body--has-modal"]}
        onRequestClose={() => close && close()}
        ariaHideApp={false}
        {...rest}
      >
        <div className={classNames(styles.modal, styles["modal--secondary"])} ref={wrapRef}>
          {onReturn && (
            <Button
              className={classNames(styles.return, styles["return--secondary"])}
              kind="TEXT"
              height="AUTO"
              onClick={onReturn}
            >
              <IconArrow fill={isMobile ? "#fff" : "#b5b5be"} width={25} height={25} />
            </Button>
          )}
          {to && (
            <Link to={to} className={classNames(styles.close, styles["close--secondary"])}>
              <IconClose className={styles["close-icon--secondary"]} />
            </Link>
          )}

          {!to && close && (
            <Button
              kind="TEXT"
              height="AUTO"
              className={classNames(styles.close, styles["close--secondary"])}
              onClick={close}
            >
              <IconClose className={styles["close-icon--secondary"]} />
            </Button>
          )}

          <Panel panelStyle="SECONDARY" {...panelProps} hideImage={loading}>
            {children}
          </Panel>
        </div>
      </ReactModal>
    );
  }

  if (kind === ModalKind.TERTIARY) {
    return (
      <ReactModal
        className={classNames(styles.modal, styles["modal--tertiary"], (styles as any)[`center--${centerContent}`], {
          [styles["modal--loading"]]: loading,
        })}
        overlayClassName={classNames(styles["modal-overlay"], { [styles["modal-overlay--loading"]]: loading })}
        onRequestClose={() => close && close()}
        bodyOpenClassName={styles["body--has-modal"]}
        ariaHideApp={false}
        {...rest}
      >
        {close && (
          <Button
            kind="TEXT"
            height="AUTO"
            className={classNames(styles.close, styles["close--tertiary"], { [styles["close--loading"]]: loading })}
            onClick={close}
          >
            ✖
          </Button>
        )}
        {children}
      </ReactModal>
    );
  }

  return (
    <ReactModal
      className={classNames(styles.modal, styles["modal--primary"], { [styles["modal--loading"]]: loading }, className)}
      overlayClassName={classNames(styles["modal-overlay"], { [styles["modal-overlay--loading"]]: loading })}
      onRequestClose={close}
      bodyOpenClassName={styles["body--has-modal"]}
      ariaHideApp={false}
      {...rest}
    >
      {!hideCloseButton && close && (
        <Button
          kind="TEXT"
          height="AUTO"
          className={classNames(styles.close, { [styles["close--loading"]]: loading })}
          onClick={close}
        >
          ✖
        </Button>
      )}
      {children}
    </ReactModal>
  );
};
