import { FC, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { Heading, HeadingGutter } from "../../../../components/heading/Heading";
import { IconCreditCard } from "../../../../components/icon/IconCreditCard";
import { Loader, LoaderSize } from "../../../../components/loader/Loader";
import { PaymentOption } from "../../../../components/payment-option/PaymentOption";
import { useArrangePaymentSourcesMutation, useRemovePaymentSourceMutation } from "../../../../graphql/schema";
import { Routes } from "../../../../services/constants";
import { Button } from "../../../../components/button/Button";

import styles from "./payment-methods-section.module.scss";

interface PaymentMethodsCreditCard {
  __typename?: "PaymentSourceCreditCard";
  expMonth?: number | null;
  expYear?: number | null;
  last4?: string | null;
  type?: string | null;
}

interface PaymentMethodsShopCredit {
  __typename?: "PaymentSourceShopCredit";
}

export interface PaymentMethodsSource {
  id: string;
  info?: PaymentMethodsCreditCard | PaymentMethodsShopCredit | null;
  hasExpired: boolean;
}

interface PaymentMethodsSectionProps {
  paymentSources?: PaymentMethodsSource[] | null;
}

export const PaymentMethodsSection: FC<PaymentMethodsSectionProps> = ({ paymentSources }) => {
  // access translation keys
  const { t } = useTranslation();
  const { push } = useHistory();
  const [column, setColumn] = useState<PaymentMethodsSource[]>([]);
  const [arrangePaymentSources] = useArrangePaymentSourcesMutation();
  const [removePaymentSource] = useRemovePaymentSourceMutation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (paymentSources) {
      setColumn(paymentSources.filter((e) => e.info?.__typename === "PaymentSourceCreditCard"));
    }

    if (paymentSources === null) {
      setColumn([]);
    }
  }, [paymentSources]);

  const handleAddNewModalOpen = () => {
    push(Routes.ADD_NEW_CARD);
  };

  const onDragEnd = async (result: any) => {
    const { destination, source, draggableId } = result;
    // Check if all needed values exist
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    // Set loading to disable change while we are changing.
    setIsLoading(true);

    const droppableElement = column.find((e) => e.id === draggableId);

    if (!droppableElement) {
      return;
    }

    // Sort list to correct order
    var newColumn = [...column];
    const el = newColumn[source.index];
    newColumn.splice(source.index, 1);
    newColumn.splice(destination.index, 0, el);

    const reArrangedPaymentSourceIds = newColumn.map((e) => e.id);
    setColumn(newColumn);

    await arrangePaymentSources({
      variables: { paymentSourcesIds: reArrangedPaymentSourceIds },
      refetchQueries: ["PaymentMethodsView"],
      awaitRefetchQueries: true,
    });

    setIsLoading(false);
  };

  const handleRemovePaymentSource = async (paymentSourceId: string) => {
    setIsLoading(true);

    try {
      await removePaymentSource({
        variables: {
          paymentSourceId,
        },
        refetchQueries: ["PaymentMethodsView"],
        awaitRefetchQueries: true,
      });
    } catch (err) {
      toast.error(t("Failed to remove payment method"));
    } finally {
      setIsLoading(false);
    }
  };

  // Handle type translation
  const handleType = (type: string | undefined | null) => {
    if (!type) {
      return undefined;
    }

    switch (type) {
      case "credit":
        return t("Card");
      case "debit":
        return t("debit");
      case "prepaid":
        return t("prepaid");
      case "unknown":
        return t("unknown");
      default:
        return type;
    }
  };

  return (
    <div className={styles.wrap}>
      <Heading className={styles.title} level={3} gutter={HeadingGutter.SEMI_LARGE}>
        {t("Saved payment methods")}
      </Heading>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"droppable-area"}>
          {(provided: any) => (
            <div className={styles["draggable-wrap"]} ref={provided.innerRef} {...provided.droppableProps}>
              {isLoading && <Loader size={LoaderSize.SMALL} />}
              {column.map((source, index) => (
                <Draggable key={source.id} draggableId={source.id} index={index} isDragDisabled={isLoading}>
                  {(provided: any) => (
                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                      <PaymentOption
                        className={styles["payment-option"]}
                        label={handleType((source.info as PaymentMethodsCreditCard).type) || ""}
                        icon={<IconCreditCard width={50} height={38} />}
                        dragAndDropEnabled
                        isCardExpired={source.hasExpired}
                        caption={`xxxx xxxx xxxx ${(source.info as PaymentMethodsCreditCard).last4}`}
                        expirationDate={handlePaymentMethodDate(
                          (source.info as PaymentMethodsCreditCard).expMonth,
                          (source.info as PaymentMethodsCreditCard).expYear,
                        )}
                        onDelete={() => handleRemovePaymentSource(source.id)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        color="ORANGE"
        borderRadius="SMALL"
        weight="BOLD"
        fontSize={16}
        width={180}
        center="BLOCK_AND_MARGIN"
        onClick={handleAddNewModalOpen}
      >
        {t("Add new")}
      </Button>
    </div>
  );
};

function handlePaymentMethodDate(month?: number | null, year?: number | null) {
  if (!month || !year) {
    return "";
  }

  return `${month > 9 ? month : "0" + month}/${`${year}`.substr(2)}`;
}
