import { GraphQLError } from "graphql";
import { TFunction } from "react-i18next";
import { toast } from "react-toastify";

import { translateValidationError } from "./translateValidationError";

export function showGraphqlValidationErrors(t: TFunction, e: any) {
  if (!Array.isArray(e.graphQLErrors)) {
    return false;
  }

  let wasToastShown = false;

  e.graphQLErrors.forEach((graphqlError: GraphQLError) => {
    if (graphqlError?.extensions?.validationErrors) {
      graphqlError.extensions.validationErrors.forEach((validationError: any) => {
        if (validationError.errors) {
          validationError.errors.forEach((error: any) => {
            toast.error(translateValidationError(t, error), { autoClose: 5000 });
          });
        } else {
          toast.error(validationError?.message, { autoClose: 5000 });
        }

        wasToastShown = true;
      });
    }
  });

  return wasToastShown;
}
