export enum CookieConsentCategory {
  "FUNCTIONAL" = "FUNCTIONAL",
  "SOCIAL_MEDIA" = "SOCIAL_MEDIA",
  "ANALYTICAL" = "ANALYTICAL",
  "TRACKING" = "TRACKING",
}

export type TrackerEventType =
  | "registerUser"
  | "requestInvite"
  | "login"
  | "referralDataReceived"
  | "click"
  | "VIEW_CART"
  | "DISPLAYED_UPSELL"
  | "ACCEPTED_UPSELL"
  | "DECLINED_UPSELL"
  | "VIEW_PRODUCT";

export interface TrackerEventParams {
  [key: string]: any;
}

export type ConsentValues = { [K in CookieConsentCategory]: boolean };
