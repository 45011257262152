import { useReactiveVar } from "@apollo/client";
import { FC, ReactNode } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { isLoggedInVar } from "../../cache/isLoggedIn";
import { Routes } from "../../services/constants";

export interface RequireAuthProps {
  type: "public" | "private";
  redirectTo?: keyof typeof Routes;
  children?: ReactNode;
}

export const RequireAuth: FC<RequireAuthProps> = ({
  type,
  redirectTo = type === "private" ? "LOGIN" : "PRODUCTS",
  children,
}) => {
  const isAuthenticated = useReactiveVar(isLoggedInVar);
  const location = useLocation();

  if ((isAuthenticated && type === "public") || (!isAuthenticated && type === "private")) {
    return <Redirect to={{ pathname: Routes[redirectTo], state: { from: location } }} />;
  }

  return <>{children}</>;
};
