import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { toast } from "react-toastify";
import useClipboard from "react-use-clipboard";

import { Button, ButtonIconGutter } from "../button/Button";
import { ButtonField, ButtonFieldVisualProps } from "../button-field/ButtonField";
import { Heading, HeadingGutter } from "../heading/Heading";
import { IconFacebook } from "../icon/IconFacebook";
import { IconFacebookMessenger } from "../icon/IconFacebookMessenger";
import { IconGmail } from "../icon/IconGmail";
import { Layout } from "../layout/Layout";
import { Section, SectionProps } from "../section/Section";

import styles from "./share-panel.module.scss";

export enum SharePanelColor {
  ORANGE_GRADIENT = "ORANGE_GRADIENT",
  DARK_BLUE = "DARK_BLUE",
}

export interface SharePanelProps extends ButtonFieldVisualProps, SectionProps {
  color: keyof typeof SharePanelColor;
  icon: React.ReactNode;
  title: string;
  linkUrl: string;
}

export const SharePanel: React.FC<SharePanelProps> = ({ color, icon, title, linkUrl, ...props }) => {
  // access translation keys
  const { t } = useTranslation();

  const [isCopied, setCopied] = useClipboard(linkUrl, { successDuration: 1000 });

  // show success notification if link is copied to clipboard
  useEffect(() => {
    if (isCopied) {
      toast.success(t("Referral link copied"));
    }
  }, [isCopied, t]);

  function handleShareWithMessenger() {
    alert(`sharing ${linkUrl} not implemented!`);
  }

  function handleShareOnFacebook() {
    alert(`sharing ${linkUrl} not implemented!`);
  }

  function handleShareWithEmail() {
    alert(`sharing ${linkUrl} not implemented!`);
  }

  const isMessengerEnabled = false;
  const isFacebookEnabled = false;
  const isEmailEnabled = false;

  return (
    <Section gutter={props.gutter} center withSpace={props.withSpace ? props.withSpace : true}>
      <div className={classNames(styles.panel, (styles as any)[`color--${color}`])}>
        {icon}
        <Heading className={styles.title} gutter={HeadingGutter.LARGE} level={3} center white>
          {title}
        </Heading>
        <ButtonField
          disabled
          value={linkUrl}
          className={styles["field-button-wrap"]}
          buttonClassName={styles["field-button"]}
          buttonColor={props.buttonColor}
          onClick={setCopied}
        >
          <span className={styles["button-text"]}>{t("Copy")}</span>
        </ButtonField>
        <Layout className={styles["share-button-wrap"]} wrap="MOBILE">
          {isMessengerEnabled && (
            <Button
              className={styles["share-button"]}
              borderRadius="SMALL"
              iconLeft={<IconFacebookMessenger width={20} height={20} fill="#FF871C" />}
              iconGutter={ButtonIconGutter.SMALL}
              weight="MEDIUM"
              fontSize={14}
              onClick={handleShareWithMessenger}
            >
              {t("Share with messenger")}
            </Button>
          )}
          {isFacebookEnabled && (
            <Button
              className={styles["share-button"]}
              borderRadius="SMALL"
              iconLeft={<IconFacebook width={20} height={20} fill="#FF871C" />}
              iconGutter={ButtonIconGutter.SMALL}
              weight="MEDIUM"
              fontSize={14}
              onClick={handleShareOnFacebook}
            >
              {t("Share on Facebook")}
            </Button>
          )}
          {isEmailEnabled && (
            <Button
              className={styles["share-button"]}
              borderRadius="SMALL"
              iconLeft={<IconGmail width={20} height={20} />}
              iconGutter={ButtonIconGutter.SMALL}
              weight="MEDIUM"
              fontSize={14}
              onClick={handleShareWithEmail}
            >
              {t("Share with email")}
            </Button>
          )}
        </Layout>
      </div>
    </Section>
  );
};
