import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/button/Button";
import { ServiceProductCode, ServiceSubscriptionStatus } from "../../../../../graphql/schema";
import { IconArrow } from "../../../../../components/icon/IconArrow";
import { getTitle } from "../../../../../components/subscription-list-item/SubscriptionListItem";
import { UpgradeOffer } from "../EditSubscription";

import styles from "./change-plan.module.scss";

export interface ChangePlanProps {
  subscription: {
    status: ServiceSubscriptionStatus;
    isLegacy: boolean;
    productCodes: ServiceProductCode[];
  };
  productUpgrades: UpgradeOffer[];
  hasPeriodUpgrades: boolean;
  isModalLoading: boolean;
  onDiscontinueSubscription(): void;
  onContinueSubscription(): void;
  onClose(): void;
  onChangePlan(): void;
  handleUpgradeOffer(offerId: string | undefined, productCode: ServiceProductCode[] | undefined): void;
}

export const ChangePlan: React.FC<ChangePlanProps> = ({
  subscription,
  productUpgrades,
  hasPeriodUpgrades,
  isModalLoading,
  onDiscontinueSubscription,
  onContinueSubscription,
  onClose,
  onChangePlan,
  handleUpgradeOffer,
}) => {
  // access translation keys
  const { t } = useTranslation();

  // handle loading
  const [loadingItems, setLoadingItems] = useState<number[]>([]);
  const productCodes = subscription?.productCodes ?? [];

  return (
    <>
      {hasPeriodUpgrades && (
        <>
          <Button
            center="WITH_MARGIN"
            borderRadius="MEDIUM"
            className={styles.option}
            stretch={true}
            iconRight={<IconArrow className={styles["arrow-icon"]} width={10} height={20} fill={"#f2f3f7"} />}
            onClick={onChangePlan}
          >
            <div
              className={styles["button-text"]}
              dangerouslySetInnerHTML={{ __html: t(`I’d like to <strong>upgrade the billing period</strong>`) }}
            />
          </Button>
        </>
      )}

      {productCodes.indexOf(ServiceProductCode.MEMBERSHIP_BASIC) === -1 &&
        productUpgrades.length > 0 &&
        productUpgrades.map((upgrade, index) => (
          <Button
            key={index}
            className={styles.option}
            stretch={true}
            borderRadius="MEDIUM"
            center="WITH_MARGIN"
            iconRight={<IconArrow className={styles["arrow-icon"]} width={10} height={20} fill={"#f2f3f7"} />}
            onClick={() => {
              setLoadingItems([...loadingItems, index]);
              handleUpgradeOffer(
                upgrade.id,
                upgrade.products.map((p) => p.code),
              );
            }}
            disabled={loadingItems.includes(index)}
            isLoading={loadingItems.includes(index)}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t(`I’d like to <strong>upgrade to {{productName}}</strong>`, {
                  productName: upgrade.products.length > 1 ? t("Combo offer") : getTitle([upgrade.products[0].code]),
                }),
              }}
              className={styles["button-text"]}
            />
          </Button>
        ))}

      {subscription.status === ServiceSubscriptionStatus.ACTIVE && (
        <Button
          className={styles.option}
          stretch={true}
          borderRadius="MEDIUM"
          center="WITH_MARGIN"
          onClick={onDiscontinueSubscription}
          iconRight={<IconArrow className={styles["arrow-icon"]} width={10} height={20} fill={"#f2f3f7"} />}
        >
          <div
            dangerouslySetInnerHTML={{ __html: t(`I’d like to <strong>discontinue my subscription</strong>`) }}
            className={styles["button-text"]}
          />
        </Button>
      )}

      {subscription.status === ServiceSubscriptionStatus.DISCONTINUED && !subscription.isLegacy && (
        <Button
          className={styles.option}
          stretch={true}
          borderRadius="MEDIUM"
          center="WITH_MARGIN"
          onClick={onContinueSubscription}
          iconRight={<IconArrow className={styles["arrow-icon"]} width={10} height={20} fill={"#f2f3f7"} />}
        >
          <div
            dangerouslySetInnerHTML={{ __html: t(`I’d like to <strong>continue my subscription</strong>`) }}
            className={styles["button-text"]}
          />
        </Button>
      )}

      <Button
        className={styles["close-modal-button"]}
        color="BLUE"
        borderRadius="SMALL"
        disabled={isModalLoading}
        onClick={onClose}
        stretch="MOBILE"
      >
        {t("Cancel")}
      </Button>
    </>
  );
};
