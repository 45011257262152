import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { ApolloError } from "@apollo/client";

import RegistrationFormBase from "../registrationFormBase/RegistrationFormBase";
import { digitsRegexp, gsmRegexp, spaceRegexp } from "../../services/constants";
import { formatISODate } from "../../services/formatDate";
import { useCountryQuery, useRegisterLooreUserMutation } from "../../graphql/schema";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";
import { handleFormErrors } from "../../services/handleFormErrors";
import { Loader } from "../loader/Loader";
import { Container } from "../container/Container";
import { Panel, PanelWidth } from "../panel/Panel";
import { Button } from "../button/Button";

import styles from "./lucky-loore-registration-form.module.scss";

const maxBirthdayDate = new Date();
maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);

// const registerUserSchema = yup.object().shape({
//   email: yup
//     .string()
//     .required("This field is required")
//     .email("Not a valid email")
//     .test("is-gsm", "Should only contain latin chars", (value) => gsmRegexp.test(value)),
//   password: yup
//     .string()
//     .min(8, "Password should be at least 8 char long")
//     .max(60, "Password should be not longer than 60 chars")
//     .test("has-uppercase", "Password should have uppercase letters", (value) => value.toLowerCase() !== value)
//     .test("has-digits", "Password should have at least one digit", (value) => digitsRegexp.test(value))
//     .test("has-space", "Password should have no space", (value) => !spaceRegexp.test(value))
//     .required("This field is required"),
//   firstName: yup.string().required("This field is required"),
//   lastName: yup.string().required("This field is required"),
//   countryId: yup.string().required("This field is required"),
//   termsAndConditions: yup
//     .boolean()
//     .oneOf([true], "Please agree to the Privacy Policy")
//     .required("Please agree to the Privacy Policy"),
// });

export type RegisterLuckyLooreFormValues = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  username: string;
  countryId: string;
  state: string;
  city: string;
  postcode: string;
  address: string;
  dateOfBirth: string;
  termsAndConditions: boolean;
};

export default function LuckyLooreRegistrationForm() {
  const [t] = useTranslation();

  const registerUserSchema = yup.object().shape({
    email: yup.string().required(t("This field is required")).email(t("Not a valid email")),
    password: yup
      .string()
      .min(8, t("Password should be at least 8 char long"))
      .max(60, t("Password should be not longer than 60 chars"))
      .test("has-uppercase", t("Password should have uppercase letters"), (value) => value?.toLowerCase() !== value)
      .test("has-digits", t("Password should have at least one digit"), (value) => digitsRegexp.test(value || ""))
      .test("has-space", t("Password should have no space"), (value) => !spaceRegexp.test(value || ""))
      .required(t("This field is required")),
    firstName: yup.string().required(t("This field is required")),
    username: yup
      .string()
      .required(t("Please provide a username"))
      .test("is-gsm", t("Should only contain latin chars"), (value) => gsmRegexp.test(value || ""))
      .test("has-space", t("Username should have no space"), (value) => !spaceRegexp.test(value || ""))
      .min(4, t("Username should be at least 4 char long"))
      .max(245, t("Username should be not longer than 245 chars")),
    lastName: yup.string().required(t("This field is required")),
    countryId: yup.string().required(t("This field is required")),
    state: yup.string().trim(),
    city: yup.string().trim(),
    postcode: yup.string().trim(),
    address: yup.string().trim(),
    termsAndConditions: yup
      .boolean()
      .oneOf([true], t("Please agree to the Privacy Policy"))
      .required(t("Please agree to the Privacy Policy")),
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [hasEightChars, setHasEightChars] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [userPasswd, setUserPasswd] = useState("");
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const {
    register,
    handleSubmit,
    errors: userInputErrors,
    setError,
  } = useForm<RegisterLuckyLooreFormValues>({
    resolver: yupResolver(registerUserSchema),
    defaultValues: { termsAndConditions: false },
    reValidateMode: "onSubmit",
  });
  const [registerMutation, { loading: registerUserLoading }] = useRegisterLooreUserMutation();
  const [formError] = React.useState<string>("");

  const { isMobile } = useWindowDimensionsContext();

  useEffect(() => {
    if (userPasswd.length >= 8) {
      setHasEightChars(true);
    } else {
      setHasEightChars(false);
    }

    if (/[a-z]+/.test(userPasswd)) {
      setHasLowerCase(true);
    } else {
      setHasLowerCase(false);
    }

    if (/[A-Z]+/.test(userPasswd)) {
      setHasUpperCase(true);
    } else {
      setHasUpperCase(false);
    }

    if (/\d+/.test(userPasswd)) {
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }
  }, [userPasswd]);

  const handleRegisterUser = handleSubmit(
    async ({
      email,
      password,
      firstName,
      lastName,
      username,
      termsAndConditions,
      countryId,
      state,
      city,
      postcode,
      address,
    }) => {
      if (!selectedDate) {
        setError("dateOfBirth", { type: "notMatch", message: "Date of birth is required" });

        return;
      }

      try {
        const result = await registerMutation({
          variables: {
            email,
            password,
            firstName: firstName,
            lastName: lastName,
            username: username,
            dateOfBirth: selectedDate ? formatISODate(selectedDate, "YYYY-MM-DD") : "",
            countryId: parseInt(countryId.toString(), 10),
            state,
            city,
            postcode,
            address,
            termsAndConditions,
          },
        });

        if (result) {
          setShowSuccessScreen(true);
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          handleFormErrors({ setError, error, t });
        }
        return;
      }
    },
  );

  const { data: countryData, loading } = useCountryQuery();

  if (loading || !countryData || registerUserLoading) {
    return <Loader />;
  }

  const countries = countryData.country.countries ?? [];

  if (showSuccessScreen) {
    return (
      <Container secondary>
        <Panel
          panelStyle={isMobile ? "SECONDARY" : "PRIMARY"}
          title={t("Welcome to Successfactory")}
          imagePath="/images/registerIllustration.png"
          width={PanelWidth.LARGE}
        >
          <p className={styles.notification}>Registration successful</p>
          <Button
            className={styles["submit-button"]}
            color="YELLOW"
            fontSize={18}
            weight="MEDIUM"
            stretch
            gutter="MEDIUM"
            borderRadius="SMALL"
            type="button"
            onClick={() => setShowSuccessScreen(false)}
          >
            {t("Done")}
          </Button>
        </Panel>
      </Container>
    );
  }

  return (
    <RegistrationFormBase
      countries={countries}
      formError={formError}
      handleRegisterUser={handleRegisterUser}
      passwordTipValues={{ hasEightChars, hasLowerCase, hasNumber, hasUpperCase }}
      register={register}
      registerUserLoading={registerUserLoading}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      setUserPasswd={setUserPasswd}
      userInputErrors={userInputErrors}
    />
  );
}
