import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { IconLogoSuccessFactoryNoText } from "../icon/IconLogoSuccessFactoryNoText";
import Price, { CurrencyCode } from "../price/Price";

import styles from "./gift-card.module.scss";

export interface GiftCardProps {
  className?: string;
  width?: number;
  value?: string;
  currencyCode: keyof typeof CurrencyCode;
}

export const GiftCard: FC<GiftCardProps> = ({ className, width = 350, value, currencyCode }) => {
  // access translation keys
  const { t } = useTranslation();

  const heightRatio = 0.63;

  return (
    <div
      style={{ height: `calc(${width}px * ${heightRatio})`, width: `${width}px` }}
      className={classNames(styles.wrap, className)}
    >
      <IconLogoSuccessFactoryNoText className={styles["background-logo"]} />
      <IconLogoSuccessFactoryNoText className={styles.logo} width={28} height={35} fill="#fff" />
      <div className={styles.price}>
        {value && !Number.isNaN(parseFloat(value)) ? <Price price={value} currency={currencyCode} /> : ""}
      </div>
      <div className={styles.label}>{t("Gift Card")}</div>
    </div>
  );
};
