import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/button/Button";
import { GiftCard } from "../../../../components/gift-card/GiftCard";
import { Layout } from "../../../../components/layout/Layout";
import { Link } from "../../../../components/link/Link";
import { Section } from "../../../../components/section/Section";
import { useWindowDimensionsContext } from "../../../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { CurrencyCode } from "../../../../graphql/schema";
import { Routes } from "../../../../services/constants";

import styles from "./gift-card-section.module.scss";

interface GiftCardSectionProps {
  currencyCode: keyof typeof CurrencyCode;
  openRedeemModal: () => void;
}

export const GiftCardSection: FC<GiftCardSectionProps> = ({ currencyCode, openRedeemModal }) => {
  // access translation keys
  const { t } = useTranslation();

  // calculate viewport dimensions
  const { isTabletLandscapeOrBigger } = useWindowDimensionsContext();

  return (
    <Section className={styles.wrap} gutter={50} centerSelf>
      <Layout className={styles.content} wrap="TABLET_PORTRAIT_MAX">
        <GiftCard
          className={styles["gift-card"]}
          width={isTabletLandscapeOrBigger ? 350 : 250}
          value={"125"}
          currencyCode={currencyCode}
        />
        <div className={styles.section}>
          <p
            className={styles.caption}
            dangerouslySetInnerHTML={{
              __html: t(`The best gift for friends & family is the <strong>SF Suite <span>Gift Card</span></strong>`),
            }}
          />
          <div className={styles["button-wrap"]}>
            <Link
              to={Routes.GIFT_CARD}
              kind="SOLID"
              shape="RECT"
              borderRadius="SMALL"
              weight="BOLD"
              height="MEDIUM"
              color="ORANGE"
              fontSize={16}
            >
              {t("Learn more")}
            </Link>
            <Button
              kind="SOLID"
              shape="RECT"
              borderRadius="SMALL"
              weight="BOLD"
              height="MEDIUM"
              color="YELLOW"
              fontSize={16}
              onClick={openRedeemModal}
            >
              {t("Redeem card")}
            </Button>
          </div>
        </div>
      </Layout>
    </Section>
  );
};
