import { CurrencyCode, ServiceProductCode } from "../graphql/schema";

export enum Routes {
  HOME = "/",
  ABOUT = "/",
  TRAINING = "/training",
  SF_FEE = "/sf-business-fee",
  LOGIN = "/login",
  LOGOUT = "/logout",
  NEWS_LIST = "/account/news",
  INVOICES_LIST = "/account/invoices",
  PRODUCTS = "/account/products",
  SUBSCRIPTION_LIST = "/account/subscriptions",
  PROFILE = "/account/profile",
  GROW_LINKING = "/account/grow",
  PAYMENT_METHODS = "/account/payment-methods",
  AFFILIATE = "/account/affiliate",
  ACCESS = "/account/access",
  DRIVE_FOR_FIVE = "/account/drive-for-five",
  GIFT_CARD = "/account/gift-card",
  CONTACT = "/contact",
  FORGOT_PASSWORD = "/forgot-password",
  EMAIL_CONFIRMATION = "/account/email-confirmation",
  ACTIVATE_EMAIL_CONFIRMATION = "/confirm-email-change",
  VALIDATE_CODE = "/validate-code",
  CHECKOUT_CART = "/",
  CHECKOUT_FAILED = "/",
  CHECKOUT_SUCCESS = "/",
  CHECKOUT_RESULT = "/",
  CHECKOUT_CART_UP_SELL = "/",
  BANK_LANDING = "/bank",
  BANK_BASIC_ACCESS = "/account/access/bank-connect",
  BANK_FULL_ACCESS = "/account/access/bank-beyond",
  FOREX_LANDING = "/forex",
  THE_VIEW_LANDING = "/the-view",
  MEMBERSHIP_ACCESS_BASIC = "/membors/basic",
  MEMBERSHIP_ACCESS_PRO = "/membors/pro",
  MEMBERSHIP_LANDING = "/membors",
  BUSINESS_FEE_BASIC_LANDING = "/sf-business-fee/basic",
  BUSINESS_FEE_PRO_LANDING = "/sf-business-fee/pro",
  REQUEST_INVITE = "/request-invite",
  SPECIAL_INVITE = "/invite/:affiliateId/:name?",
  NOT_FOUND = "/not-found",
  ADD_NEW_CARD = "payment-methods/add-new",
}

export const THE_VIEW_TERMS_OF_USE_LINK_URL = "/files/The_View_Terms_of_Use.pdf";
export const FOREX_TERMS_OF_USE_LINK_URL = "/files/Forex Insiders Terms of Use EU and US user.pdf";
export const FOREX_PRIVACY_POLICY_LINK_URL = "/files/FI Privacy Policy EU and US user.pdf";
export const FOREX_DISCOUNT_MEMBERSHIP_PRICE = 79;

export const IS_BANK_PRODUCT_ENABLED = true;

export const MOBILE_WIDTH = 600;
export const TABLET_PORTRAIT_WIDTH = 900;
export const TABLET_LANDSCAPE_WIDTH = 1200;
export const DESKTOP_WIDTH = 1800;

export const digitsRegexp = /[\d]/;
export const spaceRegexp = /[\040]/;
export const gsmRegexp =
  /^[@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞ^{}[~\]|€ÆæßÉ!"#%&'()*+,\-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà]*$/;

export enum SocialMedia {
  FACEBOOK = "https://www.facebook.com/successfactorycom/",
  YOUTUBE = "https://www.youtube.com/c/SuccessFactory",
  INSTAGRAM = "https://www.instagram.com/successfactorycom/",
}

export const SUPPORT_EMAIL = "customercare@sfsuite.com";
export const SUPPORT_EMAIL_US = "customercare@sfsuite.com";

export enum HiddenUserToolbarPathnames {
  SPECIAL_REGISTRATION = "/invite",
}

export enum HiddenHeaderPathnames {
  SPECIAL_REGISTRATION = "/invite",
}

export enum HiddenNavbarPathnames {
  GIFT_CARD = "/account/gift-card",
  DRIVE_FOR_FIVE_MEMBERSHIP_LIST = "/account/drive-for-five/",
}

export enum HiddenFooterPathnames {
  SPECIAL_REGISTRATION = "/invite",
}

export enum DisabledFooterPathnames {
  CHECKOUT = "/checkout",
  EMAIL_CONFIRMATION = "/account/email-confirmation",
  ACTIVATE_EMAIL_CONFIRMATION = "/confirm-email-change",
  PAYMENT_FAILED = "/payment-failed",
}

export enum SecondaryHeaderPathnames {
  EMAIL_CONFIRMATION = "/account/email-confirmation",
}

export enum TertiaryHeaderPathnames {
  CHECKOUT = "/checkout",
  LOGIN = "/login",
}

export const luckyLoreRegistrationId = "d0bfc647-f62e-4501-bd9e-917755b557c4";

export interface ServiceInfoType {
  title: string;
  accessUrl: Routes | string;
  publicUrl: Routes;
}

export const ServiceInfo: { [K in ServiceProductCode]: ServiceInfoType } = {
  FOREX: {
    title: "Forex Insiders",
    accessUrl: "#",
    publicUrl: Routes.FOREX_LANDING,
  },
  FOREX_PRO: {
    title: "Forex Insiders Pro",
    accessUrl: "#",
    publicUrl: Routes.FOREX_LANDING,
  },
  BANK_BASIC: {
    title: "B.A.N.K. Connect",
    accessUrl: Routes.BANK_BASIC_ACCESS,
    publicUrl: Routes.BANK_LANDING,
  },
  BANK_FULL: {
    title: "B.A.N.K. Beyond",
    accessUrl: Routes.BANK_FULL_ACCESS,
    publicUrl: Routes.BANK_LANDING,
  },
  BUSINESS_FEE_BASIC: {
    title: "SF business fee",
    accessUrl: Routes.TRAINING,
    publicUrl: Routes.BUSINESS_FEE_BASIC_LANDING,
  },
  BUSINESS_FEE_PRO: {
    title: "SF business PRO fee",
    accessUrl: Routes.TRAINING,
    publicUrl: Routes.BUSINESS_FEE_PRO_LANDING,
  },
  VIEW_BASIC: {
    title: "The View",
    accessUrl: Routes.THE_VIEW_LANDING,
    publicUrl: Routes.THE_VIEW_LANDING,
  },
  VIEW_STANDARD: {
    title: "The View",
    accessUrl: Routes.THE_VIEW_LANDING,
    publicUrl: Routes.THE_VIEW_LANDING,
  },
  VIEW_SUPER: {
    title: "The View",
    accessUrl: Routes.THE_VIEW_LANDING,
    publicUrl: Routes.THE_VIEW_LANDING,
  },
  VIEW_ULTRA: {
    title: "The View",
    accessUrl: Routes.THE_VIEW_LANDING,
    publicUrl: Routes.THE_VIEW_LANDING,
  },
  VIEW_ULTRA_PLUS: {
    title: "The View",
    accessUrl: Routes.THE_VIEW_LANDING,
    publicUrl: Routes.THE_VIEW_LANDING,
  },
  MEMBERSHIP_BASIC: {
    title: "Membors",
    accessUrl: Routes.MEMBERSHIP_ACCESS_BASIC,
    publicUrl: Routes.MEMBERSHIP_LANDING,
  },
  MEMBERSHIP_PRO: {
    title: "Membors VIP",
    accessUrl: Routes.MEMBERSHIP_ACCESS_PRO,
    publicUrl: Routes.MEMBERSHIP_LANDING,
  },
};

export enum Gutters {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export enum Breakpoints {
  MOBILE = "MOBILE",
  TABLET_PORTRAIT_UP = "TABLET_PORTRAIT_UP",
  TABLET_LANDSCAPE_UP = "TABLET_LANDSCAPE_UP",
  DESKTOP_UP = "DESKTOP_UP",
  TABLET_PORTRAIT_MAX = "TABLET_PORTRAIT_MAX",
  TABLET_LANDSCAPE_MAX = "TABLET_LANDSCAPE_MAX",
}

export enum SERVICE_BUTTON_ICON_SIZE {
  PRIMARY = 16,
  SECONDARY = 21,
}

export const DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT = 4;

export const RankNames = [
  { id: 6, name: "Topaz" },
  { id: 3, name: "Silver" },
  { id: 7, name: "Sapphire" },
  { id: 8, name: "Ruby" },
  { id: 15, name: "Royal Ambassador" },
  { id: 5, name: "Platinum" },
  { id: 1, name: "Iron" },
  { id: 4, name: "Gold" },
  { id: 9, name: "Emerald" },
  { id: 10, name: "Diamond" },
  { id: 13, name: "Crown Diamond" },
  { id: 16, name: "Crown Ambassador" },
  { id: 2, name: "Bronze" },
  { id: 11, name: "Blue Diamond" },
  { id: 12, name: "Black Diamond" },
  { id: 14, name: "Ambassador" },
];

export const SfFeeProductCodes = [ServiceProductCode.BUSINESS_FEE_BASIC, ServiceProductCode.BUSINESS_FEE_PRO];

export const TheViewProductCodes = [
  ServiceProductCode.VIEW_BASIC,
  ServiceProductCode.VIEW_STANDARD,
  ServiceProductCode.VIEW_SUPER,
  ServiceProductCode.VIEW_ULTRA,
  ServiceProductCode.VIEW_ULTRA_PLUS,
];

export const DefaultCurrencyCode = CurrencyCode.EUR;

export const USStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "District of Columbia",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const USCountryCodes = ["US"];

export const DisabledRoutesForUs: Routes[] = [
  /*
  Routes.DRIVE_FOR_FIVE,
  Routes.SUBSCRIPTION_LIST,
  Routes.INVOICES_LIST,
  Routes.PAYMENT_METHODS,
  */
];
