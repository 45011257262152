import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Routes } from "../../services/constants";
import { Button } from "../button/Button";
import { IconPadlock } from "../icon/IconPadlock";
import IconLogoSuccessFactoryMonochrome from "../icon/IconLogoSuccessFactoryMonochrome";
import { MobileMenuFooter } from "./footer/MobileMenuFooter";
import { MobileMenuItem } from "./mobile-menu-item/MobileMenuItem";
import { MobileSubMenu, MobileSubMenuItem } from "./mobile-sub-menu/MobileSubMenu";
import { IconHouse } from "../icon/IconHouse";
import { IconTag2 } from "../icon/IconTag2";
import { IconShoppingCart2 } from "../icon/IconShoppingCart2";
import { IconUser2 } from "../icon/IconUser2";
import { IconProduct } from "../icon/IconProduct";
import { IconLogoSFSimplifyed } from "../icon/IconLogoSFSimplifyed";
import { IconClose } from "../icon/IconClose";
import { Layout } from "../layout/Layout";
import { IconLogoSuccessFactoryNoText } from "../icon/IconLogoSuccessFactoryNoText";
import { useCartItemCount } from "../../hooks/useCartItemCount";

import styles from "./mobile-menu.module.scss";

export interface MobileMenuProduct {
  title: string;
  to: keyof typeof Routes;
}

export interface AccountMenuItem {
  icon: ReactNode;
  label: string;
  to: string | Routes;
  isDisabled?: boolean;
  isVisibleInMobile?: boolean;
}

export interface MobileMenuProps {
  isAuthenticated: boolean;
  setOpen: (open: boolean) => void;
  open?: boolean;
  hasAccessToWebOffice: boolean;
  products: MobileMenuProduct[];
  accountMenuItems: AccountMenuItem[];
}

export const MobileMenu: FC<MobileMenuProps> = ({
  isAuthenticated,
  setOpen,
  products,
  hasAccessToWebOffice,
  accountMenuItems,
}) => {
  const { t } = useTranslation();
  const numberOfItems = useCartItemCount();

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <div className={styles["menu-wrap"]}>
      <IconLogoSuccessFactoryNoText className={styles["flame-icon"]} gradient={["#feb362", "#fe7a2f"]} />
      <div className={styles.menu}>
        <Layout gutter="MEDIUM">
          <IconLogoSuccessFactoryMonochrome fill="#fff" />
          <Button kind="TEXT" onClick={closeMenu}>
            <IconClose fill="#fff" />
          </Button>
        </Layout>
        <div className={styles["menu-items-wrap"]}>
          <MobileMenuItem
            title={t("Home")}
            to="/"
            icon={<IconHouse width={15} height={15} />}
            handleClick={closeMenu}
          />

          {/* <MobileMenuItem title={t("About")} to={Routes.ABOUT} icon={<IconTag2 />} handleClick={closeMenu} /> */}

          {/* {products.length > 0 && (
            <MobileMenuItem
              title={t("Products")}
              to={Routes.ABOUT}
              icon={<IconProduct width={15} height={15} fill="#fff" />}
              handleClick={closeMenu}
              hasSubMenu
            >
              <MobileSubMenu>
                {products.map((product) => (
                  <MobileSubMenuItem
                    key={product.to}
                    title={product.title}
                    to={Routes[product.to]}
                    handleClick={closeMenu}
                  />
                ))}
              </MobileSubMenu>
            </MobileMenuItem>
          )} */}

          {/* <MobileMenuItem to="/checkout/cart" handleClick={closeMenu} icon={<IconShoppingCart2 />} title={t("Cart")}>
            <div className={styles.counter}>{numberOfItems}</div>
          </MobileMenuItem> */}
          {isAuthenticated ? (
            <>
              <MobileMenuItem title={t("My account")} hasSubMenu icon={<IconUser2 />}>
                <MobileSubMenu>
                  {accountMenuItems
                    .filter((i) => i.isVisibleInMobile !== false)
                    .map((item, index) => (
                      <MobileSubMenuItem
                        key={index}
                        title={item.label}
                        to={item.to}
                        isDisabled={item.isDisabled}
                        handleClick={closeMenu}
                      />
                    ))}
                </MobileSubMenu>
              </MobileMenuItem>
              {hasAccessToWebOffice && (
                <MobileMenuItem
                  title={t("Go to Web Office")}
                  isExternal
                  externalProps={{ href: `${process.env.REACT_APP_SUCCESSFACTORY_SERVER_PROXY}/en/member` }}
                  icon={<IconLogoSFSimplifyed width={11} height={15} fill="#fff" />}
                  handleClick={() => {
                    closeMenu();
                  }}
                />
              )}
              <MobileMenuItem title={t("Log out")} to="/logout" handleClick={closeMenu} icon={<IconPadlock />} />
            </>
          ) : (
            <MobileMenuItem title={t("Log in")} to="/login" handleClick={closeMenu} icon={<IconUser2 />} />
          )}
        </div>
        <MobileMenuFooter handleMenuClose={closeMenu} />
      </div>
    </div>
  );
};
