import React from "react";
import { useTranslation } from "react-i18next";
import { Link as ReactLink, useLocation } from "react-router-dom";

import { Heading } from "../../components/heading/Heading";
import { Link } from "../../components/link/Link";
import { PaymentFlow } from "../../components/payment-result/PaymentResult";
import { Section } from "../../components/section/Section";
import { Titlebar, TitlebarHeight, TitlebarType } from "../../components/titlebar/Titlebar";
import { useViewerQuery } from "../../graphql/schema";
import { tracker } from "../../libs/trackers";
import assertNever from "../../services/assertNever";
import { RankNames, Routes } from "../../services/constants";

import styles from "./payment-failed-view.module.scss";

export const PaymentFailedView: React.FC = () => {
  // access translation keys
  const { t } = useTranslation();
  const flow = new URLSearchParams(useLocation().search).get("flow") as PaymentFlow;
  const { data } = useViewerQuery({ fetchPolicy: "network-only" });

  const rankName = RankNames.find((rank) => rank.id === data?.viewer?.rankId);

  function handleRoute(flow: PaymentFlow) {
    switch (flow) {
      case "CART_PURCHASE_OF_SERVICE_PRODUCTS":
      case "CART_PURCHASE_OF_SHOP_CREDIT":
        return Routes.CHECKOUT_CART;
      case "PAYMENT_METHODS_PURCHASE_OF_GIFT_CARD":
        return Routes.GIFT_CARD;
      case "PAYMENT_METHODS_PURCHASE_OF_SHOP_CREDIT":
        return Routes.PAYMENT_METHODS;
      case "SUBSCRIPTION_PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE":
      case "SUBSCRIPTION_PURCHASE_OF_SHOP_CREDIT":
        return Routes.SUBSCRIPTION_LIST;
      case "UPSELL_PURCHASE_OF_SERVICE_PRODUCTS":
      case "UPSELL_PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE":
      case "UPSELL_PURCHASE_OF_SHOP_CREDIT":
        return Routes.CHECKOUT_CART_UP_SELL;
      case "DEFAULT":
        return Routes.PRODUCTS;
      default:
        assertNever(flow);
    }
  }

  return (
    <>
      <Titlebar
        className={styles.titlebar}
        type={TitlebarType.PRIMARY}
        height={TitlebarHeight.SEMI_LARGE}
        imageUrl="/images/paymentFailedIllustration.png"
      />
      <Section gutter={100} center centerText withSpace>
        <Heading className={styles.title} level={2}>
          {t("Your payment failed")}...
        </Heading>
        <p className={styles.notification}>
          {t(
            "Unfortunately your payment did not go through, please try again. If this continues to happen, please try other payment method. ",
          )}
        </p>
        <Link
          className={styles["cart-link"]}
          to={flow ? handleRoute(flow) : Routes.PRODUCTS}
          shape="ROUND"
          color="DARK_BLUE"
          fontSize={14}
          gutter="SEMI_LARGE"
          weight="BOLD"
        >
          {flow ? t("Go back") : t("Go to My Account")}
        </Link>
        <p className={styles["support-message"]}>
          {t("Need help?")}
          <ReactLink
            className={styles["support-link"]}
            to={Routes.CONTACT}
            onClick={() => {
              if (data?.viewer?.id) {
                tracker.identify(data?.viewer?.id, {
                  userHash: data?.me.intercomHash || null,
                  cocvalue: data?.me.info?.isCOC || false,
                  referralCode: data?.viewer?.referralCode || null,
                  email: data?.viewer?.email || null,
                  username: data?.viewer?.username || null,
                  country: data?.viewer?.country || null,
                  firstName: data?.viewer?.firstName || null,
                  lastName: data?.viewer?.lastName || null,
                  phone: data?.viewer?.phone || null,
                  rank: rankName?.name || null,
                  registerDate: data?.viewer?.registerDate || null,
                  birthdayDate: data?.viewer?.dateOfBirth || null,
                });
              }
            }}
          >
            {t("Contact support")}
          </ReactLink>
        </p>
      </Section>
    </>
  );
};
