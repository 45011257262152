import React from "react";

export interface IconLogoBankProductProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconLogoBankProduct: React.FC<IconLogoBankProductProps> = ({
  hasTitle,
  className,
  width = 58,
  height = 76,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      id="svg29"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58.4 76.5"
    >
      {hasTitle && <title>IconLogoBankProduct</title>}
      <defs>
        <linearGradient
          id="linear-gradient--IconLogoBankProduct"
          x1="-1450.74"
          y1="-15.9"
          x2="-1449.74"
          y2="-15.9"
          gradientTransform="matrix(-26.68, -54.96, -54.96, 26.68, -39537.45, -79229.08)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#b61f24" />
          <stop offset="0.03" stopColor="#b61f24" />
          <stop offset="0.2" stopColor="#b61f24" />
          <stop offset="0.39" stopColor="#cb2027" />
          <stop offset="0.43" stopColor="#cb2027" />
          <stop offset="1" stopColor="#991b1e" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2--IconLogoBankProduct"
          x1="-1384.94"
          y1="-25.13"
          x2="-1383.94"
          y2="-25.13"
          gradientTransform="matrix(30.95, -1.42, -1.42, -30.95, 42825.97, -2696.73)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0c4b80" />
          <stop offset="0.52" stopColor="#0c4b80" />
          <stop offset="1" stopColor="#3b9ed8" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3--IconLogoBankProduct"
          x1="-1419.25"
          y1="-42.13"
          x2="-1418.25"
          y2="-42.13"
          gradientTransform="matrix(24.06, -27.82, -27.82, -24.06, 33011.18, -40422.02)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#1a6935" />
          <stop offset="0.52" stopColor="#1a6935" />
          <stop offset="1" stopColor="#0cae4b" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4--IconLogoBankProduct"
          x1="-1452.86"
          y1="-17.56"
          x2="-1451.86"
          y2="-17.56"
          gradientTransform="matrix(-24.2, -49.84, -49.84, 24.2, -35989.99, -71919.6)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ec4852" />
          <stop offset="0.01" stopColor="#ea7b23" />
          <stop offset="0.47" stopColor="#ea7b23" />
          <stop offset="0.6" stopColor="#f9a11b" />
          <stop offset="0.79" stopColor="#f9c80f" />
          <stop offset="1" stopColor="#f9c80f" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-5--IconLogoBankProduct"
          x1="-1374.41"
          y1="-27.72"
          x2="-1373.41"
          y2="-27.72"
          gradientTransform="matrix(25.72, 0, 0, -25.72, 35360.05, -672.2)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#12284d" />
          <stop offset="0.03" stopColor="#12284d" />
          <stop offset="0.2" stopColor="#12284d" />
          <stop offset="0.39" stopColor="#3b9ed8" />
          <stop offset="0.43" stopColor="#3b9ed8" />
          <stop offset="1" stopColor="#12284d" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-6--IconLogoBankProduct"
          x1="-1415.37"
          y1="-57.27"
          x2="-1414.37"
          y2="-57.27"
          gradientTransform="matrix(17.69, -20.45, -20.45, -17.69, 23910.16, -29905.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#193018" />
          <stop offset="0.28" stopColor="#1a6935" />
          <stop offset="0.29" stopColor="#1a6935" />
          <stop offset="0.59" stopColor="#0cae4b" />
          <stop offset="1" stopColor="#0cae4b" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-7--IconLogoBankProduct"
          x1="-1351.82"
          y1="-43.54"
          x2="-1350.82"
          y2="-43.54"
          gradientTransform="matrix(17.84, -0.82, -0.82, -17.84, 24084.21, -1858.45)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#3b9ed8" />
          <stop offset="0.07" stopColor="#3b9ed8" />
          <stop offset="0.48" stopColor="#3b9ed8" />
          <stop offset="1" stopColor="#3b9ed8" />
        </linearGradient>
        <radialGradient
          id="radial-gradient--IconLogoBankProduct"
          cx="-1349.45"
          cy="40.22"
          r="1"
          gradientTransform="translate(24000.16 -675.92) scale(17.75)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f9c80f" />
          <stop offset="0.21" stopColor="#f9c80f" />
          <stop offset="0.4" stopColor="#f9a11b" />
          <stop offset="0.53" stopColor="#ea7b23" />
          <stop offset="0.99" stopColor="#ea7b23" />
          <stop offset="1" stopColor="#ec4852" />
        </radialGradient>
      </defs>
      <path
        id="path65"
        d="M30.1,6.5a11.23,11.23,0,0,0,1.3,6.2h0c1,2.2,2.4,4.3,3.1,6.6h0a21.88,21.88,0,0,1,.9,5.7h0a16.14,16.14,0,0,1-3.6,11.1h0c-3.6,4.2-9.2,6-13.8,8.8h0c-8.4,5.2-9,17.2-3.7,24.9h0a22,22,0,0,0,1.4,1.8h0a15.45,15.45,0,0,0,7.8,4.3h0c1.4.3,1.5.3,1.9.4h0a27.48,27.48,0,0,0,11.7-.7h0A11.94,11.94,0,0,1,29,74.5h0c-3.5-2-4.6-7-3.8-10.6h0a11.13,11.13,0,0,1,5.3-7.2h0c3.1-1.8,6.8-2.2,10.1-3.8h0C49.3,48.8,53.2,38.8,51,29.6h0c-1.4-6-4.7-12.5-9.6-16.2h0A26.33,26.33,0,0,1,36,8.8h0a13,13,0,0,1-2.4-4.1h0A21.74,21.74,0,0,1,33.1,0h0c-1.6,1.2-2.9,4.6-3,6.5"
        fill="url(#linear-gradient--IconLogoBankProduct)"
      />
      <path
        id="path87"
        d="M25.1,12.3Zm-.3,7.2c-2,5.7-6.4,9.8-12.8,9.6h0c-3.9-.1.3-5.5.5-5.8h0A28.48,28.48,0,0,0,6,29.4H6A29.29,29.29,0,0,0,3,60.3H3a30.46,30.46,0,0,0,4.9,7h0a22.74,22.74,0,0,0,3.2,3h0c.4.3,2.2,1.6,2.7,1.9h0a20,20,0,0,1-4.3-4.9h0A23,23,0,0,1,7,53H7a16.57,16.57,0,0,1,1.1-3.8h0C12.4,39.5,26,39,29.2,28.6h0A16.13,16.13,0,0,0,27,14.3h0a8.06,8.06,0,0,0-1.2-1.4h0c-.1-.1-.6-.4-.6-.5h0c0,1.6.5,4.7-.4,7.1"
        fill="url(#linear-gradient-2--IconLogoBankProduct)"
      />
      <path
        id="path109"
        d="M51.5,50.7c-3.4,4.8-5.7,6.8-14.1,8.7h0c-3,.7-7.2,2.4-7.4,7.5h0a7,7,0,0,0,6.9,7.4h0c.4,0,3.2,0,10.1-4.3h0c9.7-6.1,16.7-24.4,5.9-39.8h0c3.6,6.6,2.3,15.3-1.4,20.5"
        fill="url(#linear-gradient-3--IconLogoBankProduct)"
      />
      <path
        id="path137"
        d="M31.2,41.9C3.2,44.2,12,65.6,12.5,66.7h0c.3.7,5,13.5,24.5,8.7h0C20.5,78,16.8,63.3,20.5,57.8h0c3-4.4,6.7-6.4,17.9-9.4h0C51,45,51.5,32.5,50.9,29.4h0c-.6-3.9-4.5-13-10.8-17.1h0s23.2,26.9-8.9,29.6"
        fill="url(#linear-gradient-4--IconLogoBankProduct)"
      />
      <path
        id="path165"
        d="M10,43.1c-13.7,14.3,1.9,28,.8,26.4h0C7.2,64,.8,48.9,18.1,39.9h0c20.5-10.7,8.6-26.9,7-27.6h0C36.5,32.7,16.6,36.2,10,43.1"
        fill="url(#linear-gradient-5--IconLogoBankProduct)"
      />
      <path
        id="path191"
        d="M53.1,48c-3.8,8.1-9.7,9.7-9.7,9.7h0a12.44,12.44,0,0,0,5.2-1.8h0c1.1-.6,6.3-4.8,7.5-10.2h0a21.05,21.05,0,0,0,.2-8.3h0a18,18,0,0,0-2-5.3h0c-.4-.6-.9-1.2-1.3-1.8h0c4.6,8.8.1,17.7.1,17.7"
        fill="url(#linear-gradient-6--IconLogoBankProduct)"
      />
      <path
        id="path215"
        d="M17.7,28c-12.4,4.3-5.1-4.7-5.1-4.7h0s-10.9,9.9,2.2,6.9h0C28.9,27,25.1,12.3,25.1,12.3h0C25.7,25,17.7,28,17.7,28"
        fill="url(#linear-gradient-7--IconLogoBankProduct)"
      />
      <path
        id="path243"
        d="M40.7,13.1c.3.4.7.9,1.2,1.6h0a56.31,56.31,0,0,1,3.7,6h0A24.15,24.15,0,0,1,47.4,25h0c.2.8.4,1.6.6,2.5h0a22.75,22.75,0,0,1,.2,2.6h0a9.8,9.8,0,0,1-1.5,5.2h0c-.1.2-.3.4-.4.6h0a1.21,1.21,0,0,1-.5.5h0l-.1.1h0l-.1.1-.3.3-.3.3-.3.2A10,10,0,0,1,42.3,39h0a16.87,16.87,0,0,1-2.7,1.1h0a22.91,22.91,0,0,1-2.8.7h0c-1.9.4-3.7.6-5.5.9h0c-1.8.2-3.5.5-5.2.8h0a26.74,26.74,0,0,0-4.5,1.2h0A20.2,20.2,0,0,0,18,45.4h0c-1,.6-1.8,1.3-2.5,1.8h0c-.2.1-.3.3-.5.4h0c-.1.1-.3.2-.4.3h0l-.3.3h0l-.1.1h0l-.1.1h0c-.1.1-.1.2-.2.2h0c0,.1-.1.1-.1.2h0v.1h0v-.1h0a.35.35,0,0,0,.1-.2h0c0-.1.1-.1.2-.2h0l.1-.1h0l.1-.1h0l.3-.3h0c.1-.1.3-.2.4-.3h0a2.18,2.18,0,0,1,.5-.4h0c.7-.5,1.5-1.1,2.5-1.7h0a20.2,20.2,0,0,1,3.6-1.7h0a29.57,29.57,0,0,1,4.5-1.2h0c1.6-.3,3.4-.5,5.2-.8h0c1.8-.2,3.7-.5,5.5-.8h0a28.64,28.64,0,0,0,2.8-.7h0c.9-.3,1.8-.7,2.7-1.1h0a10,10,0,0,0,2.4-1.6h0l.3-.2.3-.3.3-.3.1-.1h0l.1-.1c.2-.2.3-.4.5-.6h0c.1-.2.3-.4.4-.6h0A10.32,10.32,0,0,0,48.3,30h0a24.52,24.52,0,0,0-.2-2.7h0c-.2-.9-.4-1.7-.6-2.5h0a34.8,34.8,0,0,0-1.8-4.3h0c-.7-1.3-1.4-2.4-2-3.4h0c-.6-1-1.2-1.8-1.7-2.5h0c-.6-.6-1-1.2-1.3-1.5h0a5.76,5.76,0,0,0-.4-.5h0a5.76,5.76,0,0,1,.4.5"
        fill="url(#radial-gradient--IconLogoBankProduct)"
      />
    </svg>
  );
};
