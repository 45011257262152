import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";

import { useOnScreen } from "../../hooks/useOnScreen";

import styles from "./async-img.module.scss";

export interface AsyncImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
}

export const AsyncImg: React.FC<AsyncImgProps> = ({ className, src, alt, ...rest }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentSrc, setCurrentSrc] = useState<string>();
  const imgRef = useRef<HTMLImageElement | null>(null);
  const isOnScreen = useOnScreen(imgRef, "100px");

  useEffect(() => {
    if (isOnScreen && currentSrc !== src) {
      setCurrentSrc(src);
    }
  }, [isOnScreen, currentSrc, setCurrentSrc, src]);

  return (
    <img
      src={currentSrc}
      ref={imgRef}
      {...rest}
      alt={alt}
      className={classNames(styles["async-img"], { [styles["async-img--loaded"]]: isLoaded }, className)}
      onLoad={() => {
        setIsLoaded(true);
      }}
      onError={() => {
        setIsLoaded(false);
      }}
    />
  );
};
