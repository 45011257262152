import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { useViewerQuery } from "../../graphql/schema";
import { CookieConsentCategory, useCookieConsent } from "../../libs/trackers";
import { getDocumentUrl } from "../../services/getDocumentUrl";
import { isUserFromUs } from "../../services/isUserFromUs";
import { Button } from "../button/Button";
import { CookieConsentDetails } from "../cookie-consent-details/CookieConsentDetails";
import { CookieConsentOption } from "../cookie-consent-option/CookieConsentOption";
import { IconArrow } from "../icon/IconArrow";
import { Layout } from "../layout/Layout";
import { Modal } from "../modal/Modal";
import { Panel, PanelWidth } from "../panel/Panel";
import { Section, SectionGutter } from "../section/Section";

import styles from "./cookie-consent.module.scss";

export const CookieConsent: FC = () => {
  const { t } = useTranslation();
  const consent = useCookieConsent();
  const [showConsentPreferences, setShowConsentPreferences] = useState(false);
  const [detailsCategoryId, setDetailsCategoryId] = useState<CookieConsentCategory | undefined>(undefined);
  const { data } = useViewerQuery();

  const isUsCountry = isUserFromUs(data?.me);
  const details = consent.options.find(({ category }) => detailsCategoryId === category);

  if (!consent.isVisible) {
    return null;
  }

  // preference details view
  if (details) {
    return (
      <Modal isOpen className={styles.wrap}>
        <Button
          className={styles["back-button"]}
          kind="TEXT"
          weight="MEDIUM"
          fontSize={16}
          height="AUTO"
          onClick={() => setDetailsCategoryId(undefined)}
          iconLeft={<IconArrow fill="black" width={16} height={16} />}
          iconGutter={10}
        />
        <Panel
          className={styles.panel}
          contentClassName={styles.content}
          title={details.title}
          width={PanelWidth.SMALL}
        >
          <CookieConsentDetails cookies={details.details.cookies}>
            <p>{details.description}</p>
            {details.links && (
              <ul>
                {details.links.map(({ title, url }) => (
                  <li key={title}>
                    <a href={url} target="_blank" rel="noreferrer noopener">
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
            <p dangerouslySetInnerHTML={{ __html: details.details.description || "" }} />
          </CookieConsentDetails>
        </Panel>
        <Layout wrap="MOBILE">
          {/* <Button
            className={styles.button}
            shape="RECT"
            kind="SOLID"
            color="LIGHT_RED"
            height="MEDIUM"
            fontSize={16}
            weight="MEDIUM"
            borderRadius="SMALL"
            stretch="MOBILE"
            onClick={consent.discardAndClose}
          >
            {t("Discard changes")}
          </Button> */}
          <Button
            className={styles.button}
            color="LIGHT_GREY"
            fontSize={16}
            weight="MEDIUM"
            borderRadius="SMALL"
            stretch="MOBILE"
            onClick={consent.saveAndClose}
          >
            {t("Save and Finish")}
          </Button>
        </Layout>
      </Modal>
    );
  }

  // preferences view
  if (showConsentPreferences) {
    return (
      <Modal isOpen className={styles.wrap}>
        <Button
          className={styles["back-button"]}
          kind="TEXT"
          weight="MEDIUM"
          fontSize={16}
          height="AUTO"
          onClick={() => setShowConsentPreferences(false)}
          iconLeft={<IconArrow fill="black" width={16} height={16} />}
          iconGutter={10}
        />
        <Panel
          width={PanelWidth.SMALL}
          className={styles.panel}
          contentClassName={styles.content}
          title={t("Your Cookie Preferences")}
        >
          <Section gutter={SectionGutter.MEDIUM}>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  `We use different types of cookies to optimize your experience on our website. Click on the categories below to learn more about their purposes. You may choose which types of cookies to allow and can change your preferences at any time. Remember that disabling cookies may affect your experience on the website. You can learn more about how we use cookies by visiting our <a href="{{cookiePolicy}}" target="blank" rel="noreferrer noopener">Cookie Policy</a> and <a href="{{privacyPolicy}}" target="blank" rel="noreferrer noopener">Privacy Policy.</a>`,
                  {
                    cookiePolicy: getDocumentUrl("COOKIE_POLICY", isUsCountry),
                    privacyPolicy: getDocumentUrl("PRIVACY_POLICY", isUsCountry),
                  },
                ),
              }}
            />
          </Section>

          <div className={styles["preferences-container"]}>
            {consent.options.map((option) => (
              <CookieConsentOption
                key={option.category}
                value={option.category}
                checked={option.enabled}
                isMandatory={option.isMandatory}
                title={option.title}
                cookiesCount={option.details.cookies.length}
                onChange={(isChecked) => consent.setOption(option.category, isChecked)}
                onShowDetails={() => setDetailsCategoryId(option.category)}
              >
                <p className={styles["cookie-description"]}>{option.description}</p>
              </CookieConsentOption>
            ))}
          </div>

          <Layout wrap="MOBILE">
            <Button
              className={styles.button}
              shape="RECT"
              kind="SOLID"
              color="LIGHT_RED"
              height="MEDIUM"
              fontSize={16}
              weight="MEDIUM"
              borderRadius="SMALL"
              stretch="MOBILE"
              onClick={consent.saveRequiredOnlyAndClose}
            >
              {t("Only allow functional cookies")}
            </Button>
            <Button
              className={styles.button}
              color="LIGHT_GREY"
              fontSize={16}
              weight="MEDIUM"
              borderRadius="SMALL"
              stretch="MOBILE"
              onClick={consent.saveAndClose}
            >
              {t("Save and Finish")}
            </Button>
          </Layout>
        </Panel>
      </Modal>
    );
  }

  // initial view
  return (
    <Modal isOpen>
      <Panel width={PanelWidth.SMALL} contentClassName={styles.content} title={t("Your Cookie Preferences")}>
        <Section gutter={SectionGutter.MEDIUM}>
          <p>
            {t(
              "SF Suite use cookies to analyse website usage and improve usability. We also use third party tracking-technologies to measure user preference and assist in our marketing efforts. By clicking ‘Accept’ you allow us to place the different types of cookies.",
            )}
          </p>
        </Section>

        <Layout wrap="MOBILE">
          <Button
            className={styles.button}
            color="LIGHT_GREY"
            fontSize={16}
            weight="MEDIUM"
            borderRadius="SMALL"
            stretch="MOBILE"
            onClick={() => setShowConsentPreferences(true)}
          >
            {t("Preferences")}
          </Button>
          <Button
            className={styles.button}
            color="BLUE"
            fontSize={16}
            weight="MEDIUM"
            borderRadius="SMALL"
            stretch="MOBILE"
            onClick={consent.acceptAllAndClose}
          >
            {t("Accept All")}
          </Button>
        </Layout>
      </Panel>
    </Modal>
  );
};
