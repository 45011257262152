import { TypedTypePolicies } from "../graphql/apolloHelpers";
import { ForexSignalsQuery } from "../graphql/schema";
import { mergeNonNormalizedObjectArrays } from "../services/mergeNonNormalizedObjectArrays";

export const typePolicies: TypedTypePolicies = {
  ForexSignalsQuery: {
    keyFields: [],
    fields: {
      rows: mergeNonNormalizedObjectArrays<ForexSignalsQuery>(),
    },
  },
};
