import React from "react";

export interface IconShieldCancelProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
  opacity?: number;
}

export const IconShieldCancel: React.FC<IconShieldCancelProps> = ({
  hasTitle,
  className,
  fill = "#cfd0df",
  width,
  height,
  opacity,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      opacity={opacity}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 25"
    >
      {hasTitle && <title>IconShieldCancel</title>}
      <path
        id="Fill-302"
        fill={fill}
        fillRule="evenodd"
        d="M12.64,13.36a1,1,0,0,1,0,1.48,1.06,1.06,0,0,1-.74.3,1.05,1.05,0,0,1-.75-.3L9.5,13.21,7.85,14.84a1.05,1.05,0,0,1-.75.3,1.06,1.06,0,0,1-.74-.3,1,1,0,0,1,0-1.48L8,11.74,6.36,10.11a1,1,0,0,1,0-1.47,1.06,1.06,0,0,1,1.49,0L9.5,10.27l1.65-1.63a1.06,1.06,0,0,1,1.49,0,1,1,0,0,1,0,1.47L11,11.74Zm4.9-9.58L10.21.17a1.55,1.55,0,0,0-1.42,0L1.46,3.78A2.6,2.6,0,0,0,0,6.12V14.5a9.36,9.36,0,0,0,4.89,8.2l3.84,2.1a1.57,1.57,0,0,0,1.54,0l3.84-2.1A9.36,9.36,0,0,0,19,14.5V6.12a2.6,2.6,0,0,0-1.46-2.34Z"
      />
    </svg>
  );
};
