import React, { ReactNode } from "react";
import classNames from "classnames";

import Field, { FieldProps } from "../field/Field";
import { IconCheckmarkThin } from "../icon/IconCheckmarkThin";
import { IconGift } from "../icon/IconGift";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";

import styles from "./button-field.module.scss";

export enum ButtonColor {
  ORANGE = "ORANGE",
  DARK_BLUE = "DARK_BLUE",
}

export interface ButtonFieldVisualProps {
  buttonColor?: keyof typeof ButtonColor;
}

export interface ButtonFieldProps extends Omit<FieldProps, "children">, ButtonFieldVisualProps {
  value: string;
  onClick(): void;
  className?: string;
  buttonClassName?: string;
  children?: ReactNode;
}

export const ButtonField: React.FC<ButtonFieldProps> = ({
  children,
  value,
  onClick,
  buttonColor = "DARK_BLUE",
  className,
  buttonClassName,
  ...props
}) => {
  // check window dimensions
  const { isMobile } = useWindowDimensionsContext();

  return (
    <div className={classNames(styles.wrap, className)}>
      <IconGift className={styles.icon} fill="#FF871C" />
      <Field
        className={styles.field}
        inputClassName={styles.input}
        inputWrapClassName={styles["input-wrap"]}
        type="text"
        defaultValue={value}
        disabled={props.disabled}
      />
      <button
        className={classNames(styles.button, (styles as any)[`button--${buttonColor}`], buttonClassName)}
        onClick={onClick}
      >
        {isMobile ? <IconCheckmarkThin /> : children}
      </button>
    </div>
  );
};
