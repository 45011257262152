import React from "react";
import classNames from "classnames";
import { TFunction, useTranslation } from "react-i18next";

import { Columns } from "../../../components/columns/Columns";
import { Heading } from "../../../components/heading/Heading";
import { Layout } from "../../../components/layout/Layout";
import { Section, SectionGutter } from "../../../components/section/Section";
import { Separator } from "../../../components/separator/Separator";
import { IconCheckmarkInCircle } from "../../../components/icon/IconCheckmarkInCircle";
import { Dropdown } from "../../../components/dropdown/Dropdown";
import { AddServicesToCartButton } from "../../../components/add-services-to-cart-button/AddServicesToCartButton";
import { CurrencyCode, ServiceOfferAvailability, ServiceProductCode } from "../../../graphql/schema";
import Price, { CurrencySign, formatPrice } from "../../../components/price/Price";

import styles from "./package-section.module.scss";

export interface PackageSectionProduct {
  name: string;
  price: string;
  availability: keyof typeof ServiceOfferAvailability;
  activeSubscriptionId: string | null | undefined;
  value: ServiceProductCode;
  currencyCode: keyof typeof CurrencyCode;
}

export interface PackageSectionProps {
  products: PackageSectionProduct[];
}

export const PackageSection: React.FC<PackageSectionProps> = ({ products }) => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <Section className={styles.wrap} gutter={SectionGutter.EXTRA_LARGE} withSpace>
      <div className={styles.content}>
        <Layout className={styles.header} wrap="TABLET_PORTRAIT_MAX">
          <div className={styles["header-section"]}>
            <Heading className={styles.title} level={2}>
              {t("Select the package to get shares")}
            </Heading>
          </div>
          <div className={styles["header-section"]}>
            <p className={styles.caption}>
              {t("Choose between four different packages. You can also purchase many packages simultaneously.")}
            </p>
          </div>
        </Layout>
        {products.map((pack, index) => {
          const countries = getProductCountries(pack.value, t);
          const isHighlighted = index === products.length - 1;

          return (
            <div key={index}>
              <Separator className={styles.separator} />
              <Columns className={styles.package}>
                <div
                  className={classNames(styles["package-section"], styles["package-name"], {
                    [styles.highlighted]: isHighlighted,
                  })}
                >
                  {pack.name}
                </div>
                <div className={classNames(styles["package-section"])}>
                  <p className={styles.feature}>
                    <IconCheckmarkInCircle className={styles["feature-icon"]} fill="#fff" width={24} height={24} />
                    {t("{{price}}{{currency}} paid content in The View platform", {
                      price: formatPrice(pack.price, pack.currencyCode, true),
                      currency: CurrencySign[pack.currencyCode],
                    })}
                  </p>
                  {getProductExtras(pack.value, t).map((content, subIndex) => (
                    <p className={styles.feature} key={`${index}-${subIndex}`}>
                      <IconCheckmarkInCircle className={styles["feature-icon"]} fill="#fff" width={24} height={24} />
                      {content}
                    </p>
                  ))}
                  {countries.length > 0 && (
                    <p className={styles.feature} key={index}>
                      <IconCheckmarkInCircle className={styles["feature-icon"]} fill="#fff" width={24} height={24} />
                      {t("Allocation available for {{listLength}} regions", {
                        listLength: countries.length,
                      })}
                    </p>
                  )}
                  {countries.length > 0 && (
                    <Dropdown
                      className={styles["country-dropdown"]}
                      buttonClassName={styles["country-list-trigger"]}
                      openClassName={styles["country-list-trigger--open"]}
                      button={t("See the regions list")}
                      kind="TEXT"
                      height="AUTO"
                    >
                      <ul className={styles["contry-list"]}>
                        {countries.sort().map((country, index) => (
                          <li className={styles.country} key={index}>
                            {index + 1}. {country}
                          </li>
                        ))}
                      </ul>
                    </Dropdown>
                  )}
                </div>
                <div
                  className={classNames(styles["package-section"], styles.price, {
                    [styles.highlighted]: isHighlighted,
                  })}
                >
                  <Price price={pack.price} currency={pack.currencyCode} hideSubUnits />
                </div>
                <AddServicesToCartButton
                  className={styles["cart-button"]}
                  productCodes={[pack.value]}
                  periodCode="LIFETIME"
                  kind={isHighlighted ? "YELLOW_ROUND" : "DARK_BLUE_2"}
                  hideCartIcon
                  width={160}
                  availability={pack.availability}
                  activeSubscriptionId={pack.activeSubscriptionId}
                />
              </Columns>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

function getProductCountries(code: ServiceProductCode, t: TFunction) {
  switch (code) {
    case ServiceProductCode.VIEW_BASIC:
      return [
        t("Jordan"),
        t("Colombia"),
        t("Ukraine"),
        t("Iran"),
        t("Ghana"),
        t("Congo"),
        t("Uganda"),
        t("Yemen"),
        t("Morocco"),
        t("Mumbai (India)"),
        t("Peru"),
        t("Bali (Indonesia)"),
        t("Venezuela"),
        t("Kenya"),
        t("Lebanon"),
        t("Costa Rica"),
        t("Vietnam"),
        t("Nigeria"),
        t("South Africa"),
        t("Jamaica"),
        t("Tanzania"),
        t("Bolivia"),
        t("Fiji"),
        t("Maldives"),
        t("Philippines"),
        t("Tunisia"),
        t("Ecuador"),
        t("Uzbekistan"),
        t("Sumatra (Indonesia)"),
        t("Sri Lanka"),
        t("Dominican Republic"),
        t("Pakistan"),
        t("Siberia (Russia)"),
        t("Honduras"),
        t("Madagascar"),
        t("Samoa"),
        t("Serbia"),
        t("Cameroon"),
        t("Ivory Coast"),
        t("Senegal"),
        t("Georgia"),
        t("Java (Indonesia)"),
        t("Belize"),
        t("Mongolia"),
      ];

    case ServiceProductCode.VIEW_STANDARD:
      return [
        t("Mexico"),
        t("Trinidad & Tobago"),
        t("Turkey"),
        t("Kazakhstan"),
        t("Oman"),
        t("Egypt"),
        t("Sao Paulo (Brazil)"),
        t("Croatia"),
        t("Panama"),
        t("Azerbaijan"),
        t("Suriname"),
        t("Thailand"),
        t("Chile"),
        t("Argentina"),
        t("Rio de Janeiro (Brazil)"),
        t("Uruguay"),
        t("The Bahamas"),
        t("French Polynesia, Bora Bora, Tahiti, Moorea"),
        t("Mauritius"),
        t("Barbados"),
        t("Cook Islands"),
        t("Puerto Rico"),
        t("Curacao"),
        t("Saint Kitts & Nevis"),
        t("Tibet (China)"),
        t("Cuba"),
        t("Bulgaria"),
      ];

    case ServiceProductCode.VIEW_SUPER:
      return [
        t("Estonia"),
        t("Italy"),
        t("Romania"),
        t("South Korea"),
        t("Spain"),
        t("Greece"),
        t("New Zealand"),
        t("Poland"),
        t("France"),
        t("Hungary"),
        t("Slovenia"),
        t("Portugal"),
        t("Malaysia"),
        t("St. Petersburg (Russia)"),
        t("Czech Republic"),
        t("Beijing (China)"),
        t("Seychellen"),
        t("Cyprus"),
        t("Finland"),
        t("British Virgin Islands"),
        t("Malta"),
        t("Lithuania"),
        t("Hong Kong (China)"),
        t("Saint Martin"),
        t("Andorra"),
        t("Aruba"),
        t("Canary Islands"),
        t("Antigua & Barbuda"),
      ];

    case ServiceProductCode.VIEW_ULTRA:
      return [
        t("Netherlands"),
        t("United Arab Emirates"),
        t("Australia"),
        t("Sweden"),
        t("Moscow (Russia)"),
        t("Saudi-Arabia"),
        t("Germany"),
        t("Japan"),
        t("United Kingdom"),
        t("Austria"),
        t("Canada"),
        t("Singapore"),
        t("Qatar"),
        t("Denmark"),
        t("Hawaii (United States)"),
        t("Ireland"),
        t("Belgium"),
        t("Illinois Chicago (United States)"),
        t("Gibraltar"),
        t("Switzerland"),
        t("Alaska (United States)"),
        t("Monaco"),
        t("Bermuda"),
        t("Luxembourg"),
        t("Saint Barths"),
        t("Rhode Island"),
        t("San Marino"),
        t("Brunei"),
        t("Cayman Islands"),
        t("Norway"),
        t("Channel Islands"),
        t("Liechtenstein"),
        t("Macao"),
        t("Vatican City"),
        t("Kuwait"),
        t("Shanghai (China)"),
        t("Iceland"),
        t("Florida Keys (United States)"),
        t("Connecticut (United States)"),
        t("Washington D.C. (United States)"),
        t("Delaware (United States)"),
        t("Nevada (United States)"),
        t("Washington State (United States)"),
        t("Taiwan"),
      ];

    case ServiceProductCode.VIEW_ULTRA_PLUS:
      return [];

    default:
      return [];
  }
}

function getProductExtras(code: ServiceProductCode, t: TFunction) {
  switch (code) {
    case ServiceProductCode.VIEW_STANDARD:
      return [t("Discounts")];

    case ServiceProductCode.VIEW_SUPER:
      return [t("Discounts & Benefits")];

    case ServiceProductCode.VIEW_ULTRA:
      return [t("Discounts, Benefits and VIP Pass")];

    case ServiceProductCode.VIEW_ULTRA_PLUS:
      return [t("Discounts, Benefits and VIP Pass"), t("Can be used for any offered region")];

    default:
      return [];
  }
}
