export interface IconSubscriptionsProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

function IconSubscriptions({ hasTitle, className, width = 36, height = 36 }: IconSubscriptionsProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
    >
      {hasTitle && <title>IconSubscriptions</title>}
      <path d="M4,6.71A18,18,0,1,1,0,18a1,1,0,0,1,2,0A16,16,0,1,0,5.51,8H11a1,1,0,0,1,0,2H5.47A3.5,3.5,0,0,1,2,6.5V1A1,1,0,0,1,4,1V6.5A1.48,1.48,0,0,0,4,6.71ZM9,17h9a1,1,0,0,1,0,2H9A1,1,0,0,1,9,17ZM19,5V18a1,1,0,0,1-2,0V5A1,1,0,1,1,19,5Z" />
    </svg>
  );
}

export default IconSubscriptions;
