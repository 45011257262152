interface IconLogoFacebookProps {
  hasTitle?: boolean;
  width?: number;
  height?: number;
  className?: string;
}

function IconLogoFacebook({ hasTitle, width = 14, height = 15, className }: IconLogoFacebookProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 15">
      {hasTitle && <title>IconLogoFacebook</title>}
      <path
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M0 0v13.992h7.451V8.587H5.63V6.381H7.45V4.528a2.44 2.44 0 012.44-2.44h1.905v1.984h-1.363a.775.775 0 00-.775.775v1.534h2.104l-.291 2.206H9.658v5.405h4.334V0H0z"
        transform="translate(-1421 -5582) translate(0 5402) translate(1421 180.5)"
      ></path>
    </svg>
  );
}

export default IconLogoFacebook;
