import React from "react";

interface IconArrowClassicProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconArrowClassic: React.FC<IconArrowClassicProps> = ({
  hasTitle,
  className,
  fill,
  width = 48,
  height = 48,
}) => {
  return (
    <svg
      fill={fill}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={className}
    >
      {hasTitle && <title>IconArrowClassic</title>}
      <path d="M10.416 25l.06.063 13.231 13.23a1 1 0 01-1.414 1.414l-13.23-13.23a3.486 3.486 0 01-1.01-2.139 1.112 1.112 0 010-.679 3.485 3.485 0 011.007-2.13L22.273 8.295a1 1 0 011.415 1.412L10.475 22.943a1.524 1.524 0 00-.054.057H43c1.333 0 1.333 2 0 2H10.416z"></path>
    </svg>
  );
};
