interface IconArrowTriangleProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

function IconArrowTriangle({ hasTitle, className, width = 10, height = 8 }: IconArrowTriangleProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 10 8">
      {hasTitle && <title>IconArrowTriangle</title>}
      <path
        fillRule="evenodd"
        d="M9.855 1.62l-4.283 5.6a.723.723 0 01-1.143 0L.144 1.62C-.21 1.158.127.5.715.5h8.568c.59 0 .927.658.572 1.12"
      />
    </svg>
  );
}

export default IconArrowTriangle;
