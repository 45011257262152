import React from "react";

export interface IconSendProps {
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
  opacity?: number;
}

export const IconSend: React.FC<IconSendProps> = ({ className, fill, width, height, opacity }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 38"
    >
      <path
        opacity={opacity}
        fill={fill}
        d="M49.9,5A6,6,0,0,0,43,0L2,6.5A2.26,2.26,0,0,0,.3,7.8,2.37,2.37,0,0,0,1.4,11l15.2,7.5V32a1.18,1.18,0,0,0,1.7,1.1l4.8-2.4A1.19,1.19,0,1,0,22,28.6l-3.1,1.5V21L32.8,37.2a1.56,1.56,0,0,0,.8.6,2.37,2.37,0,0,0,3.2-1.1L49.4,8.6A6.24,6.24,0,0,0,49.9,5ZM47.2,7.6l-12.6,28-15-17.5L42.2,7.6a1.21,1.21,0,1,0-1-2.2l-23.5,11L2.4,8.8l41-6.4a3.54,3.54,0,0,1,4.1,3A2.87,2.87,0,0,1,47.2,7.6Z"
      />
    </svg>
  );
};
