import React from "react";

export interface IconHouseProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconHouse: React.FC<IconHouseProps> = ({ hasTitle, className, width = 13, height = 15 }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 15" className={className}>
    {hasTitle && <title>IconHouse</title>}
    <path
      fillRule="evenodd"
      d="M6.5 10.938c-.699 0-1.265-.56-1.265-1.25 0-.691.566-1.25 1.265-1.25s1.265.559 1.265 1.25c0 .69-.566 1.25-1.265 1.25zm6.206-5.413L7.158.262a.956.956 0 00-1.316 0L.294 5.525c-.617.584-.2 1.613.658 1.613h.487v6.3A1.574 1.574 0 003.021 15h6.958c.873 0 1.582-.7 1.582-1.563v-6.3h.487c.857 0 1.275-1.028.658-1.612z"
    />
  </svg>
);
