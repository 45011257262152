import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import * as yup from "yup";

import { Routes, ServiceInfo, TheViewProductCodes } from "../../../services/constants";
import { DigitalProduct } from "../../../components/digital-product/DigitalProduct";
import { Loader } from "../../../components/loader/Loader";
// import Price from "../../../components/price/Price";
import { Section } from "../../../components/section/Section";
import { notUndefinedOrNull } from "../../../services/notUndefinedOrNull";
// import { isFirstPeriodDiscounted } from "../../../services/isFirstPeriodDiscounted";
import { findBestServiceOffer } from "../../../services/findBestServiceOffer";
import {
  ServiceProductCode,
  useDigitalProductsViewQuery,
  PaymentEntityType,
  useDigitalProductsCreateTheViewForDagsCartMutation,
  useDigitalProductsCreateProductsForDagsCartMutation,
  ServiceOfferAvailability,
  ServiceSubscriptionStatus,
  useViewerQuery,
  UserXeraInfo,
  useRegisterWebinarMutation,
} from "../../../graphql/schema";
import { showGraphqlValidationErrors } from "../../../services/showGraphqlValidationErrors";
import NotFound from "../../../components/not-found/NotFound";
import { ServiceIcon } from "../../../components/service-icon/ServiceIcon";
import { TheView, TheViewExclusiveOfferMembership } from "./the-view/TheView";
import { OfferSubmitMethod } from "../../../components/digital-product-exclusive-offer/offers/Offers";
import { useHandelPayment } from "../../../hooks/useHandlePayment";
import assertNever from "../../../services/assertNever";
// import { useServicePeriodTranslation } from "../../../hooks/useServicePeriodTranslation";
import { hasAvailableOffers } from "../../../services/hasAvailableOffers";
import { getServicesOffers } from "../../../services/getServicesOffers";
import { PAYMENT_METHODS_ORDER } from "../../../components/payment-methods-list/PaymentMethodsList";
import { Badge } from "../../../components/badge/Badge";
import { Countdown } from "../../../components/countdown/Countdown";
import { DigitalProductBase } from "../../../components/digital-product-base/DigitalProductBase";
import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { Button } from "../../../components/button/Button";
import { Color } from "../../../services/buttonLinkConstants";
import { Modal, ModalKind } from "../../../components/modal/Modal";
import { PanelWidth } from "../../../components/panel/Panel";
import Field from "../../../components/field/Field";

import styles from "./digital-products-view.module.scss";

type WebinarFormInfo = {
  email: string;
};
function useRegisterWebinarForm() {
  const webinarInfoValidationSchema: yup.SchemaOf<WebinarFormInfo> = yup.object().shape({
    email: yup.string().email().required(),
  });

  const { register, handleSubmit, errors } = useForm<WebinarFormInfo>({
    resolver: yupResolver(webinarInfoValidationSchema),
  });

  return {
    register,
    handleSubmit,
    errors,
  };
}

export default function DigitalProductsView() {
  const [t] = useTranslation();
  // const getPeriodName = useServicePeriodTranslation();
  const { push } = useHistory();
  const {
    register: webinarRegistration,
    handleSubmit: handleWebinarSubmit,
    errors: webinarInputErrors,
  } = useRegisterWebinarForm();

  const query = useDigitalProductsViewQuery({
    variables: { theViewProductCodes: TheViewProductCodes, paymentMethod: PAYMENT_METHODS_ORDER[0] },
  });
  const [paymentResId, setPaymentResId] = useState<string | null>(null);
  const [createTheViewForDagsCart] = useDigitalProductsCreateTheViewForDagsCartMutation();
  const [registerWebinar] = useRegisterWebinarMutation();
  const [createProductsForDagsCart] = useDigitalProductsCreateProductsForDagsCartMutation();
  const handleTheViewForDagsPayment = useHandelPayment({
    onSuccess: () => push(`${Routes.CHECKOUT_RESULT}/CART_PURCHASE_OF_SERVICE_PRODUCTS/${paymentResId}`),
    onError: () => toast.error(t("Something went wrong")),
    onUnknownResponse: () => console.warn("payment status unknwon"),
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLHMetaverseModalOpen, setIsLHMetaverseModalOpen] = useState(false);
  const [isTravelViewModalOpen, setIsTravelViewModalOpen] = useState(false);
  const [isWeb3ModalOpen, setIsWeb3ModalOpen] = useState(false);
  const [isAirdropsModalOpen, setIsAirdropsModalOpen] = useState(false);
  const [isCommunityModalOpen, setIsCommunityModalOpen] = useState(false);
  const [isWebinarsModalOpen, setIsWebinarsModalOpen] = useState(false);

  const { data: me } = useViewerQuery({ fetchPolicy: "network-only" });

  const services = [query.data?.forex, query.data?.forexPro, query.data?.membershipBasic].filter(notUndefinedOrNull);

  // SF Business Fee active subscription
  const activeSfFeeSubscription = query.data?.me.activeBusinessFeeSubscription;

  // check if SF Buseness Fee subscription is to expire in 14 or less days
  const isSfFeeExipring =
    new Date(activeSfFeeSubscription?.dateEnd || 0).getTime() <= daysToMilliseconds(14) + Date.now() &&
    new Date(activeSfFeeSubscription?.dateEnd || 0) > new Date();

  // check if SF Business fee subscription grace period has expired
  const isSfFeeInGracePeriod =
    new Date(activeSfFeeSubscription?.gracePeriodDateEnd || 0).getTime() <= daysToMilliseconds(30) + Date.now() &&
    new Date(activeSfFeeSubscription?.gracePeriodDateEnd || 0) > new Date();

  function getDetail(availability: keyof typeof ServiceOfferAvailability | keyof typeof ServiceSubscriptionStatus) {
    switch (availability) {
      case "UNAVAILABLE_ALREADY_SUBSCRIBED":
      case "UNAVAILABLE_FOR_UPGRADE":
      case "ACTIVE":
      case "PAYMENT_PENDING":
      case "PAYMENT_FAILED":
      case "DISCONTINUED":
        return "";
      // return t("Active membership");

      case "AVAILABLE_FOR_PURCHASE":
      case "AVAILABLE_FOR_UPGRADE":
        return "";
      // return t("Purchase membership");

      case "UNAVAILABLE_ALREADY_IN_CART":
        return t("In cart");

      case "UNAVAILABLE_FOR_COUNTRY":
        return t("Unavailable");

      case "EXPIRED":
      case "CANCELLED":
        return t("Discontinued");

      default:
        return assertNever(availability);
    }
  }

  async function handleTheViewOfferSubmit(paymentMethod: OfferSubmitMethod, ids: string[], totalPrice: string) {
    const dagOffersIds = (query.data?.me.theViewForDags?.offers ?? [])
      .filter((off) => ids.includes(off.id))
      .map((off) => off.dagOffer.id);

    try {
      const cartRes = await createTheViewForDagsCart({ variables: { dagOffersIds } });
      const paymentRes = await handleTheViewForDagsPayment({
        errorMessage: t("Something went wrong"),
        entityId: cartRes.data?.createTheViewForDagsCart.id,
        totalPrice,
        paymentVariables: {
          entityType: PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS,
          currencyCode: "DAG",
        },
        selectedPaymentSource: {
          id: null,
          paymentMethod,
        },
      });

      setPaymentResId(paymentRes.data?.payments.create.id ?? null);
    } catch (e) {
      if (!showGraphqlValidationErrors(t, e) && e instanceof Error) {
        toast.error(e.message, { autoClose: false });
      }
    }
  }

  async function handleSpecialOfferSubmit(paymentMethod: OfferSubmitMethod, ids: string[], totalPrice: string) {
    try {
      const cartRes = await createProductsForDagsCart({ variables: { offerIds: ids } });
      const paymentRes = await handleTheViewForDagsPayment({
        errorMessage: t("Something went wrong"),
        entityId: cartRes.data?.createProductsForDagsCart.id,
        totalPrice,
        paymentVariables: {
          entityType: PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS,
          currencyCode: "DAG",
        },
        selectedPaymentSource: {
          id: null,
          paymentMethod,
        },
      });

      setPaymentResId(paymentRes.data?.payments.create.id ?? null);
    } catch (e) {
      if (!showGraphqlValidationErrors(t, e) && e instanceof Error) {
        toast.error(e.message, { autoClose: false });
      }
    }
  }

  if (query.error) {
    return <NotFound>{t("Something went wrong. Please reload the page or contact support")}</NotFound>;
  }

  const theViewSpecialOfferMemberships: TheViewExclusiveOfferMembership[] = (
    query.data?.me.theViewForDags?.offers ?? []
  ).map((offer) => ({
    id: offer.id,
    labelText: offer.eurOffer.labelText,
    datePurchased: offer.eurOfferPurchaseDate,
    eurPrice: offer.eurOffer.fullPrice,
    dagPrice: offer.dagOffer.fullPrice,
  }));

  const hasTheViewOffers = hasAvailableOffers(getServicesOffers(query.data?.theView ?? []));
  const hasTheViewOffersOrAccess =
    hasAvailableOffers(getServicesOffers(query.data?.theView ?? [])) ||
    theViewSpecialOfferMemberships.length > 0 ||
    query.data?.me.theiaAccess;

  const availablePaymentMethods = query?.data?.me.paymentMethods.filter((m) => m.enabled).map((m) => m.method) ?? [];
  const dagsBalance = query?.data?.viewer?.coinAccountBalance ?? 0;

  const xeraUser = (user: UserXeraInfo) => (
    <>
      <div style={{ fontSize: "12px", padding: "5px 0" }}>
        {user.username}
        <span style={{ fontSize: "10px", color: "#666", fontWeight: 600 }}>{user.isIncomeCentre ? " (IC)" : null}</span>
      </div>
      <div style={{ fontSize: "12px", padding: "5px 0" }}>{user.xeraUsername}</div>
      <div style={{ fontSize: "12px", padding: "5px 0" }}>{user.xeraEmail}</div>
    </>
  );

  const handleRegisterWebinar = handleWebinarSubmit(async ({ email }) => {
    try {
      const result = await registerWebinar({
        variables: { email },
      });

      if (result.data) {
        toast.success(t("Registered Successfully!"));
        setIsWebinarsModalOpen(false);
      } else {
        const errorMessage = result?.errors?.[0]?.message;
        if (errorMessage) {
          // handle error?
        } else {
          toast.error(t("Something went wrong"));
        }
      }
    } catch (err: any) {
      console.error("Registering to Webinar failed", err);

      if (!showGraphqlValidationErrors(t, err) && err instanceof Error) {
        toast.error(err?.message, { autoClose: false });
      }
    }
  });

  return (
    <>
      <Section gutter={100} center withSpace className={styles.wrap}>
        <Loader visibleOnFirstLoad={query} />

        {me?.me?.xera && me?.me?.xera?.length > 0 && (
          <DigitalProductBase
            key={"XERAPRO"}
            title={t("XERAPRO Login Details")}
            logo={<AsyncImg className={styles.view} src="/images/XERAPRO.png" />}
            publicUrl={undefined}
            price={undefined}
            exclusiveOffer={undefined}
            onExclusiveOfferClick={() => null}
          >
            <Button
              className={styles["access-product-link"]}
              borderRadius="SMALL"
              color={Color.DARK_BLUE}
              fontSize={18}
              onClick={() => setIsModalOpen(true)}
            >
              <span className={styles["link-label"]}>{t("Show Details")}</span>
            </Button>
            <Modal
              isOpen={isModalOpen}
              close={() => setIsModalOpen(false)}
              kind={ModalKind.PRIMARY}
              panelProps={{
                imagePath: "/images/XERAPRO.png",
                width: PanelWidth.SMALL,
                title: t("XERAPRO Login Details"),
                caption: t("Here can you see your XERAPRO login details"),
                label: t("XERAPRO"),
                titleClassName: styles["modal-title"],
                captionClassName: styles["modal-caption"],
              }}
            >
              <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
                {<AsyncImg className={styles.view} style={{ width: "100px" }} src="/images/XERAPRO.png" />}
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridGap: "0",
                }}
              >
                <div style={{ backgroundColor: "#fafafa", fontWeight: 600, fontSize: "12px", padding: "5px 0" }}>
                  SF user
                </div>
                <div style={{ backgroundColor: "#fafafa", fontWeight: 600, fontSize: "12px", padding: "5px 0" }}>
                  XERAPRO user
                </div>
                <div style={{ backgroundColor: "#fafafa", fontWeight: 600, fontSize: "12px", padding: "5px 0" }}>
                  XERAPRO email
                </div>
                {me.me.xera.map((user) => xeraUser(user))}
              </div>
              <div
                style={{
                  maxWidth: "700px",
                  border: "1px solid #ddd",
                  backgroundColor: "#fafafa",
                  padding: "7px 15px",
                  marginTop: "15px",
                }}
              >
                <p style={{ marginTop: "15px", marginBottom: "0", fontWeight: 600 }}>
                  In order to gain access to your accounts in XERAPRO:
                </p>
                <ol>
                  <li>
                    Please go to the single sign-on platform{" "}
                    <a
                      style={{ color: "#ffb100" }}
                      href="https://homnifi.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      homnifi.com
                    </a>
                  </li>
                  <li>Click on Go to App and there Forget Password, choose Reset Anyway.</li>
                  <li>Enter the e-mail address you have used to register your account in SF</li>
                  <li>Open your mailbox and click on the link you received</li>
                  <li>Set up a new password and go back to front page</li>
                  <li>Log in using your username (seen in the e-mail you received) or e-mail address</li>
                  <li>Choose either a personal or company account</li>
                  <li>
                    Set up your 2-FA (2 factor authentication) with Google Authenticator (download from app stores) for
                    account security
                  </li>
                  <li>Choose the platforms to enroll to (recommended to choose all) and you`re done!</li>
                </ol>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  XERAPRO operates with unique user access, disallowing special characters in usernames and duplicate
                  e-mails. In case you had multiple accounts/income centers with the same e-mail or used special
                  characters in your username, then your username(s) have been modified, and/or numbers have been added
                  to duplicate e-mails for differentiation.
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  For example, if you previously had a main account and two income centers (or family members)
                  registered with the same e-mail address, for example with yourusername@gmail.com, then your e-mails
                  for those accounts will be like this: Main account - yourusername@gmail.com IC1 or family member 1: -
                  yourusername+1@gmail.com IC2 or family member 2: - yourusername+2@gmail.com
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  Each income center is a separate account in XERAPRO and you should follow steps 1-9 for each one of
                  those accounts, using the new adjusted e-mail addresses (XERAPRO e-mail, as shown above). All the +1,
                  +2 etc e-mails are forwarded to your main e-mail (yourusername@gmail.com) so you get all your logins
                  in one and the same mailbox.
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  In case you face difficulties/issues in logging in, please submit a support ticket (
                  <a
                    style={{ color: "#ffb100" }}
                    href="https://support.homnifi.com/support/home"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.homnifi.com/support/home
                  </a>
                  ) and the support team will assist you
                </p>
              </div>
            </Modal>
          </DigitalProductBase>
        )}

        {activeSfFeeSubscription && isSfFeeExipring && (
          <Badge
            className={classNames(styles.note, styles["note--expiration"])}
            label={
              <h4 className={styles["expiry-notification-title"]}>
                {t("Your subscription for the {{product}} will renew in", {
                  product: ServiceInfo[activeSfFeeSubscription.productCodes[0]].title,
                })}
              </h4>
            }
            badgeGutter={"LARGE"}
            wrap
          >
            <Countdown
              targetDate={
                activeSfFeeSubscription && activeSfFeeSubscription.dateEnd
                  ? activeSfFeeSubscription?.dateEnd?.toString()
                  : ""
              }
              onCountDownEnd={query.refetch}
            />
          </Badge>
        )}

        {activeSfFeeSubscription && isSfFeeInGracePeriod && (
          <Badge
            className={classNames(styles.note, styles["note--grace-period"])}
            label={
              <h4 className={styles["expiry-notification-title"]}>
                {t("Your subscription grace period for the {{product}} will expire in", {
                  product: ServiceInfo[activeSfFeeSubscription.productCodes[0]].title,
                })}
                :
              </h4>
            }
            badgeGutter={"LARGE"}
            wrap
          >
            <Countdown
              targetDate={activeSfFeeSubscription?.gracePeriodDateEnd.toString()}
              onCountDownEnd={query.refetch}
            />
          </Badge>
        )}

        {hasTheViewOffersOrAccess && (
          <TheView
            publicUrl={hasTheViewOffers ? Routes.THE_VIEW_LANDING : undefined}
            className={styles.view}
            packages={query.data?.theView}
            detail={hasTheViewOffers ? t("Purchase a package") : undefined}
            key="theia-service"
            hasAccess={query.data?.me.theiaAccess ?? null}
            exclusiveOffer={
              query.data?.me.theViewForDags && theViewSpecialOfferMemberships.length > 0
                ? {
                    dagsBalance,
                    dateStart: new Date(query.data.me.theViewForDags.dateStart),
                    dateEnd: new Date(query.data.me.theViewForDags.dateEnd),
                    expiresInMS: Number(query.data.me.theViewForDags.expiresInSeconds) * 1000,
                    memberships: theViewSpecialOfferMemberships,
                    onSubmit: handleTheViewOfferSubmit,
                    availablePaymentMethods,
                  }
                : undefined
            }
          />
        )}

        {services.map((service) => {
          const offer = findBestServiceOffer(service.offers);
          const offerCampaign = getExclusiveOfferCampaignInfo(service.code, query.data?.me.productsForDags ?? []);
          const exclusiveOffer = offerCampaign?.offers[0];
          const activeSubscription = query.data?.me.serviceSubscriptions.find((s) =>
            s.productCodes.includes(service.code),
          );

          if (!offer && !activeSubscription) {
            return null;
          }

          return (
            <DigitalProduct
              key={service.code}
              logo={
                <ServiceIcon
                  productCode={service.code}
                  forexLogoClassName={styles.forex}
                  businessFeeLogoClassName={styles["business-fee"]}
                  bankLogoClassName={styles.bank}
                />
              }
              title={service.name}
              detail={getDetail((offer?.availability || activeSubscription?.status) ?? "UNAVAILABLE_FOR_COUNTRY")}
              price={
                undefined
                /*
                offer && (
                  <Price
                    price={offer.fullPrice}
                    discountPrice={offer.discountedPrice}
                    currency={offer.currency.code}
                    isDigitalProduct
                    digitalProductPurchaseType={offer.period.code}
                  />
                )*/
              }
              priceNext={
                undefined
                /*
                offer && isFirstPeriodDiscounted(offer)
                  ? t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                      price: offer.fullPriceNext,
                      currencySign: offer.currency.sign,
                      period: getPeriodName(offer.period.code, "SHORT"),
                    })
                  : undefined
				  */
              }
              productCode={service.code}
              periodCode={offer?.period.code || activeSubscription?.periodCode}
              availability={getOfferAvailability(offer, !!activeSubscription)}
              activeSubscriptionId={offer?.upgradableSubscriptions[0]?.id ?? activeSubscription?.id}
              producerName={renderProducerName(service.name)}
              externalUrl={getAccessUrl(service.code)}
              publicUrl={
                offer ||
                [ServiceProductCode.BUSINESS_FEE_BASIC, ServiceProductCode.BUSINESS_FEE_PRO].includes(service.code)
                  ? ServiceInfo[service.code]?.publicUrl || undefined
                  : undefined
              }
              exclusiveOfferInfo={
                offerCampaign &&
                exclusiveOffer && {
                  dateStart: new Date(offerCampaign.dateStart),
                  dateEnd: new Date(offerCampaign.dateEnd),
                  expiresInMS: offerCampaign.expiresInSeconds * 1000,
                  dagsBalance,
                  id: exclusiveOffer.id,
                  periodCode: exclusiveOffer.period.code,
                  priceInDag: exclusiveOffer.fullPrice,
                  priceInEur: exclusiveOffer.fullPriceEur,
                  availablePaymentMethods,
                  onSubmit: handleSpecialOfferSubmit,
                }
              }
              gracePeriod={
                activeSubscription &&
                [ServiceSubscriptionStatus.PAYMENT_FAILED, ServiceSubscriptionStatus.PAYMENT_PENDING].includes(
                  activeSubscription.status,
                )
                  ? {
                      dateEnd: new Date(activeSubscription.gracePeriodDateEnd),
                      expiresInMS: activeSubscription.gracePeriodExpiresInSeconds * 1000,
                      subscription: {
                        ...activeSubscription,
                        billingCurrency: query.data?.me.currency ?? activeSubscription.billingCurrency,
                      },
                    }
                  : undefined
              }
              onGracePeriodRenewalSuccess={() => {
                query.refetch();
              }}
              isLoading={query.loading}
              onPaymentMethodChange={(paymentMethod) => query.refetch({ paymentMethod })}
              serviceCode={service.code}
              comingSoon={service.code === ServiceProductCode.MEMBERSHIP_PRO}
              isPaymentInfoVisible={service.code !== ServiceProductCode.MEMBERSHIP_PRO}
            />
          );
        })}
        <DigitalProductBase
          key="lh-metaverse"
          title={t("LHMetaverse")}
          logo={<AsyncImg className={styles.view} src="/images/metaverse-icon.svg" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
          bottomMessage="Coming soon"
        >
          <Button
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            onClick={() => setIsLHMetaverseModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Show Details")}</span>
          </Button>
          <Modal
            isOpen={isLHMetaverseModalOpen}
            close={() => setIsLHMetaverseModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/metaverse-icon.svg",
              width: PanelWidth.SMALL,
              title: t("LHMetaverse"),
              caption: t("LHMetaverse details"),
              label: t("LHMetaverse"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("LHMetaverse")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Metaverse, in short, is a virtual world. In most cases so far they have been with a cartoonish graphics,
                not appealing to most of the people. There are some companies that have started to create metaverses
                with realistic, even life-like graphics, giving people a more appealing and relatable experience. This
                is also the route we are on.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                The way we have our social media accounts and our personal/business website in the web2, similarly we
                have our avatar and location in metaverse. The location can be a land plot with a private residence
                (home), an apartment, commercial building or office, an island - imagination and sometimes
                boundaries/concepts of the metaverse are the only limits in the digital world. Our presence in the
                metaverse is like our presence in the web2 space, but in an enhanced visual representation, more
                immersive, more interactive.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Any account owned in web2 belongs to the company running the platform, for example social media.
                Shifting to web3 each user becomes the owner of their account, owner of their products, owner of their
                digital location. This principle is followed in the metaverse project as well.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                If you remember, then Kris has been talking about these principles in depth at several conferences,
                always explaining the differences of web2 and web3, emphasizing the benefits and rights web3 can bring
                us. These principles were supposed to be implemented also into The View, but as the View management team
                cut both Kris and Nils out of the project and went their own way, the decision to put together a
                separate metaverse project with ownership to the SF community was a simple one to make. The plans,
                vision and knowhow from Kris and Nils are now flowing into this metaverse, mixed with the experience of
                partners who have been working in this space for years already.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                The concept is simple, yet beautiful - an aesthetic virtual world where users can own and create a
                virtual real estate to their liking, their dream house or a unique showcase for their business. The
                project is built on blockchain where users are in control of their persona and their possessions.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Our community members have been receiving tokens via airdrop. These tokens represent ownership of the
                project. Any profit generated by the project is distributed to the token holders. The basic info about
                the ownership tokens (LHO) has been already previously shared (revisit the news section).
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Once the project launches the first revenue is from plot/land sale. Preparations are ongoing to set up
                also the first business model that users can start utilizing in the metaverse. This business model
                brings revenue share to the project - majority of business income to the user, and a part to the project
                which is then also distributed to the token holders.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                The extraordinary growth in the AI sector will impact the metaverse worlds and concept in the future.
                Right now it is difficult to predict how this space will evolve, but it is definitely an interesting
                moment to be part of such an industry as it is in its baby shoes. It is fair to expect that AI will
                bring a lot of opportunities for users to create in the metaverse.
              </p>
            </div>
          </Modal>
        </DigitalProductBase>

        <DigitalProductBase
          key={"travelview"}
          title={t("TravelView")}
          logo={<AsyncImg className={styles.view} src="/images/travelview.svg" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
          bottomMessage="Coming in 2025"
        >
          <Button
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            onClick={() => setIsTravelViewModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Show Details")}</span>
          </Button>
          <Modal
            isOpen={isTravelViewModalOpen}
            close={() => setIsTravelViewModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/travelview.svg",
              width: PanelWidth.SMALL,
              title: t("TravelView"),
              caption: t("TravelView details"),
              label: t("TravelView"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("TravelView")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                TravelView is a virtual reality (VR) application designed to bring the world’s most captivating
                destinations directly to you through immersive 360-degree videos.. An app, that allows you to virtually
                travel to the most iconic cities, hidden gems, and breathtaking landscapes across the globe—all from the
                comfort of your home. Our goal is to make you feel as if you're truly standing at the heart of each
                destination.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Start your journey in our vibrant home lobby, where you can easily explore a map of the world and select
                any country or city you wish to visit. Whether you're craving the sights of bustling city streets,
                serene nature reserves, or historic landmarks, TravelView offers numerous possibilities for discovery.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                To ensure that every experience in TravelView is as authentic and engaging as possible, we’ll be
                partnering with a wide array of companies that have already created incredible 360-degree content.
                Additionally, we’ll be creating an opportunity for independent creators and videographers to upload and
                share their own 360-degree travel experiences. This creates a unique opportunity for content creators to
                showcase destinations they are passionate about while offering users a continually growing library of
                global adventures. As the community grows, so too will the diversity of experiences—from popular
                landmarks to hidden gems, allowing for both a curated and crowd-sourced travel journey.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                So whether you’re a curious traveler, a seasoned globetrotter, or simply someone who wants to experience
                the world, TravelView offers an ever-expanding world of possibility.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                As the VR and travel industries continue to grow, there is an increasing demand for accessible, virtual
                travel experiences. People are craving ways to explore the world, whether due to budget constraints,
                time limitations, or the desire to experience new places without the commitment of physical travel.
                Thanks to this shift the virtual tourism is becoming a growing trend. As consumers continue to embrace
                virtual experiences, the demand for apps like TravelView is poised to increase.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                This app is currently at its initial stages. The concept is in place, next step is designs and then to
                development. Simultaneously with development we can work on the collaborations for the video content.
                Part of this application will be owned by our existing community through tokenized shareholding - this
                is another added value for being part of the community, as the ownership is gifted to the community
                members. Some bigger content collaborators (companies) might also become shareholders, plus there will
                be some external investors who we are looking for right now to help to develop and launch this
                application. The plan is to launch this app during the year of 2025, most probably it will be somewhere
                in the end of the year.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Financially, a VR travel experience app presents a significant opportunity. With travel becoming more
                expensive and unpredictable, many people are seeking affordable ways to satisfy their wanderlust.
                Monetization of the app will be either a one time payment or monthly subscription through the app store.
                There might be additional bundles that the customers can purchase, but this will be more clear once in
                the conversations with collaborating companies and content creators. From the subscription fees and
                purchases, profits are then distributed via crypto payments as the easiest, fastest and most cost
                effective way.
              </p>
            </div>
          </Modal>
        </DigitalProductBase>
        <DigitalProductBase
          key={"web3-social-media"}
          title={t("WEB3 Social Media")}
          logo={<AsyncImg className={styles.view} src="/images/web-3-black-icon.webp" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
          bottomMessage="Coming in Q1 2025"
        >
          <Button
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            onClick={() => setIsWeb3ModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Show Details")}</span>
          </Button>
          <Modal
            isOpen={isWeb3ModalOpen}
            close={() => setIsWeb3ModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/web-3-black-icon.webp",
              width: PanelWidth.SMALL,
              title: t("WEB3 Social Media"),
              caption: t("WEB3 Social Media details"),
              label: t("WEB3 Social Media"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("WEB3 Social Media")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                TravelView is a virtual reality (VR) application designed to bring the world’s most captivating
                destinations directly to you through immersive 360-degree videos.. An app, that allows you to virtually
                travel to the most iconic cities, hidden gems, and breathtaking landscapes across the globe—all from the
                comfort of your home. Our goal is to make you feel as if you're truly standing at the heart of each
                destination.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Start your journey in our vibrant home lobby, where you can easily explore a map of the world and select
                any country or city you wish to visit. Whether you're craving the sights of bustling city streets,
                serene nature reserves, or historic landmarks, TravelView offers numerous possibilities for discovery.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                To ensure that every experience in TravelView is as authentic and engaging as possible, we’ll be
                partnering with a wide array of companies that have already created incredible 360-degree content.
                Additionally, we’ll be creating an opportunity for independent creators and videographers to upload and
                share their own 360-degree travel experiences. This creates a unique opportunity for content creators to
                showcase destinations they are passionate about while offering users a continually growing library of
                global adventures. As the community grows, so too will the diversity of experiences—from popular
                landmarks to hidden gems, allowing for both a curated and crowd-sourced travel journey.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                So whether you’re a curious traveler, a seasoned globetrotter, or simply someone who wants to experience
                the world, TravelView offers an ever-expanding world of possibility.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                As the VR and travel industries continue to grow, there is an increasing demand for accessible, virtual
                travel experiences. People are craving ways to explore the world, whether due to budget constraints,
                time limitations, or the desire to experience new places without the commitment of physical travel.
                Thanks to this shift the virtual tourism is becoming a growing trend. As consumers continue to embrace
                virtual experiences, the demand for apps like TravelView is poised to increase.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                This app is currently at its initial stages. The concept is in place, next step is designs and then to
                development. Simultaneously with development we can work on the collaborations for the video content.
                Part of this application will be owned by our existing community through tokenized shareholding - this
                is another added value for being part of the community, as the ownership is gifted to the community
                members. Some bigger content collaborators (companies) might also become shareholders, plus there will
                be some external investors who we are looking for right now to help to develop and launch this
                application. The plan is to launch this app during the year of 2025, most probably it will be somewhere
                in the end of the year.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Financially, a VR travel experience app presents a significant opportunity. With travel becoming more
                expensive and unpredictable, many people are seeking affordable ways to satisfy their wanderlust.
                Monetization of the app will be either a one time payment or monthly subscription through the app store.
                There might be additional bundles that the customers can purchase, but this will be more clear once in
                the conversations with collaborating companies and content creators. From the subscription fees and
                purchases, profits are then distributed via crypto payments as the easiest, fastest and most cost
                effective way.
              </p>
            </div>
          </Modal>
        </DigitalProductBase>
        <DigitalProductBase
          key={"webinars"}
          title={t("Webinars")}
          logo={<AsyncImg className={styles.view} src="/images/video-conference-icon.svg" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
        >
          <Button
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            onClick={() => setIsWebinarsModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Read more")}</span>
          </Button>
          <Modal
            isOpen={isWebinarsModalOpen}
            close={() => setIsWebinarsModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/video-conference-icon.svg",
              width: PanelWidth.SMALL,
              title: t("Webinars"),
              caption: t("Webinars details"),
              label: t("Webinars"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("Webinars")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Join us for a series of webinars designed to educate and empower you with essential knowledge on
                technology and finance. Learn how to navigate the digital world, make informed financial decisions, and
                stay ahead in today’s fast-evolving landscape. Whether you’re a beginner or looking to deepen your
                understanding, these sessions will provide practical insights and tools to help you thrive.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                You need to register for each of the webinars separately. The link will be sent via email couple of
                hours before the start.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px", fontWeight: "bold" }}>
                21th of November, 8PM CET time: Crypto for Beginners
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                So whether you’re a curious traveler, a seasoned globetrotter, or simply someone who wants to experience
                the world, TravelView offers an ever-expanding world of possibility.
              </p>
              <form onSubmit={handleRegisterWebinar}>
                <Section className={styles.form} gutter={20} withSpace centerSelf>
                  <Field
                    className={styles.field}
                    label={t("Email")}
                    name="email"
                    gutter={20}
                    defaultValue={""}
                    error={webinarInputErrors.email}
                    internalRef={webinarRegistration}
                  />
                  <Button
                    className={styles["access-product-link"]}
                    borderRadius="SMALL"
                    color={Color.DARK_BLUE}
                    fontSize={18}
                    type="submit"
                  >
                    {t("Register")}
                  </Button>
                </Section>
              </form>
            </div>
          </Modal>
        </DigitalProductBase>
        <DigitalProductBase
          key="games"
          title={t("Games")}
          logo={<AsyncImg className={styles.view} src="/images/games-icon.svg" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
          bottomMessage="Both Fun and Play-To-Earn"
          isLight={true}
        >
          <Button
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            disabled
            color={Color.DARK_BLUE}
            fontSize={18}
          >
            <span className={styles["link-label"]}>{t("Coming soon")}</span>
          </Button>
        </DigitalProductBase>
        <DigitalProductBase
          key="deals_discounts"
          title={t("Deals & Discounts ")}
          logo={<AsyncImg className={styles.view} src="/images/discount-icon.svg" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
        >
          <Button
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            disabled
            color={Color.DARK_BLUE}
            fontSize={18}
          >
            <span className={styles["link-label"]}>{t("Coming soon")}</span>
          </Button>
        </DigitalProductBase>
        <DigitalProductBase
          key={"airdrops"}
          title={t("Airdrops")}
          logo={<AsyncImg className={styles.view} src="/images/airdrop.png" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
        >
          <Button
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            onClick={() => setIsAirdropsModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Read more")}</span>
          </Button>
          <Modal
            isOpen={isAirdropsModalOpen}
            close={() => setIsAirdropsModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/airdrop.png",
              width: PanelWidth.SMALL,
              title: t("Airdrops"),
              caption: t("Airdrops details"),
              label: t("Airdrops"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("Airdrops")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                A token airdrop is a strategy used by blockchains and web3 projects to distribute free tokens to users’
                wallets in order to promote their project, reward loyal users or incentivize engagement with the
                platform. Airdrops can be a great way for our users to discover new projects in the market and earn free
                tokens. Looking at the size of our community we can be interesting for many projects. We will continue
                to look around to bring more and more projects that our community can benefit from.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                In order to benefit from free token airdrops, you will be able to shortly insert your wallet addresses
                where these airdrops can be sent to.
              </p>
            </div>
          </Modal>
        </DigitalProductBase>
        <DigitalProductBase
          key={"dag-community"}
          title={t("DAGS Community")}
          logo={<AsyncImg className={styles.view} src="/images/crowd-icon.svg" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
          bottomMessage="In progress"
          isLight={true}
        >
          <Button
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            onClick={() => setIsCommunityModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Read more")}</span>
          </Button>
          <Modal
            isOpen={isCommunityModalOpen}
            close={() => setIsCommunityModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/crowd-icon.svg",
              width: PanelWidth.SMALL,
              title: t("DAGS Community"),
              caption: t("DAGS Community details"),
              label: t("DAGS Community"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("DAGS Community")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                An area for Dag community members where the features and benefits they unlocked with their membership
                packages are being brought together. This includes both existing and upcoming.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Additionally, who has not transferred their dags to their personal wallet will be able to access their
                balances in this section. There is no more need to migrate to Grow, as the holdings will be accessible
                from here. For ease of use, separate website for Grow will not be functional any longer. You will also
                find your link to crypto card once it is launched.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                In case you feel you did not get enough value out of the membership and courses, you will have the
                opportunity to convert your received bonus coins to store credit for the amount of your membership and
                opt for another product or gift card. This possibility will also be added here shortly.
              </p>
            </div>
          </Modal>
        </DigitalProductBase>
      </Section>
    </>
  );

  // get external bank product url
  function getAccessUrl(code: ServiceProductCode) {
    // bank connect url
    const bankFullUrl = query.data?.me.codebreakerAccess?.bankFullUrl;
    // bank beyond url
    const bankBasicUrl = query.data?.me.codebreakerAccess?.bankBasicUrl;

    // forex url
    if (code === ServiceProductCode.FOREX || code === ServiceProductCode.FOREX_PRO) {
      return query.data?.me.forexInsidersAccess ?? undefined;
    }

    // return bank connect url
    if (code === ServiceProductCode.VIEW_BASIC && bankBasicUrl && !bankFullUrl) {
      return bankBasicUrl;
    }

    // return bank beyond url
    if ((code === ServiceProductCode.BANK_BASIC || code === ServiceProductCode.BANK_FULL) && bankFullUrl) {
      return bankFullUrl;
    }

    // membership url
    if (code === ServiceProductCode.MEMBERSHIP_BASIC || code === ServiceProductCode.MEMBERSHIP_PRO) {
      return query.data?.me.membershipAccess ?? undefined;
    }

    // no access link for sf fee
    if (code === ServiceProductCode.BUSINESS_FEE_BASIC || code === ServiceProductCode.BUSINESS_FEE_PRO) {
      return false;
    }

    return undefined;
  }
}

// render digital product producer name
function renderProducerName(producerName: string) {
  switch (producerName) {
    case "Forex Insiders":
    case "Forex Insiders Pro":
      return "Forex Insiders BV";
    case "B.A.N.K. Connect":
    case "B.A.N.K. Beyond":
      return " Codebreaker Technologies Inc.";
  }
}

function getExclusiveOfferCampaignInfo<T extends { product: { code: ServiceProductCode } }>(
  code: ServiceProductCode,
  productsForDags: T[],
) {
  return productsForDags.find((p) => p.product.code === code);
}

function getOfferAvailability(
  offer?: { availability: ServiceOfferAvailability },
  hasActiveSubscription?: boolean,
): ServiceOfferAvailability | undefined {
  if (offer?.availability) {
    return offer.availability;
  }

  if (hasActiveSubscription) {
    return ServiceOfferAvailability.UNAVAILABLE_ALREADY_SUBSCRIBED;
  }

  return undefined;
}

function daysToMilliseconds(days: number) {
  return days * 24 * 60 * 60 * 1000;
}
