import React from "react";

export interface IconNavbarBackgroundProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconNavbarBackground: React.FC<IconNavbarBackgroundProps> = ({ hasTitle, className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 850.39 850.39"
    >
      {hasTitle && <title>IconNavbarBackground</title>}
      <circle fill={"#fff"} opacity={0.1} cx="425.2" cy="425.2" r="425.2" />
      <circle fill={"#fff"} opacity={0.1} cx="425.2" cy="425.2" r="302.23" />
      <circle fill={"#fff"} opacity={0.1} cx="425.2" cy="425.2" r="175.68" />
    </svg>
  );
};
