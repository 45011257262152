import { InMemoryCache, Resolvers } from "@apollo/client";
import { mergeDeepArray } from "@apollo/client/utilities";

import { TypedTypePolicies } from "../graphql/apolloHelpers";
import * as meCache from "./meCache";
import * as currencyCache from "./currencyCache";
import * as serviceCartCache from "./serviceCartCache";
import * as servicePeriodCache from "./servicePeriodCache";
import * as serviceProductCache from "./serviceProductCache";
import * as serviceSubscriptionCache from "./serviceSubscriptionCache";
import * as serviceSubscriptionUpgradeCache from "./serviceSubscriptionUpgradeCache";
import * as serviceSubscriptionInvoiceCache from "./serviceSubscriptionInvoiceCache";
import * as forexSignalsQueryCache from "./forexSignalsQueryCache";
import * as paymentsQueriesCache from "./paymentsQueriesCache";

// TODO: resolvers are deprecated. move this code under typePolicies once there is a way to get parent info from field resolver
export const resolvers: Resolvers = {};

// add all client side type-definitions here
export const typeDefs = [];

const typePolicies = mergeDeepArray<TypedTypePolicies>([
  meCache.typePolicies,
  currencyCache.typePolicies,
  serviceCartCache.typePolicies,
  servicePeriodCache.typePolicies,
  serviceProductCache.typePolicies,
  serviceSubscriptionCache.typePolicies,
  serviceSubscriptionUpgradeCache.typePolicies,
  serviceSubscriptionInvoiceCache.typePolicies,
  forexSignalsQueryCache.typePolicies,
  paymentsQueriesCache.typePolicies,
]);

export const cache = new InMemoryCache({ typePolicies });
