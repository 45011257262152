import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import SfFeeIllustrationImg from "./sf-fee-illustration.jpg";

import styles from "./sf-fee-illustration.module.scss";

export interface SfFeeIllustrationProps {
  isSale?: boolean;
  isNew?: boolean;
}

export const SfFeeIllustration: FC<SfFeeIllustrationProps> = ({ isSale, isNew }) => {
  const { t } = useTranslation();

  return (
    <div className={styles["image-wrap"]}>
      {isSale && <span className={classNames(styles.label, styles["label--sale"])}>{t("SALE")}</span>}
      {isNew && <span className={classNames(styles.label, styles["label--new"])}>{t("NEW")}</span>}
      <img className={styles.image} src={SfFeeIllustrationImg} alt="" />
    </div>
  );
};
