export interface IconLogoSuccessFactoryMonochromeProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

function IconLogoSuccessFactoryMonochrome({
  hasTitle,
  className,
  fill,
  width = 88,
  height = 37,
}: IconLogoSuccessFactoryMonochromeProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 98 40">
      {hasTitle && <title>IconLogoSuccessFactoryMonochrome</title>}
      <defs></defs>
      <path
        fill={fill}
        id="Group-9"
        d="M12.37,36.53c-.18,0-.24,0-.94-.18a7.22,7.22,0,0,1-3.8-2.06,9.72,9.72,0,0,1-.7-.86C4.35,29.76,4.64,24,8.74,21.51c2.25-1.36,5-2.21,6.76-4.22A7.72,7.72,0,0,0,17.23,12a9.31,9.31,0,0,0-.41-2.73,33.59,33.59,0,0,0-1.51-3.15,5.63,5.63,0,0,1-.64-3A5.32,5.32,0,0,1,16.16,0a8.79,8.79,0,0,0,.22,2.15,6.4,6.4,0,0,0,1.16,2c.73.89,6.63,7.12,7.33,10a9.66,9.66,0,0,1-5.1,11.15c-1.58.73-3.38.94-4.91,1.8a5.39,5.39,0,0,0-2.57,3.46c-.4,1.75.14,4.16,1.84,5.1a5.92,5.92,0,0,0,3.95.55A13.11,13.11,0,0,1,12.37,36.53ZM5.9,13.94c3.1.11,5.26-1.87,6.22-4.6a10,10,0,0,0,.14-3.44s.26.23.29.26a5.18,5.18,0,0,1,.57.67,7.73,7.73,0,0,1,1.09,6.86C12.66,18.71,6,19,3.93,23.58a6.93,6.93,0,0,0-.53,1.84,10.94,10.94,0,0,0,1.21,6.8,9.59,9.59,0,0,0,2.13,2.36c-.23-.14-1.12-.77-1.31-.9a10.68,10.68,0,0,1-1.56-1.43,14,14,0,0,1-2.38-3.36A13.84,13.84,0,0,1,3,14.09a14.8,14.8,0,0,1,3.17-2.91C6,11.3,4,13.87,5.9,13.94ZM23,33.59c4.72-2.93,8.14-11.69,2.87-19.08a9.8,9.8,0,0,1-.68,9.8c-1.66,2.32-2.81,3.27-6.9,4.19-1.47.33-3.49,1.13-3.59,3.61a3.37,3.37,0,0,0,3.38,3.54C18.23,35.64,19.62,35.66,23,33.59Z"
      ></path>
      <text
        style={{
          fill: `${fill}`,
          fontFamily: "Montserrat, sans-serif",
          fontSize: "1.6px",
          fontWeight: 700,
          whiteSpace: "pre",
        }}
        transform="matrix(8, 0, 0, 8, -270, -45)"
      >
        <tspan x="38" y="9">
          SF SUITE
        </tspan>
      </text>
    </svg>
  );
}

export default IconLogoSuccessFactoryMonochrome;
