export interface IconBoxProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

function IconBox({ hasTitle, className, fill, width = 44, height = 44 }: IconBoxProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 43.43"
    >
      {hasTitle && <title>IconBox</title>}
      <path
        fill={fill}
        d="M43.27,11.41A5,5,0,0,0,41,7.86,64.36,64.36,0,0,0,33.41,3.8,89.71,89.71,0,0,0,24.14.33a7,7,0,0,0-4.28,0A91.08,91.08,0,0,0,10.59,3.8,62.27,62.27,0,0,0,3,7.86H3A5,5,0,0,0,.73,11.4,71.83,71.83,0,0,0,0,21.63,75.17,75.17,0,0,0,.73,32,5.05,5.05,0,0,0,3,35.57a63.81,63.81,0,0,0,7.56,4,88,88,0,0,0,9.27,3.48,7,7,0,0,0,4.27,0,95.45,95.45,0,0,0,9.28-3.46A60.57,60.57,0,0,0,41,35.56,5,5,0,0,0,43.27,32,73.42,73.42,0,0,0,43.27,11.41ZM4.09,9.56h0a59.59,59.59,0,0,1,7.32-3.93,91.64,91.64,0,0,1,9.06-3.39,5,5,0,0,1,3.06,0,88,88,0,0,1,9.06,3.39,62.65,62.65,0,0,1,7.32,3.93,3,3,0,0,1,.7.63L22,17.64,3.39,10.19A2.82,2.82,0,0,1,4.09,9.56ZM11.41,37.8a59.88,59.88,0,0,1-7.32-3.93,3,3,0,0,1-1.38-2.13A74,74,0,0,1,2,21.63a71.5,71.5,0,0,1,.66-9.58L21,19.39V41.32a4.56,4.56,0,0,1-.53-.13A85.53,85.53,0,0,1,11.41,37.8Zm29.88-6a3,3,0,0,1-1.37,2.12,58,58,0,0,1-7.33,3.93,91.63,91.63,0,0,1-9.07,3.39,4.87,4.87,0,0,1-.52.12V19.39l18.34-7.34A71.42,71.42,0,0,1,41.29,31.75Z"
      />
    </svg>
  );
}

export default IconBox;
