import React, { ReactNode, useState } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

import IconArrowTriangle from "../../icon/IconArrowTriangle";

import styles from "./mobile-menu-item.module.scss";

export interface MobileMenuItemProps {
  icon: React.ReactNode;
  title: string;
  externalProps?: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
  isExternal?: boolean;
  hasSubMenu?: boolean;
  to?: string;
  children?: ReactNode;
  handleClick?(): void;
}

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  icon,
  title,
  externalProps,
  isExternal,
  hasSubMenu,
  to,
  handleClick,
  children,
}) => {
  // handle sub-menu open/closed state
  const [openMenu, setOpenMenu] = useState(false);

  if (isExternal) {
    return (
      <a
        href={externalProps && externalProps.href}
        className={styles["mobile-menu-item"]}
        target="_blank"
        rel="noreferrer noopener"
        onClick={() => {
          handleClick && handleClick();
        }}
      >
        <div className={styles["icon-wrap"]}>{icon}</div>
        {title}
        {children && children}
      </a>
    );
  }

  if (hasSubMenu) {
    return (
      <>
        <div
          className={classNames(styles["mobile-menu-item"], {
            [styles["mobile-menu-item--open"]]: openMenu,
          })}
          onClick={() => setOpenMenu(!openMenu)}
        >
          <div className={styles["icon-wrap"]}>{icon}</div>
          {title}
          <IconArrowTriangle
            className={classNames(
              styles.arrow,
              { [styles["arrow--down"]]: !openMenu },
              { [styles["arrow--up"]]: openMenu },
            )}
          />
        </div>
        {openMenu && children}
      </>
    );
  }

  return (
    <NavLink
      to={to || ""}
      onClick={() => {
        handleClick && handleClick();
      }}
      className={styles["mobile-menu-item"]}
    >
      <div className={styles["icon-wrap"]}>{icon}</div>
      {title}
      {children && children}
    </NavLink>
  );
};
