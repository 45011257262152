import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ApolloError } from "@apollo/client";

import { Button } from "../../components/button/Button";
import { Container } from "../../components/container/Container";
import Field, { FieldGutter } from "../../components/field/Field";
import { Form } from "../../components/form/Form";
import { Panel, PanelWidth } from "../../components/panel/Panel";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { useChangeForgottenPasswordMutation } from "../../graphql/schema";
import { digitsRegexp, Routes, spaceRegexp } from "../../services/constants";
import { handleFormErrors } from "../../services/handleFormErrors";

import styles from "./restorePasswordView.module.scss";

interface RestorePasswordFormValues {
  newPassword: string;
  repeatNewPassword: string;
}

interface RouteParams {
  linkHash: string;
}

export default function RestorePasswordView() {
  const { linkHash } = useParams<RouteParams>();

  const { t } = useTranslation();

  const restorePasswordSchema = yup.object().shape({
    newPassword: yup
      .string()
      .oneOf([yup.ref("repeatNewPassword"), null], t("Passwords do not match"))
      .min(8, t("Password should be at least 8 characters long"))
      .max(60, t("Password should be not longer than 60 characters"))
      .test("has-uppercase", t("Password should have uppercase letters"), (value) => value?.toLowerCase() !== value)
      .test("has-digits", t("Password should have at least one digit"), (value) => digitsRegexp.test(value || ""))
      .test("has-space", t("Password should have no space"), (value) => !spaceRegexp.test(value || ""))
      .required(t("Password is missing")),
    repeatNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], t("Passwords do not match"))
      .min(8, t("Password should be at least 8 characters long"))
      .max(60, t("Password should be not longer than 60 characters"))
      .test("has-uppercase", t("Password should have uppercase letters"), (value) => value?.toLowerCase() !== value)
      .test("has-digits", t("Password should have at least one digit"), (value) => digitsRegexp.test(value || ""))
      .test("has-space", t("Password should have no space"), (value) => !spaceRegexp.test(value || ""))
      .required(t("Repeat your password")),
  });

  const { register, handleSubmit, errors, setError } = useForm<RestorePasswordFormValues>({
    resolver: yupResolver(restorePasswordSchema),
  });
  const [changePasswordMutation] = useChangeForgottenPasswordMutation();
  const { push } = useHistory();
  const { isMobile } = useWindowDimensionsContext();

  const handleRestorePassword = handleSubmit(async ({ newPassword, repeatNewPassword }) => {
    try {
      const result = await changePasswordMutation({
        variables: {
          data: { linkHash: linkHash, newPassword, repeatNewPassword },
        },
      });

      if (result.data?.changeForgottenPassword.success) {
        push(Routes.LOGIN);
        toast.success(t("Password is changed"));

        return;
      }
      toast.error(t("Something went wrong"));
    } catch (error) {
      if (error instanceof ApolloError) {
        handleFormErrors({ error, t, setError });
      }

      toast.error(t("Something went wrong"));
    }
  });

  return (
    <Container secondary>
      <Panel
        panelStyle={isMobile ? "SECONDARY" : "PRIMARY"}
        width={PanelWidth.MEDIUM}
        title={t("Restore your password")}
        caption={t("You can now set a new secure password")}
        imagePath="/images/loginIllustration.png"
      >
        <Form onSubmit={handleRestorePassword}>
          <Field
            type="password"
            label={t("Password")}
            name="newPassword"
            internalRef={register}
            isRequired
            error={errors.newPassword}
            gutter={FieldGutter.MEDIUM}
          />
          <Field
            type="password"
            label={t("Repeat password")}
            name="repeatNewPassword"
            internalRef={register}
            isRequired
            error={errors.repeatNewPassword}
            gutter={FieldGutter.MEDIUM}
          />
          <Button
            className={styles.button}
            type="submit"
            color="YELLOW"
            borderRadius="SMALL"
            gutter="LARGE"
            stretch="MOBILE"
            fontSize={16}
            weight="MEDIUM"
          >
            {t("Set new password")}
          </Button>
        </Form>
      </Panel>
    </Container>
  );
}
