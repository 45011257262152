import React from "react";

export interface IconDotLineHorisontalProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconDotLineHorisontal: React.FC<IconDotLineHorisontalProps> = ({
  hasTitle,
  className,
  width = 1592,
  height = 200,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 1592 200" className={className}>
      {hasTitle && <title>IconDotLineHorisontal</title>}
      <g fill="none" fillRule="evenodd" stroke="none" strokeDasharray="4,9" strokeLinecap="square" strokeWidth="1">
        <g stroke="#7789AD" transform="translate(-8 -1295)">
          <g transform="translate(0 1050)">
            <path d="M1600 257.372c-69.203 33.681-109.385 55.093-120.547 64.235-117.95 96.611-17.068 120.022 0 104.393 28.393-26 28.393-63.81-69.402-85-144.792-31.373-372.54 95.77-407.136 28.801-31.031-60.067-203.839-221.99-390.441-40.801-106.36 103.273-483.655-60-419.335 20.605s30.21 109-64.32 87.165C78.389 425.122 35.449 422.013 0 427.443"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
