import classNames from "classnames";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Heading } from "../heading/Heading";
import { IconArrowClassic } from "../icon/IconArrowClassic";
import { IconNavbarBackground } from "./icon-navbar-background/IconNavbarBackground";

import styles from "./titlebar.module.scss";

export enum TitlebarHeight {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
}

export enum TitleBarGutter {
  SMALL = "SMALL",
  LARGE = "LARGE",
}

export enum TitlebarType {
  PRIMARY = "PRIMARY", // orange gradient with background icon
  SECONDARY = "SECONDARY", // blue with background icon
  TERTIARY = "TERTIARY", // orange gradient with image
  QUATERNARY = "QUATERNARY", // dark blue
  QUINARY = "QUINARY", // yellow with background icon
}

export enum FormatText {
  UNSET = "UNSET",
  UPPERCASE = "UPPERCASE",
}

export interface TitlebarProps {
  type: TitlebarType;
  gutter?: TitleBarGutter;
  height?: keyof typeof TitlebarHeight;
  formatUrlLabel?: keyof typeof FormatText;
  large?: boolean;
  title?: ReactNode;
  backUrl?: { to: string; title: string };
  imageUrl?: string;
  className?: string;
}

export const Titlebar: React.FC<TitlebarProps> = ({
  type,
  gutter = TitleBarGutter.LARGE,
  height = "MEDIUM",
  formatUrlLabel = "UPPERCASE",
  large,
  title,
  backUrl,
  imageUrl,
  className,
}) => {
  return (
    <div
      className={classNames(
        styles.titlebar,
        (styles as any)[`height--${height}`],
        {
          [styles["titlebar--primary"]]: type === TitlebarType.PRIMARY,
          [styles["titlebar--secondary"]]: type === TitlebarType.SECONDARY,
          [styles["titlebar--tertiary"]]: type === TitlebarType.TERTIARY,
          [styles["titlebar--quaternary"]]: type === TitlebarType.QUATERNARY,
          [styles["titlebar--quinary"]]: type === TitlebarType.QUINARY,
          [styles["titlebar--large"]]: large,
          [styles["titlebar--gutter-small"]]: gutter === TitleBarGutter.SMALL,
          [styles["titlebar--gutter-large"]]: gutter === TitleBarGutter.LARGE,
        },
        className,
      )}
    >
      {backUrl && (
        <Link
          to={backUrl.to}
          className={classNames(styles["back-link"], {
            [styles["back-link--quaternary"]]: type === TitlebarType.QUATERNARY,
          })}
        >
          <IconArrowClassic
            className={classNames(styles["back-link-icon"], {
              [styles["back-link-icon--quaternary"]]: type === TitlebarType.QUATERNARY,
            })}
          />
          <span
            className={classNames(styles["back-link-text"], (styles as any)[`format-text--${formatUrlLabel}`], {
              [styles["back-link-text--quaternary"]]: type === TitlebarType.QUATERNARY,
            })}
          >
            {backUrl.title}
          </span>
        </Link>
      )}

      {title && (
        <Heading
          level={2}
          center
          white
          thin
          gutter={false}
          className={classNames({ [styles["title--compact"]]: !large })}
        >
          {title}
        </Heading>
      )}

      {imageUrl && (
        <img
          src={imageUrl}
          alt=""
          className={classNames(styles.image, { [styles["image--semi-large"]]: height === TitlebarHeight.SEMI_LARGE })}
        />
      )}

      {type === TitlebarType.PRIMARY || type === TitlebarType.SECONDARY || type === TitlebarType.QUINARY ? (
        <div className={styles["titlebar-background-wrap"]}>
          <IconNavbarBackground className={styles["titlebar-background-icon"]} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
