import { useMount } from "../../hooks/useMount";
import { useLogoutViewLogoutMutationMutation } from "../../graphql/schema";
import { tracker } from "../../libs/trackers";
import { isUserAlreadyRedirectedToAffiliateViewVar } from "../../cache/isUserAlreadyRedirectedToAffiliateView";

export default function LogoutView() {
  const [logoutMutation, { client }] = useLogoutViewLogoutMutationMutation();

  useMount(() => {
    logoutMutation({}).then(async () => {
      client?.resetStore();
      tracker.unIdentify();
      isUserAlreadyRedirectedToAffiliateViewVar(false);
      await client.refetchQueries({ include: ["all"] });
    });
  });

  return null;
}
