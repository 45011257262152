import React from "react";

export interface YellowCircleIconProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const YellowCircleIcon: React.FC<YellowCircleIconProps> = ({
  hasTitle,
  className,
  width = 652,
  height = 652,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 652 652" className={className}>
      {hasTitle && <title>YellowCircleIcon</title>}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFC542" transform="translate(-626 -3925)">
          <g transform="translate(0 3611)">
            <g transform="translate(345 100)">
              <g transform="translate(0 150)">
                <g transform="translate(281)">
                  <circle cx="326" cy="390" r="326"></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
