import { useEffect, useState } from "react";

import { useInterval } from "./useInterval";

export const useCountdown = (targetDate: string) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());
  const [isRunning, setIsRunning] = useState(true);

  useInterval(
    () => {
      setCountDown(countDownDate - new Date().getTime());
    },
    isRunning ? 1000 : null,
  );

  useEffect(() => {
    if (countDown <= 0) {
      setCountDown(0);
      setIsRunning(false);
    }
  }, [countDown]);

  return {
    stop: () => {
      setIsRunning(false);
    },
    days: Math.floor(countDown / (1000 * 60 * 60 * 24)),
    hours: Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((countDown % (1000 * 60)) / 1000),
  };
};
