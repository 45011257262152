import classNames from "classnames";

import { Button } from "../button/Button";

import styles from "./pills.module.scss";

export type Option<T> = {
  label: string;
  value: T;
};

export enum PillsGutter {
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
}

export interface PillsProps<T = string> {
  options: Option<T>[];
  value: T;
  onChange: (value: T) => void;
  gutter?: keyof typeof PillsGutter;
  width?: number;
}

export function Pills<T>({ options, gutter, width, onChange, value }: PillsProps<T>) {
  if (options.length === 0) {
    return null;
  }

  return (
    <div
      style={{ width: width }}
      className={classNames(styles.wrap, {
        [styles["gutter--medium"]]: gutter === "MEDIUM",
        [styles["gutter--semi-large"]]: gutter === "SEMI_LARGE",
      })}
    >
      {options.map((option, index) => (
        <Button
          key={index}
          className={classNames(styles.period, { [styles["period--selected"]]: option.value === value })}
          onClick={() => onChange(option.value)}
          kind={option.value === value ? "SOLID" : "TEXT"}
          shape={option.value === value ? "ROUND" : "RECT"}
          height="AUTO"
          fontSize={12}
          weight="NORMAL"
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
}
