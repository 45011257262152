import React from "react";

export interface IconArrowHandDrawn3Props {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconArrowHandDrawn3: React.FC<IconArrowHandDrawn3Props> = ({
  hasTitle,
  className,
  fill = "#ffc542",
  width = 73,
  height = 56,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 73.42 56.22"
    >
      {hasTitle && <title>IconArrowHandDrawn3</title>}
      <path
        id="Line-2"
        fill={fill}
        d="M18.52,55.13,23.73,44.9l.05-.08a.74.74,0,0,1,1-.24l.09.05a.75.75,0,0,1,.24.95l-3.91,7.68,5.93-3.95.53-.41,1.68-1.29.79-.6q10.19-7.86,28.53-22.24c13.13-10.3,16-16.69,10.85-20C64.94,1.79,54.61,1,40.12,1.84c-3.44.21-7.07.52-10.86.91-6.51.67-13.08,1.54-19.77,2.57C9.54,5.31.7,6.75.41,6.6a.75.75,0,0,1,.68-1.34c-.16-.08,0-.11-.13-.11H1l.31,0L3,4.83c2.58-.42,4.12-.67,6.24-1,6.72-1,13.31-1.91,19.85-2.58C32.91.86,36.56.55,40,.34,54.86-.57,65.39.29,70.29,3.48,76.68,7.65,73.37,15.11,59.54,26Q38.33,42.58,28,50.53l-6,4,8.58-.65h.11a.75.75,0,0,1,0,1.5l-11.45.85h-.1A.75.75,0,0,1,18.52,55.13Z"
      />
    </svg>
  );
};
