import React, { createContext, useContext, useEffect, useState } from "react";

import { MOBILE_WIDTH, TABLET_PORTRAIT_WIDTH, TABLET_LANDSCAPE_WIDTH, DESKTOP_WIDTH } from "../../services/constants";

const WindowDimensionsContext = createContext<WindowDimensions>({
  width: 0,
  height: 0,
  isMobile: false,
  isTabletPortrait: false,
  isTabletPortraitOrBigger: false,
  isTabletLandscape: false,
  isTabletLandscapeOrBigger: false,
  isDesktop: false,
  isDesktopUp: false,
  isBigDesktop: false,
});

export type WindowDimensions = {
  width: number;
  height: number;
  isMobile: boolean;
  isTabletPortrait: boolean;
  isTabletPortraitOrBigger: boolean;
  isTabletLandscape: boolean;
  isTabletLandscapeOrBigger: boolean;
  isDesktop: boolean;
  isDesktopUp: boolean;
  isBigDesktop: boolean;
};

interface Props {
  children: React.ReactNode;
}

function getWindowDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < MOBILE_WIDTH,
    isTabletPortrait: window.innerWidth >= MOBILE_WIDTH && window.innerWidth < TABLET_PORTRAIT_WIDTH,
    isTabletPortraitOrBigger: window.innerWidth >= MOBILE_WIDTH,
    isTabletLandscape: window.innerWidth >= TABLET_PORTRAIT_WIDTH && window.innerWidth < TABLET_LANDSCAPE_WIDTH,
    isTabletLandscapeOrBigger: window.innerWidth >= TABLET_PORTRAIT_WIDTH,
    isDesktop: window.innerWidth >= TABLET_LANDSCAPE_WIDTH && window.innerWidth < DESKTOP_WIDTH,
    isDesktopUp: window.innerWidth >= TABLET_LANDSCAPE_WIDTH,
    isBigDesktop: window.innerWidth >= DESKTOP_WIDTH,
  };
}

export default function WindowDimensionsProvider({ children }: Props) {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <WindowDimensionsContext.Provider value={windowDimensions}>{children}</WindowDimensionsContext.Provider>;
}

export const useWindowDimensionsContext = () => useContext<WindowDimensions>(WindowDimensionsContext);
