import React from "react";

import styles from "./notFound.module.scss";

interface Props {
  children: React.ReactNode;
}
export default function NotFound(props: Props) {
  return <div className={styles.notFound}>{props.children}</div>;
}
