import React from "react";
import classNames from "classnames";

import { IconUser2 } from "../icon/IconUser2";
import { DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT } from "../../services/constants";

import styles from "./subscribers.module.scss";

export interface SubscribersProps {
  subscribers?: SubscriptionStatusColor[];
  className?: string;
}

export enum SubscriptionStatusColor {
  GREEN = "#3DD598",
  YELLOW = "#FFC542",
  GREY = "#CFD0DF",
}

export const Subscribers: React.FC<SubscribersProps> = ({ subscribers = [], className }) => {
  // use provided colors, fill rest of them with default color
  const colors = Array.from(
    new Array(DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT),
    (_, i) => subscribers[i] || SubscriptionStatusColor.GREY,
  );

  return (
    <div className={classNames(styles.subscribers, className)}>
      {sortByColor(colors).map((c, index) => (
        <IconUser2 key={index} width={20} height={20} fill={c} />
      ))}
    </div>
  );
};

function sortByColor(colors: SubscriptionStatusColor[]) {
  return colors.sort((a) => {
    if (a === SubscriptionStatusColor.GREEN) {
      return -1;
    }

    if (a === SubscriptionStatusColor.YELLOW) {
      return 0;
    }

    return 1;
  });
}
