export interface IconPieChartProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

function IconPieChart({ hasTitle, className, width = 44, height = 44 }: IconPieChartProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
    >
      {hasTitle && <title>IconPieChart</title>}
      <path d="M26.83,6.22a1,1,0,1,1,.16-2l.15,0A20,20,0,1,1,4.25,27.17a1,1,0,0,1,2-.31A18,18,0,1,0,26.83,6.22ZM0,23A23,23,0,0,1,23,0a1,1,0,0,1,1,1V23a1,1,0,0,1-1,1H1A1,1,0,0,1,0,23Zm22-1V2A21,21,0,0,0,2,22Z" />
    </svg>
  );
}

export default IconPieChart;
