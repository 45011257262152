import React from "react";

export interface IconTagProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconTag: React.FC<IconTagProps> = ({ hasTitle, className, width = 15, height = 15 }) => (
  <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
    {hasTitle && <title>IconTag</title>}
    <path
      fillRule="evenodd"
      d="M11.072 5.357A1.428 1.428 0 1111.07 2.5a1.428 1.428 0 010 2.856zM13.929 0H8.165C7.69 0 7.236.19 6.9.521L.522 6.9a1.787 1.787 0 000 2.524l5.054 5.054a1.787 1.787 0 002.524 0L14.48 8.1a1.8 1.8 0 00.521-1.265V1.071A1.07 1.07 0 0013.929 0z"
    />
  </svg>
);
