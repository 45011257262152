export function getCookie<T>(name: string, defaultValue: T | null = null) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    const value = parts.pop()?.split(";")[0];
    const decodedValue = value ? decodeURIComponent(value) : null;

    if (!decodedValue) {
      return defaultValue;
    }

    try {
      return JSON.parse(decodedValue);
    } catch (err) {
      console.error("Failed to parse cookie value", err);

      return defaultValue;
    }
  }

  return defaultValue;
}
