import { FC, ReactNode } from "react";
import classNames from "classnames";

import { AsyncImg } from "../async-img/AsyncImg";

import styles from "./media-section.module.scss";

export interface MediaSectionProps {
  src?: string;
  className?: string;
  contentClassName?: string;
  children?: ReactNode;
}

export const MediaSection: FC<MediaSectionProps> = ({ src, contentClassName, className, children }) => (
  <div className={classNames(styles["media-section"], className)}>
    {src && <AsyncImg className={styles.image} src={src} />}

    <div className={contentClassName}>{children}</div>
  </div>
);
