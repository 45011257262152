import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Button } from "../../button/Button";
import { Layout } from "../../layout/Layout";

import styles from "./redemption-confirmation.module.scss";

export interface RedemptionConfirmationProps {
  onClose(): void;
  redeemGiftCard: () => Promise<void>;
}

export const RedemptionConfirmation: React.FC<RedemptionConfirmationProps> = ({ onClose, redeemGiftCard }) => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <Layout wrap="MOBILE">
      <Button className={styles.button} color="BLUE" borderRadius="SMALL" weight="MEDIUM" onClick={onClose}>
        {t("No")}
      </Button>
      <Button
        className={classNames(styles.button, styles["close-button"])}
        color="LIGHT_RED"
        borderRadius="SMALL"
        weight="MEDIUM"
        onClick={redeemGiftCard}
      >
        {t("Yes")}
      </Button>
    </Layout>
  );
};
