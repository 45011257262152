import { datadogRum } from "@datadog/browser-rum";

import { BaseTracker } from "./BaseTracker";
import { CookieConsentCategory, TrackerEventParams, TrackerEventType } from "../types";

const IS_ENABLED = process.env.REACT_APP_DATA_DOG_ENABLED === "true";
const APPLICATION_ID = `${process.env.REACT_APP_DATA_DOG_APPLICATION_ID}`;
const CLIENT_TOKEN = `${process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN}`;
const ENV = process.env.REACT_APP_DATA_DOG_ENV;
const VERSION = process.env.REACT_APP_VERSION;
const USE_RECORDER = process.env.REACT_APP_DATA_DOG_RECORD === "true";
const SERVER_ORIGIN = `${process.env.REACT_APP_SUCCESSFACTORY_SERVER_PROXY}`;

export class DataDogTracker extends BaseTracker {
  private isTrackerInitialized = false;

  public constructor() {
    super(CookieConsentCategory.FUNCTIONAL);
  }

  protected initialize() {
    if (!IS_ENABLED) {
      return false;
    }

    if (!this.isTrackerInitialized) {
      // init rum
      datadogRum.init({
        applicationId: APPLICATION_ID,
        clientToken: CLIENT_TOKEN,
        env: ENV,
        version: VERSION,
        service: "success-factory-shop-frontend",
        site: "datadoghq.eu",
        sampleRate: 100,
        trackInteractions: false,
        allowedTracingOrigins: [SERVER_ORIGIN, /https:\/\/.*\.successfactory\.com/],
      });

      this.isTrackerInitialized = true;
    }

    // init recorder
    if (USE_RECORDER) {
      datadogRum.startSessionReplayRecording();
    }

    return true;
  }

  protected destroy() {
    if (!this.isInitialized) {
      return;
    }

    datadogRum.removeUser();
    datadogRum.stopSessionReplayRecording();

    // TODO: there is no stop method for rum: https://github.com/DataDog/browser-sdk/issues/1008
    this.isInitialized = false;
  }

  public identify(userId: string) {
    if (!this.isInitialized) {
      return;
    }

    datadogRum.setUser({ id: userId });
  }

  public unIdentify(): void {
    if (!this.isInitialized) {
      return;
    }

    datadogRum.removeUser();
  }

  public trackEvent(event: TrackerEventType, params?: TrackerEventParams) {
    if (!this.isInitialized) {
      return;
    }

    datadogRum.addAction(event, params);
  }

  public trackError(error: Error, from?: string) {
    if (!this.isInitialized) {
      return;
    }

    datadogRum.addError(error, { from });
  }
}
