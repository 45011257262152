import React from "react";
import { useTranslation } from "react-i18next";

import { AddServicesToCartButton } from "../../../components/add-services-to-cart-button/AddServicesToCartButton";
import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { Heading } from "../../../components/heading/Heading";
import { Layout } from "../../../components/layout/Layout";
import { Modal, ModalKind } from "../../../components/modal/Modal";
import { PlayButton } from "../../../components/play-button/PlayButton";
import { ProductSelector } from "../../../components/product-selector/ProductSelector";
import { TextAccent } from "../../../components/text-accent/TextAccent";
import { ViewSection } from "../../../components/view-section/ViewSection";
import { CurrencyCode, ServiceOfferAvailability, ServicePeriodCode, ServiceProductCode } from "../../../graphql/schema";
import TheViewBannerImage from "../assets/the-view-banner--optimized.png";

import styles from "./banner-section.module.scss";

export interface BannerSectionOption {
  name: string;
  price: string;
  value: ServiceProductCode;
  currencyCode: keyof typeof CurrencyCode;
  availability: keyof typeof ServiceOfferAvailability;
  activeSubscriptionId: string | null | undefined;
}

export interface BannerSectionProps {
  productCode: keyof typeof ServiceProductCode;
  periodCode: keyof typeof ServicePeriodCode;

  options?: BannerSectionOption[];
  selectedOptionValue?: string;
  onOptionChange?: (value: any) => any;
}

export const BannerSection: React.FC<BannerSectionProps> = ({
  productCode,
  periodCode,
  options = [],
  selectedOptionValue,
  onOptionChange,
}) => {
  // access translation keys
  const { t } = useTranslation();

  // check if video modal is open
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const selectedOption = options.find((opt) => opt.value === selectedOptionValue);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles["banner-wrap"]}>
          <AsyncImg className={styles.banner} src={TheViewBannerImage} />
        </div>
        <ViewSection image={<PlayButton center onClick={() => setIsModalOpen(true)} />} gap="GAP_40">
          <Heading className={styles.title} level={2}>
            <TextAccent
              dangerouslySetInnerHTML={{
                __html: t("Imagine that with <strong>vision and innovation</strong>, you can explore the world."),
              }}
            />
          </Heading>
          <p className={styles.text}>
            {t(
              "Welcome to The View. This is the rebirth of everything you've ever imagined. A new way to travel, to build and do business, to invest or just to meet with friends. The VIEW is a virtual platform where everything you do in the real world you can do better in the virtual world.",
            )}
          </p>

          <p className={styles.text}>{t("Select Package:")}</p>
          <Layout className={styles["button-wrap"]} wrap="MOBILE">
            {options.length > 1 && selectedOptionValue && onOptionChange && (
              <ProductSelector
                className={styles["product-selector"]}
                options={options.map((opt) => ({
                  ...opt,
                  currencySign: CurrencyCode[opt.currencyCode],
                  fullPrice: opt.price.toString(),
                }))}
                value={selectedOptionValue}
                onSelect={onOptionChange}
              />
            )}
            <AddServicesToCartButton
              className={styles["cart-button"]}
              productCodes={[productCode]}
              periodCode={periodCode}
              activeSubscriptionId={selectedOption?.activeSubscriptionId}
              availability={selectedOption?.availability}
            />
          </Layout>
        </ViewSection>
      </div>
      {isModalOpen && (
        <Modal
          kind={ModalKind.TERTIARY}
          centerContent="TABLET_PORTRAIT_MAX"
          ariaHideApp={false}
          isOpen={isModalOpen}
          close={() => setIsModalOpen(false)}
        >
          <iframe
            title="Welcome to The View"
            src="https://player.vimeo.com/video/515277780?color=bca556&title=0&byline=0&portrait=0"
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </Modal>
      )}
    </>
  );
};
