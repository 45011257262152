export interface IconCheckProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

function IconCheck({ hasTitle, className, width = 48, height = 48 }: IconCheckProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 43.44"
    >
      {hasTitle && <title>IconCheck</title>}
      <path d="M6,2V15q0,7.2.91,26.2L10.23,39a3,3,0,0,1,3.54.15l2.61,2.09a1,1,0,0,0,1.25,0l2.49-2a3,3,0,0,1,3.76,0l2.47,2a1,1,0,0,0,1.25,0l2.64-2.1A3,3,0,0,1,33.76,39l3.37,2.23Q38,24.86,38,15q0-9.08,0-12.88Zm34-.87Q40,4.58,40,15q0,9.93-.87,26.31A2,2,0,0,1,36,42.87l-3.37-2.23a1,1,0,0,0-1.17,0l-2.65,2.1a3,3,0,0,1-3.74,0l-2.48-2a1,1,0,0,0-1.25,0l-2.49,2a3,3,0,0,1-3.75,0l-2.61-2.09a1,1,0,0,0-1.18,0L8,42.85a2,2,0,0,1-2.77-.55,1.93,1.93,0,0,1-.33-1Q4,22.24,4,15V1A1,1,0,0,1,5,0H5L39,.12a1,1,0,0,1,1,1ZM13,12a1,1,0,0,1,0-2H23a1,1,0,0,1,0,2Zm14,0a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2ZM13,18a1,1,0,0,1,0-2H23a1,1,0,0,1,0,2Zm14,0a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2ZM13,24a1,1,0,0,1,0-2H23a1,1,0,0,1,0,2Zm14,0a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2ZM13,30a1,1,0,0,1,0-2H23a1,1,0,0,1,0,2Zm14,0a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2ZM1,2A1,1,0,0,1,1,0H43a1,1,0,0,1,0,2Z" />
    </svg>
  );
}

export default IconCheck;
