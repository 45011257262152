export interface IconMoneyBagProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}
function IconMoneyBag({ hasTitle, className, width = 48, height = 48 }: IconMoneyBagProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
      {hasTitle && <title>IconMoneyBag</title>}
      <path d="M6 33c0 4.325 1.461 6.654 4.492 7.844 2.503.982 5.158 1.172 12.159 1.158a600.092 600.092 0 012.693 0c7.004.014 9.66-.177 12.163-1.159C40.539 39.654 42 37.325 42 33c0-6.003-4.622-11.64-14.036-16.878-1.319.583-2.65.878-3.987.878-1.342 0-2.666-.297-3.966-.885C10.61 20.919 6 26.548 6 33zm12.78-18.5l-2.395-7.183a3 3 0 013.617-3.848A15.5 15.5 0 0024 4c1.33 0 2.663-.177 3.998-.532a3 3 0 013.617 3.848l-2.404 7.211C39.015 20.044 44 26.19 44 33c0 5.119-1.935 8.204-5.763 9.705-2.837 1.113-5.603 1.312-12.897 1.297a601.693 601.693 0 00-2.685 0c-7.292.015-10.057-.184-12.893-1.296C5.935 41.204 4 38.119 4 33c0-7.251 4.986-13.418 14.78-18.5zm2.035-.217c1.052.48 2.104.717 3.162.717 1.061 0 2.129-.238 3.21-.721l2.53-7.595a1 1 0 00-1.205-1.282c-1.501.399-3.006.6-4.512.6a17.51 17.51 0 01-4.512-.6 1 1 0 00-1.206 1.282l2.533 7.6zM29 25.545a1 1 0 11-2 0c0-.853-.692-1.545-1.545-1.545h-2.773c-.929 0-1.682.753-1.682 1.682 0 1.053.766 1.95 1.806 2.115l2.703.43A4.142 4.142 0 0129 32.318 3.682 3.682 0 0125.318 36h-2.773A3.545 3.545 0 0119 32.455a1 1 0 112 0c0 .853.692 1.545 1.545 1.545h2.773c.929 0 1.682-.753 1.682-1.682 0-1.053-.766-1.95-1.806-2.115l-2.703-.43A4.142 4.142 0 0119 25.682 3.682 3.682 0 0122.682 22h2.773A3.545 3.545 0 0129 25.545zM23 21a1 1 0 012 0v2a1 1 0 01-2 0v-2zm0 14a1 1 0 012 0v2a1 1 0 01-2 0v-2z"></path>
    </svg>
  );
}

export default IconMoneyBag;
