import React from "react";
import classNames from "classnames";

import styles from "./password-tip.module.scss";

export interface PasswordTipProps {
  checked: boolean;
  label: string;
  description: string;
}

export const PasswordTip: React.FC<PasswordTipProps> = ({ checked, label, description }) => (
  <div className={styles["password-tip-wrap"]}>
    <span className={classNames(styles["password-tip-addon"], { [styles["password-tip-addon--checked"]]: checked })}>
      {label}
    </span>
    <span className={classNames({ [styles["password-tooltip--checked"]]: checked })}>{description}</span>
  </div>
);
