import { useEffect, useState } from "react";

import { useMount } from "./useMount";
import usePrevious from "./usePrevious";

const hasFocus = () => typeof document !== "undefined" && document.hasFocus();

export const useOnWindowFocusChange = (onChange: (isInFocus: boolean) => void) => {
  const [isInFocus, setIsInFocus] = useState(hasFocus);
  const prevIsInFocus = usePrevious(isInFocus);

  // do callback when focus state changes
  useEffect(() => {
    if (prevIsInFocus === undefined || prevIsInFocus === isInFocus) {
      return;
    }

    onChange(isInFocus);
  }, [isInFocus, prevIsInFocus, onChange]);

  const handleFocus = () => setIsInFocus(true);
  const handleBlur = () => {
    // ignore if iframe was focused (eg stripe credit card form input). Timeout is required for firefox
    setTimeout(() => {
      if (document.activeElement instanceof HTMLIFrameElement) {
        return;
      }

      setIsInFocus(false);
    }, 0);
  };

  useMount(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  });
};
