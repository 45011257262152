import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useCreateStripeSetupIntentMutation, useRegisterStripePaymentSourceMutation } from "../../graphql/schema";
import { Button } from "../button/Button";
import { Layout } from "../layout/Layout";
import { Loader } from "../loader/Loader";
import { Modal, ModalKind } from "../modal/Modal";
import { PanelWidth } from "../panel/Panel";
import { StripeCreditCardForm, useAddNewCardInfo } from "../stripe-credit-card-form/StripeCreditCardForm";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";

import styles from "./add-new-payment-method-modal.module.scss";

export const AddNewPaymentMethodModal: React.FC = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const [stripeSetupIntent] = useCreateStripeSetupIntentMutation();
  const [registerStripePaymentSource] = useRegisterStripePaymentSourceMutation();
  const stripeConfirmSetup = useAddNewCardInfo();
  const [creditCardHolderName, setCreditCardHolderName] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const { isMobile } = useWindowDimensionsContext();

  const handleAddNewStripePaymentMethod = async () => {
    setLoading(true);
    try {
      const intent = await stripeSetupIntent();

      if (!intent.data?.createStripeSetupIntent) {
        console.error("Stripe setup intent was missing");
        throw new Error("Could not add card");
      }

      const res = await stripeConfirmSetup({
        stripeSetupIntent: intent.data.createStripeSetupIntent,
        creditCardHolderName,
      });

      if (!res) {
        console.error("Failed to setup stripe intent");
        throw new Error("Failed to setup stripe intent");
      }

      // Register payment source
      await registerStripePaymentSource({
        variables: {
          setupIntentClientSecret: intent.data.createStripeSetupIntent,
        },
        refetchQueries: ["PaymentMethodsView"],
        awaitRefetchQueries: true,
      });

      toast.success(t("New payment source added"));
      goBack();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen
      kind={ModalKind.SECONDARY}
      close={() => goBack()}
      panelProps={{
        imagePath: "../../../../images/upsellIllustration-2.png",
        title: t("Save new payment method"),
        width: PanelWidth.SMALL,
        caption: t(
          "You can add a new payment method that could be used for one-time purchase and also for your monthly subscription payments.",
        ),
        captionClassName: styles["title-caption"],
      }}
    >
      <Loader fixed visible={loading} />

      <div className={styles["form-wrapper"]}>
        <StripeCreditCardForm
          withSpace={false}
          onCreditCardHolderNameChange={setCreditCardHolderName}
          fieldColumnWidth={isMobile ? 240 : 120}
          // onSubmitButtonClicked={onCreditCardEditClicked}
        />
      </div>

      <Layout className={styles["button-wrap"]} wrap="MOBILE">
        <Button
          className={styles.button}
          kind="SOLID"
          borderRadius="SMALL"
          color="GREY"
          type="button"
          onClick={goBack}
          disabled={loading}
        >
          {t("Cancel")}
        </Button>
        <Button
          className={styles.button}
          disabled={loading}
          kind="SOLID"
          borderRadius="SMALL"
          color="BLUE"
          type="button"
          onClick={handleAddNewStripePaymentMethod}
        >
          {t("Save")}
        </Button>
      </Layout>
    </Modal>
  );
};
