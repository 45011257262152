import classNames from "classnames";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import { Benefits } from "../../components/benefits/Benefits";
import { Button } from "../../components/button/Button";
import { Container } from "../../components/container/Container";
import Field from "../../components/field/Field";
import { Loader } from "../../components/loader/Loader";
import { Panel, PanelWidth } from "../../components/panel/Panel";
import { Separator, SeparatorGutter } from "../../components/separator/Separator";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { useGetAffiliateQuery, useValidateAffiliateMutationMutation, ValidationResultType } from "../../graphql/schema";
import { Routes } from "../../services/constants";
import { handleFormErrors } from "../../services/handleFormErrors";

import styles from "./validateCodeView.module.scss";

interface ValidateCodeFormValues {
  affiliateId: string;
}

export default function ValidateCodeView() {
  const [t] = useTranslation();

  const validateCodeValidationSchema: yup.SchemaOf<ValidateCodeFormValues> = yup.object().shape({
    affiliateId: yup
      .string()
      .min(6, t("Invite code should be at least 6 characters long"))
      .required(t("Invite code is missing")),
  });

  const { isMobile } = useWindowDimensionsContext();
  const { data: affiliateData, loading } = useGetAffiliateQuery({ fetchPolicy: "network-only" });
  const {
    register,
    handleSubmit,
    errors: userInputErrors,
    setError,
  } = useForm<ValidateCodeFormValues>({
    resolver: yupResolver(validateCodeValidationSchema),
  });
  const [validateAffiliateMutation] = useValidateAffiliateMutationMutation({
    fetchPolicy: "no-cache",
  });
  const { push } = useHistory();

  if (loading) {
    return <Loader />;
  }

  const handleValidateCode = handleSubmit(async ({ affiliateId }) => {
    try {
      const result = await validateAffiliateMutation({ variables: { affiliateId } });

      handleFormErrors<ValidateCodeFormValues>({
        errorMap: [{ affiliateId: { name: "affiliate", customError: t("Invite code is invalid") } }],
        setError,
        result: result.data?.validateAffiliate?.result ?? undefined,
        t,
      });

      if (result.data?.validateAffiliate?.result.type === ValidationResultType.SUCCESS) {
        push(`/registration/${affiliateId}`);
      }
    } catch (err) {
      toast.error(t("Something went wrong"));
    }
  });

  return (
    <Container secondary>
      <Panel
        panelStyle={isMobile ? "SECONDARY" : "PRIMARY"}
        imagePath="/images/validateCodeIllustration.png"
        title={t("SF Suite is invite only.")}
        caption={t("Do you already have the invite code?")}
        width={PanelWidth.LARGE}
      >
        <form onSubmit={handleValidateCode} className={styles["validation-form"]}>
          <Field
            expand
            type="text"
            label={t("My invite code is...")}
            name="affiliateId"
            defaultValue={affiliateData?.getAffiliate?.affiliateId}
            internalRef={register}
            isRequired
            error={userInputErrors.affiliateId}
          />
          <Button
            className={styles.button}
            type="submit"
            color="YELLOW"
            borderRadius="MEDIUM"
            fontSize={18}
            weight="MEDIUM"
            width={180}
            stretch="TABLET_LANDSCAPE_MAX"
          >
            {t("Get access")}
          </Button>
        </form>
        <Benefits />
        <Separator gutter={SeparatorGutter.MEDIUM} />
        <div className={classNames(styles["invitation-paragraph"], styles["invitation-paragraph--with-gutter"])}>
          <span className={styles["invitation-paragraph--highlight"]}>
            <b>
              <i>{t("Not yet?")}</i>
            </b>
          </span>
          {t(
            "If you don’t have the invitation yet, but would like to join our community, you can request access. After requesting invitation, somebody from our community within your country will contact you and might share his invitation with you…",
          )}
        </div>
        <p className={styles["invitation-paragraph"]}>
          {t("Click here to")}{" "}
          <Link className={styles.link} to="/request-invite">
            {t("request invite")}
          </Link>{" "}
          {t("or")}{" "}
          <Link className={styles.link} to={Routes.LOGIN}>
            {t("login")}
          </Link>
        </p>
      </Panel>
    </Container>
  );
}
