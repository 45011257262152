import classNames from "classnames";
import { FC, BaseSyntheticEvent, FormEvent, ReactNode } from "react";

import styles from "./form.module.scss";

export interface FormProps {
  onSubmit: (e: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  doubleColumn?: boolean;
  stretch?: boolean;
  className?: string;
  children?: ReactNode;
}

export const Form: FC<FormProps> = ({ doubleColumn, stretch, onSubmit, className, children }) => {
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // prevent submitting the form
    event.preventDefault();

    // call the submit handler if available
    if (typeof onSubmit === "function") {
      await onSubmit(event);
    }
  };

  return (
    <form
      className={classNames(
        {
          [styles["form--double-column"]]: doubleColumn,
          [styles["form--stretch"]]: stretch,
        },
        className,
      )}
      onSubmit={handleSubmit}
    >
      {children}
      <input type="submit" style={{ display: "none" }} />
    </form>
  );
};
