import React from "react";
import classNames from "classnames";

import styles from "./data-fraction.module.scss";

export interface DataFractionProps {
  icon: React.ReactNode;
  label: string;
  value: any;
  wrapText?: boolean;
  addendum?: string;
  className?: string;
}

export const DataFraction: React.FC<DataFractionProps> = ({ icon, label, value, wrapText, addendum, className }) => {
  return (
    <div className={classNames(styles.wrap, { [styles["wrap-text"]]: wrapText }, className)}>
      <div className={styles["icon-wrap"]}>{icon}</div>
      <div>
        <div className={styles.label}>{label}:</div>
        <div className={styles.value}>
          {value}
          {addendum && <span className={styles.addendum}>{addendum}</span>}
        </div>
      </div>
    </div>
  );
};
