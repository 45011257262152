import React from "react";
import classNames from "classnames";
import { Link as ReactLink, LinkProps as ReactLinkProps } from "react-router-dom";

import { Gutters, Breakpoints } from "../../services/constants";
import { Height, Color, Shape, FontWeight, BorderRadius } from "../../services/buttonLinkConstants";

import styles from "./link.module.scss";

export enum LinkKind {
  SOLID = "SOLID",
  HOLLOW = "HOLLOW",
  TEXT = "TEXT",
}

export interface LinkProps extends ReactLinkProps {
  gutter?: keyof typeof Gutters;
  height?: keyof typeof Height;
  shape?: keyof typeof Shape;
  color?: keyof typeof Color;
  kind?: keyof typeof LinkKind;
  weight?: keyof typeof FontWeight;
  borderRadius?: keyof typeof BorderRadius;
  stretch?: boolean | keyof typeof Breakpoints;
  className?: string;
  externalUrl?: string;
  fontSize?: number | "inherit";
  width?: number;
  inlineStyles?: {};
}

export const Link: React.FC<LinkProps> = ({
  children,
  gutter,
  height = "MEDIUM",
  shape = "RECT",
  color,
  kind = "SOLID",
  weight,
  borderRadius,
  stretch,
  externalUrl,
  className,
  fontSize,
  width,
  inlineStyles,
  ...props
}) => {
  const linkClassnames = classNames(
    // @ts-ignore remove me once typescript 4.2 is installed
    styles[`gutter--${gutter}`],
    // @ts-ignore remove me once typescript 4.2 is installed
    styles[`height--${height}`],
    // @ts-ignore remove me once typescript 4.2 is installed
    styles[`shape--${shape}`],
    // @ts-ignore remove me once typescript 4.2 is installed
    styles[`color--${color}`],
    // @ts-ignore remove me once typescript 4.2 is installed
    styles[`kind--${kind}`],
    // @ts-ignore remove me once typescript 4.2 is installed
    styles[`weight--${weight}`],
    // @ts-ignore remove me once typescript 4.2 is installed
    styles[`radius--${borderRadius}`],
    // @ts-ignore remove me once typescript 4.2 is installed
    styles[`stretch--${stretch}`],
    {
      [styles.link]: color !== undefined || height !== undefined || gutter !== undefined || shape !== undefined,
      [styles.stretch]: stretch === true,
    },
    className,
  );

  if (externalUrl) {
    return (
      <a
        style={{ fontSize: fontSize, width: `${width}px`, ...inlineStyles }}
        className={linkClassnames}
        href={externalUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={props.onClick}
      >
        {children}
      </a>
    );
  }

  return (
    <ReactLink
      style={{ fontSize: fontSize, width: `${width}px`, ...inlineStyles }}
      className={linkClassnames}
      {...props}
    >
      {children}
    </ReactLink>
  );
};
