import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-parallax";

import { ContentSection } from "../../../components/content-section/ContentSection";
import { Heading } from "../../../components/heading/Heading";
import { Separator } from "../../../components/separator/Separator";
import { Columns } from "../../../components/columns/Columns";
import { MediaSection } from "../../../components/media-section/MediaSection";
import ForexPhoneNewsImg from "../assets/ForexPhoneNews.png";
import ForexPhoneReportsImg from "../assets/ForexPhoneReports.png";
import ForexPhoneTradingImg from "../assets/ForexPhoneTrading.png";
import AppleEmojiBiceps from "../assets/AppleEmojiBiceps.png";

import styles from "./features-section.module.scss";

export const FeaturesSection: FC = () => {
  // access translation keys
  const [t] = useTranslation();

  return (
    <ContentSection
      className={styles["relative-wrap"]}
      wrapClassName={styles.wrap}
      contentClassName={styles.content}
      centerText
    >
      <Separator type="SECONDARY" onBackground />

      <p className={styles.caption}>{t("What’s included in Forex Insiders Products?")}</p>

      <Heading level={4} className={styles.heading} center>
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              "Forex Insiders Memberships give you everything you need to <strong>start trading</strong> in forex markets <strong>like an absolute pro.</strong>",
            ),
          }}
        />
        <img src={AppleEmojiBiceps} className={styles.emoji} alt="apple_emoji_biceps" />
      </Heading>

      <Columns gap={40} minWidth={230}>
        <MediaSection className={styles["media-wrap"]} contentClassName={styles["media-content"]}>
          <Parallax
            className={styles["circle-image-wrap"]}
            bgClassName={styles["circle-image"]}
            bgImage={ForexPhoneTradingImg}
            strength={-160}
          />

          <span className={styles["section-heading"]}>{t("Access to trading ideas")}</span>

          <p className={styles.description}>
            {t(
              "Trading ideas are the first things you’ll see when you log in to your account. Every idea comes with all the information and explanation you’ll need, giving you the tools to easily replicate the trade.",
            )}
          </p>
        </MediaSection>
        <MediaSection className={styles["media-wrap"]} contentClassName={styles["media-content"]}>
          <Parallax
            className={styles["circle-image-wrap"]}
            bgClassName={styles["circle-image"]}
            bgImage={ForexPhoneReportsImg}
            strength={-160}
          />

          <span className={styles["section-heading"]}>{t("Transparent reports")}</span>

          <p className={styles.description}>
            {t(
              "Every idea published by the algorithm remains there. You can see how any idea performed, as well as weekly, monthly, and annual reports.",
            )}
          </p>
        </MediaSection>
        <MediaSection className={styles["media-wrap"]} contentClassName={styles["media-content"]}>
          <Parallax
            className={styles["circle-image-wrap"]}
            bgClassName={styles["circle-image"]}
            bgImage={ForexPhoneNewsImg}
            strength={-160}
          />

          <span className={styles["section-heading"]}>{t("News & Education")}</span>

          <p className={styles.description}>
            {t(
              "With a Forex Insiders account you’ll get access to unique books that aren’t available anywhere else, as well as educational training videos and carefully curated forex news from our team.",
            )}
          </p>
        </MediaSection>
      </Columns>
    </ContentSection>
  );
};
