import classNames from "classnames";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { Routes } from "../../services/constants";
import IconUserCard from "../icon/IconUserCard";

import styles from "./account-menu-button.module.scss";

export interface AccountMenuItem {
  icon: ReactNode;
  label: string;
  to: string | Routes;
  isDisabled?: boolean;
  isExternal?: boolean;
}

export interface AccountMenuButtonProps {
  hasAccessToWebOffice: boolean;
  isActive: boolean;
  menuClassName?: string;
  accountMenuItems: AccountMenuItem[];
}

interface MenuItemProps {
  icon: ReactNode;
  label: string;
  to: string;
  isDisabled?: boolean;
  isExternal?: boolean;
}

export const AccountMenuButton: FC<AccountMenuButtonProps> = ({ menuClassName, accountMenuItems }) => {
  // access translation keys
  const [t] = useTranslation();

  // get url pathname
  const { pathname } = useLocation();

  // check if url contains 'account'
  const isAccountView = pathname.startsWith("/account/");

  return (
    <>
      <div className={styles.menuContainer}>
        <div className={classNames(styles.button, { [styles["button--active"]]: isAccountView })}>
          <p className={styles.label}>{t("My Account").toUpperCase()}</p>
          <IconUserCard className={styles.icon} />
        </div>

        <div className={classNames(styles.dropdown, menuClassName)}>
          <div className={styles.shadow}>
            <div className={styles.menu}>
              {accountMenuItems.map((menuItem, index) => (
                <MenuItem
                  key={index}
                  icon={menuItem.icon}
                  label={menuItem.label}
                  to={menuItem.to}
                  isExternal={menuItem.isExternal}
                  isDisabled={menuItem.isDisabled}
                />
              ))}
            </div>

            <Link className={styles.logout} to="/logout">
              <p>{t("Log Out").toUpperCase()}</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const MenuItem: FC<MenuItemProps> = ({ icon, label, to, isExternal, isDisabled }) => {
  if (isExternal) {
    return (
      <a className={styles.link} href={to} target="_blank" rel="noopener noreferrer">
        {icon}
        <p className={styles["link-label"]}>{label}</p>
      </a>
    );
  }

  if (isDisabled) {
    return (
      <span className={classNames(styles["link"], styles["link--disabled"])}>
        {icon}
        <p className={styles["link-label"]}>{label}</p>
      </span>
    );
  }

  return (
    <Link className={styles.link} to={to}>
      {icon}
      <p className={styles["link-label"]}>{label}</p>
    </Link>
  );
};
