import React from "react";

export interface IconPortfolioProps {
  hasTitle?: boolean;
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}

export const IconPortfolio: React.FC<IconPortfolioProps> = ({
  hasTitle,
  width = 12,
  height = 12,
  fill = "#fff",
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      className={className}
      fillRule="evenodd"
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.72 11.58"
    >
      {hasTitle && <title>IconPortfolio</title>}
      <path
        id="Fill-229"
        d="M9.49,6.84H2.23a.56.56,0,0,1,0-1.12H9.49a.56.56,0,0,1,0,1.12ZM4.05,1.53a.13.13,0,0,1,.14-.13H7.53a.13.13,0,0,1,.14.13v.84H4.05Zm6.28.84H9.07V1.53A1.54,1.54,0,0,0,7.53,0H4.19A1.54,1.54,0,0,0,2.65,1.53v.84H1.4A1.4,1.4,0,0,0,0,3.77v6.42a1.4,1.4,0,0,0,1.4,1.39h8.93a1.39,1.39,0,0,0,1.39-1.39V3.77a1.4,1.4,0,0,0-1.39-1.4Z"
      />
    </svg>
  );
};
