import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Button } from "../button/Button";
import Field, { FieldGutter } from "../field/Field";
import { Modal, ModalKind } from "../modal/Modal";
import { PanelWidth } from "../panel/Panel";
import { PaymentEntityType, PaymentMethod, CurrencyCode } from "../../graphql/schema";
import { useHandelPayment } from "../../hooks/useHandlePayment";
import { PaymentFlow } from "../payment-result/PaymentResult";
import {
  ADD_NEW_BANK_CARD_ID,
  PaymentMethodsList,
  PAYMENT_METHODS_ORDER,
} from "../payment-methods-list/PaymentMethodsList";

import styles from "./top-up-modal.module.scss";

export interface TopUpModalProps {
  refetchPaymentSources: () => void;
  paymentFlow?: PaymentFlow;
  currencyCode: keyof typeof CurrencyCode;
}

export const TopUpModal: React.FC<TopUpModalProps> = ({ refetchPaymentSources, paymentFlow, currencyCode }) => {
  const { t } = useTranslation();
  const { goBack, push, location } = useHistory();
  const [amount, setAmount] = useState("");
  const [creditCardHolderName, setCreditCardHolderName] = useState("");
  const [isIdealBankSelected, setIsIdealBankSelected] = useState(false);
  const [saveCardForLaterUse, setSaveCardForLaterUse] = useState<boolean>(false);

  const [paymentLimitError, setPaymentLimitError] = useState<string | undefined>(undefined);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PAYMENT_METHODS_ORDER[0]);
  const [selectedPaymentMethodSourceId, setSelectedPaymentMethodSourceId] = useState<string | null>(null);

  const handlePayment = useHandelPayment({
    onError: handleOnPaymentFailed,
    onSuccess: handleOnPaymentSuccess,
    onUnknownResponse: handleOnPaymentFailed,
    useCardForLaterUse: saveCardForLaterUse,
    selectedPaymentMethod: { id: selectedPaymentMethodSourceId, type: selectedPaymentMethod },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleOnPaymentSuccess() {
    setIsSubmitting(false);
    toast.success(t("Top-up successful"));
    refetchPaymentSources();
    push(location.pathname.replace("top-up", ""));
  }

  // OnUnknownResponse and Fail use same function
  function handleOnPaymentFailed() {
    setIsSubmitting(false);

    toast.error(t("Something went wrong"));
  }

  return (
    <Modal
      isOpen
      kind={ModalKind.SECONDARY}
      close={() => (isSubmitting ? undefined : goBack())}
      panelProps={{
        imagePath: "../../../../images/illustrationSwitchPlan.png",
        title: t("Top up your Shop Credit"),
        width: PanelWidth.SMALL,
        caption: t("First select the amount that you want to add and then select your preferred payment method."),
        captionClassName: styles["title-caption"],
      }}
    >
      <form
        className={styles["modal-field-wrap"]}
        onSubmit={async (e) => {
          e.preventDefault();

          if (paymentLimitError) {
            return;
          }

          if (!amount) {
            setPaymentLimitError(t("Amount is missing"));
            return;
          }
          setIsSubmitting(true);

          try {
            await handlePayment({
              creditCardHolderName,
              errorMessage: t("Something went wrong"),
              paymentVariables: {
                entityType: PaymentEntityType.PURCHASE_OF_SHOP_CREDIT,
                currencyCode,
              },
              selectedPaymentSource: {
                id: selectedPaymentMethodSourceId === ADD_NEW_BANK_CARD_ID ? null : selectedPaymentMethodSourceId,
                paymentMethod: selectedPaymentMethod,
              },
              isIDealBankSelected: isIdealBankSelected,
              totalPrice: amount,
              paymentFlow,
            });
          } catch (err) {
            if (err instanceof Error) {
              console.error(err.message);
              toast.error(err.message, { autoClose: false });
            }
            push(location.pathname.replace("top-up", ""));
          }
        }}
      >
        <Field
          baseInputProps={{ onWheel: (e) => e.target instanceof HTMLElement && e.target.blur() }}
          inputClassName={styles.input}
          label={t("Amount")}
          gutter={FieldGutter.MEDIUM}
          type="number"
          defaultValue={amount}
          onChange={setAmount}
          error={paymentLimitError ? { type: "", message: paymentLimitError } : undefined}
        />

        <PaymentMethodsList
          gutter="MEDIUM"
          selectedPaymentMethod={selectedPaymentMethod}
          selectedPaymentMethodSourceId={selectedPaymentMethodSourceId}
          onPaymentMethodChange={setSelectedPaymentMethod}
          onPaymentMethodSourceIdChange={setSelectedPaymentMethodSourceId}
          onOwnerNameChange={setCreditCardHolderName}
          getSaveForLaterUseStatus={setSaveCardForLaterUse}
          amount={amount ? parseFloat(amount) : undefined}
          currencyCode={currencyCode}
          handleIsIdealBankSelected={setIsIdealBankSelected}
          onErrorStateChange={({ hasLimitError, max, sign }) => {
            setPaymentLimitError(
              hasLimitError
                ? t(
                    "Total amount should not be larger than {{limit}}{{sign}} for selected payment method. Please split your {{purchaseType}} into smaller payments.",
                    {
                      limit: max,
                      sign,
                      purchaseType: t("purchase"),
                    },
                  )
                : undefined,
            );
          }}
          disabledPaymentMethods={[PaymentMethod.SHOP_CREDIT]}
        />

        <Button
          type="submit"
          borderRadius="SMALL"
          color="BLUE"
          fontSize={18}
          width={280}
          weight="MEDIUM"
          center="BLOCK_AND_MARGIN"
          disabled={isSubmitting || !!paymentLimitError || !selectedPaymentMethod}
        >
          {t("Top up")}
        </Button>
      </form>
    </Modal>
  );
};
