import React from "react";

export interface IconExpandProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconExpand: React.FC<IconExpandProps> = ({ hasTitle, className, fill, width, height }) => (
  <svg
    className={className}
    width={width}
    height={height}
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    {hasTitle && <title>IconExpand</title>}
    <path
      fill={fill}
      fillRule="evenodd"
      d="M20.63,16.25H16.25v4.38a1.25,1.25,0,0,1-2.5,0V16.25H9.38a1.25,1.25,0,0,1,0-2.5h4.37V9.38a1.25,1.25,0,0,1,2.5,0v4.37h4.38a1.25,1.25,0,0,1,0,2.5ZM15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Z"
    />
  </svg>
);
