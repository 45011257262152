import React from "react";

interface IconDollarBillProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconDollarBill: React.FC<IconDollarBillProps> = ({
  hasTitle,
  className,
  fill = "#fff",
  width = 48,
  height = 48,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48" className={className}>
      {hasTitle && <title>IconDollarBill</title>}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill} transform="translate(-1169 -1499)">
          <g transform="translate(0 1050)">
            <g transform="translate(1043 173)">
              <path d="M152.5 311.83v1.29c0 1.04-.84 1.88-1.88 1.88h-1.24c-1.04 0-1.88-.84-1.88-1.88v-1.27c-1.9-.13-3.66-.82-5.08-1.92-.89-.69-.96-2.01-.17-2.81l.89-.88c.66-.67 1.69-.71 2.46-.17.71.51 1.58.81 2.52.81h3.74c.74 0 1.44-.41 1.73-1.07.46-1.04-.13-2.17-1.12-2.46l-6.38-1.91a6.776 6.776 0 01-4.84-6.32c-.1-3.58 2.75-6.56 6.25-6.95v-1.29c0-1.04.84-1.88 1.88-1.88h1.24c1.04 0 1.88.84 1.88 1.88v1.27c1.9.13 3.66.82 5.09 1.92.88.69.95 2.01.16 2.8l-.89.89c-.66.67-1.69.71-2.45.17-.72-.51-1.59-.81-2.53-.81h-3.75c-.94 0-1.79.69-1.87 1.63-.08.87.45 1.65 1.27 1.9l6.38 1.91c2.83.85 4.75 3.38 4.84 6.32.1 3.58-2.75 6.56-6.25 6.95zM150 276c-13.25 0-24 10.75-24 24s10.75 24 24 24 24-10.75 24-24-10.75-24-24-24z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
