import classNames from "classnames";

import { AsyncImg } from "../async-img/AsyncImg";

import styles from "./testimonial.module.scss";

export enum TestimonialType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export interface TestimonialProps {
  type?: keyof typeof TestimonialType;
  imagePath: string;
  quote: string;
  author: string;
  description: string;
}

export default function Testimonial({ type = "PRIMARY", description, imagePath, author, quote }: TestimonialProps) {
  return (
    <div className={classNames(styles.wrap, { [styles["wrap--primary"]]: type === "PRIMARY" })}>
      <div
        className={classNames(styles.content, {
          [styles["content--primary"]]: type === "PRIMARY",
          [styles["content--secondary"]]: type === "SECONDARY",
        })}
      >
        <blockquote
          className={styles.quote}
          dangerouslySetInnerHTML={{
            __html: quote,
          }}
        />
        <AsyncImg
          src={imagePath}
          alt={author}
          className={classNames(styles.image, { [styles["image--primary"]]: type === "PRIMARY" })}
        />
        <p className={styles.author}>- {author}</p>
        <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
}
