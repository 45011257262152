export interface IconSafeProps {
  hasTitle?: boolean;
  width?: number;
  height?: number;
  className?: string;
}

function IconSafe({ hasTitle, width = 30, height = 30, className }: IconSafeProps) {
  return (
    <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      {hasTitle && <title>IconSafe</title>}
      <path
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M4.89 61.774a3.41 3.41 0 00-3.16 3.136c-.244 3.137-.366 6.046-.366 8.726 0 2.68.122 5.59.366 8.725a3.41 3.41 0 003.16 3.136c3.917.275 7.302.412 10.154.412 2.844 0 6.2-.136 10.067-.41a3.41 3.41 0 003.159-3.135c.244-3.137.366-6.047.366-8.728 0-2.68-.122-5.589-.366-8.725a3.41 3.41 0 00-3.16-3.136c-3.909-.274-7.287-.411-10.132-.411-2.842 0-6.205.136-10.089.41zm-.096-1.36C8.707 60.138 12.102 60 14.978 60c2.88 0 6.289.138 10.227.415a4.773 4.773 0 014.424 4.39c.247 3.17.371 6.114.371 8.831 0 2.718-.124 5.663-.37 8.834a4.773 4.773 0 01-4.423 4.39c-3.897.275-7.284.413-10.163.413-2.887 0-6.303-.139-10.249-.415a4.773 4.773 0 01-4.424-4.39C.124 79.297 0 76.352 0 73.635c0-2.717.124-5.661.37-8.832a4.773 4.773 0 014.424-4.39zM8.182 86.59a.682.682 0 111.363 0v1.364C9.545 89.085 8.63 90 7.5 90H4.773a2.045 2.045 0 01-2.046-2.045v-2.046a.682.682 0 111.364 0v2.046c0 .376.305.681.682.681H7.5a.682.682 0 00.682-.681V86.59zm17.727-.682a.682.682 0 111.364 0v2.046c0 1.13-.916 2.045-2.046 2.045H22.5a2.045 2.045 0 01-2.045-2.045V86.59a.682.682 0 111.363 0v1.364c0 .376.305.681.682.681h2.727a.682.682 0 00.682-.681v-2.046zm-5.454-6.818a5.455 5.455 0 110-10.91 5.455 5.455 0 010 10.91zm0-1.364a4.09 4.09 0 100-8.182 4.09 4.09 0 000 8.182zM4.773 80.455a.682.682 0 010-1.364h6.818a.682.682 0 110 1.364H4.773zm0-12.273a.682.682 0 010-1.364h6.818a.682.682 0 110 1.364H4.773zm.682-2.046a.682.682 0 011.363 0v15a.682.682 0 11-1.363 0v-15z"
        transform="translate(-815 -710) translate(80 90) translate(720 100) translate(15 460)"
      />
    </svg>
  );
}

export default IconSafe;
