// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays-of-non-normalized-objects
export function mergeNonNormalizedObjectArrays<T>() {
  return {
    merge: (existing: T[] = [], incoming: T[], { readField, mergeObjects }: any): T[] => {
      const merged = existing.slice(0);
      const rowToIndex: Record<string, number> = Object.create(null);

      if (existing) {
        existing.forEach((row, index: number) => {
          rowToIndex[readField("id", row)] = index;
        });
      }

      incoming.forEach((row) => {
        const id = readField("id", row);
        const index = rowToIndex[id];
        if (typeof index === "number") {
          // Merge the new row data with the existing row data.
          merged[index] = mergeObjects(merged[index], row);
        } else {
          // First time we've seen this row in this array.
          rowToIndex[id] = merged.length;
          merged.push(row);
        }
      });
      return merged;
    },
    read: (existing = []) => {
      return existing;
    },
  };
}
