import React from "react";
import classNames from "classnames";
import { TFunction } from "i18next";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Heading } from "../../../../components/heading/Heading";
import { IconCalendar } from "../../../../components/icon/IconCalendar";
import { IconStopWatch } from "../../../../components/icon/IconStopWatch";
import { Section } from "../../../../components/section/Section";
import { ServiceIcon } from "../../../../components/service-icon/ServiceIcon";
import { SharePanel } from "../../../../components/share-panel/SharePanel";
import { StatsListing } from "../../../../components/stats-listing/StatsListing";
import { StatusIcon, StatusIconStatus } from "../../../../components/status-icon/StatusIcon";
import { StatusLabel } from "../../../../components/status-label/StatusLabel";
import { Titlebar, TitlebarType } from "../../../../components/titlebar/Titlebar";
import { useDriveForFiveMembershipViewQuery, ReferralsSubscriptionStatus } from "../../../../graphql/schema";
import assertNever from "../../../../services/assertNever";
import { Routes } from "../../../../services/constants";
import { MonthlySubscriptionsTag } from "../../../../components/monthly-subscriptions-tag/MonthlySubscriptionsTag";
import { Loader } from "../../../../components/loader/Loader";
import NotFound from "../../../../components/not-found/NotFound";
import DateTime from "../../../../components/date-time/DateTime";

import styles from "./drive-for-five-membership-view.module.scss";

export const DriveForFiveMembershipView: React.FC = () => {
  const [t] = useTranslation();
  const { id } = useParams<{ id?: string }>();
  const { loading, data, previousData, error } = useDriveForFiveMembershipViewQuery({
    variables: { subscriptionId: id || "-1" },
    skip: !id,
  });

  const inviteLink = data?.me.info?.inviteLink ?? "";
  const subscription = (data?.me.serviceSubscriptions ?? []).length > 0 ? data?.me.serviceSubscriptions[0] : undefined;
  const currentMonthUsers = subscription?.referralsSubscriptionsForCurrentMonth?.records ?? [];

  const stats = [
    {
      value: subscription?.referralsStatsForCurrentMonth?.registrations ?? 0,
      label: t("Total visitors"),
    },
    {
      value: subscription?.referralsStatsForCurrentMonth?.customers ?? 0,
      label: t("Sign ups"),
    },
    {
      value: subscription?.referralsStatsForCurrentMonth?.active ?? 0,
      label: t("Active friends"),
    },
  ];

  if (error || (!loading && !subscription)) {
    return <NotFound>{t("Something went wrong. Please reload the page or contact support")}</NotFound>;
  }

  // render product name
  const productName = subscription ? getProductName(subscription.products) : "";

  return (
    <>
      <Loader cover visibleOnFirstLoad={{ loading, previousData, data }} />
      <Titlebar
        className={styles.titlebar}
        type={TitlebarType.PRIMARY}
        title={subscription ? getProductName(subscription.products) : ""}
        backUrl={{ to: Routes.DRIVE_FOR_FIVE, title: t("Back") }}
      />
      <div className={styles["tabs-wrap"]}>
        <MonthlySubscriptionsTag
          subscriptions={subscription?.referralsSubscriptionsActiveForNearestMonths ?? []}
          productName={subscription ? getProductName(subscription.products) : ""}
        />
      </div>
      <SharePanel
        linkUrl={inviteLink}
        color="DARK_BLUE"
        icon={
          <Section gutter={10}>
            {(subscription?.products ?? []).map(({ code }) => (
              <ServiceIcon key={code} productCode={code} width={50} height={34} />
            ))}
          </Section>
        }
        title={t("Share {{productName}} with your friends", { productName: productName })}
        buttonColor="ORANGE"
        gutter={85}
      />
      <Section gutter={105} center withSpace>
        <StatsListing stats={stats} horizontal="TABLET_PORTRAIT_UP" width={780} />
      </Section>
      {currentMonthUsers.length > 0 && (
        <Section gutter={100} center withSpace>
          <div className={styles["title-wrap"]}>
            <Heading className={classNames(styles.title, styles["title-offset"])} level={6}>
              {t("Friend's details")}
            </Heading>
            <Heading className={styles.title} level={6}>
              {t("Customer since & paid until")}
            </Heading>
            <Heading className={styles.title} level={6}>
              {t("Product")}
            </Heading>
            <Heading className={styles.title} level={6}>
              {t("Status this month")}
            </Heading>
          </div>
          {currentMonthUsers.map(({ status, createdDate, expiresDate, referral, products }) => (
            <div className={styles.listing} key={referral.email}>
              <div className={styles["name-wrap"]}>
                <StatusIcon className={styles["status-icon"]} status={getStatusIconColor(status)} />
                <div>
                  <div>{getReferralFullName(referral)}</div>
                  <div className={styles.email}>{referral.email}</div>
                </div>
              </div>
              <div className={styles["date-wrap"]}>
                {createdDate && (
                  <div className={styles.date}>
                    <IconCalendar className={styles["date-icon"]} fill="#CFD0DF" width={16} height={16} />
                    <DateTime value={createdDate} />
                  </div>
                )}
                {expiresDate && (
                  <div className={styles.date}>
                    <IconStopWatch className={styles["date-icon"]} fill="#CFD0DF" width={16} height={19} />
                    <strong>
                      <DateTime value={expiresDate} />
                    </strong>
                  </div>
                )}
              </div>
              <div className={styles["product-wrap"]}>{products.map((p) => p.name).join(", ")}</div>
              <div className={styles.activity}>
                <StatusLabel color={getStatusLabelColor(status)} />
                {getStatusIconTranslation(t, status)}
              </div>
            </div>
          ))}
        </Section>
      )}
    </>
  );
};

function getStatusIconColor(status: ReferralsSubscriptionStatus) {
  switch (status) {
    case ReferralsSubscriptionStatus.ACTIVE:
    case ReferralsSubscriptionStatus.ACTIVE_DISCOUNT:
      return StatusIconStatus.SUCCESS;

    case ReferralsSubscriptionStatus.INACTIVE:
      return StatusIconStatus.CANCEL;

    case ReferralsSubscriptionStatus.PENDING:
    case ReferralsSubscriptionStatus.PENDING_DISCOUNT:
      return StatusIconStatus.WARNING;

    default:
      return assertNever(status);
  }
}
function getStatusLabelColor(status: ReferralsSubscriptionStatus) {
  switch (status) {
    case ReferralsSubscriptionStatus.ACTIVE:
    case ReferralsSubscriptionStatus.ACTIVE_DISCOUNT:
      return "GREEN";

    case ReferralsSubscriptionStatus.INACTIVE:
      return "LIGHT_GREY";

    case ReferralsSubscriptionStatus.PENDING:
    case ReferralsSubscriptionStatus.PENDING_DISCOUNT:
      return "YELLOW";

    default:
      return assertNever(status);
  }
}

function getStatusIconTranslation(t: TFunction, status: ReferralsSubscriptionStatus) {
  switch (status) {
    case ReferralsSubscriptionStatus.ACTIVE:
    case ReferralsSubscriptionStatus.ACTIVE_DISCOUNT:
      return t("Active");

    case ReferralsSubscriptionStatus.INACTIVE:
      return t("Not active");

    case ReferralsSubscriptionStatus.PENDING:
    case ReferralsSubscriptionStatus.PENDING_DISCOUNT:
      return `${t("Pending")}...`;

    default:
      return assertNever(status);
  }
}

function getProductName(products: { name: string }[] = []) {
  return products.map((p) => p.name).join(" + ");
}

function getReferralFullName(referral: { firstName?: string | null; lastName?: string | null }) {
  return [referral.firstName, referral.lastName].filter((n) => n).join(" ");
}
