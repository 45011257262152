import { FC } from "react";

export interface IconCreditCardProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconCreditCard: FC<IconCreditCardProps> = ({ hasTitle, className, fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 18"
    >
      {hasTitle && <title>IconCreditCard</title>}
      <path
        id="Fill-153"
        fillRule="evenodd"
        fill={fill}
        d="M19.5,5.5H4.5a1,1,0,0,1,0-2h15a1,1,0,0,1,0,2Zm0,9H17a1,1,0,0,1,0-2h2.5a1,1,0,0,1,0,2ZM21.5,0H2.5A2.5,2.5,0,0,0,0,2.5v13A2.5,2.5,0,0,0,2.5,18h19A2.5,2.5,0,0,0,24,15.5V2.5A2.5,2.5,0,0,0,21.5,0Z"
      />
    </svg>
  );
};
