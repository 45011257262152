export interface IconMenuBurgerProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

function IconMenuBurger({ hasTitle, className, fill = "#292F48", width = 28, height = 20 }: IconMenuBurgerProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 20">
      {hasTitle && <title>IconMenuBurger</title>}
      <path
        fill={fill}
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M272.7 30.429c-.387 0-.7-.32-.7-.715 0-.394.313-.714.7-.714h26.599c.387 0 .7.32.7.714a.707.707 0 01-.7.715H272.7zm0-9c-.387 0-.7-.32-.7-.715 0-.394.313-.714.7-.714h26.599c.387 0 .7.32.7.714a.707.707 0 01-.7.715H272.7zm0 18c-.387 0-.7-.32-.7-.715 0-.394.313-.714.7-.714h26.599c.387 0 .7.32.7.714a.707.707 0 01-.7.715H272.7z"
        transform="translate(-272 -20) translate(-314) translate(314)"
      ></path>
    </svg>
  );
}

export default IconMenuBurger;
