import { BaseTracker } from "./BaseTracker";
import { CookieConsentCategory, TrackerEventParams, TrackerEventType } from "../types";

const TOKEN = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID_EXTERNAL;

export class GoogleTagManagerTracker extends BaseTracker {
  private isTrackerInitialized = false;

  public constructor() {
    super(CookieConsentCategory.FUNCTIONAL);
  }

  protected initialize() {
    if (!TOKEN) {
      return false;
    }

    if (!this.isTrackerInitialized) {
      document.head.insertBefore(this.getScript(TOKEN), document.head.childNodes[0]);
      document.body.insertBefore(this.getNoScript(TOKEN), document.body.childNodes[0]);
      this.isTrackerInitialized = true;
    }

    return true;
  }

  protected destroy() {
    this.isInitialized = false;
  }

  public identify(userId: string, params: TrackerEventParams = {}) {
    if (!this.isInitialized) {
      return;
    }

    this.pushToDataLayer("userDataReceived", { userId, ...params });
  }

  public unIdentify() {
    if (!this.isInitialized) {
      return;
    }

    this.pushToDataLayer("logout");
  }

  public trackEvent(event: TrackerEventType, params: TrackerEventParams = {}) {
    if (!this.isInitialized || !("dataLayer" in document)) {
      return;
    }

    this.pushToDataLayer(event, params);
  }

  public trackError(error: Error, from?: string) {
    if (!this.isInitialized) {
      return;
    }

    this.pushToDataLayer("error", { error, from });
  }

  private pushToDataLayer(event: string, params: TrackerEventParams = {}) {
    if (!this.isInitialized || !("dataLayer" in document)) {
      return;
    }

    (document as any).dataLayer.push({ event, ...params });
  }

  private getScript(token: string) {
    const script = document.createElement("script");

    // https://developers.google.com/tag-platform/tag-manager/web
    script.innerHTML = `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${token}')`;

    return script;
  }

  private getNoScript(token: string) {
    const noScript = document.createElement("noscript");

    // https://developers.google.com/tag-platform/tag-manager/web
    noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${token}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    return noScript;
  }
}
