import React from "react";
import Modal from "react-modal";
import classNames from "classnames";

import { IconClose } from "../icon/IconClose";
import { IconPlayButton } from "../icon/IconPlayButton";
import { Button, ButtonIconGutter } from "../button/Button";
import { tracker } from "../../libs/trackers";

import styles from "./videoButton.module.scss";

export interface VideoButtonProps {
  videoId: string;
  videoTitle?: string;
  videoLength?: string;
  alignLeft?: boolean;
}

export const VideoButton: React.FC<VideoButtonProps> = ({ videoId, videoTitle, videoLength, alignLeft }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <Button
        kind="TEXT"
        iconLeft={<IconPlayButton />}
        iconGutter={ButtonIconGutter.SMALL}
        className={classNames(styles.button, { [styles["align-left"]]: alignLeft })}
        onClick={() => {
          tracker.trackEvent("click", { name: "Resources - See Video", description: videoTitle });

          setModalOpen(true);
        }}
      >
        <div className={styles["video-information"]}>
          <strong className={styles["video-information__title"]}>{videoTitle}</strong>
          <p className={styles["video-information__length"]}>{videoLength}</p>
        </div>
      </Button>

      <Modal
        isOpen={isModalOpen}
        className={styles.videoModal}
        overlayClassName={styles.modalBackground}
        ariaHideApp={false}
      >
        <div className={styles.modalHeader}>
          <span onClick={() => setModalOpen(false)}>
            Close
            <IconClose width={16} height={16} fill="#fff" />
          </span>
        </div>
        <div className={styles.iFrameContainer}>
          <iframe
            title={videoTitle}
            width="720"
            height="412"
            src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Modal>
    </>
  );
};
