import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import NotFound from "../../components/not-found/NotFound";

interface NotFoundViewProps {
  text?: string;
  path?: string;
  hardReload?: boolean;
}

export default function NotFoundView({ text, path, hardReload }: NotFoundViewProps) {
  const [t] = useTranslation();
  const { push } = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => (hardReload ? (window.location.href = "/") : push(path ? path : "/")), 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [path, push, hardReload]);

  return (
    <div>
      <NotFound>
        {text ? text : t("Page not found, you will be redirected back to homepage after few seconds...")}
      </NotFound>
    </div>
  );
}
