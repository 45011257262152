import classNames from "classnames";
import React, { ReactNode } from "react";

import styles from "./section.module.scss";

export enum SectionGutter {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export interface SectionProps {
  gutter: SectionGutter | number;
  className?: string;
  center?: boolean;
  centerText?: boolean;
  centerSelf?: boolean;
  withSpace?: boolean;
  relative?: boolean;
  children?: ReactNode;
}

export const Section: React.FC<SectionProps> = ({
  gutter,
  className,
  center,
  centerText,
  centerSelf,
  withSpace,
  children,
  relative,
}) => {
  const customGutter = typeof gutter === "number" ? gutter : undefined;

  return (
    <div
      style={{ marginBottom: customGutter }}
      className={classNames(
        {
          [styles["section--small-gutter"]]: gutter === SectionGutter.SMALL,
          [styles["section--medium-gutter"]]: gutter === SectionGutter.MEDIUM,
          [styles["section--semi-large-gutter"]]: gutter === SectionGutter.SEMI_LARGE,
          [styles["section--large-gutter"]]: gutter === SectionGutter.LARGE,
          [styles["section--extra-large-gutter"]]: gutter === SectionGutter.EXTRA_LARGE,
          [styles["section--center"]]: center,
          [styles["section--center-text"]]: centerText,
          [styles["section--center-self"]]: centerSelf,
          [styles["section--with-space"]]: withSpace,
          [styles["section--relative"]]: relative,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
