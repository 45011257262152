import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import preval from "preval.macro";

import {
  DisabledFooterPathnames,
  HiddenFooterPathnames,
  RankNames,
  Routes,
  SocialMedia,
} from "../../services/constants";
import IconLogoFacebook from "../icon/IconLogoFacebook";
import IconLogoInstagram from "../icon/IconLogoInstagram";
import IconLogoSuccessFactory from "../icon/IconLogoSuccessFactory";
import IconYoutube from "../icon/IconYoutube";
import { Separator } from "../separator/Separator";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";
import { FooterSocialMediaItem } from "./children/footer-social-media-item/FooterSocialMediaItem";
import { FooterTermItem } from "./children/footer-term-item/FooterTermItem";
import { useCartItemCount } from "../../hooks/useCartItemCount";
import { useViewerQuery } from "../../graphql/schema";
import { isIntercomEnabled } from "../../services/isIntercomEnabled";
import { isUserFromUs } from "../../services/isUserFromUs";
import { getDocumentUrl } from "../../services/getDocumentUrl";
import { tracker } from "../../libs/trackers";
import useLocalStorage from "../../hooks/useLocalStorage";

import styles from "./footer.module.scss";

export interface FooterProps {
  bottomPadding?: number;
}

export const Footer: React.FC<FooterProps> = ({ bottomPadding }) => {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { isMobile } = useWindowDimensionsContext();
  const disabledFooter = Object.values(DisabledFooterPathnames).find((url) => pathname.startsWith(url));
  const hiddenFooter = Object.values(HiddenFooterPathnames).find((url) => pathname.startsWith(url));
  const totalProductCount = useCartItemCount();
  const { data } = useViewerQuery();

  if (hiddenFooter) {
    return null;
  }

  if (disabledFooter && isMobile) {
    return (
      <div className={styles.container} style={{ paddingBottom: bottomPadding }}>
        <Separator />
        <IconLogoSuccessFactory logoFill="#92929d" textFill="#92929d" className={styles.logo} />
      </div>
    );
  }

  const rankName = RankNames.find((rank) => rank.id === data?.viewer?.rankId);
  const isUsCountry = isUserFromUs(data?.me);

  if (disabledFooter) {
    return (
      <div className={styles.container} style={{ paddingBottom: bottomPadding }}>
        <Separator />
        <IconLogoSuccessFactory logoFill="#92929d" textFill="#92929d" className={styles.logo} />
        <div className={styles["link-wrap"]}>
          <div className={styles["terms-wrap"]}>
            <FooterTermItem>© {new Date().getFullYear()}</FooterTermItem>
            <FooterTermItem isExternalLink url="https://www.sfuite.com" label="SFSuite.com" />
            <FooterTermItem
              label={t("Terms of Use")}
              fileName="SF Terms of Use 19072021"
              url="/files/SF_Suite_Terms_of_Use.pdf"
            />
            <FooterTermItem
              label={t("Terms and Conditions")}
              fileName="SuccessFactory_General_Terms_and_Conditions"
              url="/files/SF_Suite_General_Terms.pdf"
            />
            <FooterTermItem
              label={t("Privacy Policy")}
              fileName="PRIVACY_POLICY"
              url="/files/SF_Suite_Privacy_Policy.pdf"
            />
            <RenderVersionInfo />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container} style={{ paddingBottom: bottomPadding }}>
      <Separator />
      <IconLogoSuccessFactory logoFill="#92929d" textFill="#92929d" className={styles.logo} />
      <div className={styles.navigation}>
        <div className={styles["navigation-column"]}>
          <Link to="/" className={styles["navigation-item"]}>
            {t("Home")}
          </Link>
          {/* <Link to={Routes.ABOUT} className={styles["navigation-item"]}>
            {t("About")}
          </Link> */}
        </div>
        <div className={styles["navigation-column"]}>
          {/* <Link to={Routes.CHECKOUT_CART} className={styles["navigation-item"]}>
            {t("Cart")}
            <span className={styles.counter}>{totalProductCount}</span>
          </Link> */}
          <Link
            to={"#"}
            className={styles["navigation-item"]}
            onClick={() => {
              if (data?.viewer?.id) {
                tracker.identify(data?.viewer?.id, {
                  userHash: data?.me.intercomHash || null,
                  cocvalue: data?.me.info?.isCOC || false,
                  referralCode: data?.viewer?.referralCode || null,
                  email: data?.viewer?.email || null,
                  username: data?.viewer?.username || null,
                  country: data?.viewer?.country || null,
                  firstName: data?.viewer?.firstName || null,
                  lastName: data?.viewer?.lastName || null,
                  phone: data?.viewer?.phone || null,
                  rank: rankName?.name || null,
                  registerDate: data?.viewer?.registerDate || null,
                  birthdayDate: data?.viewer?.dateOfBirth || null,
                });
              }

              if (isIntercomEnabled()) {
                try {
                  // @ts-ignore TODO: add intercom global types
                  window.Intercom("show");
                } catch (e) {
                  console.error('window.Intercom("show") failed', e);
                }
              } else {
                push(Routes.CONTACT);
              }
            }}
          >
            {t("Contact")}
          </Link>
        </div>
      </div>
      <div className={styles["link-wrap"]}>
        {/* <div className={styles["social-media-wrap"]}>
          <FooterSocialMediaItem icon={<IconLogoFacebook />} url={SocialMedia.FACEBOOK} />
          <FooterSocialMediaItem icon={<IconLogoInstagram />} url={SocialMedia.INSTAGRAM} />
          <FooterSocialMediaItem icon={<IconYoutube />} url={SocialMedia.YOUTUBE} />
        </div> */}
        <div className={styles["terms-wrap"]}>
          <FooterTermItem>© {new Date().getFullYear()}</FooterTermItem>
          <FooterTermItem
            isExternalLink
            url="https://www.sfsuite.com"
            label={isUsCountry ? "SF Suite" : "SFSuite.com"}
          />
          <FooterTermItem
            label={t("Terms of Use")}
            fileName="SF Suite Terms of Use"
            url={getDocumentUrl("TERMS_OF_USE", isUsCountry)}
          />
          <FooterTermItem
            label={t("Terms and Conditions")}
            fileName="SF Suite General Terms and Conditions"
            url={getDocumentUrl("GENERAL_TERMS_AND_CONDITIONS", isUsCountry)}
          />
          <FooterTermItem
            label={t("Privacy Policy")}
            fileName="Privacy Policy"
            url={getDocumentUrl("PRIVACY_POLICY", isUsCountry)}
          />
          <FooterTermItem
            label={t("Cookies Policy")}
            fileName="SF Suite Cookie Policy"
            url={getDocumentUrl("COOKIE_POLICY", isUsCountry)}
          />
          <RenderVersionInfo />
        </div>
      </div>
    </div>
  );
};

const RenderVersionInfo: React.FC = () => {
  const [timestamp] = useLocalStorage<string>("crowdin-build-timestamp", "");

  function toLocaleDate(date: Date) {
    return date.toLocaleString(undefined, {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  }

  if (process.env.REACT_APP_SHOW_BUILD_VERSION !== "true") {
    return null;
  }

  return (
    <>
      <FooterTermItem label={`Version: ${process.env.REACT_APP_VERSION} `} />
      <FooterTermItem
        label={`Build time: ${toLocaleDate(new Date(preval`module.exports = new Date().toISOString();`))}`}
      />
      <FooterTermItem label={`Translations updated: ${toLocaleDate(new Date(parseInt(timestamp ?? 1) * 1000))} `} />
    </>
  );
};
