import { TFunction } from "i18next";

import assertNever from "./assertNever";

export type DateFormat = "YYYY-MM-DD" | "YYYY-MM-DD hh:mm:ss";

/** used to format date object before sending to api */
export function formatISODate(date: Date, format: DateFormat) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const dt = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  switch (format) {
    case "YYYY-MM-DD":
      return `${year}-${zeroPad(month)}-${zeroPad(dt)}`;

    case "YYYY-MM-DD hh:mm:ss":
      return `${year}-${zeroPad(month)}-${zeroPad(dt)} ${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;

    default:
      return assertNever(format);
  }
}

export interface DateTimeProps {
  month?: Intl.DateTimeFormatOptions["month"];
  showTime?: boolean;
  showSeconds?: boolean;
}

/** used for showing localized date string to user */
export function formatLocaleDate(date: Date | string | number, language: string, options: DateTimeProps = {}) {
  return new Date(date).toLocaleDateString(language, {
    year: "numeric",
    month: options.month || "short",
    day: "numeric",
    hour: options.showTime ? "2-digit" : undefined,
    minute: options.showTime ? "2-digit" : undefined,
    second: options.showSeconds ? "2-digit" : undefined,
  });
}

/* returns localized time left in days, in hours or in minutes */
export function timeLeft(millisecondsLeft: number, t: TFunction) {
  const minutesLeft = Math.floor(millisecondsLeft / 1000 / 60);
  const hoursLeft = Math.floor(minutesLeft / 60);
  const daysLeft = Math.floor(hoursLeft / 24);

  if (millisecondsLeft < 0) {
    return t("expired");
  }

  if (minutesLeft < 1) {
    return t("less than a minute");
  }

  if (minutesLeft === 1) {
    return t("1 minute");
  }

  if (hoursLeft < 1) {
    return t("{{value}} minutes", { value: minutesLeft });
  }

  if (hoursLeft === 1) {
    return t("1 hour");
  }

  if (daysLeft < 1) {
    return t("{{value}} hours", { value: hoursLeft });
  }

  if (daysLeft === 1) {
    return t("1 day");
  }

  return t("{{value}} days", { value: daysLeft });
}

function zeroPad(nr: number) {
  return nr < 10 ? `0${nr}` : nr.toString();
}
