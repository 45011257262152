import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/button/Button";

import styles from "./upgrade-successful.module.scss";

export interface UpgradeSuccessfulProps {
  onClose(): void;
}

export const UpgradeSuccessful: React.FC<UpgradeSuccessfulProps> = ({ onClose }) => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <Button
      className={styles.button}
      borderRadius="SMALL"
      color="BLUE"
      weight="MEDIUM"
      center="BLOCK_AND_MARGIN"
      fontSize={18}
      stretch="MOBILE"
      onClick={onClose}
    >
      {t("Close")}
    </Button>
  );
};
