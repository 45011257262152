import classNames from "classnames";
import { FC, ReactNode } from "react";

import { Section, SectionGutter } from "../section/Section";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";
import { SubTitle } from "../sub-title/SubTitle";
import { Heading } from "../heading/Heading";
import { Link } from "../link/Link";

import styles from "./call-to-action-panel.module.scss";

export interface CallToActionPanelProps {
  title: string;
  icon?: ReactNode;
  subTitle?: string;
  linkTitle: ReactNode;
  linkRoute: string | null;
  isExternalUrl?: boolean;
  isDangerouslySetInnerHTML?: boolean;
  button?: ReactNode;
  className?: string;
}

export const CallToActionPanel: FC<CallToActionPanelProps> = ({
  icon,
  button,
  title,
  subTitle,
  linkTitle,
  linkRoute,
  isExternalUrl,
  isDangerouslySetInnerHTML,
  className,
}) => {
  const { isMobile } = useWindowDimensionsContext();

  return (
    <Section
      className={classNames(styles["products-wrap"], className)}
      center
      gutter={isMobile ? SectionGutter.MEDIUM : 150}
    >
      <div className={styles.products}>
        {icon}

        <div className={styles.content}>
          {subTitle && <SubTitle className={styles["sub-title"]}>{subTitle}</SubTitle>}

          <Heading level={3} white className={styles.title}>
            {isDangerouslySetInnerHTML ? (
              <div
                className={styles.congratulations}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            ) : (
              title
            )}
          </Heading>
        </div>

        {button}
        {linkRoute && (
          <Link
            to={linkRoute}
            externalUrl={isExternalUrl ? linkRoute : undefined}
            className={styles.link}
            color="WHITE"
            height="LARGE"
            borderRadius="SMALL"
            weight="BOLD"
            width={300}
          >
            {linkTitle}
          </Link>
        )}
      </div>
    </Section>
  );
};
