import React from "react";
import { useTranslation } from "react-i18next";

import { Heading, HeadingGutter } from "../../../components/heading/Heading";
import { TextAccent } from "../../../components/text-accent/TextAccent";
import discoverSectionImg from "../img/bank-illustration.png";
import { tools } from "../bank-landing-content";
import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { ViewSection } from "../../../components/view-section/ViewSection";
import { IconShieldSuccess } from "../../../components/icon/IconShieldSuccess";

import styles from "./buying-behavior.module.scss";

export const BuyingBehavior: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ViewSection
      image={<AsyncImg src={discoverSectionImg} />}
      gap="GAP_40"
      gutter={100}
      reverse="SMALL_SCREEN"
      withBackground
      imageWrapClassName={styles["image-wrap"]}
    >
      <Heading level={3} gutter={HeadingGutter.SEMI_LARGE} className={styles.heading}>
        <TextAccent
          dangerouslySetInnerHTML={{
            __html: t("Discover the Secret to Accurately <strong>Predicting Buying Behavior</strong> in Nanoseconds"),
          }}
        />
      </Heading>

      <ul className={styles.list}>
        {tools.map((tool, index) => (
          <li key={index} className={styles.item}>
            <IconShieldSuccess className={styles.icon} fill="#ff871c" width={15} height={20} />
            <span>
              <b>{tool.name} - </b>
              {tool.description}
            </span>
          </li>
        ))}
      </ul>
    </ViewSection>
  );
};
