import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { Layout } from "../../../../../components/layout/Layout";
import Price from "../../../../../components/price/Price";
import Field from "../../../../../components/field/Field";
import { Button } from "../../../../../components/button/Button";
import { getTitle } from "../../../../../components/subscription-list-item/SubscriptionListItem";
import {
  CurrencyCode,
  PaymentEntityType,
  PaymentMethod,
  ServicePeriodCode,
  ServiceProductCode,
} from "../../../../../graphql/schema";
import { useHandelPayment } from "../../../../../hooks/useHandlePayment";
import {
  ADD_NEW_BANK_CARD_ID,
  PaymentMethodsList,
  PAYMENT_METHODS_ORDER,
} from "../../../../../components/payment-methods-list/PaymentMethodsList";
import { Checkbox } from "../../../../../components/checkbox/Checkbox";
import { getDocumentUrl } from "../../../../../services/getDocumentUrl";
import { isUserFromUs } from "../../../../../services/isUserFromUs";
import usePrevious from "../../../../../hooks/usePrevious";

import styles from "./upgrade.module.scss";

export interface UpgradeOffer {
  id: string;
  difference?: string;
  differenceFee?: string;
  differenceTax?: string;
  differenceTotal: string;
  currency: { code: keyof typeof CurrencyCode };
  offer: {
    products: { code: ServiceProductCode }[];
    period: { code: ServicePeriodCode };
    fullPrice: string;
    discountPrice?: string | null;
  };
}
export interface UpgradeProps {
  upgradeOffer: UpgradeOffer;
  isLoading?: boolean;
  upgradeType?:
    | PaymentEntityType.PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE
    | PaymentEntityType.PURCHASE_OF_SERVICE_SUBSCRIPTION_RENEWAL;
  handleDisableModalClose: (state: boolean) => void;
  onUpgradeSuccess(): void;
  onClose(): void;
  onPaymentMethodChange?(paymentMethod: PaymentMethod): void;
}

export const Upgrade: React.FC<UpgradeProps> = ({
  upgradeOffer,
  upgradeType = PaymentEntityType.PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE,
  onUpgradeSuccess,
  onClose,
  handleDisableModalClose,
  onPaymentMethodChange,
  isLoading,
}) => {
  // access translation keys
  const { t } = useTranslation();
  const { register, trigger, userInputErrors } = useCartFormValidation();

  // calculate window dimensions
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [creditCardHolderName, setCreditCardHolderName] = useState("");
  const [isIdealBankSelected, setIsIdealBankSelected] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PAYMENT_METHODS_ORDER[0]);
  const prevSelectedPaymentMethod = usePrevious(selectedPaymentMethod);
  const [selectedPaymentMethodSourceId, setSelectedPaymentMethodSourceId] = useState<string | null>(null);
  const [saveCardForLaterUse, setSaveCardForLaterUse] = useState<boolean>(false);

  const handlePayment = useHandelPayment({
    onError: handleOnPaymentFailed,
    onSuccess: handleOnPaymentSuccess,
    onUnknownResponse: handleOnPaymentFailed,
    selectedPaymentMethod: { id: selectedPaymentMethodSourceId, type: selectedPaymentMethod },
    useCardForLaterUse: saveCardForLaterUse,
  });

  // notify on payment method change
  useEffect(() => {
    if (!onPaymentMethodChange || prevSelectedPaymentMethod === selectedPaymentMethod) {
      return;
    }

    onPaymentMethodChange(selectedPaymentMethod);
  }, [onPaymentMethodChange, prevSelectedPaymentMethod, selectedPaymentMethod]);

  const handleLoaderStates = (state: boolean) => {
    setIsSubmitting(state);
    handleDisableModalClose(state);
  };

  function handleOnPaymentSuccess() {
    handleDisableModalClose(false);
    onUpgradeSuccess();
  }

  // OnUnknownResponse and Fail use same function
  function handleOnPaymentFailed() {
    handleDisableModalClose(false);
    setIsSubmitting(false);
    toast.error(t("Something went wrong"));

    onClose();
  }

  const isUsCountry = isUserFromUs({ currency: { code: upgradeOffer.currency.code } });

  /* Handle purchasing subscription upgrade */
  async function handleCheckoutPressed() {
    // validate form input values
    handleLoaderStates(true);
    if (!(await trigger())) {
      console.log("Error");
      handleLoaderStates(false);
      return;
    }

    try {
      // Payment
      await handlePayment({
        creditCardHolderName,
        errorMessage: t("Something went wrong"),
        paymentVariables: {
          entityType: upgradeType,
          currencyCode: upgradeOffer.currency.code,
        },
        selectedPaymentSource: {
          id: selectedPaymentMethodSourceId === ADD_NEW_BANK_CARD_ID ? null : selectedPaymentMethodSourceId,
          paymentMethod: selectedPaymentMethod,
        },
        isIDealBankSelected: isIdealBankSelected,

        totalPrice: upgradeOffer.differenceTotal,
        entityId: upgradeOffer.id,
        paymentFlow: "SUBSCRIPTION_PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE",
      });
    } catch (err) {
      handleLoaderStates(false);

      if (err instanceof Error) {
        console.error(err.message);
        toast.error(err.message, { autoClose: false });
      }
    }
  }

  return (
    <div className={styles.wrap}>
      <Layout className={styles.details}>
        <div>
          <div className={styles.title}>{getTitle(upgradeOffer.offer.products.map((p) => p.code))}</div>
        </div>
        <div className={styles.value}>
          {upgradeOffer.offer.discountPrice && (
            <div className={styles.red}>
              <Price price={upgradeOffer.offer.fullPrice} currency={upgradeOffer.currency.code} />
            </div>
          )}
          <div className={styles.yellow}>
            <Price
              price={upgradeOffer.offer.discountPrice || upgradeOffer.offer.fullPrice}
              currency={upgradeOffer.currency.code}
            />
          </div>
        </div>
      </Layout>
      {upgradeOffer.differenceFee && (
        <Layout className={styles.details}>
          <div>
            <div className={styles.title}>{t("FEE")}</div>
          </div>
          <div className={styles.value}>
            <Price price={upgradeOffer.differenceFee} currency={upgradeOffer.currency.code} />
          </div>
        </Layout>
      )}
      {upgradeOffer.differenceTax && parseFloat(upgradeOffer.differenceTax) > 0 && (
        <Layout className={styles.details}>
          <div>
            <div className={styles.title}>{t("TAX")}</div>
          </div>
          <div className={styles.value}>
            <Price price={upgradeOffer.differenceTax} currency={upgradeOffer.currency.code} />
          </div>
        </Layout>
      )}
      {upgradeOffer.difference && (
        <Layout className={styles.details}>
          <div>
            <div className={styles.title}>{t("Deduct already paid days")}</div>
            <div className={styles.caption}>{t("Unused days from previous billing period")}</div>
          </div>
          <div className={classNames(styles.value, styles.green)}>
            <Price
              price={
                parseFloat(upgradeOffer.offer.discountPrice || upgradeOffer.offer.fullPrice) -
                parseFloat(upgradeOffer.difference)
              }
              currency={upgradeOffer.currency.code}
            />
          </div>
        </Layout>
      )}
      <div className={styles.total}>
        {t("Total")}
        <span className={classNames(styles.yellow, styles.price)}>
          <Price price={upgradeOffer.differenceTotal} currency={upgradeOffer.currency.code} />
        </span>
      </div>

      <PaymentMethodsList
        gutter="LARGE"
        selectedPaymentMethod={selectedPaymentMethod}
        selectedPaymentMethodSourceId={selectedPaymentMethodSourceId}
        onPaymentMethodChange={setSelectedPaymentMethod}
        onPaymentMethodSourceIdChange={setSelectedPaymentMethodSourceId}
        onOwnerNameChange={setCreditCardHolderName}
        handleIsIdealBankSelected={setIsIdealBankSelected}
        getSaveForLaterUseStatus={setSaveCardForLaterUse}
        amount={upgradeOffer.differenceTotal ? parseFloat(upgradeOffer.differenceTotal) : undefined}
        currencyCode={upgradeOffer.currency.code}
      />

      <div className={styles["terms-wrap"]}>
        <Field
          className={styles.checkbox}
          type="checkbox"
          name="termsAndConditions"
          checkboxStyle="SECONDARY"
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: t('I agree to the <a href={{href}} target="_blank">Terms and Conditions</a>', {
                  href: getDocumentUrl("GENERAL_TERMS_AND_CONDITIONS", isUsCountry),
                }),
              }}
            />
          }
          error={userInputErrors.termsAndConditions}
          isRequired
          internalRef={register}
        />
        <Checkbox
          name="recurringPayment"
          className={styles.checkbox}
          checkboxStyle="SECONDARY"
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: t("I authorise SF Suite to apply automatic debit for my recurring subscription."),
              }}
            />
          }
          error={userInputErrors.recurringPayment}
          internalRef={register}
        />
        <p className={styles["terms-description"]}>
          {t(
            "You authorise us to automatically charge your selected payment method on the billing due date for your selected subscription. Any discounts will be deducted from the payment. You will receive a reminder email of your next billing due date. You can cancel your subscription at any time.",
          )}{" "}
          {!isUsCountry && (
            <span>
              {t(
                "Since you pay for a 1 month/ 6 months/ 12 months depending on your selected subscription, you won’t receive any restitution when you cancel.",
              )}
            </span>
          )}
        </p>
      </div>
      <Button
        className={styles.button}
        center="WITH_MARGIN"
        color="BLUE"
        borderRadius="SMALL"
        type="submit"
        disabled={isSubmitting || isLoading}
        isLoading={isSubmitting || isLoading}
        stretch="MOBILE"
        onClick={handleCheckoutPressed}
        fontSize={18}
      >
        {t("Upgrade now")}
      </Button>
    </div>
  );
};

interface ValidateCodeFormValues {
  termsAndConditions: boolean;
  recurringPayment: boolean;
}
/* Validate checkbox */
function useCartFormValidation() {
  const [t] = useTranslation();

  const validationSchema: yup.SchemaOf<ValidateCodeFormValues> = yup.object().shape({
    termsAndConditions: yup
      .boolean()
      .oneOf([true], t("Please agree to terms of service"))
      .required(t("Please agree to terms of service")),
    recurringPayment: yup
      .boolean()
      .oneOf([true], t("Please agree to automatic recurring payments"))
      .required(t("Please agree to automatic recurring payments")),
  });

  const {
    register,
    errors: userInputErrors,
    trigger,
  } = useForm<ValidateCodeFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: { termsAndConditions: false, recurringPayment: false },
    reValidateMode: "onChange",
  });

  return {
    userInputErrors,
    register,
    trigger,
  };
}
