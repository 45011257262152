import React, { ReactNode } from "react";
import classNames from "classnames";

import { tracker } from "../../../../libs/trackers";

import styles from "./footerTermItem.module.scss";

export interface FooterTermItemProps {
  isExternalLink?: boolean;
  label?: string;
  url?: string;
  fileName?: string;
  className?: string;
  children?: ReactNode;
  onClick?(): void;
}

export const FooterTermItem: React.FC<FooterTermItemProps> = ({
  children,
  label,
  url,
  fileName,
  className,
  onClick,
}) => {
  return (
    <div className={classNames(styles.container, className)} onClick={onClick}>
      {children ? (
        children
      ) : (
        <a
          className={styles.link}
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => {
            tracker.trackEvent("click", { name: "Resources - Download Downloads", description: label });
          }}
        >
          {label}
        </a>
      )}
    </div>
  );
};
