import React from "react";

export interface IconGiftProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconGift: React.FC<IconGiftProps> = ({ hasTitle, className, fill, width = 16, height = 16 }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.63 15.62"
    >
      {hasTitle && <title>IconGift</title>}
      <path
        fill={fill || "#ff871c"}
        fillRule="evenodd"
        d="M6.7,9.29V14.5a1.12,1.12,0,0,1-1.12,1.12H3a1.86,1.86,0,0,1-1.86-1.86V9.29A1.11,1.11,0,0,1,2.23,8.18H5.58A1.12,1.12,0,0,1,6.7,9.29m2.23,0V14.5a1.12,1.12,0,0,0,1.12,1.12h2.6a1.86,1.86,0,0,0,1.86-1.86V9.29A1.11,1.11,0,0,0,13.4,8.18H10.05A1.12,1.12,0,0,0,8.93,9.29"
      />
      <path
        fill={fill || "#ffc542"}
        id="Fill-132"
        d="M14.51,2.6H9.68l1-1A.93.93,0,0,0,9.37.27L7.81,1.84,6.26.27A.93.93,0,0,0,5,1.59l1,1H1.12A1.11,1.11,0,0,0,0,3.71V5.57A1.12,1.12,0,0,0,1.12,6.69H14.51a1.12,1.12,0,0,0,1.12-1.12V3.71A1.11,1.11,0,0,0,14.51,2.6"
      />
    </svg>
  );
};
