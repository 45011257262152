export interface IconEyeOffProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

function IconEyeOff({ hasTitle, className, width = 25, height = 20 }: IconEyeOffProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 20">
      {hasTitle && <title>IconEyeOff</title>}
      <path
        fill="#FFC542"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M242.993 265.886a.52.52 0 01-.667-.314.527.527 0 01.311-.674c1.52-.558 3.142-.837 4.866-.837 4.696 0 8.623 2.07 11.744 6.175a3.709 3.709 0 01.002 4.478c-1.109 1.463-2.322 2.667-3.638 3.613a.518.518 0 01-.726-.124.529.529 0 01.122-.734c1.23-.882 2.367-2.013 3.415-3.394a2.65 2.65 0 00-.002-3.199c-2.928-3.853-6.554-5.763-10.917-5.763-1.604 0-3.107.258-4.51.773zm9.995 13.921c-1.694.72-3.523 1.08-5.485 1.08-4.705 0-8.635-2.067-11.75-6.17a3.709 3.709 0 01.002-4.483c1.2-1.577 2.52-2.854 3.957-3.83l-2.473-2.497c-.491-.496.245-1.24.736-.744l18.75 18.93c.491.496-.245 1.24-.737.744l-3-3.03zm-12.524-12.643c-1.407.922-2.702 2.158-3.883 3.711a2.65 2.65 0 00-.002 3.201c2.924 3.85 6.552 5.76 10.924 5.76 1.672 0 3.232-.28 4.684-.837l-2-2.019a5.15 5.15 0 01-2.684.752c-2.877 0-5.209-2.354-5.209-5.258 0-.969.261-1.9.745-2.71l-2.575-2.6zm3.34 3.371a4.22 4.22 0 00-.468 1.94c0 2.322 1.865 4.206 4.167 4.206.68 0 1.334-.165 1.92-.473l-5.62-5.673zm3.699-2.267c-.686 0-1.346.167-1.939.482a.518.518 0 01-.704-.22.528.528 0 01.219-.71 5.18 5.18 0 013.501-.49c.362.076.531.5.323.809a2.105 2.105 0 00-.359 1.18c0 1.162.933 2.104 2.084 2.104.423 0 .827-.128 1.169-.363a.52.52 0 01.802.327 5.327 5.327 0 01-.44 3.446.519.519 0 01-.7.234.528.528 0 01-.231-.706 4.22 4.22 0 00.438-2.065c-.33.117-.68.178-1.038.178-1.726 0-3.125-1.412-3.125-3.155 0-.362.06-.715.176-1.048a4.21 4.21 0 00-.176-.003z"
        transform="translate(-1035 -453) translate(80 90) translate(720 100)"
      ></path>
    </svg>
  );
}

export default IconEyeOff;
