export interface IconAddCartProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconAddCart: React.FC<IconAddCartProps> = ({ hasTitle, className, fill, width = 48, height = 48 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 15" className={className}>
      {hasTitle && <title>IconAddCart</title>}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833374 0.525513C0.557232 0.525513 0.333374 0.74937 0.333374 1.02551C0.333374 1.30166 0.557232 1.52551 0.833374 1.52551H2.16671C2.62693 1.52551 3.00002 1.89859 3.00004 2.35881H3V8.35881C3 9.83156 4.19391 11.0255 5.66667 11.0255H11C12.4728 11.0255 13.6667 9.83156 13.6667 8.35881V5.02547C13.6667 3.55271 12.4728 2.35881 11 2.35881H4.00004C4.00002 1.3463 3.17922 0.525513 2.16671 0.525513H0.833374ZM5.33333 14.3588C5.88562 14.3588 6.33333 13.9111 6.33333 13.3588C6.33333 12.8065 5.88562 12.3588 5.33333 12.3588C4.78105 12.3588 4.33333 12.8065 4.33333 13.3588C4.33333 13.9111 4.78105 14.3588 5.33333 14.3588ZM12.3333 13.3588C12.3333 13.9111 11.8856 14.3588 11.3333 14.3588C10.781 14.3588 10.3333 13.9111 10.3333 13.3588C10.3333 12.8065 10.781 12.3588 11.3333 12.3588C11.8856 12.3588 12.3333 12.8065 12.3333 13.3588ZM8.83333 5.02551C8.83333 4.74937 8.60948 4.52551 8.33333 4.52551C8.05719 4.52551 7.83333 4.74937 7.83333 5.02551V6.19218H6.66667C6.39052 6.19218 6.16667 6.41604 6.16667 6.69218C6.16667 6.96832 6.39052 7.19218 6.66667 7.19218H7.83333V8.35885C7.83333 8.63499 8.05719 8.85885 8.33333 8.85885C8.60948 8.85885 8.83333 8.63499 8.83333 8.35885V7.19218H10C10.2761 7.19218 10.5 6.96832 10.5 6.69218C10.5 6.41604 10.2761 6.19218 10 6.19218H8.83333V5.02551Z"
        fill={fill}
      />
    </svg>
  );
};
