import React from "react";
import { useTranslation } from "react-i18next";

import { Benefit } from "../../../components/benefit/Benefit";
import { Columns } from "../../../components/columns/Columns";
import IconChat from "../../../components/icon/IconChat";
import { IconCompass } from "../../../components/icon/IconCompass";
import IconCrown from "../../../components/icon/IconCrown";
import IconLineChart from "../../../components/icon/IconLineChart";
import IconMedal from "../../../components/icon/IconMedal";
import IconShieldSuccessHollow from "../../../components/icon/IconShieldSuccessHollow";
import { Section, SectionGutter } from "../../../components/section/Section";

import styles from "./benefits-section.module.scss";

export const BenefitsSection: React.FC = () => {
  // access translation keys
  const { t } = useTranslation();

  const benefits = [
    {
      icon: <IconCrown />,
      title: t("OWN YOUR LIFE"),
      description: t(
        "Take control and decide when and where to live a new experience, without restrictions, knowing that your growth, your enjoyment and everything you want to do on a personal or professional level has no limits.",
      ),
    },
    {
      icon: <IconCompass />,
      title: t("LIVE FREE"),
      description: t(
        "Everyday should be an adventure. Live free, thanks to a wide and ever-growing catalogue of experiences, every day. No day will be like the one before.",
      ),
    },
    {
      icon: <IconMedal />,
      title: t("BE YOU"),
      description: t(
        "Be who you want to be, make your mark, plant your virtual flag. In this world, it’s all about you.",
      ),
    },
    {
      icon: <IconChat />,
      title: t("BE CONNECTED"),
      description: t(
        "Human Beings require social connection. Imagine the ability to be fully immersed and connect with your family, friends or business associates, anyplace, anytime, anywhere.",
      ),
    },
    {
      icon: <IconLineChart />,
      title: t("UNLIMITED POTENTIAL"),
      description: t(
        "Control and build the life of your dreams and create powerful experiences that will drive your life, family and business to new levels. The VIEW lets you experience and live with unlimited potential.",
      ),
    },
    {
      icon: <IconShieldSuccessHollow />,
      title: t("MAKE A DIFFERENCE"),
      description: t(
        "We all thrive in a community of meaning, where giving back and making a difference is as important as winning. Create your new world of infinite possibilities that empowers and develops people and gives them a new freedom.",
      ),
    },
  ];
  return (
    <Section className={styles.wrap} gutter={SectionGutter.LARGE} centerSelf withSpace>
      <Columns className={styles.columns} minWidth={300} gap="85px 150px">
        {benefits.map((benefit, index) => (
          <Benefit icon={benefit.icon} title={benefit.title} key={index}>
            {benefit.description}
          </Benefit>
        ))}
      </Columns>
    </Section>
  );
};
