export interface IconLogoInstagramProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

function IconLogoInstagram({ hasTitle, className, width = 14, height = 15 }: IconLogoInstagramProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 15">
      {hasTitle && <title>IconLogoInstagram</title>}
      <path
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M44.21 0C46.3 0 48 1.7 48 3.79v6.42C48 12.3 46.3 14 44.21 14h-6.42C35.7 14 34 12.3 34 10.21V3.79C34 1.7 35.7 0 37.79 0h6.42zM41 10.828A3.832 3.832 0 0044.828 7 3.832 3.832 0 0041 3.172 3.832 3.832 0 0037.172 7 3.832 3.832 0 0041 10.828zm3.92-6.753a1.132 1.132 0 000-2.263 1.132 1.132 0 000 2.263zM41 3.993A3.01 3.01 0 0144.007 7 3.01 3.01 0 0141 10.007 3.01 3.01 0 0137.993 7 3.01 3.01 0 0141 3.993zm3.92-1.36a.31.31 0 110 .622.31.31 0 010-.622z"
        transform="translate(-1455 -5582) translate(0 5402) translate(1421 180.5)"
      />
    </svg>
  );
}

export default IconLogoInstagram;
