import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./status-label.module.scss";

export enum StatusLabelColor {
  GREEN = "GREEN",
  LIGHT_GREY = "LIGHT_GREY",
  YELLOW = "YELLOW",
  GRAY = "GRAY",
  RED = "RED",
}

export interface StatusLabelProps {
  color: keyof typeof StatusLabelColor;
  children?: ReactNode;
}

export const StatusLabel: React.FC<StatusLabelProps> = ({ color, children }) => {
  return <span className={classNames(styles.wrap, (styles as any)[`${color}`])}>{children}</span>;
};
