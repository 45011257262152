import React from "react";

export interface IconLogoDagcoin2Props {
  className?: string;
  width?: number;
  height?: number;
}

export const IconLogoDagcoin2: React.FC<IconLogoDagcoin2Props> = ({ className, width = 36, height = 36 }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
    >
      <defs>
        <linearGradient
          id="3bda70ec-b08b-11ea-b3de-0242ac130004"
          x1="-388.32"
          y1="449.78"
          x2="-387.46"
          y2="448.83"
          gradientTransform="matrix(59.92, 0, 0, -60, 23271.9, 26989.62)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#5a82f6" />
          <stop offset="1" stopColor="#0009f5" />
        </linearGradient>
      </defs>
      <rect id="Rectangle-Copy" fill="url(#3bda70ec-b08b-11ea-b3de-0242ac130004)" width="59.92" height="60" rx="21.6" />
      <path
        id="D-Copy"
        fill="#fff"
        d="M29.77,41.54l.85-4a12,12,0,0,0,3.1-1,8.71,8.71,0,0,0,5-8,6,6,0,0,0-.95-3.46,6.54,6.54,0,0,0-2.63-2.36,5.69,5.69,0,0,0-1.32-.45l.75-3.82H30.71l-.66,3.45H24.4L21.2,37.63h5.56l-.85,3.82h3.86Zm-6-7.18,2.45-.73L27.79,26l-2.16-.72h4.89a3.83,3.83,0,0,1,2.73,1,3.91,3.91,0,0,1,1,2.72,5.36,5.36,0,0,1-.66,2.73,5.12,5.12,0,0,1-1.79,1.9,4.8,4.8,0,0,1-2.54.73H23.74Z"
      />
    </svg>
  );
};
