import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "../../components/alert/Alert";
import { GiftCard } from "../../components/gift-card/GiftCard";
import { Heading } from "../../components/heading/Heading";
import { Section } from "../../components/section/Section";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { useGiftCardViewQuery, UserVerificationStatus } from "../../graphql/schema";
import { DefaultCurrencyCode } from "../../services/constants";
import { BenefitsSection } from "./benefits-section/BenefitsSection";
import { GiftCardPriceSection } from "./gift-card-price-section/GiftCardValueSection";

import styles from "./gift-card-view.module.scss";

export interface GiftCardViewProps {
  className?: string;
}

export const GiftCardView: FC<GiftCardViewProps> = () => {
  // access translation keys
  const { t } = useTranslation();
  const { data, loading } = useGiftCardViewQuery();

  const [selectedValue, setSelectedValue] = useState<string>("");

  const isVerified = loading || data?.viewer?.verifyStatus === UserVerificationStatus.VERIFIED;

  // calculate viewport dimensions
  const { isTabletLandscapeOrBigger } = useWindowDimensionsContext();
  const giftCardPrices = ["25", "50", "100", "150", "200", "250", "500"];
  const currencyCode = data?.me.currency.code ?? DefaultCurrencyCode;

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.titlebar}>
          <Heading className={styles.title} level={2}>
            {t("Gift Card")}
          </Heading>
          <GiftCard
            currencyCode={currencyCode}
            className={styles["gift-card"]}
            width={isTabletLandscapeOrBigger ? 350 : 250}
            value={selectedValue}
          />
        </div>
        {/* <Section gutter={0} center withSpace>
          {!isVerified && (
            <Alert type="warning">
              <p>
                <strong>Your account is not verified!</strong>
              </p>
              <p>
                {t(
                  "Gift Cards are available for verified Affiliates only. In order to purchase Gift Cards, please make sure you are an Affiliate and you have verified yourself in the Web Office.",
                )}
              </p>
            </Alert>
          )}
        </Section> */}

        <GiftCardPriceSection
          value={selectedValue}
          setValue={setSelectedValue}
          currencyCode={currencyCode}
          giftCardPrices={giftCardPrices}
          minAmount={data?.me ? parseFloat(data.me.giftCardLimits.minimum.amount) : undefined}
          maxAmount={data?.me ? parseFloat(data.me.giftCardLimits.maximum.amount) : undefined}
          /*disabled={!isVerified}*/
        />
        <BenefitsSection minAmount={10} maxAmount={500} currencyCode={currencyCode} />
      </div>
    </>
  );
};
