import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/button/Button";
import { Layout } from "../../../../../components/layout/Layout";

import styles from "./continue-subscription.module.scss";

export interface ContinueSubscriptionProps {
  onCancel(): void;
  onSuccess(): void;
}

export const ContinueSubscription: React.FC<ContinueSubscriptionProps> = ({ onCancel, onSuccess }) => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <Layout>
      <Button className={styles.button} borderRadius="SMALL" color="BLUE" weight="MEDIUM" onClick={onCancel}>
        {t("No")}
      </Button>
      <Button className={styles.button} borderRadius="SMALL" color="LIGHT_RED" weight="MEDIUM" onClick={onSuccess}>
        {t("Yes")}
      </Button>
    </Layout>
  );
};
