import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { AddServicesToCartButton } from "../../components/add-services-to-cart-button/AddServicesToCartButton";
import { Heading } from "../../components/heading/Heading";
import { Loader } from "../../components/loader/Loader";
import Price from "../../components/price/Price";
import { ServiceProductCode, useSfFeeViewQuery } from "../../graphql/schema";
import { DefaultCurrencyCode, ServiceInfo } from "../../services/constants";
import { SfFeeIllustration } from "./sf-fee-illustration/SfFeeIllustration";

import styles from "./sfFeeView.module.scss";

const FALLBACK_BASIC_PRICE = 50;
const FALLBACK_PRO_PRICE = 125;

export default function SfFeeView() {
  const { type } = useParams<{ type: "basic" | "pro" }>();
  const productCode = type === "basic" ? ServiceProductCode.BUSINESS_FEE_BASIC : ServiceProductCode.BUSINESS_FEE_PRO;
  const serviceTitle = ServiceInfo[productCode].title;
  const query = useSfFeeViewQuery({ variables: { productCode } });
  const [t] = useTranslation();

  const service = query.data?.sfFee ?? null;
  const price =
    service?.discountedPrice || service?.fullPrice || (type === "basic" ? FALLBACK_BASIC_PRICE : FALLBACK_PRO_PRICE);
  const currencyCode = service?.currency.code || query.data?.me.currency.code || DefaultCurrencyCode;

  const titleTranslation = t(
    "With the SF Business Fee, you will get the opportunity to become SF Suite’s business affiliate for a year. You also will get access to the following:",
  );
  const contentTranslation = t(
    "<li>Your own personalized shop</li><li>Hosting & billing service</li><li>Customer care</li><li>Ongoing product development</li><li>Business reporting tools</li><li>Web Office</li><li>SF Business Training</li><li>eBook “Why they buy”</li>",
  );
  const contentTranslationPro = t(
    "<li>Your own personalized shop</li><li>Hosting & billing service</li><li>Customer care</li><li>Ongoing product development</li><li>Business reporting tools</li><li>Web Office</li><li>Multiple income centres</li>",
  );

  if (query.loading) {
    return <Loader />;
  }

  // if (!service) {
  //   return (
  //     <NotFoundView text={t("Product not available, you will be redirected back to homepage after few seconds...")} />
  //   );
  // }

  return (
    <div className={styles.productContainer}>
      <div className={styles.inner}>
        <div className={styles.column}>
          <SfFeeIllustration isSale={!!service?.discountedPrice} isNew />
        </div>
        <div className={classNames(styles.product, styles.column)}>
          <Heading level={3}>{serviceTitle}</Heading>
          <div className={styles.pricing}>
            <div className={styles.discount}>
              {t("Subscription")}:{" "}
              <span>
                <Price
                  price={price}
                  isDigitalProduct
                  digitalProductPurchaseType="TWELVE_MONTHS"
                  currency={currencyCode}
                />
              </span>
            </div>
          </div>
          <div className={classNames(styles.description, styles["hidden-mobile"])}>
            <p dangerouslySetInnerHTML={{ __html: titleTranslation }} />
            <ul dangerouslySetInnerHTML={{ __html: type === "basic" ? contentTranslation : contentTranslationPro }} />
          </div>

          {service && (
            <AddServicesToCartButton
              productCodes={[productCode]}
              periodCode="TWELVE_MONTHS"
              availability={service.availability}
              activeSubscriptionId={service.upgradableSubscriptions[0]?.id}
              externalUrl={false}
              kind="DARK_BLUE"
            />
          )}

          <div className={classNames(styles.description, styles["visible-mobile"])}>
            <p dangerouslySetInnerHTML={{ __html: titleTranslation }} />
            <ul dangerouslySetInnerHTML={{ __html: type === "basic" ? contentTranslation : contentTranslationPro }} />
          </div>
        </div>
      </div>
    </div>
  );
}
