import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Container } from "../../components/container/Container";
import { LoginForm } from "../../components/login-form/LoginForm";
import { Panel, PanelWidth } from "../../components/panel/Panel";
import { Separator, SeparatorGutter } from "../../components/separator/Separator";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { Routes } from "../../services/constants";

import styles from "./loginView.module.scss";

export default function LoginView() {
  const { isMobile } = useWindowDimensionsContext();
  const [t] = useTranslation();

  return (
    <Container secondary>
      <Panel
        panelStyle={isMobile ? "SECONDARY" : "PRIMARY"}
        width={PanelWidth.MEDIUM}
        imagePath="/images/loginIllustration.png"
        title={t("Hey, welcome back!")}
        caption={t("Login to your SF Suite account")}
      >
        <LoginForm />

        <Separator gutter={SeparatorGutter.MEDIUM} />
        <Link to={Routes.FORGOT_PASSWORD} className={styles["forgotten-password-link"]}>
          {t("Forgot password? Click here...")}
        </Link>
      </Panel>
    </Container>
  );
}
