export interface IconLogoMemborsProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconLogoMembors: React.FC<IconLogoMemborsProps> = ({
  hasTitle,
  className,
  fill = "white",
  width = 81,
  height = 52,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 81 52" className={className}>
      {hasTitle && <title>IconLogoMembors</title>}

      <path
        d="M58.7594 12.6075C58.7594 12.0661 59.0578 11.5688 59.5355 11.314L78.5931 1.14992C79.5696 0.629126 80.7489 1.33673 80.7489 2.44342L80.7489 38.7017C80.7489 39.2431 80.4505 39.7405 79.9728 39.9952L60.9152 50.1593C59.9387 50.6801 58.7594 49.9725 58.7594 48.8658L58.7594 12.6075Z"
        fill={fill}
      />
      <path
        d="M28.1832 18.262C28.1832 17.7206 28.4816 17.2233 28.9593 16.9685L44.8759 8.47984C45.8524 7.95905 47.0317 8.66666 47.0317 9.77334L47.0317 40.3772C47.0317 40.9186 46.7333 41.4159 46.2556 41.6707L30.3391 50.1594C29.3626 50.6802 28.1832 49.9726 28.1832 48.8659L28.1832 18.262Z"
        fill={fill}
      />
      <path
        d="M0.748779 23.9164C0.748779 23.375 1.04717 22.8777 1.52487 22.6229L14.2994 15.8097C15.2759 15.2889 16.4553 15.9965 16.4553 17.1032L16.4553 42.0526C16.4553 42.594 16.1569 43.0913 15.6792 43.3461L2.90463 50.1593C1.92813 50.6801 0.748779 49.9725 0.748779 48.8658L0.748779 23.9164Z"
        fill={fill}
      />
    </svg>
  );
};
