export interface IconYoutubeProps {
  hasTitle?: boolean;
  width?: number;
  height?: number;
  className?: string;
}

function IconYoutube({ hasTitle, className, width = 21, height = 15 }: IconYoutubeProps) {
  return (
    <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 15">
      {hasTitle && <title>IconYoutube</title>}
      <path
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M88.108 1.35C87.538.366 86.92.185 85.66.116 84.404.034 81.241 0 78.503 0c-2.745 0-5.908.034-7.164.116-1.256.07-1.876.25-2.45 1.233C68.302 2.33 68 4.021 68 6.996v.01c0 2.963.302 4.666.889 5.637.574.983 1.193 1.161 2.449 1.244 1.257.071 4.42.113 7.165.113 2.739 0 5.9-.042 7.16-.112 1.258-.083 1.876-.26 2.446-1.243.592-.972.891-2.674.891-5.637V7v-.004c0-2.976-.3-4.667-.892-5.648zm-12.233 9.468V3.182L82.438 7l-6.563 3.818z"
        transform="translate(-1489 -5582) translate(0 5402) translate(1421 180.5)"
      />
    </svg>
  );
}

export default IconYoutube;
