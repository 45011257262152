import { getItem, removeItem, setItem } from "./localStorage";

export function increaseAndReturnPosition(id: string) {
  const upsellIds = getItem("upsellIds")?.split(",");

  if (upsellIds?.includes(id)) {
    const pos = `${upsellIds.findIndex((e) => e === id) + 1}`;
    return pos;
  }

  addNewIdToUpsell(id);

  const newUpsells = getItem("upsellIds");
  const newPos = newUpsells ? `${newUpsells.split(",").length}` : "1";

  return newPos;
}

export function removeUpsellIds() {
  removeItem("upsellIds");
}

function addNewIdToUpsell(id: string) {
  const ids = getItem("upsellIds")?.split(",");

  if (ids?.includes(id)) {
    return;
  }

  if (!ids) {
    setItem("upsellIds", id);
    return;
  }

  setItem("upsellIds", `${ids},${id}`);
}
