import React from "react";

export interface IconShoppingCart2Props {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconShoppingCart2: React.FC<IconShoppingCart2Props> = ({
  hasTitle,
  className,
  fill,
  width = 15,
  height = 15,
}) => (
  <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
    {hasTitle && <title>IconShoppingCart2</title>}
    <path
      fill={fill}
      fillRule="evenodd"
      d="M4.793 2.557H13.2c1.057 0 1.87.931 1.727 1.981l-.583 4.295a1.746 1.746 0 01-1.601 1.503l-6.502.481a1.745 1.745 0 01-1.839-1.395L2.906 2.023c-.018-.104-.09-.164-.175-.164H1.165c-.51 0-.92-.44-.868-.962C.342.443.76.115 1.214.115h1.517c.932 0 1.724.666 1.888 1.58l.174.862zm2.128 10.814a1.395 1.395 0 11-2.79 0 1.395 1.395 0 012.79 0zm6.279 0a1.395 1.395 0 11-2.79 0 1.395 1.395 0 012.79 0z"
    />
  </svg>
);
