import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Heading, HeadingGutter } from "../../components/heading/Heading";
import { IconBell3 } from "../../components/icon/IconBell3";
import { IconBookMark } from "../../components/icon/IconBookMark";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { HeroSection } from "../../components/hero-section/HeroSection";
import { AsyncImg } from "../../components/async-img/AsyncImg";
import { SignUpPanel } from "../../components/sign-up-panel/SignUpPanel";
import { FrequentlyBought } from "../../components/frequently-bought/FrequentlyBought";
import { Suggestion } from "../../components/suggestion/Suggestion";
import { ServiceInfo } from "../../services/constants";
import { Separator } from "../../components/separator/Separator";
import { Section, SectionGutter } from "../../components/section/Section";
import { TextAccent } from "../../components/text-accent/TextAccent";
import { IconYellowDots } from "../../components/icon/IconYellowDots";
import { IconDottedLineArrow } from "./icon-dotted-line-arrow/IconDottedLineArrow";
import { Pills } from "../../components/period-selector/Pills";
import { findOfferByPeriod } from "../../services/findProductByPeriod";
import { ServicePeriodCode, ServiceProductCode, useForexLandingViewQueriesQuery } from "../../graphql/schema";
import { useServicePeriodTranslation } from "../../hooks/useServicePeriodTranslation";
import { ViewSection } from "../../components/view-section/ViewSection";
import { IconShieldSuccess } from "../../components/icon/IconShieldSuccess";
import { FeaturesSection } from "./features-section/FeaturesSection";
import { ChecklistItem } from "../../components/checklist-item/ChecklistItem";
import BannerPhoneMobileImg from "./assets/BannerPhoneMobile.png";
import ForexBannerImg from "./assets/ForexBanner.jpg";
import ForexBullImg from "./assets/ForexBull.png";
import ForexHeaderPhoneImg from "./assets/ForexHeaderPhone.png";
import ForexPhonesImg from "./assets/ForexPhones.png";
import ForexQualityImg from "./assets/ForexQuality.png";
import PremiumQualityImg from "./assets/PremiumQuality.png";
import { useMount } from "../../hooks/useMount";
import NotFoundView from "../not-found-view/NotFoundView";
import { hasAvailableOffers } from "../../services/hasAvailableOffers";
import { getServicesOffers } from "../../services/getServicesOffers";
import { tracker } from "../../libs/trackers";
import { notUndefinedOrNull } from "../../services/notUndefinedOrNull";

import styles from "./product-forex-insiders-landing-view.module.scss";

export const ProductForexInsidersLandingView = () => {
  const [t] = useTranslation();
  const { isMobile, isTabletPortrait, isTabletLandscapeOrBigger } = useWindowDimensionsContext();
  const { data, loading, called } = useForexLandingViewQueriesQuery();
  const [selectedForexPeriodCode, setForexSelectedPeriodCode] = useState<ServicePeriodCode>(
    ServicePeriodCode.ONE_MONTH,
  );
  const [selectedForexProPeriodCode, setForexProSelectedPeriodCode] = useState<ServicePeriodCode>(
    ServicePeriodCode.ONE_MONTH,
  );
  const [selectedHeroProductCode, setSelectedHeroProductCode] = useState<ServiceProductCode>(ServiceProductCode.FOREX);
  const getPeriodTranslation = useServicePeriodTranslation();

  const forexOffers = data?.forex?.offers ?? [];
  const forexProOffers = data?.forexPro?.offers ?? [];
  const selectedForexOffer = findOfferByPeriod(selectedForexPeriodCode, forexOffers);
  const selectedForexProOffer = findOfferByPeriod(selectedForexProPeriodCode, forexProOffers);
  const forexOneMonth = findOfferByPeriod(ServicePeriodCode.ONE_MONTH, forexOffers);
  const forexProOneMonth = findOfferByPeriod(ServicePeriodCode.ONE_MONTH, forexProOffers);

  const products = [
    forexOneMonth
      ? {
          ...forexOneMonth,
          code: data?.forex?.code,
          name: ServiceInfo.FOREX.title,
          value: "FOREX",
          price: forexOneMonth.discountedPrice || forexOneMonth.fullPrice,
          currencyCode: forexOneMonth.currency.code,
        }
      : undefined,
    forexProOneMonth
      ? {
          ...forexProOneMonth,
          code: data?.forexPro?.code,
          name: ServiceInfo.FOREX_PRO.title,
          value: "FOREX_PRO",
          price: forexProOneMonth.discountedPrice || forexProOneMonth.fullPrice,
          currencyCode: forexProOneMonth.currency.code,
        }
      : undefined,
  ].filter(notUndefinedOrNull);

  const selectedHeroProduct = products.find((p) => p.code === selectedHeroProductCode);

  const currentIllustrationUrl = isTabletPortrait || isMobile ? BannerPhoneMobileImg : ForexHeaderPhoneImg;

  const forexSignUpFeatures = [
    {
      description: t("Access to trading ideas"),
    },
    {
      description: t("Instant notifications"),
    },
    {
      description: t("Following ideas"),
    },
    {
      description: t("Latest news"),
    },
    {
      description: t("Transparent reporting"),
    },
    {
      description: t("Market monitoring"),
    },
    {
      description: t("Training videos & education"),
    },
  ];

  const forexProSignUpFeatures = [
    {
      description: t("Additional trading ideas"),
    },
    {
      description: t("Exclusive expert webinars"),
    },
    {
      description: t("Forex Academy"),
    },
    {
      description: t("Live chat with experts"),
    },
    {
      description: t("Instant notifications"),
    },
    {
      description: t("Follow ideas - Latest news & updates"),
    },
    {
      description: t("Market monitoring & Reporting"),
    },
    {
      description: t("Training videos & education"),
    },
  ];

  useMount(() => {
    tracker.trackEvent("VIEW_PRODUCT", { productName: "Forex", type: "Subscription", url: window.location.href });
  });

  if (!loading && called && !hasAvailableOffers(forexOffers)) {
    return (
      <NotFoundView text={t("Product not available, you will be redirected back to homepage after few seconds...")} />
    );
  }

  return (
    <>
      <Section gutter={50}>
        <HeroSection
          bgUrl={ForexBannerImg}
          illustrationUrl={currentIllustrationUrl}
          illustrationWidth="480px"
          illustrationBottomOffset="189px"
          bgPositionY="center"
          illustrationClassName={styles["hero-illustration"]}
          periodCode="ONE_MONTH"
          availability={selectedHeroProduct?.availability}
          activeSubscriptionId={selectedHeroProduct?.upgradableSubscriptions[0]?.id}
          options={products}
          selectedOptionValue={selectedHeroProductCode}
          onOptionChange={setSelectedHeroProductCode}
          productCode={selectedHeroProductCode}
          // videoId={"z_3GmsnKh58"}
          videoTitle={t("What is Forex Insiders")}
          videoLength={t("({{minutes}} minute {{seconds}} seconds)", { minutes: "1", seconds: "37" })}
          reverseButtons={isMobile}
          gap="SEMI_LARGE"
        >
          <Heading
            white
            level={2}
            gutter={isMobile ? HeadingGutter.SEMI_LARGE : HeadingGutter.SMALL}
            className={styles["hero-title"]}
          >
            <TextAccent
              dangerouslySetInnerHTML={{
                __html: t(
                  "Forex Insiders memberships - forex trading is <strong>made easy & profitable</strong> for beginners and experts alike.",
                ),
              }}
            />
          </Heading>

          <p
            className={styles["hero-content"]}
            dangerouslySetInnerHTML={{
              __html: t(
                "Get access to the most advanced Forex Insiders trading AI, and you’ll have access to the best deals it tracks. Get notified as soon as our AI tech finds an ideal trading idea. Thus, enabling you to <strong>ethically “replicate” the same idea with the exact parameters and trade like a forex expert,</strong> even without previous trading experience.",
              ),
            }}
          />
        </HeroSection>
      </Section>

      {/* Forex, quality over quantity */}

      <ViewSection image={<AsyncImg src={ForexQualityImg} />} reverse="ALL_SCREENS" gap="GAP_40" gutter={60}>
        <Heading className={styles.title} level={4} gutter={isMobile ? HeadingGutter.LARGE : HeadingGutter.MEDIUM}>
          <TextAccent dangerouslySetInnerHTML={{ __html: t("<strong>Quality trumps</strong> quantity every time") }} />
        </Heading>

        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "Forex Insiders algorithm and team are scanning hundreds of multiple currency pairs with various strategies, culminating in millions of possible trading ideas. Therefore, you'll only <strong>receive the best pair ideas.</strong> Our top industry analysts will further verify the carefully selected Forex insiders' algorithm recommendations with a minimum of once-a-day advice.",
            ),
          }}
        />
      </ViewSection>

      {/* Included in Forex Insiders */}

      <FeaturesSection />

      {/* Forex, How it works */}

      <ViewSection
        className={styles["steps-wrap"]}
        image={<AsyncImg src={ForexBullImg} />}
        reverse="SMALL_SCREEN"
        gutter={isTabletLandscapeOrBigger ? 0 : 135}
      >
        <Separator type="SECONDARY" />
        <Heading className={styles.title} center="MOBILE" level={4} gutter={HeadingGutter.MEDIUM}>
          <TextAccent dangerouslySetInnerHTML={{ __html: t("Works in <strong>3 easy to follow steps</strong>") }} />
        </Heading>

        <ul className={styles.steps}>
          <li className={styles["steps-item"]}>
            <IconShieldSuccess className={styles["steps-icon"]} fill="#fff" />
            <span
              dangerouslySetInnerHTML={{
                __html: t("<strong>Find excellent trading ideas</strong> with your Forex Insiders membership."),
              }}
            />
          </li>
          <li className={styles["steps-item"]}>
            <IconBell3 className={styles["steps-icon"]} />
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  "<strong>Get instant in-App notification</strong> with the needed details to execute a trade.",
                ),
              }}
            />
          </li>
          <li className={styles["steps-item"]}>
            <IconBookMark className={styles["steps-icon"]} />
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  "Open your broker account, copy & paste the details to <strong>“replicate” the same trading idea.</strong>",
                ),
              }}
            />
          </li>
        </ul>
      </ViewSection>

      {/* Ribbon */}

      <div className={styles["ribbon"]}>
        <img className={styles["ribbon-img"]} src={PremiumQualityImg} alt="Premium quality" />

        <p className={styles["ribbon-label"]}>
          10 YEARS
          <span className={styles["ribbon-label--small"]}>BACK-TESTED</span>
        </p>
      </div>

      {/* Forex results */}
      {/* <ForexResults /> */}

      {/* Forex phones */}

      <div className={styles["forex-phones"]}>
        <AsyncImg src={ForexPhonesImg} alt="phones" />
      </div>

      {/* Sign-up */}

      <Section gutter={0} center withSpace className={styles["sign-up-wrap"]}>
        <Heading className={styles.heading} level={3} center>
          <TextAccent
            wrap
            dangerouslySetInnerHTML={{ __html: t("Sign up now and <strong>start trading like a pro<strong>") }}
          />
        </Heading>

        <div className={styles["sign-up-panels"]}>
          {forexOffers.length > 0 && (
            <SignUpPanel
              className={styles["sign-up-panel"]}
              title={
                <Heading className={styles["panel-title"]} level={3} white center>
                  <TextAccent
                    wrap
                    type="YELLOW"
                    dangerouslySetInnerHTML={{ __html: "<strong>Forex Insiders</strong>" }}
                  />
                </Heading>
              }
              price={selectedForexOffer?.discountedPrice || selectedForexOffer?.fullPrice}
              priceNext={selectedForexOffer?.discountedPriceNext || selectedForexOffer?.fullPriceNext}
              currencyCode={selectedForexOffer?.currency.code}
              productCode="FOREX"
              availability={selectedForexOffer?.availability}
              periodCode={selectedForexPeriodCode}
              activeSubscriptionId={selectedForexOffer?.upgradableSubscriptions[0]?.id}
            >
              <Pills
                options={forexOffers.map(({ period: { code } }) => ({
                  value: code as ServicePeriodCode,
                  label: getPeriodTranslation(code, "NUMERIC"),
                }))}
                onChange={setForexSelectedPeriodCode}
                value={selectedForexPeriodCode}
                gutter="SEMI_LARGE"
                width={300}
              />

              <Section gutter={SectionGutter.LARGE}>
                <ul className={styles["check-list"]}>
                  {forexSignUpFeatures.map((feature, index) => (
                    <ChecklistItem key={index}>{feature.description}</ChecklistItem>
                  ))}
                </ul>
              </Section>
            </SignUpPanel>
          )}

          {forexProOffers.length > 0 && (
            <SignUpPanel
              className={styles["sign-up-panel"]}
              title={
                <Heading className={styles["panel-title"]} level={3} white center>
                  <TextAccent
                    wrap
                    type="YELLOW"
                    dangerouslySetInnerHTML={{ __html: "<strong>Forex Insiders</strong> Pro" }}
                  />
                </Heading>
              }
              isMostPopular
              price={selectedForexProOffer?.discountedPrice || selectedForexProOffer?.fullPrice}
              priceNext={selectedForexProOffer?.discountedPriceNext || selectedForexProOffer?.fullPriceNext}
              currencyCode={selectedForexProOffer?.currency.code}
              productCode="FOREX_PRO"
              availability={selectedForexProOffer?.availability}
              periodCode={selectedForexProPeriodCode}
              activeSubscriptionId={selectedForexProOffer?.upgradableSubscriptions[0]?.id}
            >
              <Pills
                options={forexProOffers.map(({ period: { code } }) => ({
                  value: code as ServicePeriodCode,
                  label: getPeriodTranslation(code, "NUMERIC"),
                }))}
                onChange={setForexProSelectedPeriodCode}
                value={selectedForexProPeriodCode}
                gutter="SEMI_LARGE"
                width={300}
              />

              <Section gutter={SectionGutter.LARGE}>
                <ul className={styles["check-list"]}>
                  {forexProSignUpFeatures.map((feature, index) => (
                    <ChecklistItem key={index}>{feature.description}</ChecklistItem>
                  ))}
                </ul>
              </Section>
            </SignUpPanel>
          )}

          <IconYellowDots className={styles["yellow-dots"]} />
        </div>

        {isMobile && <IconDottedLineArrow className={styles["dotted-line-arrow"]} />}
      </Section>

      {hasAvailableOffers(getServicesOffers(data?.bank ?? [])) && (
        <FrequentlyBought productName={ServiceInfo["FOREX"].title}>
          <Suggestion
            productCodes={["BANK_FULL"]}
            periodCode="ONE_MONTH"
            name={ServiceInfo["BANK_FULL"].title}
            to={ServiceInfo["BANK_FULL"].publicUrl}
          />
        </FrequentlyBought>
      )}
    </>
  );
};
