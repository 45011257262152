import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ContentSection } from "../../../components/content-section/ContentSection";
import { Heading } from "../../../components/heading/Heading";
import { TextAccent } from "../../../components/text-accent/TextAccent";
import { IconYellowDots } from "../../../components/icon/IconYellowDots";
import { SignUpPanel } from "../../../components/sign-up-panel/SignUpPanel";
import { Pills } from "../../../components/period-selector/Pills";
import { YellowCircleIcon } from "./yellow-circle-icon/YellowCircleIcon";
import { findOfferByPeriod } from "../../../services/findProductByPeriod";
import { BankLandingViewQueriesQueryHookResult, ServicePeriodCode } from "../../../graphql/schema";
import { useServicePeriodTranslation } from "../../../hooks/useServicePeriodTranslation";
import { ChecklistItem } from "../../../components/checklist-item/ChecklistItem";

import styles from "./sign-up-cta.module.scss";

interface SignUpCtaProps {
  data: BankLandingViewQueriesQueryHookResult["data"];
}

export const SignUpCta: React.FC<SignUpCtaProps> = ({ data }) => {
  // access translation keys
  const [t] = useTranslation();

  const getPeriodTranslation = useServicePeriodTranslation();

  const [selectedBankBasicPeriodCode, setSelectedBankBasicPeriodCode] = useState<ServicePeriodCode>(
    ServicePeriodCode.ONE_MONTH,
  );
  const [selectedBankFullPeriodCode, setSelectedBankFullPeriodCode] = useState<ServicePeriodCode>(
    ServicePeriodCode.ONE_MONTH,
  );

  const bankBasicOffers = data?.bankBasic?.offers ?? [];
  const selectedBankBasicOffer = findOfferByPeriod(selectedBankBasicPeriodCode, bankBasicOffers);

  const bankFullOffers = data?.bankFull?.offers ?? [];
  const selectedBankFullOffer = findOfferByPeriod(selectedBankFullPeriodCode, bankFullOffers);

  const bankBeyondFeatures = [
    {
      name: "BANKPASS",
      description: t(
        "<strong>BANKPASS</strong> - Generate unlimited leads and send unlimited personality assessments to your clients!",
      ),
    },
    {
      name: "CODEBREAKER_AI",
      description: t(
        "<strong>CODEBREAKER AI</strong> - Use cutting edge artificial intelligence to analyze any texts, emails, and more.",
      ),
    },
    {
      name: "GOOGLE_CHRONE_EXT",
      description: t(
        "<strong>GOOGLE CHROME EXT</strong> - Lookup any profile on LinkedIn and decode their personality in a single click.",
      ),
    },
    {
      name: "BANKVAULT",
      description: t(
        "<strong>BANKVAULT</strong> - Your all-access pass to 50+ hours of comprehensive B.A.N.K virtual training resources.",
      ),
    },
  ];

  const bankConnectFeatures = [
    {
      name: "BANKPASS",
      description: t(
        "<strong>BANKPASS</strong> - Generate unlimited leads and send unlimited personality assessments to your clients!",
      ),
    },
  ];

  return (
    <ContentSection className={styles["section-wrap"]}>
      <Heading level={3} center className={styles.title}>
        <TextAccent
          wrap
          dangerouslySetInnerHTML={{
            __html: t("Sign up now and <strong>take it to the bank!</strong>"),
          }}
        />
      </Heading>

      <div className={styles.wrap}>
        <IconYellowDots className={styles["yellow-dots"]} />

        <SignUpPanel
          className={classNames(styles.panel, styles["panel--transform"])}
          activeSubscriptionId={selectedBankBasicOffer?.upgradableSubscriptions[0]?.id ?? null}
          title={
            <Heading level={3} className={styles["panel-title"]} white center>
              <TextAccent
                wrap
                type="YELLOW"
                dangerouslySetInnerHTML={{ __html: "<strong>B.A.N.K.</strong> Connect" }}
              />
            </Heading>
          }
          price={selectedBankBasicOffer?.discountedPrice || selectedBankBasicOffer?.fullPrice}
          priceNext={selectedBankBasicOffer?.discountedPriceNext || selectedBankBasicOffer?.fullPriceNext}
          currencyCode={selectedBankBasicOffer?.currency.code}
          productCode="BANK_BASIC"
          availability={selectedBankBasicOffer?.availability}
          periodCode={selectedBankBasicPeriodCode}
        >
          {bankBasicOffers.length > 0 && (
            <Pills
              options={bankBasicOffers.map(({ period: { code } }) => ({
                value: code as ServicePeriodCode,
                label: getPeriodTranslation(code, "NUMERIC"),
              }))}
              onChange={setSelectedBankBasicPeriodCode}
              value={selectedBankBasicPeriodCode}
              gutter="SEMI_LARGE"
              width={300}
            />
          )}

          <ul className={styles["check-list"]}>
            {bankConnectFeatures.map((feature) => (
              <ChecklistItem key={feature.name}>
                <TextAccent
                  bold
                  className={styles["check-list-item"]}
                  type="WHITE"
                  dangerouslySetInnerHTML={{
                    __html: feature.description,
                  }}
                />
              </ChecklistItem>
            ))}
          </ul>
        </SignUpPanel>

        <SignUpPanel
          className={styles.panel}
          title={
            <Heading className={styles["panel-title"]} level={3} white center>
              <TextAccent wrap type="YELLOW" dangerouslySetInnerHTML={{ __html: "<strong>B.A.N.K.</strong> Beyond" }} />
            </Heading>
          }
          price={selectedBankFullOffer?.discountedPrice || selectedBankFullOffer?.fullPrice}
          priceNext={selectedBankFullOffer?.discountedPriceNext || selectedBankFullOffer?.fullPriceNext}
          isMostPopular
          productCode="BANK_FULL"
          periodCode={selectedBankFullPeriodCode}
          availability={selectedBankFullOffer?.availability}
          currencyCode={selectedBankFullOffer?.currency.code}
          activeSubscriptionId={selectedBankFullOffer?.upgradableSubscriptions[0]?.id}
        >
          {bankFullOffers.length > 0 && (
            <Pills
              options={bankFullOffers.map(({ period: { code } }) => ({
                value: code as ServicePeriodCode,
                label: getPeriodTranslation(code, "NUMERIC"),
              }))}
              onChange={setSelectedBankFullPeriodCode}
              value={selectedBankFullPeriodCode}
              gutter="SEMI_LARGE"
              width={300}
            />
          )}

          <ul className={styles["check-list"]}>
            {bankBeyondFeatures.map((feature) => (
              <ChecklistItem key={feature.name}>
                <TextAccent
                  type={feature.name === "BANKVAULT" ? "YELLOW" : "WHITE"}
                  bold
                  className={styles["check-list-item"]}
                  dangerouslySetInnerHTML={{
                    __html: feature.description,
                  }}
                />
              </ChecklistItem>
            ))}
          </ul>
        </SignUpPanel>

        <YellowCircleIcon className={styles["yellow-circle"]} />
      </div>
    </ContentSection>
  );
};
