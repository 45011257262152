import React, { ReactNode } from "react";
import classNames from "classnames";

import { Heading } from "../heading/Heading";

import styles from "./benefit.module.scss";

export interface BenefitProps {
  icon: React.ReactNode;
  title: string;
  className?: string;
  children?: ReactNode;
}

export const Benefit: React.FC<BenefitProps> = ({ children, icon, title, className }) => {
  return (
    <div className={classNames(styles.wrap, className)}>
      {icon}
      <Heading className={styles.title} level={4}>
        {title}
      </Heading>
      <p>{children}</p>
    </div>
  );
};
