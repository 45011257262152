import React from "react";
import classNames from "classnames";

import assertNever from "../../services/assertNever";
import { IconShieldCancel } from "../icon/IconShieldCancel";
import { IconShieldSuccess } from "../icon/IconShieldSuccess";

import styles from "./status-icon.module.scss";

export enum StatusIconStatus {
  SUCCESS = "SUCCESS",
  CANCEL = "CANCEL",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export interface StatusIconProps {
  status: keyof typeof StatusIconStatus;
  className?: string;
}

export const StatusIcon: React.FC<StatusIconProps> = ({ status, className }) => {
  switch (status) {
    case "SUCCESS":
      return <IconShieldSuccess className={classNames(styles.icon, className)} />;

    case "WARNING":
      return <IconShieldSuccess className={classNames(styles.icon, className)} fill="#FFC542" />;

    case "CANCEL":
      return <IconShieldCancel className={classNames(styles.icon, className)} fill="#CFD0DF" />;

    case "ERROR":
      return <IconShieldCancel className={classNames(styles.icon, className)} fill="#FC5A5A" />;

    default:
      return assertNever(status);
  }
};
