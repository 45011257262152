// Polyfills for older browsers
import "react-app-polyfill/stable";

import React, { useEffect } from "react";
import i18n from "i18next";
import { v4 as uuid } from "uuid";
import { ApolloProvider, ApolloClient, ApolloLink, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import App from "./components/app/App";
import { ScrollToTop } from "./components/scroll-to-top/ScrollToTop";
import "./services/i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./theme/main.scss";
import "./theme/typography.scss";
import "react-toastify/dist/ReactToastify.css";
import { cache, resolvers, typeDefs } from "./cache/cache";
import { isNewAppVersionAvailableVar } from "./cache/isNewAppVersionAvailable";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import { Loader } from "./components/loader/Loader";
import { isLoggedInVar } from "./cache/isLoggedIn";
import { tracker } from "./libs/trackers";

// Init event trackers
tracker.initialize();

const link = from([
  // update local isLoggedIn var from response metadata
  new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const isLoggedIn = (response as any)?.metadata?.isLoggedIn ?? false;

      isLoggedInVar(isLoggedIn);

      // force to be logged out if response is UNAUTHENTICATED
      if (response.extensions?.code === "UNAUTHENTICATED" && isLoggedIn) {
        isLoggedInVar(false);
      }

      return response;
    });
  }),
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }

    if (networkError) {
      console.warn(`[Network error]: ${networkError}`);
    }
  }),
  new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        "X-Client-Locale": i18n.language,
        "X-Client-Name": "SHOP",
        "x-Client-Version": process.env.REACT_APP_VERSION || null,
        "x-Client-Path": window.location.pathname || null,
        "x-Request-Id": uuid(),
      },
    });

    return forward(operation);
  }),
  new HttpLink({
    credentials: "include",
    uri: `${process.env.REACT_APP_SUCCESSFACTORY_SERVER_PROXY}/graphql`,
  }),
]);

const client = new ApolloClient({
  cache,
  link,
  typeDefs,
  resolvers,
  version: process.env.REACT_APP_VERSION,
  connectToDevTools:
    process.env.NODE_ENV === "development" || process.env.REACT_APP_APOLLO_DEV_TOOLS_ENABLED === "true",
});

// define defaultOptions separately
// https://github.com/apollographql/apollo-client/issues/2555#issuecomment-648280766
client.defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network", // always query data, even if it was cached before
  },
};

function Root() {
  // listen updates from service worker
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
          waitingServiceWorker.addEventListener("statechange", (event) => {
            if ((event?.target as any)?.state === "activated") {
              isNewAppVersionAvailableVar(true);
            }
          });

          waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
      },
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <Router>
        <ErrorBoundary>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
          />
          <ScrollToTop />
          <React.Suspense fallback={<Loader />}>
            <App />
          </React.Suspense>
        </ErrorBoundary>
      </Router>
    </ApolloProvider>
  );
}

let isReloadingApp = false;

function reloadApp() {
  if (isReloadingApp) {
    return;
  }

  isReloadingApp = true;

  // this forces to hard reload
  window.location.href = window.location.href.replace(/#.*$/, "");
}

if (navigator.serviceWorker) {
  // force page reload if new app version is installed
  navigator.serviceWorker.addEventListener("controllerchange", function () {
    // do not auto-reload if reload is in process or document has focus
    if ("visibilityState" in document && document.visibilityState === "visible") {
      return;
    }

    reloadApp();
  });

  // force reload on every window if user requested reload
  navigator.serviceWorker.addEventListener("message", (evt) => {
    // event is a MessageEvent object
    console.log(`App message`, evt);

    reloadApp();
  });
}

ReactDOM.render(<Root />, document.getElementById("appRoot"));
