import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { Heading } from "../../components/heading/Heading";
import { useContactViewQuery } from "../../graphql/schema";
import { SUPPORT_EMAIL, SUPPORT_EMAIL_US } from "../../services/constants";
import { isUserFromUs } from "../../services/isUserFromUs";

import styles from "./contactView.module.scss";

export default function ContactView() {
  const [t] = useTranslation();
  const { data } = useContactViewQuery();

  const isUsCountry = isUserFromUs(data?.me);
  const supportEmail = isUsCountry ? SUPPORT_EMAIL_US : SUPPORT_EMAIL;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <Heading level={2} white gutter={false}>
            {t("Contact")}
          </Heading>
        </div>
        <div className={styles.body}>
          <div className={styles["body-info"]}>
            <div className={styles["body-header"]}>
              <Heading level={5}>{t("Customer Support")}</Heading>
              <p>{t("Contact customer support via email")}</p>
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </div>
          </div>
        </div>
        <Helmet>
          <title>{`Contact SF Suite at ${supportEmail}`}</title>
          <meta
            property="og:description"
            content={`If you have any problems or questions regarding SF Suite feel free to contact us at ${supportEmail} or fill out the contact form.`}
          />
        </Helmet>
      </div>
    </>
  );
}
