import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { FrequentlyBought } from "../../components/frequently-bought/FrequentlyBought";
import { Suggestion } from "../../components/suggestion/Suggestion";
import { ServicePeriodCode, ServiceProductCode, useTheViewLandingViewQueriesQuery } from "../../graphql/schema";
import { useMount } from "../../hooks/useMount";
import { tracker } from "../../libs/trackers";
import { ServiceInfo, TheViewProductCodes } from "../../services/constants";
import { hasAvailableOffers } from "../../services/hasAvailableOffers";
import { notUndefinedOrNull } from "../../services/notUndefinedOrNull";
import { translateProductName } from "../../services/translateProductName";
import NotFoundView from "../not-found-view/NotFoundView";
import { BannerSection } from "./banner-section/BannerSection";
import { BenefitsSection } from "./benefits-section/BenefitsSection";
import { ExperienceSection } from "./experience-section/ExperienceSection";
import { IntroSection } from "./intro-section/IntroSection";
import { PackageSection } from "./package-section/PackageSection";

export const ProductTheViewLandingView: React.FC = () => {
  const { data, loading, called } = useTheViewLandingViewQueriesQuery({
    variables: { productCodes: TheViewProductCodes },
  });
  const { t } = useTranslation();

  // check what product is selected
  const [selectedTheViewProductCode, setSelectedTheViewProductCode] = useState<ServiceProductCode>(
    ServiceProductCode.VIEW_BASIC,
  );

  useMount(() => {
    tracker.trackEvent("VIEW_PRODUCT", { productName: "The View", type: "Regular", url: window.location.href });
  });

  const products = (data?.services ?? [])
    .map((product) => {
      const offer = product && product.offers.length > 0 ? product?.offers[0] : undefined;

      if (!product || !offer) {
        return null;
      }

      return {
        name: translateProductName(product, t) ?? product.name,
        price: offer?.discountedPrice ?? offer?.fullPrice ?? undefined,
        availability: offer.availability,
        activeSubscriptionId: null,
        currencyCode: offer.currency.code,
        value: product.code,
      };
    })
    .filter(notUndefinedOrNull);

  if (!loading && called && !hasAvailableOffers(products)) {
    return (
      <NotFoundView text={t("Product not available, you will be redirected back to homepage after few seconds...")} />
    );
  }

  return (
    <>
      {/* banner section */}
      <BannerSection
        productCode={selectedTheViewProductCode}
        periodCode={ServicePeriodCode.LIFETIME}
        options={products}
        selectedOptionValue={selectedTheViewProductCode}
        onOptionChange={setSelectedTheViewProductCode}
      />

      {/* what is the view */}
      <IntroSection />

      {/* experience section */}
      <ExperienceSection />

      {/* benefits section */}
      <BenefitsSection />

      {/* package section */}
      <PackageSection products={products} />

      {/* faq section */}
      {/* TODO: Add real faq blocks here, currently disabled */}
      {/* <FaqSection /> */}

      {/* frequently bought together */}
      <FrequentlyBought productName={t(`The View products`)}>
        <Suggestion
          productCodes={["FOREX"]}
          periodCode={ServicePeriodCode.ONE_MONTH}
          name={ServiceInfo["FOREX"].title}
          to={ServiceInfo["FOREX"].publicUrl}
        />
        {/* <Suggestion
          productCodes={["BANK_FULL"]}
          periodCode={ServicePeriodCode.ONE_MONTH}
          name={ServiceInfo["BANK_FULL"].title}
          to={ServiceInfo["BANK_FULL"].publicUrl}
        /> */}
      </FrequentlyBought>
    </>
  );
};
