export interface IsFirstPeriodDiscountedParams {
  fullPrice: string;
  fullPriceNext?: string | null;
  discountedPrice?: string | null;
  discountedPriceNext?: string | null;
}

/** is offer first payment period different than the next period */
export function isFirstPeriodDiscounted(params: IsFirstPeriodDiscountedParams) {
  const priceFirst = params.discountedPrice || params.fullPrice;
  const priceNext = params.discountedPriceNext || params.fullPriceNext;

  return priceNext && priceFirst !== priceNext;
}
