import { TypedTypePolicies } from "../graphql/apolloHelpers";

export const typePolicies: TypedTypePolicies = {
  ServiceCart: {
    keyFields: [], // this is a singleton type
    fields: {
      items: { merge: false }, // always prefer incoming data
      totalPrice: { keyArgs: [] },
      totalDiscount: { keyArgs: [] },
      comboDiscount: { keyArgs: [] },
      totalFee: { keyArgs: [] },
      totalTax: { keyArgs: [] },
    },
  },
};
