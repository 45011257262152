import { useEffect, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import { tracker } from "..";
import { useCookie } from "../../../hooks/useCookie";
import usePrevious from "../../../hooks/usePrevious";
import assertNever from "../../../services/assertNever";
import { ConsentValues, CookieConsentCategory } from "../types";

export interface CookieConsentCookie {
  name: string;
  provider: string;
  retentionPeriod: string;
  purpose: string;
  needCookieConsent: boolean;
}

export interface CookieConsentInfo {
  category: CookieConsentCategory;
  enabled: boolean;
  isMandatory: boolean;
  title: string;
  description: string;
  links?: { title: string; url: string }[];
  details: {
    description: string | null;
    cookies: CookieConsentCookie[];
  };
}

const MANDATORY_CONSENTS = [CookieConsentCategory.FUNCTIONAL];

export function useCookieConsent() {
  const { t } = useTranslation();

  const [storedConsentValues, setStoredConsentValues] = useCookie<ConsentValues | null>("sf_cookie_consent", null);
  const [userConsentValues, setUserConsentValues] = useState<ConsentValues>(
    storedConsentValues || getDefaultConsentValues(),
  );
  const [isVisible, setIsVisible] = useState(storedConsentValues === null);
  const wasVisible = usePrevious(isVisible);

  // store user preferences after closing
  useEffect(() => {
    if (!isVisible && wasVisible) {
      // make sure we also save all mandatory cookies
      const finalConsents = Object.assign(
        userConsentValues,
        MANDATORY_CONSENTS.reduce((res, row) => ({ ...res, [row]: true }), {}),
      );

      setUserConsentValues(finalConsents);
      setStoredConsentValues(finalConsents, 365); // 1 year

      tracker.reload();
    }
  }, [userConsentValues, isVisible, setStoredConsentValues, wasVisible]);

  // create memoized options with translated data
  const options = useMemo<CookieConsentInfo[]>(
    () =>
      Object.values(CookieConsentCategory).map((category) => ({
        category,
        enabled: userConsentValues[category] || MANDATORY_CONSENTS.includes(category),
        isMandatory: MANDATORY_CONSENTS.includes(category),
        ...getConsentsDetails(t, category),
      })),
    [userConsentValues, t],
  );

  return {
    isVisible,
    options,
    open: () => {
      setIsVisible(true);
    },
    setOption: (id: CookieConsentCategory, isEnabled: boolean) => {
      setUserConsentValues({ ...userConsentValues, [id]: isEnabled });
    },
    acceptAllAndClose: () => {
      setUserConsentValues(
        Object.keys(CookieConsentCategory).reduce((res, row) => ({ ...res, [row]: true }), {} as ConsentValues),
      );
      setIsVisible(false);
    },
    saveAndClose: () => {
      setIsVisible(false);
    },
    saveRequiredOnlyAndClose: () => {
      setUserConsentValues(getDefaultConsentValues());
      setIsVisible(false);
    },
    discardAndClose: () => {
      setUserConsentValues(storedConsentValues || getDefaultConsentValues());
      setIsVisible(false);
    },
  };
}

function getDefaultConsentValues() {
  return Object.keys(CookieConsentCategory).reduce(
    (res, row) => ({ ...res, [row]: MANDATORY_CONSENTS.includes(row as CookieConsentCategory) }),
    {} as ConsentValues,
  );
}

function getConsentsDetails(
  t: TFunction,
  category: CookieConsentCategory,
): Pick<CookieConsentInfo, "title" | "description" | "details" | "links"> {
  switch (category) {
    case CookieConsentCategory.ANALYTICAL:
      return {
        title: t("Analytical cookies"),
        description: t(
          "We use analytical cookies to analyse how visitors use our website. The collecting and reporting of information is done anonymously. With this information we can improve our marketing activities, as well as the quality, effectiveness, and user-friendliness of our website.",
        ),
        details: {
          description: null,
          cookies: [
            {
              name: "_gat",
              provider: "Google Analytics",
              retentionPeriod: t("1 minute"),
              purpose: t(
                "This cookie is to throttle the request rate to limit the collection of data on high traffic sites.",
              ),
              needCookieConsent: false,
            },
            {
              name: "_ga",
              provider: "Google Analytics",
              retentionPeriod: t("2 years"),
              purpose: t(
                "The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site's analytics report. The cookies store information anonymously and assign a randomly generated number to identify unique visitors.",
              ),
              needCookieConsent: false,
            },
            {
              name: "_gid",
              provider: "Google Analytics",
              retentionPeriod: t("1 day"),
              purpose: t(
                "The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site's analytics report. The cookies store information anonymously and assign a randomly generated number to identify unique visitors.",
              ),
              needCookieConsent: false,
            },
          ],
        },
      };

    case CookieConsentCategory.FUNCTIONAL:
      return {
        title: t("Functional cookies (necessary cookies)"),
        description: t(
          "We use functional cookies to allow our website to function properly, to create an account and to save information you have already entered such as login information, usernames, languages choices, and your location. These cookies also help us identify suspicious behavior on our website in order to prevent fraud.",
        ),
        details: {
          description: t(
            "Because functional cookies are necessary for the use of our website, they may be placed without permission.",
          ),
          cookies: [
            {
              name: "connect.sid",
              provider: "SF Suite",
              retentionPeriod: t("session"),
              purpose: t(
                "This cookie is used for authentication and for secure log-in. It registers the log-in information.",
              ),
              needCookieConsent: false,
            },
            {
              name: "__stripe_mid",
              provider: "Stripe payment gateway",
              retentionPeriod: t("1 year"),
              purpose: t(
                "This cookie is used to enable payment on the website without storing any payment information on a server.",
              ),
              needCookieConsent: false,
            },
            {
              name: "__stripe_sid",
              provider: "Stripe payment gateway",
              retentionPeriod: t("1 year"),
              purpose: t(
                "This cookie is used to enable payment on the website without storing any payment information on a server.",
              ),
              needCookieConsent: false,
            },
            {
              name: "locale",
              provider: "SF Suite",
              retentionPeriod: t("session"),
              purpose: t("This cookie is used for showing site in user specified language."),
              needCookieConsent: false,
            },
            {
              name: "DefaultLocale",
              provider: "SF Suite",
              retentionPeriod: t("session"),
              purpose: t("This cookie is used for showing site in user specified language."),
              needCookieConsent: false,
            },
            {
              name: "intercom-",
              provider: "Intercom chat plugin",
              retentionPeriod: t("7 days"),
              purpose: t("It is required for enabling intercom chat for logged in users."),
              needCookieConsent: false,
            },
            {
              name: "_dd_s",
              provider: "Datadog",
              retentionPeriod: t("15 minutes up to maximum user session duration (4 hours)"),
              purpose: t(
                "Error handling: It contains the current session ID, whether the session is excluded due to sampling,and the expiration date of the session.",
              ),
              needCookieConsent: false,
            },
            {
              name: "_dd_site_test_*",
              provider: "Datadog",
              retentionPeriod: t("1 second"),
              purpose: t("Temporary cookie used to test for cookie support."),
              needCookieConsent: false,
            },
            {
              name: "_dd_cookie_test_*",
              provider: "Datadog",
              retentionPeriod: t("1 second"),
              purpose: t("Temporary cookie used to test for cookie support."),
              needCookieConsent: false,
            },
          ],
        },
      };

    case CookieConsentCategory.SOCIAL_MEDIA:
      return {
        title: t("Social Media Cookies"),
        description: t(
          `Social media buttons are included on our websites to promote (“like”) web pages or to share them on social networks. These buttons work with pieces of code that come from the social media platform(s) itself. Cookies are placed using this code and cookies are used by these third parties. Please read their privacy statement (which can change regularly) to know what they do with your (personal) data that they process via these cookies.`,
        ),
        links: [
          { title: "Facebook", url: "https://www.facebook.com/privacy/explanation" },
          { title: "Instagram", url: "http://www.instagram.com/about/legal/privacy/" },
          { title: "YouTube", url: "https://policies.google.com/privacy" },
        ],
        details: {
          description: t(""),
          cookies: [],
        },
      };

    case CookieConsentCategory.TRACKING:
      return {
        title: t("Tracking cookies"),
        description: t(
          "In order to better match advertisements to your preferences, we use tracking cookies. This way we can see which services/products you view on our website, so we can show you advertisements that might be interesting to you. We only place these cookies with your permission.",
        ),
        details: {
          description: "",
          cookies: [
            {
              name: "_fbp",
              provider: "Facebook",
              retentionPeriod: t("3 months"),
              purpose: t(
                "This cookie is to deliver advertisement when they are on Facebook, or a digital platform powered by Facebook advertising after visiting this website.",
              ),
              needCookieConsent: true,
            },
            {
              name: "fr",
              provider: "Facebook",
              retentionPeriod: t("3 months"),
              purpose: t(
                "The cookie to show relevant advertisements to the users and measure and improve the advertisements. The cookie also tracks the behaviour of the user across the web on sites that have Facebook pixel or Facebook social plugin.",
              ),
              needCookieConsent: true,
            },
          ],
        },
      };

    default:
      return assertNever(category);
  }
}
