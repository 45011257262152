import { TypedTypePolicies } from "../graphql/apolloHelpers";

export const typePolicies: TypedTypePolicies = {
  ServiceSubscriptionUpgrade: {
    fields: {
      differenceFee: { keyArgs: [] },
      differenceTax: { keyArgs: [] },
      differenceTotal: { keyArgs: [] },
    },
  },
};
