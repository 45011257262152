import { Component, ErrorInfo, ReactNode } from "react";
import { Trans } from "react-i18next";

import { tracker } from "../../libs/trackers";
import NotFoundView from "../../views/not-found-view/NotFoundView";

import styles from "./error-boundary.module.scss";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    tracker.trackError(error, "Error Boundary");
    console.error("Error Boundary Caught:", error, errorInfo);
  }

  public render() {
    const { error, hasError } = this.state;

    if (hasError) {
      return (
        <div className={styles.wrap}>
          {error?.name === "ChunkLoadError" && (
            <p>
              <Trans>Page has updated. Please refresh your browser.</Trans>
            </p>
          )}

          <NotFoundView hardReload />
        </div>
      );
    }

    return this.props.children;
  }
}
