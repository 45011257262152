import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { THE_VIEW_TERMS_OF_USE_LINK_URL } from "../../services/constants";
import { Button } from "../button/Button";
import { Checkbox } from "../checkbox/Checkbox";
import { Layout } from "../layout/Layout";
import { Link } from "../link/Link";
import { Modal } from "../modal/Modal";
import { Panel, PanelWidth } from "../panel/Panel";
import { Section, SectionGutter } from "../section/Section";
import { UserConsentCode } from "../../graphql/schema";
import { TermsAndConditionsConsent } from "../terms-and-conditions/TermsAndConditions";

import styles from "./the-view-consent.module.scss";

export interface TheViewConsentProps {
  isVisible: boolean;
  consents: TermsAndConditionsConsent[];
  onAccept: (codes: UserConsentCode) => Promise<void>;
  onReject: (codes: UserConsentCode) => Promise<void>;
  close: () => void;
}

export const TheViewConsent: FC<TheViewConsentProps> = ({ isVisible, consents, close, onAccept, onReject }) => {
  const { t } = useTranslation();

  const [isTermAccepted, setIsTermAccepted] = useState(
    Object.values(consents).reduce(
      (res, { code }) => ({ ...res, [code]: false }),
      {} as { [K in UserConsentCode]: boolean },
    ).TVTOUC,
  );

  const [isUpdating, setIsUpdating] = useState(false);

  async function handleUpdatePressed(granted: boolean) {
    setIsUpdating(true);

    if (granted) {
      await onAccept(UserConsentCode.TVTOUC);
    } else {
      await onReject(UserConsentCode.TVTOUC);
    }

    setIsUpdating(false);
  }

  return (
    <Modal isOpen={isVisible} close={close} hideCloseButton>
      <Panel width={PanelWidth.SMALL} contentClassName={styles.content}>
        <div>
          <Section gutter={SectionGutter.MEDIUM}>
            <div
              dangerouslySetInnerHTML={{
                __html: t("Prior proceeding further, please, read and accept our Terms of Use"),
              }}
            />
          </Section>
          <Checkbox
            checked={isTermAccepted}
            onChange={(e) => setIsTermAccepted(!isTermAccepted)}
            label={
              <>
                {t("I have read and agree to the")}{" "}
                <Link
                  to={"#"}
                  externalUrl={THE_VIEW_TERMS_OF_USE_LINK_URL}
                  color="LIGHT_GREY"
                  kind="TEXT"
                  weight="SEMI_BOLD"
                >
                  {t("Terms of Use")}
                </Link>
              </>
            }
            gutter="MEDIUM"
          />
        </div>

        <Layout wrap="MOBILE">
          <Button
            onClick={() => handleUpdatePressed(false)}
            isLoading={isUpdating}
            shape="RECT"
            kind="SOLID"
            color="LIGHT_RED"
            height="MEDIUM"
            fontSize={16}
            weight="MEDIUM"
            borderRadius="SMALL"
            stretch="MOBILE"
          >
            {t("Reject and log out")}
          </Button>
          <Button
            className={styles.button}
            color="BLUE"
            fontSize={16}
            weight="MEDIUM"
            borderRadius="SMALL"
            stretch="MOBILE"
            disabled={!isTermAccepted}
            onClick={() => handleUpdatePressed(true)}
            isLoading={isUpdating}
          >
            {t("Accept")}
          </Button>
        </Layout>
      </Panel>
    </Modal>
  );
};
