import React, { ReactNode } from "react";
import classNames from "classnames";

import { Layout } from "../layout/Layout";
import { Section } from "../section/Section";
import { Separator } from "../separator/Separator";

import styles from "./view-section.module.scss";

export enum Wrap {
  SMALL_SCREEN = "SMALL_SCREEN",
  LARGE_SCREEN = "LARGE_SCREEN",
  ALL_SCREENS = "ALL_SCREENS",
}

export enum Gap {
  GAP_20 = "GAP_20",
  GAP_40 = "GAP_40",
  GAP_60 = "GAP_60",
}

export interface ViewSectionProps {
  image: string | React.ReactNode;
  gap?: keyof typeof Gap;
  reverse?: keyof typeof Wrap;
  withBackground?: boolean;
  className?: string;
  imageWrapClassName?: string;
  sectionClassName?: string;
  contentClassName?: string;
  gutter?: number;
  children?: ReactNode;
}

export const ViewSection: React.FC<ViewSectionProps> = ({
  children,
  image,
  gap,
  reverse,
  withBackground,
  className,
  imageWrapClassName,
  sectionClassName,
  contentClassName,
  gutter,
}) => {
  return (
    <Section
      gutter={gutter || 0}
      className={classNames(styles.wrap, { [styles["wrap--with-background"]]: withBackground }, className)}
      withSpace
      center
    >
      {withBackground && <Separator type="SECONDARY" onBackground />}
      <Layout
        className={classNames(styles.content, (styles as any)[`reverse--${reverse}`], contentClassName)}
        wrap="TABLET_PORTRAIT_MAX"
      >
        <div className={classNames(styles.section, (styles as any)[`size--${gap}`], sectionClassName)}>{children}</div>
        <div
          className={classNames(
            styles["image-wrap"],
            (styles as any)[`gap--${gap}`],
            (styles as any)[`size--${gap}`],
            imageWrapClassName,
          )}
        >
          {image}
        </div>
      </Layout>
    </Section>
  );
};
