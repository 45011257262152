import React from "react";

interface IconBookMarkProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconBookMark: React.FC<IconBookMarkProps> = ({
  hasTitle,
  className,
  fill = "#fff",
  width = 15,
  height = 20,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 20" className={className}>
      {hasTitle && <title>IconBookMark</title>}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-211 -2932)">
          <g transform="translate(194 2644)">
            <g transform="translate(0 273)">
              <g transform="translate(.225)">
                <g transform="translate(17.775 15)">
                  <path
                    fill={fill}
                    d="M8.531 8.75H7.312V10a.825.825 0 01-.812.832.825.825 0 01-.813-.833V8.75H4.47a.825.825 0 01-.813-.833c0-.458.366-.833.813-.833h1.218v-1.25c0-.458.366-.833.813-.833.447 0 .813.375.813.833v1.25H8.53c.447 0 .813.375.813.833a.825.825 0 01-.813.833zM10.97 0H2.03C.91 0 0 .933 0 2.083v16.661c0 1.117 1.312 1.675 2.08.888l3.559-3.65a1.197 1.197 0 011.722 0l3.559 3.65c.768.787 2.08.229 2.08-.888V2.084C13 .933 12.09 0 10.969 0z"
                  ></path>
                  <path
                    fill="#FFC542"
                    d="M8.531 8.75H7.312V10a.825.825 0 01-.812.832.825.825 0 01-.813-.833V8.75H4.47a.825.825 0 01-.813-.833c0-.458.366-.833.813-.833h1.218v-1.25c0-.458.366-.833.813-.833.447 0 .813.375.813.833v1.25H8.53c.447 0 .813.375.813.833a.825.825 0 01-.813.833z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
