import { TypedTypePolicies } from "../graphql/apolloHelpers";
import { ServiceSubscriptionInvoice } from "../graphql/schema";
import { mergeNonNormalizedObjectArrays } from "../services/mergeNonNormalizedObjectArrays";

export const typePolicies: TypedTypePolicies = {
  ServiceSubscriptionInvoicesResult: {
    fields: {
      rows: mergeNonNormalizedObjectArrays<ServiceSubscriptionInvoice>(),
    },
  },
};
