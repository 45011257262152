import mixPanel from "mixpanel-browser";

import { BaseTracker } from "./BaseTracker";
import { CookieConsentCategory, TrackerEventParams, TrackerEventType } from "../types";

const TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

export class MixPanelTracker extends BaseTracker {
  private isTrackerInitialized = false;

  public constructor() {
    super(CookieConsentCategory.TRACKING);
  }

  protected initialize() {
    if (!TOKEN) {
      return false;
    }

    if (!this.isTrackerInitialized && TOKEN) {
      mixPanel.init(`${TOKEN}`, {
        api_host: "https://api-eu.mixpanel.com",
        opt_out_tracking_by_default: !this.isConsentGranted(),
      });
    }

    if (this.isConsentGranted() && !mixPanel.has_opted_in_tracking) {
      mixPanel.opt_in_tracking();
    }

    return true;
  }

  protected destroy() {
    if (!this.isInitialized) {
      return;
    }

    mixPanel.opt_out_tracking();

    this.isInitialized = false;
  }

  public identify(userId: string) {
    if (!this.isInitialized) {
      return;
    }

    mixPanel.identify(userId);
  }

  public unIdentify() {
    if (!this.isInitialized) {
      return;
    }

    mixPanel.reset();
  }

  public trackEvent(event: TrackerEventType, params?: TrackerEventParams) {
    if (!this.isInitialized) {
      return;
    }

    mixPanel.track(event, params);
  }

  public trackError(error: Error, from?: string) {
    if (!this.isInitialized) {
      return;
    }

    mixPanel.track("error", { error, from });
  }
}
