import React from "react";

import { SocialMedia } from "../../../services/constants";
import IconLogoFacebook from "../../icon/IconLogoFacebook";
import IconLogoInstagram from "../../icon/IconLogoInstagram";
import IconYoutube from "../../icon/IconYoutube";

import styles from "./social-media-links.module.scss";

export const SocialMediaLinks: React.FC = () => (
  <div className={styles["link-wrap"]}>
    <a href={SocialMedia.FACEBOOK} className={styles.link} target="_blank" rel="noreferrer noopener">
      <IconLogoFacebook className={styles.icon} />
    </a>
    <a href={SocialMedia.INSTAGRAM} className={styles.link} target="_blank" rel="noreferrer noopener">
      <IconLogoInstagram className={styles.icon} />
    </a>
    <a href={SocialMedia.YOUTUBE} className={styles.link} target="_blank" rel="noreferrer noopener">
      <IconYoutube className={styles.icon} />
    </a>
  </div>
);
