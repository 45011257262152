import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { TFunction, useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ServicePeriodCode,
  ServiceProductCode,
  useCartUpSellViewQuery,
  useCartUpSellViewRejectPersonalOffersMutation,
  PaymentEntityType,
  PaymentMethod,
} from "../../graphql/schema";
import { DefaultCurrencyCode, Routes, ServiceInfo } from "../../services/constants";
import { Titlebar, TitlebarType } from "../../components/titlebar/Titlebar";
import { Section, SectionGutter } from "../../components/section/Section";
import { Heading } from "../../components/heading/Heading";
import { Offer } from "../../components/offer/Offer";
import { Button } from "../../components/button/Button";
import { IconCheckmarkThin } from "../../components/icon/IconCheckmarkThin";
import { Switch } from "../../components/switch/Switch";
import { Layout } from "../../components/layout/Layout";
import { Loader } from "../../components/loader/Loader";
import assertNever from "../../services/assertNever";
import { ServiceIcon } from "../../components/service-icon/ServiceIcon";
import { showGraphqlValidationErrors } from "../../services/showGraphqlValidationErrors";
import { useHandelPayment } from "../../hooks/useHandlePayment";
import {
  ADD_NEW_BANK_CARD_ID,
  PaymentMethodsList,
  PAYMENT_METHODS_ORDER,
} from "../../components/payment-methods-list/PaymentMethodsList";
import { increaseAndReturnPosition } from "../../services/handleUpsellPosition";
import { getProductName } from "../account-view/drive-for-five-view/DriveForFiveView";
import { tracker } from "../../libs/trackers";
import NotFoundView from "../not-found-view/NotFoundView";

import styles from "./cart-upsell-view.module.scss";

export enum UpSellType {
  UPGRADE = "UPGRADE",
  NEW = "NEW",
}

export const CartUpSellView: React.FC = () => {
  const { t } = useTranslation();
  const [selectedOfferId, setSelectedOfferId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rejectOffers] = useCartUpSellViewRejectPersonalOffersMutation();

  const [creditCardHolderName, setCreditCardHolderName] = useState("");
  const [isIdealBankSelected, setIsIdealBankSelected] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PAYMENT_METHODS_ORDER[0]);
  const [selectedPaymentMethodSourceId, setSelectedPaymentMethodSourceId] = useState<string | null>(null);
  const [saveCardForLaterUse, setSaveCardForLaterUse] = useState<boolean>(false);

  const query = useCartUpSellViewQuery({ variables: { paymentMethod: selectedPaymentMethod } });

  const handlePayment = useHandelPayment({
    onError: handleOnPaymentFailed,
    onSuccess: handleOnPaymentSuccess,
    onUnknownResponse: handleOnPaymentFailed,
    useCardForLaterUse: saveCardForLaterUse,
    selectedPaymentMethod: { id: selectedPaymentMethodSourceId, type: selectedPaymentMethod },
  });

  function handleOnPaymentSuccess() {
    setIsSubmitting(false);
    query.refetch();
  }

  // OnUnknownResponse and Fail use same function
  function handleOnPaymentFailed() {
    toast.error(t("Something went wrong"));

    setIsSubmitting(false);
    query.refetch();
  }

  const offers = (
    query.data?.me.personalServiceOffers.map((row) => {
      switch (row.__typename) {
        case "ServiceSubscriptionUpgrade":
          return [{ ...row.offer, upSellType: UpSellType.UPGRADE, upSellId: row.id }];

        case "ServiceCart":
          return row.offers.map((o) => ({ ...o, upSellType: UpSellType.NEW, upSellId: row.id }));

        default:
          return [];
      }
    }) ?? []
  ).reduce((res, row) => [...res, ...row], []);
  const selectedOffer = offers.find(({ id }) => id === selectedOfferId);
  const selectedUpSell = selectedOffer
    ? query.data?.me.personalServiceOffers.find(({ id }) => id === selectedOffer.upSellId)
    : null;
  const allProductCodes = offers.map((o) => o.products.map((p) => p.code)).reduce((res, row) => [...res, ...row], []);
  const ownedProductCodes = query.data?.me.activeServiceProductCodes ?? [];

  const totalPrice = selectedOffer?.fullPrice;
  const totalDiscountedPrice =
    selectedUpSell?.__typename === "ServiceCart"
      ? selectedUpSell.totalPriceWithoutTax
      : selectedUpSell?.__typename === "ServiceSubscriptionUpgrade"
      ? selectedUpSell.difference
      : null;
  const totalTax =
    selectedUpSell?.__typename === "ServiceCart"
      ? selectedUpSell.totalTax
      : selectedUpSell?.__typename === "ServiceSubscriptionUpgrade"
      ? selectedUpSell.differenceTax
      : null;
  const totalFee =
    selectedUpSell?.__typename === "ServiceCart"
      ? selectedUpSell.totalFee
      : selectedUpSell?.__typename === "ServiceSubscriptionUpgrade"
      ? selectedUpSell.differenceFee
      : null;
  const totalPriceWithTax =
    selectedUpSell?.__typename === "ServiceCart"
      ? selectedUpSell.totalPrice
      : selectedUpSell?.__typename === "ServiceSubscriptionUpgrade"
      ? selectedUpSell.differenceTotal
      : null;

  const mainContent = useMainContent(offers, selectedOffer, totalPrice, totalDiscountedPrice);
  const offerContent = useOfferContent(offers, ownedProductCodes, selectedOffer, totalPrice, totalDiscountedPrice);

  const handleUpsellResult = (res: "ACCEPTED_UPSELL" | "DECLINED_UPSELL", pos?: string) => {
    if (selectedOffer) {
      tracker.trackEvent(res, {
        name: `${getProductName(selectedOffer.products)} ${selectedOffer.period.code}`,
        positionInUpsells: pos,
        price: selectedOffer.fullPrice,
      });
    }
  };

  async function handleCheckoutPressed() {
    if (!selectedUpSell) {
      console.error("no upSell offer was selected, this should not happen");

      throw new Error(t("Something went wrong. Payment was not made"));
    }

    if (!totalPriceWithTax) {
      console.error("totalPriceWithTax was not set");
      throw new Error(t("Something went wrong. Payment was not made"));
    }

    const currencyCode =
      selectedUpSell.__typename === "ServiceCart"
        ? selectedUpSell.maybeCurrency?.code ?? DefaultCurrencyCode
        : selectedUpSell.__typename === "ServiceSubscriptionUpgrade"
        ? selectedUpSell.currency.code
        : DefaultCurrencyCode;

    setIsSubmitting(true);

    try {
      await handlePayment({
        creditCardHolderName,
        errorMessage: t("Something went wrong"),
        paymentVariables: {
          entityType:
            selectedUpSell.__typename === "ServiceSubscriptionUpgrade"
              ? PaymentEntityType.PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE
              : PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS,
          currencyCode,
        },
        selectedPaymentSource: {
          id: selectedPaymentMethodSourceId === ADD_NEW_BANK_CARD_ID ? null : selectedPaymentMethodSourceId,
          paymentMethod: selectedPaymentMethod,
        },
        isIDealBankSelected: isIdealBankSelected,
        totalPrice: totalPriceWithTax,
        entityId: selectedUpSell.id,
        paymentFlow:
          selectedUpSell.__typename === "ServiceSubscriptionUpgrade"
            ? "UPSELL_PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE"
            : "UPSELL_PURCHASE_OF_SERVICE_PRODUCTS",
      });

      if (selectedOfferId) {
        const pos = increaseAndReturnPosition(selectedOfferId);

        handleUpsellResult("ACCEPTED_UPSELL", pos);
      }
    } catch (e) {
      console.warn("purchaseSubscription failed", e);
      // TODO: improve error handling code
      if (!showGraphqlValidationErrors(t, e) && e instanceof Error) {
        toast.error(e.message, { autoClose: 5000 });
      }

      setIsSubmitting(false);

      // If is Ideal or card error, do not refetch
      if (
        e instanceof Error &&
        e.message !== "Please review your Ideal info" &&
        e.message !== "Please review our Card info"
      ) {
        await query.refetch();
      }

      return;
    }
  }

  async function handleRejectOffers() {
    setIsSubmitting(true);
    try {
      await rejectOffers();
      if (selectedOfferId) {
        const pos = increaseAndReturnPosition(selectedOfferId);

        handleUpsellResult("DECLINED_UPSELL", pos);
      }
    } catch (error) {
      console.error("rejectOffers failed", error);
    }

    await query.refetch();

    setIsSubmitting(false);
  }

  function getTitleImageUrl() {
    const imgUrl_1 = "/images/upsellIllustration-1.png";
    const imgUrl_2 = "/images/upsellIllustration-2.png";
    const imgUrl_3 = "/images/upsellIllustration-3.png";

    const type = getOfferContentType(offers);

    switch (type) {
      case "COMBO_OFFER_WITH_DIFFERENT_PERIODS":
        return imgUrl_3;

      case "FIRST_MONTH_DISCOUNT_OFFER":
        return imgUrl_2;

      default:
        return imgUrl_1;
    }
  }

  function getOfferIcon() {
    const newOfferCodes = allProductCodes.filter((code) => !ownedProductCodes?.includes(code));

    return newOfferCodes[0];
  }

  // set initial selected offer
  useEffect(() => {
    if ((selectedOfferId && offers.map(({ id }) => id).includes(selectedOfferId)) || offers.length === 0) {
      return;
    }
    const offerId = offers[0].id;

    setSelectedOfferId(offerId);

    const upsellPos = increaseAndReturnPosition(offerId);
    const offer = offers.find((e) => e.id === offerId);
    if (offer) {
      tracker.trackEvent("DISPLAYED_UPSELL", {
        name: `${getProductName(offer.products)} ${offer.period.code}`,
        positionInUpsells: upsellPos,
        price: offer.fullPrice,
      });
    }
  }, [offers, selectedOfferId]);

  if (query.error) {
    return <NotFoundView />;
  }

  if (query.loading && !query.previousData) {
    return <Loader visible={query.loading} />;
  }

  if (offers.length === 0 && !query.loading) {
    return <Redirect to={Routes.CHECKOUT_SUCCESS} />;
  }

  const offerPeriods = offers.map((off) => off.period.code);
  const isSwitchChecked = selectedOffer?.id !== offers[0]?.id;

  return (
    <>
      <Loader visible={query.loading} />
      <Section gutter={SectionGutter.LARGE}>
        <Titlebar type={TitlebarType.SECONDARY} imageUrl={getTitleImageUrl()} />
      </Section>
      <Section gutter={0} center withSpace>
        <Heading className={styles.title} level={2} dangerouslySetInnerHTML={{ __html: mainContent.title }} />
        <p className={styles.description} dangerouslySetInnerHTML={{ __html: mainContent.description }} />
        {offerPeriods.length === 2 && (
          <Section gutter={SectionGutter.SMALL}>
            <Layout>
              <span className={classNames(styles.period, { [styles["period--selected"]]: !isSwitchChecked })}>
                {translatePeriodCode(t, offerPeriods[0])}
              </span>
              <Switch
                className={styles.switch}
                checked={isSwitchChecked}
                onChange={() => {
                  const offer = isSwitchChecked ? offers[0] : offers[1];
                  setSelectedOfferId(offer.id);

                  const pos = increaseAndReturnPosition(offer.id);

                  tracker.trackEvent("DISPLAYED_UPSELL", {
                    name: `${getProductName(offer.products)} ${offer.period.code}`,
                    positionInUpsells: pos,
                    price: offer.fullPrice,
                  });
                }}
                onColor="#FFC542"
                offColor="#FFC542"
              />
              <span className={classNames(styles.period, { [styles["period--selected"]]: isSwitchChecked })}>
                {translatePeriodCode(t, offerPeriods[1])}
              </span>
            </Layout>
          </Section>
        )}
        <Offer
          title={offerContent.primary}
          icon={getOfferIcon() ? <ServiceIcon productCode={getOfferIcon()} /> : null}
          format={handleFormat(getOfferContentType(offers))}
          receipt={{
            subTotal: totalDiscountedPrice,
            total: totalPriceWithTax,
            tax: totalTax,
            fee: totalFee,
            currencyCode: selectedOffer?.currency.code ?? "EUR",
          }}
          gutter="LARGE"
          // thinTitle={paymentId === "5"}
        >
          {/* <span dangerouslySetInnerHTML={{ __html: upsell[paymentId].price }} /> */}
          <span dangerouslySetInnerHTML={{ __html: offerContent.secondary }} />
        </Offer>

        <PaymentMethodsList
          gutter="LARGE"
          selectedPaymentMethod={selectedPaymentMethod}
          selectedPaymentMethodSourceId={selectedPaymentMethodSourceId}
          onPaymentMethodChange={setSelectedPaymentMethod}
          onPaymentMethodSourceIdChange={setSelectedPaymentMethodSourceId}
          onOwnerNameChange={setCreditCardHolderName}
          handleIsIdealBankSelected={setIsIdealBankSelected}
          amount={totalPriceWithTax ? parseFloat(totalPriceWithTax) : undefined}
          currencyCode={selectedOffer?.currency.code ?? DefaultCurrencyCode}
          getSaveForLaterUseStatus={setSaveCardForLaterUse}
        />

        <Button
          shape="ROUND"
          color="BLUE"
          height="LARGE"
          fontSize={16}
          weight="BOLD"
          width={340}
          iconRight={<IconCheckmarkThin className={styles["button-icon"]} />}
          gutter="SEMI_LARGE"
          disabled={isSubmitting}
          onClick={handleCheckoutPressed}
        >
          <div className={styles["button-content"]}>
            {t("Place order")}
            <div className={styles.sum}>
              +{totalPriceWithTax}
              {selectedOffer?.currency.sign ?? ""}
            </div>
          </div>
        </Button>
        <Button
          className={styles["decline-button"]}
          weight="MEDIUM"
          fontSize={14}
          width={245}
          shape="ROUND"
          gutter="LARGE"
          disabled={isSubmitting}
          onClick={handleRejectOffers}
        >
          {t("No, thanks")}
        </Button>
      </Section>
    </>
  );
};

export function useMainContent(
  offers: GetOfferContentTypeOffer[],
  selectedOffer?: GetOfferContentTypeOffer,
  fullPrice?: string | null,
  discountedPrice?: string | null,
) {
  const [t] = useTranslation();
  const type = getOfferContentType(offers);

  switch (type) {
    case "COMBO_OFFER_WITH_DIFFERENT_PERIODS":
      return {
        title: t("Would you prefer to get 1 month or <strong>3 months FOR FREE?!</strong>"),
        description: t(
          "When you choose 6 months for your subscription period, you’ll just pay for 5 months and <strong>get 1 month for free.</strong> BUT if you pay for a year, you’ll just pay for 9 months & <strong>get 3 months for free!</strong>",
        ),
      };

    case "COMBO_OFFER": {
      const period = translatePeriodCode(t, selectedOffer ? selectedOffer.period.code : ServicePeriodCode.ONE_MONTH);

      return {
        title: selectedOffer?.discountedPrice
          ? t("Quickly upgrade to limited time Combo Offer to <strong>save {{discount}} today!</strong>", {
              discount: `${getDiscountPercentage(selectedOffer.fullPrice, selectedOffer.discountedPrice)}%`,
            })
          : t("Quickly upgrade to limited time Combo Offer"),
        description: selectedOffer?.discountedPrice
          ? t(
              "When you get both products - {{products}}, you are eligible for Combo Offer discount. This means that instead of paying {{fullPrice}}, you’ll get access to <strong>both products for only {{discountedPrice}} per {{period}}.</strong>",
              {
                products: translateProductName(offers),
                fullPrice: `${selectedOffer.fullPrice}${selectedOffer.currency.sign}`,
                discountedPrice: `${selectedOffer.discountedPrice}${selectedOffer.currency.sign}`,
                period,
              },
            )
          : t("When you get both products - {{products}}, you are eligible for Combo Offer discount.", {
              products: translateProductName(offers),
            }),
      };
    }

    case "FIRST_MONTH_DISCOUNT_OFFER": {
      const period = selectedOffer ? translatePeriodCode(t, selectedOffer.period.code, true) : "";

      return {
        title:
          selectedOffer && discountedPrice
            ? t(
                "Want to try out the {{productName}} for <strong>only {{discountedPrice}} for the whole {{period}}?!</strong>",
                {
                  productName: translateProductName(offers),
                  discountedPrice: `${shortenPrice(discountedPrice)}${selectedOffer.currency.sign}`,
                  period,
                },
              )
            : t("Want to try out the {{productName}}?", {
                productName: translateProductName(offers),
              }),
        description:
          selectedOffer && discountedPrice
            ? t(
                "Who wouldn’t, right? This offer is applicable for new customers like you, who haven’t tried {{product}} yet. Hurry, to grab the offer, <strong>because the {{discount}} discount</strong> for the first month is here for a limited time.",
                {
                  product: translateProductName(offers),
                  discount: `${getDiscountPercentage(selectedOffer.fullPrice, discountedPrice)}%`,
                },
              )
            : t(
                "Who wouldn’t, right? This offer is applicable for new customers like you, who haven’t tried {{product}} yet. Hurry up and grab the offer!",
                { product: translateProductName(offers) },
              ),
      };
    }

    default: {
      const period = translatePeriodCode(t, selectedOffer ? selectedOffer?.period.code : ServicePeriodCode.ONE_MONTH);

      return {
        title: t("If you haven’t tried our <strong>best selling</strong> product yet…"),
        description: t(
          "We believe that {{product}} is a product that every SF Suite member should at least try for {{period}}.",
          {
            product: translateProductName(offers),
            period,
          },
        ),
      };
    }
  }
}

function useOfferContent(
  offers: GetOfferContentTypeOffer[],
  activeServiceProductCodes: ServiceProductCode[],
  selectedOffer?: GetOfferContentTypeOffer,
  fullPrice?: string | null,
  discountedPrice?: string | null,
) {
  const [t] = useTranslation();
  const type = getOfferContentType(offers);
  let primary = "";
  let secondary = "";

  if (!selectedOffer) {
    return { primary, secondary };
  }

  switch (type) {
    case "COMBO_OFFER_WITH_DIFFERENT_PERIODS": {
      const period1 = translatePeriodCode(t, selectedOffer?.period.code);
      const period2 = translatePeriodCode(
        t,
        selectedOffer.period.code === ServicePeriodCode.SIX_MONTHS ? ServicePeriodCode.ONE_MONTH : "THREE_MONTHS",
      );

      return {
        primary: t("Upgrade to the <strong>{{period}}</strong> billing period", {
          period: period1,
        }),
        secondary: t("{{period}} <strong>FOR FREE</strong>", {
          period: period2,
        }),
      };
    }

    case "COMBO_OFFER":
      return {
        primary: t("Get also <strong>{{products}}</strong> with Combo Offer discount", {
          products: translateProductName(offers, activeServiceProductCodes),
        }),
        secondary: discountedPrice
          ? t("Regular price {{fullPrice}} <strong>Upgrade for {{discountedPrice}}</strong>", {
              fullPrice: `${fullPrice}${selectedOffer.currency.sign}`,
              discountedPrice: `${discountedPrice}${selectedOffer.currency.sign}`,
            })
          : t("Price {{fullPrice}}", {
              fullPrice: `${fullPrice}${selectedOffer.currency.sign}`,
            }),
      };

    case "FIRST_MONTH_DISCOUNT_OFFER":
      return {
        primary: discountedPrice
          ? t("Try <strong>{{product}}</strong> for the whole month for just {{price}}", {
              product: translateProductName(offers),
              price: `${discountedPrice}${selectedOffer.currency.sign}`,
            })
          : "",
        secondary: discountedPrice
          ? t("Regular price {{fullPrice}} <strong>Sale price {{discountedPrice}}</strong>", {
              fullPrice: `${selectedOffer.fullPrice}${selectedOffer.currency.sign}`,
              discountedPrice: `${discountedPrice}${selectedOffer.currency.sign}`,
            })
          : "",
      };

    default: {
      const period = selectedOffer ? translatePeriodCode(t, selectedOffer.period.code) : "";

      return {
        primary: t("Give <strong>{{product}}</strong> a try for a {{period}}", {
          product: translateProductName(offers),
          period,
        }),
        secondary: t("Only {{price}} <strong>per {{period}}</strong>", {
          price: `${discountedPrice || fullPrice}${selectedOffer.currency.sign}`,
          period,
        }),
      };
    }
  }
}

interface TranslateProductNameOffer {
  products: { code: ServiceProductCode }[];
}

export function translateProductName(
  offers: TranslateProductNameOffer[],
  excludedProductCodes: ServiceProductCode[] = [],
) {
  const allProductCodes = offers.map((o) => o.products.map((p) => p.code)).reduce((res, row) => [...res, ...row], []);

  return allProductCodes
    .filter((code) => !excludedProductCodes.includes(code))
    .map((code) => ServiceInfo[code].title)
    .join(" + ");
}

export function translatePeriodCode(t: TFunction, code: ServicePeriodCode | "THREE_MONTHS", shorten = false) {
  switch (code) {
    case ServicePeriodCode.ONE_MONTH:
      return shorten ? t("month") : t("1 month");

    case "THREE_MONTHS":
      return t("3 months");

    case ServicePeriodCode.SIX_MONTHS:
      return t("6 months");

    case ServicePeriodCode.TWELVE_MONTHS:
      return t("12 months");

    case ServicePeriodCode.LIFETIME:
      return t("One-time purchase");

    default:
      return assertNever(code);
  }
}

type OfferContentType = "DEFAULT" | "COMBO_OFFER" | "COMBO_OFFER_WITH_DIFFERENT_PERIODS" | "FIRST_MONTH_DISCOUNT_OFFER";

interface GetOfferContentTypeOffer {
  products: { code: ServiceProductCode }[];
  period: { code: ServicePeriodCode };
  discountedPrice?: string | null;
  fullPrice: string;
  currency: { sign: string };
}

export function getOfferContentType(offers: GetOfferContentTypeOffer[]): OfferContentType {
  const productCodes = offers.map((o) => o.products.map((p) => p.code));
  const productPeriods = offers.map((o) => o.period.code);

  if (offers.length === 1 && offers[0].products.length > 1) {
    return "COMBO_OFFER";
  }

  const isComboWithDifferentPeriods =
    productCodes.every((codes) => productCodes[0].every((code) => codes.includes(code))) &&
    productPeriods.some((period) => productPeriods[0] !== period);

  if (isComboWithDifferentPeriods) {
    return "COMBO_OFFER_WITH_DIFFERENT_PERIODS";
  }

  if (offers.length === 1 && offers[0].products.length === 1 && offers[0].discountedPrice) {
    return "FIRST_MONTH_DISCOUNT_OFFER";
  }

  return "DEFAULT";
}

function handleFormat(type: OfferContentType) {
  switch (type) {
    case "FIRST_MONTH_DISCOUNT_OFFER":
      return "PRIMARY";
    case "COMBO_OFFER":
      return "SECONDARY";
    case "COMBO_OFFER_WITH_DIFFERENT_PERIODS":
      return "TERTIARY";
    default:
      return "SECONDARY";
  }
}

function getDiscountPercentage(fullPrice: string, discountPrice: string) {
  return (100 - (Number(discountPrice) * 100) / Number(fullPrice)).toFixed(1);
}

function shortenPrice(price: string) {
  if (price.endsWith(".00")) {
    return price.replace(".00", "");
  }

  return price;
}
