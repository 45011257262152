import React from "react";

export interface IconLogoSuccessFactoryNoTextProps {
  gradient?: [string, string];
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconLogoSuccessFactoryNoText: React.FC<IconLogoSuccessFactoryNoTextProps> = ({
  gradient,
  hasTitle,
  className,
  fill = "#ff871c",
  width,
  height,
}) => {
  const gradientStyles = (
    <linearGradient id="IconLogoSuccessFactoryNoTextGradient" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
      <stop offset="0" stopColor={gradient?.[0]} stopOpacity="1"></stop>
      <stop offset="1" stopColor={gradient?.[1]} stopOpacity="0"></stop>
    </linearGradient>
  );

  return (
    <svg
      className={className}
      width={width}
      height={height}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28.51 36.71"
    >
      {hasTitle && <title>IconLogoSuccessFactoryNoText</title>}
      {gradient && gradientStyles}
      <path
        fill={gradient ? "url(#IconLogoSuccessFactoryNoTextGradient)" : fill}
        d="M12.35,36.53a3.18,3.18,0,0,1-.94-.18,7.23,7.23,0,0,1-3.8-2.06,9.72,9.72,0,0,1-.7-.86C4.33,29.76,4.62,24,8.72,21.51c2.25-1.36,5-2.21,6.76-4.22A7.65,7.65,0,0,0,17.21,12a9,9,0,0,0-.41-2.73,30.35,30.35,0,0,0-1.51-3.15,5.77,5.77,0,0,1-.64-3A5.35,5.35,0,0,1,16.14,0a9.13,9.13,0,0,0,.22,2.15,6.67,6.67,0,0,0,1.16,2c.73.89,6.63,7.12,7.33,10a9.65,9.65,0,0,1-5.1,11.15c-1.58.73-3.38.94-4.91,1.8a5.41,5.41,0,0,0-2.57,3.46c-.4,1.75.14,4.16,1.84,5.1a6,6,0,0,0,3.95.55A13.11,13.11,0,0,1,12.35,36.53ZM5.88,13.94c3.1.11,5.26-1.87,6.22-4.6a9.7,9.7,0,0,0,.14-3.44l.29.26a5.18,5.18,0,0,1,.57.67,7.7,7.7,0,0,1,1.09,6.86C12.64,18.71,6,19,3.91,23.58a7,7,0,0,0-.53,1.84,11,11,0,0,0,1.21,6.8,9.79,9.79,0,0,0,2.13,2.36c-.23-.14-1.12-.77-1.31-.9a11.07,11.07,0,0,1-1.56-1.43,14.41,14.41,0,0,1-2.38-3.36A13.87,13.87,0,0,1,3,14.09a14.85,14.85,0,0,1,3.17-2.91C6,11.3,4,13.87,5.88,13.94ZM23,33.59c4.72-2.93,8.14-11.69,2.87-19.08a9.78,9.78,0,0,1-.68,9.8c-1.66,2.32-2.81,3.27-6.9,4.19-1.47.33-3.49,1.13-3.59,3.61a3.38,3.38,0,0,0,3.2,3.54h.18C18.21,35.64,19.6,35.66,23,33.59Z"
      />
    </svg>
  );
};
