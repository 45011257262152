import React from "react";

interface IconKeyProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconKey: React.FC<IconKeyProps> = ({ hasTitle, className, fill = "#fff", width = 48, height = 48 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 43 43" className={className}>
      {hasTitle && <title>IconKey</title>}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" transform="translate(-777 -1501)">
          <g transform="translate(0 1050)">
            <g transform="translate(649 172.331)">
              <path
                fill={fill}
                d="M160.841 293c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm-4-14c-7.73 0-14 6.27-14 14 0 2.03.44 3.96 1.22 5.7l-13.76 13.76a4.98 4.98 0 00-1.46 3.54c0 1.28.48 2.56 1.46 3.54a5.016 5.016 0 007.08 0l2.09-2.1c.57-.57.57-1.49 0-2.06l-.01-.01c-.57-.57-.57-1.49 0-2.07l3.88-3.87c.57-.57 1.49-.57 2.07 0 .57.58 1.49.58 2.06 0l3.66-3.65c1.75.78 3.68 1.22 5.71 1.22 7.73 0 14-6.27 14-14s-6.27-14-14-14z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
