import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ServicePeriodCode, ServiceProductCode } from "../../../../../graphql/schema";
import { useServicePeriodTranslation } from "../../../../../hooks/useServicePeriodTranslation";
import { Button } from "../../../../../components/button/Button";
import { Radio } from "../../../../../components/radio/Radio";
import RadioGroup from "../../../../../components/radio-group/RadioGroup";
import { Form, FormProps } from "../../../../../components/form/Form";
import assertNever from "../../../../../services/assertNever";
import { UpgradeOffer } from "../EditSubscription";

import styles from "./choose-period.module.scss";

export interface ChoosePeriodProps extends FormProps {
  onContinue(offerId: string | undefined, productCode: ServiceProductCode[] | undefined): void;
  upgradeOffers: UpgradeOffer[];
}

export const ChoosePeriod: React.FC<ChoosePeriodProps> = ({ onContinue, upgradeOffers, ...props }) => {
  // access translation keys
  const { t } = useTranslation();
  const translatePeriod = useServicePeriodTranslation();
  const [action, setAction] = useState<ServicePeriodCode>(upgradeOffers[0].period.code);
  const [isLoading, setIsLoading] = useState(false);

  const getSubTitleText = (period: ServicePeriodCode) => {
    switch (period) {
      case ServicePeriodCode.ONE_MONTH:
        return t("Once per month, 12 times per year");
      case ServicePeriodCode.SIX_MONTHS:
        return t("Pay for 5 months, get 1 month for free");
      case ServicePeriodCode.TWELVE_MONTHS:
        return t("Pay only 9 months, get 3 for FREE!");
      case ServicePeriodCode.LIFETIME:
        return "";
      default:
        assertNever(period);
    }
  };

  return (
    <div>
      <p className={styles.label}>{t("Choose your billing period")}:</p>
      <Form onSubmit={props.onSubmit}>
        <RadioGroup defaultSelected={"1"} onChange={() => {}}>
          <>
            {upgradeOffers.map((upgrade) => (
              <Radio
                key={upgrade.id}
                label={
                  <div>
                    {translatePeriod(upgrade.period.code, "NUMERIC")}
                    <span
                      className={classNames(styles["label-section"], {
                        [styles["label-section--active"]]: action === upgrade.period.code,
                      })}
                    >
                      {" "}
                      | {getSubTitleText(upgrade.period.code)}
                    </span>
                  </div>
                }
                value={upgrade.period.code}
                checked={action === upgrade.period.code}
                onChange={() => setAction(upgrade.period.code)}
              />
            ))}
          </>
        </RadioGroup>
        <Button
          className={styles.button}
          borderRadius="SMALL"
          color="BLUE"
          fontSize={18}
          weight="MEDIUM"
          stretch="MOBILE"
          disabled={isLoading}
          width={280}
          center="BLOCK_AND_MARGIN"
          onClick={() => {
            setIsLoading(true);
            const findOffer = upgradeOffers.find((r) => r.period.code === action);

            onContinue(
              findOffer?.id,
              findOffer?.products.map((p) => p.code),
            );
          }}
        >
          {t("Continue")}
        </Button>
      </Form>
    </div>
  );
};
