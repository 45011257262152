import React from "react";

export interface IconMenuBurgerThickProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconMenuBurgerThick: React.FC<IconMenuBurgerThickProps> = ({
  hasTitle,
  className,
  fill = "#fff",
  width = 19,
  height = 15,
}) => {
  return (
    <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 15">
      {hasTitle && <title>IconMenuBurgerThick</title>}
      <path stroke={fill} strokeLinecap="round" strokeWidth="3" d="M2 1.5h15m-15 6h15m-15 6h15"></path>
    </svg>
  );
};
