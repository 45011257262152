import React from "react";

export interface IconTrashbinProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconTrashbin: React.FC<IconTrashbinProps> = ({
  hasTitle,
  className,
  fill = "#f5315d",
  width = 11,
  height = 14,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 14"
    >
      {hasTitle && <title>IconTrashbin</title>}
      <path
        fill={fill}
        fillRule="evenodd"
        id="Fill-290"
        d="M7.53,11.08a.58.58,0,1,1-1.16,0V5.25a.58.58,0,0,1,1.16,0Zm-2.9,0a.58.58,0,1,1-1.16,0V5.25a.58.58,0,0,1,1.16,0ZM7,1.46a1.45,1.45,0,1,0-2.9,0H.87a.88.88,0,0,0,0,1.75l.5,9.41A1.46,1.46,0,0,0,2.82,14H8.18a1.46,1.46,0,0,0,1.45-1.38l.5-9.41a.88.88,0,0,0,0-1.75Z"
      />
    </svg>
  );
};
