import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";

import { Link } from "../../../components/link/Link";
import { Container, ContainerWidth } from "../../../components/container/Container";
import { IconCheckmarkThin } from "../../../components/icon/IconCheckmarkThin";
import { Loader } from "../../../components/loader/Loader";
import { useActivateEmailByHashMutation, useViewerQuery } from "../../../graphql/schema";
import { IconArrowClassic } from "../../../components/icon/IconArrowClassic";
import { Routes } from "../../../services/constants";

import styles from "./emailConfirmedView.module.scss";

interface RouteParams {
  hash: string;
}

export const EmailConfirmedView: React.FC = () => {
  const { params } = useRouteMatch<RouteParams>();
  const hash = params.hash;
  const [t] = useTranslation();
  const { data, loading } = useViewerQuery({ fetchPolicy: "network-only" });
  const [activateEmailByHash, { data: activateEmailByHashData, loading: activateEmailByHashLoading }] =
    useActivateEmailByHashMutation({
      fetchPolicy: "no-cache",
      variables: { hash },
    });

  useEffect(() => {
    if (hash) {
      activateEmailByHash().catch((res) => {
        console.log("res", res);
        toast.error("Email confirmation failed!");
      });
    }
  }, [hash, activateEmailByHash]);

  if (loading || activateEmailByHashLoading) {
    return <Loader />;
  }

  const firstName = data?.viewer?.firstName ?? null;

  if (activateEmailByHashData) {
    return (
      <Container title={t("Your new email is verified!")} center size={ContainerWidth.SMALL} secondaryTitle>
        <p className={styles["title-caption"]}>
          {firstName
            ? t(`Thank you, ${firstName}! We have successfully verified your new email address.`)
            : t("We have successfully verified your new email address.")}
        </p>
        <Link to={Routes.PRODUCTS} shape="ROUND" color="BLUE" height="LARGE" weight="MEDIUM">
          {t("Go to my account")}
          <IconCheckmarkThin className={styles.checkmark} />
        </Link>
      </Container>
    );
  } else {
    return (
      <Container title={t("Email confirmation failed!")} center size={ContainerWidth.SMALL} secondaryTitle>
        <p className={styles.text}>{t("Maybe wrong link?")}</p>
        <Link to="/" shape="ROUND" color="BLUE" height="LARGE" weight="MEDIUM">
          {t("Go back to home")}
          <IconArrowClassic className={styles["arrow-icon"]} width={24} height={24} />
        </Link>
      </Container>
    );
  }
};
