import prospectsImg from "./img/prospects.png";
import fasterImg from "./img/faster.png";
import leadsImg from "./img/leads.png";

export const benefits = [
  {
    type: "PROSPECTS",
    imageUrl: prospectsImg,
    title: "Understand your <strong>prospects</strong>",
    content:
      "Know what is most important to the people you are selling to so you can match their expectations and speak their language. Cracking their BANKCODE will help you start the relationship off on the right foot from the very beginning.",
  },
  {
    type: "LEADS",
    imageUrl: leadsImg,
    title: "Generate more <strong>leads</strong>",
    content:
      "Invite your target market to crack their BANKCODE so they can learn more about themselves and about how they can navigate their relationships more effectively. When they do, you’ll also know more about how to approach them as a new lead.",
  },
  {
    type: "TIME",
    imageUrl: fasterImg,
    title: "Get to yes <strong>faster</strong>",
    content:
      "Get directly to what matters most to your prospects by understanding how they think and how to speak their buyer language. Crack their BANKCODE faster and more easily by leveraging our unique tools and technologies.",
  },
];

export const tools = [
  {
    name: "BANKPASS",
    description:
      "Generate unlimited leads! Then, close them easily using the exclusive information detailed in custom BANKCODE Sales Reports generated for each lead.",
  },
  {
    name: "CODEBREAKER AI",
    description:
      "Use cutting-edge Artificial Intelligence to analyze LinkedIn profiles, emails, websites, or any text to decode and speak to the writer’s buying personality in nanoseconds.",
  },
  {
    name: "GOOGLE CHROME EXTENSION",
    description:
      "Install the Codebreaker AI Chrome Extension on your Google Chrome browser and decode anyone's BANKCODE in seconds right on their social media profiles page.",
  },
  {
    name: "BANKVAULT",
    description:
      "Access more than 50 hours of video, fun interactive B.A.N.K. virtual training courses, audio and other cutting-edge e-learning resources for success with or without the tools.",
  },
];
