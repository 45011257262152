import React from "react";
import ReactSwitch, { ReactSwitchProps } from "react-switch";
import classNames from "classnames";

import styles from "./switch.module.scss";

export const Switch: React.FC<ReactSwitchProps> = ({ ...props }) => {
  return (
    <ReactSwitch
      checked={props.checked}
      onChange={props.onChange}
      onColor={props.onColor || "#00D383"}
      offColor={props.offColor || "#fff"}
      onHandleColor="#fff"
      handleDiameter={29}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 2px 3px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 0px rgba(0, 0, 0, 0.16)"
      height={31}
      width={51}
      className={classNames("react-switch", { [styles.checked]: !props.checked }, props.className)}
    />
  );
};
