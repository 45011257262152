import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ButtonIconGutter } from "../button/Button";
import { IconRefresh } from "../icon/IconRefresh";

import styles from "./new-version-notifier.module.scss";

export interface NewVersionNotifierProps {
  onClick(): void;
}

export const NewVersionNotifier: React.FC<NewVersionNotifierProps> = ({ onClick }) => {
  // fetch translation keys
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={styles.notification}>{t("Page has updated. Please refresh your browser.")}</div>
      <Button
        shape="ROUND"
        color="BLUE"
        weight="SEMI_BOLD"
        fontSize="inherit"
        iconRight={<IconRefresh className={styles.icon} width={20} height={20} />}
        iconGutter={ButtonIconGutter.SMALL}
        onClick={onClick}
      >
        {t("Refresh")}
      </Button>
    </div>
  );
};
