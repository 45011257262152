import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import { useViewerQuery } from "../../graphql/schema";
import { CopyToClipboard } from "../copy-to-clipboard/CopyToClipBoard";
import { IconBind } from "../icon/IconBind";
import { IconPortfolio } from "../icon/IconPortfolio";
import partyPopperEmoji from "./assets/AppleEmojiPartyPopper.png";
import { HiddenUserToolbarPathnames } from "../../services/constants";
import { Layout } from "../layout/Layout";

import styles from "./userToolbar.module.scss";

export interface UserToolbarProps {
  hasInvitation?: boolean;
  userName?: string | null;
  hasAccessToWebOffice?: boolean;
  isAffiliate?: boolean;
}

export const UserToolbar: React.FC<UserToolbarProps> = ({
  hasInvitation = false,
  userName,
  hasAccessToWebOffice,
  isAffiliate,
}) => {
  const [t] = useTranslation();

  const { data } = useViewerQuery({ fetchPolicy: "network-only" });

  //   const copiedLinkNotification = () =>
  //     data?.viewer?.affiliateId
  //       ? toast.success(t("Referral link copied"))
  //       : toast.error(t("Copying referral link failed"));

  // check current url
  const { pathname } = useLocation();

  // check if current url should be hidden
  const hiddenUserToolbar = Object.values(HiddenUserToolbarPathnames).find((url) => pathname.startsWith(url));

  if (hiddenUserToolbar) {
    return null;
  }

  if (hasInvitation && userName) {
    return (
      <div className={classNames(styles["user-toolbar"], styles["user-toolbar-cta"])}>
        <div className={styles["invitation-cta-text"]}>
          <span className={styles["invitation-cta-text--highlighted"]}>
            Congratulations! <img src={partyPopperEmoji} className={styles.emoji} alt="party_popper_emoji" />
          </span>{" "}
          &nbsp; {userName} has sent you an invite to join SF Suite.
        </div>
      </div>
    );
  }

  return (
    <Layout className={styles["user-toolbar"]}>
      <div>
        <span className={styles.text}>
          {t("Welcome back")}
          {","}
        </span>

        <span className={styles.addon}>
          <b>{userName}</b>
        </span>
      </div>
      <div className={styles.tools}>
        {/* {isAffiliate && (
          <CopyToClipboard
            childClassName={styles.text}
            copyText={`https://sfsuite.com/register-user/${data?.viewer?.affiliateId}`}
            onClick={() => {
              copiedLinkNotification();
            }}
            icon={<IconBind fill="#fff" />}
          >
            {t("Copy your referral link")}
          </CopyToClipboard>
        )} */}

        {hasAccessToWebOffice && (
          <div className={styles.section}>
            <a href={`${process.env.REACT_APP_SUCCESSFACTORY_SERVER_PROXY}`} target="_blank" rel="noopener noreferrer">
              <span className={styles.text}>{t("Go to Web Office")}</span>
              <IconPortfolio className={styles.addon} />
            </a>
          </div>
        )}
      </div>
    </Layout>
  );
};
