import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link, LinkProps } from "react-router-dom";

import { DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT } from "../../services/constants";
import { Heading } from "../heading/Heading";
import { IconArrow } from "../icon/IconArrow";
import { IconGift } from "../icon/IconGift";
import { Layout } from "../layout/Layout";
import { Subscribers, SubscriptionStatusColor } from "../subscribers/Subscribers";
import { TextAccent } from "../text-accent/TextAccent";
import { Tooltip } from "../tooltip/Tooltip";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";

import styles from "./membership-link-panel.module.scss";

export interface MembershipLinkPanelProps extends LinkProps {
  logo: ReactNode;
  isFree: boolean;
  activeBadgesCount: number;
  pendingBadgesCount: number;
  name: string;
}

export const MembershipLinkPanel: React.FC<MembershipLinkPanelProps> = ({
  children,
  logo,
  isFree = false,
  name,
  activeBadgesCount,
  pendingBadgesCount,
  ...props
}) => {
  // access translation keys
  const { t } = useTranslation();

  // get window dimensions
  const { isDesktopUp } = useWindowDimensionsContext();

  return (
    <Link className={styles.link} to={props.to}>
      <Layout className={styles.wrap} wrap="MOBILE">
        <Layout className={styles.section} wrap="MOBILE">
          <div className={styles["logo-wrap"]}>{logo}</div>
          <div>
            <Heading className={styles.title} level={6} gutter={0}>
              {name}{" "}
              {isFree && (
                <span className={styles["label-wrap"]}>
                  <span className={styles.separator}>|</span>{" "}
                  <strong className={styles.label}>{t("Free access")}</strong>
                </span>
              )}
            </Heading>
            <div className={styles.main}>{children}</div>
          </div>
        </Layout>
        <Layout>
          {isDesktopUp ? (
            <Tooltip
              className={styles.tooltip}
              arrowClassName={styles["tooltip-arrow"]}
              reference={<Subscribers subscribers={getSubscribersColors(activeBadgesCount, pendingBadgesCount)} />}
              openOnHover={true}
            >
              <div className={styles["tooltip-content"]}>
                <IconGift className={styles["gift-icon"]} fill="#FFC542" />
                <Heading className={styles["tooltip-title"]} level={6}>
                  <TextAccent
                    wrap
                    bold
                    type="GREEN"
                    dangerouslySetInnerHTML={{
                      __html: t("Invite {{userCount}} friends and <strong>GET IT FREE FOR YOURSELF!</strong>", {
                        userCount: DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT,
                      }),
                    }}
                  />
                </Heading>
                <p
                  className={styles["tooltip-text"]}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "<strong>Green</strong> icon shows you friends who have active subscriptions and have already paid in this month. <strong>Yellow</strong> shows friends who have active membership, but this month’s payment is still upcoming (or declined).",
                    ),
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <Subscribers subscribers={getSubscribersColors(activeBadgesCount, pendingBadgesCount)} />
          )}
          <IconArrow className={styles.arrow} width={16} height={32} fill="#7789ad" />
        </Layout>
      </Layout>
    </Link>
  );
};

function getSubscribersColors(activeCount: number, pendingCount: number) {
  return Array.from(new Array(activeCount), () => SubscriptionStatusColor.GREEN).concat(
    Array.from(new Array(pendingCount), () => SubscriptionStatusColor.YELLOW),
  );
}
