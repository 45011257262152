import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./productBadge.module.scss";

export enum ProductBadgeKind {
  YELLOW = "YELLOW",
  GREEN = "GREEN",
}

export interface ProductBadgeProps {
  color: keyof typeof ProductBadgeKind;
  className?: string;
  children?: ReactNode;
}

export const ProductBadge: React.FC<ProductBadgeProps> = ({ children, color, className }) => {
  return (
    <div className={classNames(styles["product-badge"], (styles as any)[`product-badge--${color}`], className)}>
      <div className={styles["product-badge-text"]}>{children}</div>
    </div>
  );
};
