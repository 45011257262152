import React from "react";

export interface IconStopWatchProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconStopWatch: React.FC<IconStopWatchProps> = ({
  hasTitle,
  className,
  fill = "#7789ad",
  width = 26,
  height = 26,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
    >
      {hasTitle && <title>IconStopWatch</title>}
      <path
        fill={fill}
        id="Stopwatch"
        d="M20.81,4.39l2.12-2.12L21.64,1a.56.56,0,1,1,.79-.79l3.39,3.39a.56.56,0,1,1-.79.79l-1.3-1.29L21.61,5.19a12.43,12.43,0,1,1-10.31-4V.58A.56.56,0,0,1,11.86,0H13a.56.56,0,0,1,.57.57v.61A12.39,12.39,0,0,1,20.81,4.39ZM1.13,13.57a11.3,11.3,0,1,0,11.3-11.3A11.3,11.3,0,0,0,1.13,13.57ZM13,11.38a2.26,2.26,0,0,1,0,4.38V17a.57.57,0,0,1-1.13,0v-1.2a2.26,2.26,0,0,1,0-4.38V5.66a.57.57,0,0,1,1.13,0v5.72Zm-.56,3.32a1.13,1.13,0,1,0-1.13-1.13A1.14,1.14,0,0,0,12.43,14.7Z"
      />
    </svg>
  );
};
