import React, { useEffect, useState } from "react";

import { Loader } from "../../../components/loader/Loader";
import { Section } from "../../../components/section/Section";
import { useGetAffiliateQuery } from "../../../graphql/schema";
import { LoginSection } from "../login-section/LoginSection";
import { RegisterAccountSection } from "../register-account-section/RegisterAccountSection";

export const VerificationSection: React.FC = () => {
  const [isRegistrationEnabled, setIsRegistrationEnabled] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const { data: affiliateData, loading: isGetAffiliateLoading } = useGetAffiliateQuery();

  const toggleShowLogin = () => {
    setShowLogin(!showLogin);
  };

  // decide which section to display if affiliate has loaded or affiliate data is changing
  useEffect(() => {
    if (affiliateData?.getAffiliate?.id) {
      setIsRegistrationEnabled(true);
    } else if (!isGetAffiliateLoading) {
      setShowLogin(true);
    }
  }, [affiliateData, isGetAffiliateLoading]);

  if (isGetAffiliateLoading) {
    return <Loader />;
  }

  return (
    <Section gutter={0}>
      {showLogin ? (
        <LoginSection toggleShowLogin={toggleShowLogin} isRegistrationEnabled={isRegistrationEnabled} />
      ) : (
        <RegisterAccountSection toggleShowLogin={toggleShowLogin} />
      )}
    </Section>
  );
};
