import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Checkbox } from "../../checkbox/Checkbox";
import { Button } from "../../button/Button";
import { IconLogoDagcoin } from "../../icon/IconLogoDagcoin";
import { formatLocaleDate } from "../../../services/formatDate";
import Price from "../../price/Price";
import { PaymentMethod, usePaymentMethodInfoQuery } from "../../../graphql/schema";
import { getDocumentUrl } from "../../../services/getDocumentUrl";
import { isUserFromUs } from "../../../services/isUserFromUs";

import styles from "./offers.module.scss";

export interface OffersProps {
  onSubmit(method: OfferSubmitMethod): Promise<void>;
  onMembershipChecked?(id: string, isChecked: boolean): void;
  memberships?: OfferMembership[];
  totalPriceInEur: number;
  totalPriceInDags: number;
  availablePaymentMethods: PaymentMethod[];
}

export interface OfferMembership {
  id: string;
  labelText?: string | null;
  eurPrice: string;
  datePurchased: Date | string;
  isChecked: boolean;
}

export type OfferSubmitMethod = PaymentMethod.DAGPAY | PaymentMethod.COIN_ACCOUNT;

export const Offers: FC<OffersProps> = ({
  onSubmit,
  onMembershipChecked,
  memberships,
  totalPriceInDags,
  totalPriceInEur,
  availablePaymentMethods,
}) => {
  const [t, i18n] = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // check if terms of service are accepted
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  // check if terms of service error should be shown
  const [showTermsAgreementError, setShowTermsAgreementError] = useState(false);

  // check if any membership is selected
  const isMembershipSelected = memberships ? memberships.some((membership) => membership.isChecked) : false;

  useEffect(() => {
    // hide error message when terms of service are agreed with
    if (isTermsAccepted) {
      setShowTermsAgreementError(false);
    }
  }, [isTermsAccepted]);

  // get payment method info
  const query = usePaymentMethodInfoQuery();
  const isUsCountry = isUserFromUs(query.data?.me);

  // get the max limit for specific payment method (returns number or Nan)
  const getMaxLimit = (method: PaymentMethod) => {
    const getPaymentMethod = (method: PaymentMethod) => query.data?.me.paymentMethods.find((m) => m.method === method);

    const convertMaxLimit = getPaymentMethod(method)?.limits?.maximum.amount.replace(/,/g, ".");

    return parseFloat(convertMaxLimit || "");
  };

  const paymentMethods = query.data?.me.paymentMethods;

  // return user-friendly name based on PaymentMethod
  const paymentMethodName = (method: PaymentMethod) => {
    switch (method) {
      case PaymentMethod.COIN_ACCOUNT:
        return t("coin balance");
      case PaymentMethod.DAGPAY:
        return "WebWallet";
    }
  };

  return (
    <>
      {memberships && <p className={styles.label}>{t("Select the memberships you’d like to purchase:")}</p>}
      {(memberships || []).map((membership) => (
        <Checkbox
          key={membership.id}
          onChange={() => (onMembershipChecked ? onMembershipChecked(membership.id, !membership.isChecked) : undefined)}
          checked={membership.isChecked}
          checkboxStyle="TERTIARY"
          gutter="MEDIUM"
          label={
            <span
              className={classNames(styles["checkbox-label"], {
                [styles["checkbox-label--active"]]: membership.isChecked,
              })}
            >
              {membership.labelText}
              <Price price={membership.eurPrice} currency="EUR" />
              <span>
                | {t("Purchased {{date}}", { date: formatLocaleDate(membership.datePurchased, i18n.language) })}
              </span>
            </span>
          }
        />
      ))}

      <div
        className={classNames(styles["summary-wrap"], {
          [styles["summary-wrap--is-center"]]: (memberships?.length ?? 0) === 0,
        })}
      >
        <div className={styles["summary-title"]}>{t("Total")}</div>
        <div className={styles["summary-value"]}>
          <div className={styles["summary-value-primary"]}>
            <Price price={totalPriceInDags} currency="DAG" hideSign />
            <IconLogoDagcoin className={styles["summary-icon"]} fill="#FFC542" height={22} width={22} />
          </div>
          <div className={styles["summary-value-secondary"]}>
            (<Price price={totalPriceInEur} currency="EUR" />)
          </div>
        </div>
      </div>

      <Checkbox
        className={styles.terms}
        checked={isTermsAccepted}
        onChange={() => setIsTermsAccepted(!isTermsAccepted)}
        checkboxStyle="SECONDARY"
        label={
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                'I agree to the <a href="{{url}}" rel="noopener noreferrer" target="_blank">Terms of Service</a>',
                { url: getDocumentUrl("GENERAL_TERMS_AND_CONDITIONS", isUsCountry) },
              ),
            }}
          />
        }
        error={termsOfServiceError()}
      />

      {/* user-friendly error message when dag payment limit is exceeded */}
      {paymentMethods?.map(
        (method) =>
          IsMaxDagLimitReached(method.method) && (
            <div className={styles.error} key={method.method}>
              {t(
                "In order to pay with {{methodName}} the total amount should not be larger than {{maxLimit}}{{sign}}.",
                {
                  methodName: paymentMethodName(method.method),
                  maxLimit: getMaxLimit(method.method).toString(),
                  sign: "DAG",
                },
              )}
            </div>
          ),
      )}

      <div className={styles["button-wrap"]}>
        {availablePaymentMethods.includes(PaymentMethod.COIN_ACCOUNT) && (
          <Button
            onClick={() => onSubmitWithCoinBalance()}
            className={styles.button}
            color="LIGHT_BLUE_2"
            borderRadius="SMALL"
            fontSize={16}
            disabled={
              isSubmitting || (memberships && !isMembershipSelected) || IsMaxDagLimitReached(PaymentMethod.COIN_ACCOUNT)
            }
          >
            {t("Use my coin balance")}
          </Button>
        )}

        {availablePaymentMethods.includes(PaymentMethod.DAGPAY) && (
          <Button
            onClick={handleSubmit}
            className={styles.button}
            color="BLUE"
            borderRadius="SMALL"
            fontSize={16}
            disabled={
              isSubmitting || (memberships && !isMembershipSelected) || IsMaxDagLimitReached(PaymentMethod.DAGPAY)
            }
          >
            {t("Pay with my WebWallet")}
          </Button>
        )}
      </div>
    </>
  );

  // check if max payment limit is reached for specific payment method
  function IsMaxDagLimitReached(method: PaymentMethod) {
    switch (method) {
      case PaymentMethod.COIN_ACCOUNT:
        return totalPriceInDags > getMaxLimit(PaymentMethod.COIN_ACCOUNT);
      case PaymentMethod.DAGPAY:
        return totalPriceInDags > getMaxLimit(PaymentMethod.DAGPAY);
    }
  }

  // handle payement with dagcoins
  function onSubmitWithCoinBalance() {
    if (!isTermsAccepted) {
      setShowTermsAgreementError(true);

      return null;
    }

    onSubmit(PaymentMethod.COIN_ACCOUNT);
  }

  // handle webwallet payment
  function handleSubmit() {
    if (!isTermsAccepted) {
      setShowTermsAgreementError(true);
      return null;
    }

    setIsSubmitting(true);

    onSubmit(PaymentMethod.DAGPAY).finally(() => {
      setIsSubmitting(false);
    });
  }

  // handle terms of service acceptance error
  function termsOfServiceError() {
    const message = t("You must agree to the Terms of Service to proceed");

    if (showTermsAgreementError) {
      return { type: "error", message };
    }

    return undefined;
  }
};
