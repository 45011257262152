import React from "react";

import { ServiceOfferAvailability, ServicePeriodCode, ServiceProductCode } from "../../graphql/schema";
import assertNever from "../../services/assertNever";
import { AddServicesToCartButton } from "../add-services-to-cart-button/AddServicesToCartButton";
import { IconTag3 } from "../icon/IconTag3";
import { IconTag4 } from "../icon/IconTag4";
import { Layout } from "../layout/Layout";
import Price, { CurrencyCode } from "../price/Price";

import styles from "./business-fee-option.module.scss";

export type BusinessFeeServiceCode = keyof Pick<typeof ServiceProductCode, "BUSINESS_FEE_BASIC" | "BUSINESS_FEE_PRO">;

export interface BusinessFeeOptionProps {
  productName: string;
  productCode: BusinessFeeServiceCode;
  periodCode: keyof typeof ServicePeriodCode;
  availability: keyof typeof ServiceOfferAvailability | null | undefined;
  activeSubscriptionId: string | null | undefined;
  price: string;
  currencyCode: CurrencyCode;
}

export const BusinessFeeOption: React.FC<BusinessFeeOptionProps> = ({
  productName,
  productCode,
  periodCode,
  availability,
  activeSubscriptionId,
  price,
  currencyCode,
}) => {
  return (
    <Layout gutter="SEMI_LARGE" wrap="MOBILE">
      <Layout className={styles.product}>
        <BusinessFeeOptionIcon productCode={productCode} />

        <div className={styles["product-information"]}>
          <div className={styles["product-name"]}>{productName}</div>
          <p className={styles["product-price"]}>
            <Price price={price} isDigitalProduct digitalProductPurchaseType={periodCode} currency={currencyCode} />
          </p>
        </div>
      </Layout>
      <AddServicesToCartButton
        productCodes={[productCode]}
        periodCode={periodCode}
        availability={availability}
        activeSubscriptionId={activeSubscriptionId}
        kind={"DARK_BLUE"}
        height="LARGE"
      />
    </Layout>
  );
};

const BusinessFeeOptionIcon: React.FC<{ productCode: BusinessFeeServiceCode }> = ({ productCode }) => {
  switch (productCode) {
    case "BUSINESS_FEE_BASIC":
      return <IconTag3 />;

    case "BUSINESS_FEE_PRO":
      return <IconTag4 />;

    default:
      return assertNever(productCode);
  }
};
