import React, { ReactNode } from "react";
import classNames from "classnames";

import { Layout, LayoutProps } from "../layout/Layout";

import styles from "./badge.module.scss";

export enum BadgeGutter {
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export interface BadgeProps extends Pick<LayoutProps, "wrap"> {
  label: React.ReactNode;
  icon?: React.ReactNode;
  badgeGutter?: keyof typeof BadgeGutter;
  className?: string;
  contentClassName?: string;
  labelClassName?: string;
  iconClassName?: string;
  children?: ReactNode;
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  label,
  icon,
  badgeGutter,
  className,
  contentClassName,
  labelClassName,
  iconClassName,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.wrap,
        { [styles["gutter--large"]]: badgeGutter === "LARGE", [styles["gutter--medium"]]: badgeGutter === "MEDIUM" },
        className,
      )}
    >
      <Layout className={classNames(styles.content, contentClassName)} wrap={props.wrap}>
        <div className={classNames(styles.label, styles[`has-gap--${props.wrap}`], labelClassName)}>
          {icon && <div className={classNames(styles["icon-wrap"], iconClassName)}>{icon}</div>}
          {label}
        </div>
        {children}
      </Layout>
    </div>
  );
};
