import React from "react";
import classNames from "classnames";
import { TFunction, useTranslation } from "react-i18next";

import { ServiceProductCode, ServicePeriodCode, ServiceSubscriptionStatus } from "../../graphql/schema";
import { useServicePeriodTranslation } from "../../hooks/useServicePeriodTranslation";
import assertNever from "../../services/assertNever";
import { ServiceInfo } from "../../services/constants";
import { DataFraction } from "../data-fraction/DataFraction";
import DateTime from "../date-time/DateTime";
import { CurrencyCode } from "../price/Price";
import { StatusIcon } from "../status-icon/StatusIcon";
import {
  EditSubscription,
  UpgradeOffer,
} from "../../views/account-view/subscriptions-view/edit-subscription/EditSubscription";
import { Layout } from "../layout/Layout";
import { IconStopWatch } from "../icon/IconStopWatch";
import { IconCalendar } from "../icon/IconCalendar";

import styles from "./subscriptionListItem.module.scss";

export interface SubscriptionListItemProps {
  subscription: {
    id: string;
    name: string;
    productCodes: ServiceProductCode[];
    periodCode: ServicePeriodCode;
    dateStart: string;
    dateEnd?: string | null;
    billingDate?: string | null;
    status: ServiceSubscriptionStatus;
    isLegacy: boolean;
    upgradeOffers: UpgradeOffer[];
    isDiscontinuable: boolean;
  };
  refetchSubscriptions(): void;
}

export const SubscriptionListItem: React.FC<SubscriptionListItemProps> = ({ subscription, refetchSubscriptions }) => {
  // access translation keys
  const [t] = useTranslation();

  const translatePeriod = useServicePeriodTranslation();

  const isActive = [
    ServiceSubscriptionStatus.ACTIVE,
    ServiceSubscriptionStatus.DISCONTINUED,
    ServiceSubscriptionStatus.PAYMENT_PENDING,
  ].includes(subscription.status);

  const dateExpires = subscription.billingDate || subscription.dateEnd;

  return (
    <li className={styles.wrap}>
      <div className={styles.section}>
        <StatusIcon className={styles["status-icon"]} status={isActive ? "SUCCESS" : "ERROR"} />
        <div>
          <h4 className={styles.name}>{subscription.name}</h4>

          <div
            className={classNames(styles.status, {
              [styles["status--active"]]: isActive,
              [styles["status--inactive"]]: !isActive,
            })}
          >
            {getStatusTranslation(t, subscription.status)}
          </div>
        </div>
      </div>

      <Layout className={styles["payment-data"]}>
        <DataFraction
          className={styles["data-fraction"]}
          icon={<IconStopWatch />}
          label={t("Billing period")}
          value={translatePeriod(subscription.periodCode, "NUMERIC")}
        />

        {handleNextBilling({ subscription, t, dateExpires: dateExpires })}

        <div className={styles.placeholder} />
      </Layout>

      {(subscription.status === ServiceSubscriptionStatus.ACTIVE && subscription.isDiscontinuable) ||
      (subscription.status === ServiceSubscriptionStatus.DISCONTINUED && !subscription.isLegacy) ? (
        <EditSubscription subscription={subscription} refetchSubscriptions={refetchSubscriptions} />
      ) : (
        <div className={styles.placeholder} />
      )}
    </li>
  );
};

function handleNextBilling(props: {
  subscription: {
    status: ServiceSubscriptionStatus;
    billingDate?: string | null;
    currencyCode?: keyof typeof CurrencyCode;
  };
  t: TFunction<string>;
  dateExpires?: string | null;
}) {
  switch (props.subscription.status) {
    case ServiceSubscriptionStatus.ACTIVE:
      return (
        <DataFraction
          className={styles["data-fraction"]}
          icon={<IconCalendar />}
          label={props.subscription.billingDate ? props.t("Next billing") : props.t("Expires")}
          value={props.dateExpires && <DateTime value={props.dateExpires.replace(/-/g, "/")} />}
        />
      );
    case ServiceSubscriptionStatus.CANCELLED:
    case ServiceSubscriptionStatus.EXPIRED:
      return (
        <DataFraction
          className={styles["data-fraction"]}
          icon={<IconCalendar />}
          label={props.t("Next billing")}
          value={"—"}
        />
      );
    default:
      return <div className={styles.placeholder} />;
  }
}

export function getTitle(productCodes: ServiceProductCode[]) {
  return productCodes
    .map((code) => {
      if (code in ServiceInfo) {
        return ServiceInfo[code].title;
      }

      return code.replace(/_/g, " ").toLowerCase();
    })
    .join(" + ");
}

export function getStatusTranslation(t: TFunction, status: ServiceSubscriptionStatus) {
  switch (status) {
    case ServiceSubscriptionStatus.ACTIVE:
      return t("Active subscription");
    case ServiceSubscriptionStatus.PAYMENT_PENDING:
      return t("Pending");
    case ServiceSubscriptionStatus.PAYMENT_FAILED:
      return t("Failed payment");
    case ServiceSubscriptionStatus.DISCONTINUED:
      return t("Discontinued");
    case ServiceSubscriptionStatus.EXPIRED:
      return t("Expired");
    case ServiceSubscriptionStatus.CANCELLED:
      return t("Cancelled");
    default:
      return assertNever(status);
  }
}
