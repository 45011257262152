import { useState } from "react";

import { getCookie } from "../services/getCookie";

export function useCookie<T>(key: string, defaultValue: T | null = null) {
  const [cookie, setCookie] = useState(getCookie<T>(key, defaultValue));

  const updateCookie = (value: T, numberOfDays?: number) => {
    setCookie(value);
    storeCookie(key, value, numberOfDays);
  };

  return [cookie, updateCookie] as [T, (value: T, numberOfDays?: number) => void];
}

function storeCookie<T>(key: string, value: T, days?: number) {
  const encodedValue = encodeURIComponent(JSON.stringify(value));
  let expires = "";

  if (days !== undefined) {
    const date = new Date();

    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${key}=${encodedValue}${expires}; ${
    process.env.NODE_ENV === "development" ? "" : " domain=sfsuite.com; "
  }path=/`;
}
