import React from "react";

export interface IconLogoSFSimplifyedProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconLogoSFSimplifyed: React.FC<IconLogoSFSimplifyedProps> = ({
  hasTitle,
  className,
  fill = "#ff871c",
  width = 15,
  height = 20,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 20"
    >
      {hasTitle && <title>IconLogoSFSimplifyed</title>}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7.5,20H7.12c-4.39-.23-7.66-4.55-7-9.17A10.28,10.28,0,0,1,2.82,5.13a.85.85,0,0,1,.61-.25.82.82,0,0,1,.85.89A4.94,4.94,0,0,0,5.2,9.39,8.47,8.47,0,0,0,7,1.14.85.85,0,0,1,7.81,0a.86.86,0,0,1,.45.13,14.8,14.8,0,0,1,4.8,4.78A14,14,0,0,1,15,12a7.74,7.74,0,0,1-7.5,8"
      />
    </svg>
  );
};
