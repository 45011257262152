import classNames from "classnames";
import React from "react";

import styles from "./loader.module.scss";

export enum LoaderSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
}

export enum LoaderColor {
  ORANGE = "ORANGE",
  WHITE = "WHITE",
}

export interface LoaderProps {
  color?: keyof typeof LoaderColor;
  size?: LoaderSize;
  visible?: boolean;
  visibleOnFirstLoad?: { loading: boolean; previousData?: any; data?: any };
  cover?: boolean;
  whiteBackground?: boolean;
  fixed?: boolean;
  className?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  color = "ORANGE",
  size = LoaderSize.MEDIUM,
  visible = true,
  visibleOnFirstLoad,
  cover = true,
  whiteBackground = false,
  fixed,
  className,
}) => (
  <div
    className={classNames(
      styles.loader,
      (styles as any)[`color--${color}`],
      {
        [styles["loader--small"]]: size === LoaderSize.SMALL,
        [styles["loader--medium"]]: size === LoaderSize.MEDIUM,
        [styles.visible]:
          visibleOnFirstLoad === undefined
            ? visible
            : visibleOnFirstLoad.loading &&
              visibleOnFirstLoad.previousData === undefined &&
              visibleOnFirstLoad.data === undefined,
        [styles["loader--cover"]]: cover,
        [styles["loader--white-background"]]: whiteBackground,
        [styles["loader--fixed"]]: fixed,
      },
      className,
    )}
  />
);
