import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FC, memo, ReactNode, useMemo } from "react";

import { CurrencyCode } from "../price/Price";
import { isUserFromUs } from "../../services/isUserFromUs";

export interface StripeElementsProps {
  currencyCode?: keyof typeof CurrencyCode;
  locale?: string;
  children?: ReactNode;
}

const US_KEY = `${process.env.REACT_APP_STRIPE_US_PUBLIC_KEY}`;
const GLOBAL_KEY = `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`;

const StripeElementsComponent: FC<StripeElementsProps> = ({ currencyCode, locale = "auto", children }) => {
  const stripePublicKey = currencyCode && isUserFromUs({ currency: { code: currencyCode } }) ? US_KEY : GLOBAL_KEY;
  const stripeLoader = useMemo(() => loadStripe(stripePublicKey), [stripePublicKey]);

  if (!currencyCode) {
    return null;
  }

  return (
    <Elements
      key={stripePublicKey}
      stripe={stripeLoader}
      options={{ locale: locale as StripeElementsOptions["locale"] }}
    >
      {children}
    </Elements>
  );
};

export const StripeElements = memo(StripeElementsComponent);
