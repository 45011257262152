import { FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { GiftCardHistoryPanel, GiftCard } from "../../../../components/gift-card-history-panel/GiftCardHistoryPanel";
import { Heading } from "../../../../components/heading/Heading";
import { Section } from "../../../../components/section/Section";
import { usePaymentMethodsViewRedeemGiftCardMutation } from "../../../../graphql/schema";

import styles from "./gift-card-history-section.module.scss";

interface GiftCardHistoryProps {
  giftCards?: GiftCard[];
}

export const GiftCardHistorySection: FC<GiftCardHistoryProps> = ({ giftCards }) => {
  // access translation keys
  const { t } = useTranslation();

  const [redeemGiftCard] = usePaymentMethodsViewRedeemGiftCardMutation();

  const handleRedeemGiftCard = async (cardCode: string) => {
    try {
      await redeemGiftCard({
        variables: {
          cardCode,
        },
        refetchQueries: ["PaymentMethodsView"],
        awaitRefetchQueries: true,
      });
      toast.success(t("Gift card redeemed successfully"));
    } catch (error) {
      toast.error(t("Something went wrong. Could not redeem gift card"), { autoClose: false });
    }
  };

  if (!giftCards || giftCards.length === 0) {
    return null;
  }

  return (
    <Section className={styles.wrap} gutter={70} withSpace centerSelf>
      <Heading className={styles.title} level={3}>
        {t("Gift card history")}
      </Heading>
      {giftCards.map((card, index) => (
        <GiftCardHistoryPanel key={index} card={card} redeemGiftCard={handleRedeemGiftCard} />
      ))}
    </Section>
  );
};
