export interface IconTrashbin2Props {
  hasTitle?: boolean;
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
  onClick?: (e: any) => void;
}

function IconTrashbin2({ hasTitle, fill, width = 16, height = 20, className, onClick }: IconTrashbin2Props) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      className={className}
    >
      {hasTitle && <title>IconTrashbin2</title>}
      <path
        fill={fill}
        d="M40 15.993v7.975c0 5.448-.18 10.506-.54 15.176a7 7 0 01-6.337 6.433 98.947 98.947 0 01-18.247.003 7 7 0 01-6.336-6.43C8.18 34.487 8 29.459 8 24.065v-8.072c-.397-.02-.673-.091-.96-.245a1.909 1.909 0 01-.788-.788C6.065 14.61 6 14.275 6 13.718V12a3.994 3.994 0 013.995-3.994l13.776.029h.818l13.409-.029A3.994 3.994 0 0142 11.993v1.725c0 .557-.065.892-.252 1.242a1.9 1.9 0 01-.788.788c-.287.154-.563.225-.96.245zM10 16v8.065c0 5.344.178 10.321.534 14.93a5 5 0 004.526 4.594 96.947 96.947 0 0017.88-.003 5 5 0 004.526-4.595c.356-4.617.534-9.625.534-15.023V16H10zm30-2.282v-1.717a1.995 1.995 0 00-1.998-1.995l-13.409.029h-.827l-13.768-.029A1.995 1.995 0 008 12v1.718c0 .181.002.245.007.275.03.005.094.007.275.007h31.436c.181 0 .245-.002.275-.007.005-.03.007-.094.007-.275zM20 6.05a1 1 0 11-2 0c0-2.481 1.684-3.69 4.497-3.975a15.143 15.143 0 013.006-.001C28.316 2.358 30 3.568 30 6.05a1 1 0 11-2 0c0-1.23-.783-1.791-2.698-1.985A12.945 12.945 0 0024 4a12.9 12.9 0 00-1.302.064C20.783 4.258 20 4.82 20 6.05zm0 30.834a1 1 0 11-2 0V19a1 1 0 012 0v17.883zm10 0a1 1 0 11-2 0V19a1 1 0 012 0v17.883z"
      ></path>
    </svg>
  );
}

export default IconTrashbin2;
