import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../button/Button";
import { Checkbox } from "../checkbox/Checkbox";
import { Layout } from "../layout/Layout";
import { Link } from "../link/Link";
import { Modal } from "../modal/Modal";
import { Panel, PanelWidth } from "../panel/Panel";
import { Section, SectionGutter } from "../section/Section";
import { UserConsentCode } from "../../graphql/schema";
import { getDocumentUrl } from "../../services/getDocumentUrl";

import styles from "./terms-and-conditions.module.scss";

export interface TermsAndConditionsConsent {
  code: UserConsentCode;
  required: boolean;
}
export interface TermsAndConditionsProps {
  isVisible: boolean;
  isUsCountry: boolean;
  consents: TermsAndConditionsConsent[];
  acceptedConsents: TermsAndConditionsConsent[];
  onAccept: (codes: UserConsentCode[]) => Promise<void>;
  onReject: (codes: UserConsentCode[]) => Promise<void>;
}

export const TermsAndConditions: FC<TermsAndConditionsProps> = ({
  isVisible,
  isUsCountry,
  consents,
  acceptedConsents,
  onAccept,
  onReject,
}) => {
  const { t } = useTranslation();

  const [isTermsAccepted, setIsTermsAccepted] = useState(
    Object.values(consents).reduce(
      (res, { code }) => ({ ...res, [code]: false }),
      {} as { [K in UserConsentCode]: boolean },
    ),
  );
  const [isUpdating, setIsUpdating] = useState(false);

  async function handleUpdatePressed(granted: boolean) {
    setIsUpdating(true);

    if (granted) {
      await onAccept(Object.keys(isTermsAccepted) as UserConsentCode[]);
    } else {
      await onReject(Object.keys(isTermsAccepted) as UserConsentCode[]);
    }

    setIsUpdating(false);
  }

  return (
    <Modal isOpen={isVisible} close={() => {}} hideCloseButton>
      <Panel width={PanelWidth.SMALL} contentClassName={styles.content}>
        {isUsCountry && (
          <p className={styles.important}>
            {t(
              "PLEASE READ CAREFULLY. THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION REQUIRING ALL CLAIMS TO BE RESOLVED BY WAY OF BINDING ARBITRATION",
            )}
          </p>
        )}
        {consents.map(({ code }) => {
          switch (code) {
            case UserConsentCode.ATC1:
            case UserConsentCode.ATC2:
            case UserConsentCode.ATC3:
            case UserConsentCode.ATC4:
              return (
                <div key={code}>
                  <Section gutter={SectionGutter.MEDIUM}>
                    {t("Dear affiliate")},
                    <br />
                    <br />
                    {t(
                      "Our terms and conditions for Affiliates have been updated as 1st of October. You must read and agree to the T&C for Affiliates before you can proceed.",
                    )}
                  </Section>
                  <Checkbox
                    checked={isTermsAccepted[code]}
                    onChange={(e) => setIsTermsAccepted({ ...isTermsAccepted, [code]: e.currentTarget.checked })}
                    label={
                      <>
                        {t("I have read and agree to the updated")}{" "}
                        <Link
                          to={"#"}
                          externalUrl={getDocumentUrl("AFFILIATE_AGREEMENT", isUsCountry)}
                          color="LIGHT_GREY"
                          kind="TEXT"
                          weight="SEMI_BOLD"
                        >
                          {t("Affiliate Agreement")}
                        </Link>
                      </>
                    }
                    gutter="MEDIUM"
                  />
                </div>
              );

            case UserConsentCode.WROW:
            case UserConsentCode.WROW2:
              const hasAcceptedDeprecatedConsent = acceptedConsents.some((c) =>
                [UserConsentCode.WROW].includes(c.code),
              );

              return (
                <Checkbox
                  key={code}
                  checked={isTermsAccepted[code]}
                  onChange={(e) => setIsTermsAccepted({ ...isTermsAccepted, [code]: e.currentTarget.checked })}
                  label={
                    hasAcceptedDeprecatedConsent
                      ? t("I reconfirm that I have previously waived my right to a refund")
                      : t(
                          "I agree to the immediate execution of the Affiliate Agreement by SF Suite and I expressly waive my right to a refund",
                        )
                  }
                  gutter="MEDIUM"
                />
              );

            default:
              return null;
          }
        })}

        <Layout wrap="MOBILE">
          <Button
            onClick={() => handleUpdatePressed(false)}
            isLoading={isUpdating}
            shape="RECT"
            kind="SOLID"
            color="LIGHT_RED"
            height="MEDIUM"
            fontSize={16}
            weight="MEDIUM"
            borderRadius="SMALL"
            stretch="MOBILE"
          >
            {t("Reject and log out")}
          </Button>
          <Button
            className={styles.button}
            color="BLUE"
            fontSize={16}
            weight="MEDIUM"
            borderRadius="SMALL"
            stretch="MOBILE"
            disabled={Object.values(isTermsAccepted).some((v) => !v)}
            onClick={() => handleUpdatePressed(true)}
            isLoading={isUpdating}
          >
            {t("Accept")}
          </Button>
        </Layout>
      </Panel>
    </Modal>
  );
};
