import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router";
import { useEffect } from "react";

import { Heading } from "../../components/heading/Heading";
import { Loader } from "../../components/loader/Loader";
import { AsyncImg } from "../../components/async-img/AsyncImg";
import { Link } from "../../components/link/Link";
import { ServiceProductCode, useProductMemborsAccessViewQuery } from "../../graphql/schema";
import { ServiceInfo } from "../../services/constants";
import memborshipImage from "./assets/memborshipImage.png";
import { ViewSection } from "../../components/view-section/ViewSection";

import styles from "./product-membors-access-view.module.scss";

export const ProductMemborsAccessView: React.FC = () => {
  const { type } = useParams<{ type: "basic" | "pro" }>();
  const history = useHistory();
  const query = useProductMemborsAccessViewQuery();
  const isProPurchased = query.data?.services?.find(
    (service) => service.code === ServiceProductCode.MEMBERSHIP_PRO,
  )?.isActive;

  const productCode = isProPurchased ? ServiceProductCode.MEMBERSHIP_PRO : ServiceProductCode.MEMBERSHIP_BASIC;
  const serviceTitle = ServiceInfo[productCode].title;

  const [t] = useTranslation();

  const titleTranslation = t(
    "The Membors membership gives you access to all our courses, trainings, applications, products for one year.",
  );

  const contentTranslation = t(
    "<li>Library of books and articles</li><li>Courses and trainings</li><li>Quarterly virtual events</li><li>Productivity applications</li><li>Fitness and nutrition application - Access to travel portal</li><li>Other members perks and more..</li>",
  );

  // if (!service) {
  //   return (
  //     <NotFoundView text={t("Product not available, you will be redirected back to homepage after few seconds...")} />
  //   );
  // }

  useEffect(() => {
    if (type !== "pro" && isProPurchased) {
      history.replace("./pro");
    }
    if (type === "pro" && !isProPurchased) {
      history.replace("./basic");
    }
  }, [type, isProPurchased, history]);

  if (query.loading) {
    return <Loader />;
  }

  return (
    <div className={styles.productContainer}>
      <ViewSection
        reverse="LARGE_SCREEN"
        image={<AsyncImg src={memborshipImage} className={styles.image} />}
        gap="GAP_40"
      >
        <Heading level={4}>{serviceTitle}</Heading>

        <div className={classNames(styles.description, styles["hidden-mobile"])}>
          <p dangerouslySetInnerHTML={{ __html: titleTranslation }} />
          <ul dangerouslySetInnerHTML={{ __html: contentTranslation }} />
        </div>

        <div className={classNames(styles.description, styles["visible-mobile"])}>
          <p dangerouslySetInnerHTML={{ __html: titleTranslation }} />
          <ul dangerouslySetInnerHTML={{ __html: contentTranslation }} />
        </div>

        <div className={styles.buttons}>
          <Link
            color="DARK_BLUE"
            borderRadius="SMALL"
            weight="BOLD"
            to={""}
            externalUrl={query.data?.me.membershipAccess ?? ""}
          >
            {t("Access product")}
          </Link>
        </div>
      </ViewSection>
    </div>
  );
};
