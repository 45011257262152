import React from "react";

export interface IconLabelProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconLabel: React.FC<IconLabelProps> = ({ hasTitle, className, width = 55, height = 50 }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 55.5 49.76"
    >
      {hasTitle && <title>IconLabel</title>}
      <path
        fill="#fff"
        id="Shape"
        d="M35.46,5.3l6.71,1.53a6.18,6.18,0,0,1,4.7,4.7l3,12.87A3.58,3.58,0,0,1,49,28L32.85,44.14"
      />
      <path
        fill="#ff871c"
        id="Shape-2"
        data-name="Shape"
        d="M29.64,47.35,49,28a3.61,3.61,0,0,0,.86-3.62l-3-12.88a6.18,6.18,0,0,0-4.7-4.7L29.3,3.92a3.58,3.58,0,0,0-3.62.86L.76,29.7a2.59,2.59,0,0,0,0,3.66L13.63,46.23l.37.34A12.16,12.16,0,0,0,29.9,47"
      />
      <path
        fill="#fff"
        id="Shape-3"
        data-name="Shape"
        d="M51.69,15.71A16.06,16.06,0,0,1,48.74,18a1,1,0,0,1-.3.14l-.59-2.64a11.54,11.54,0,0,0,2-1.61,10.28,10.28,0,0,0,3-5.56,5.06,5.06,0,0,0-1.2-4.44c-2.28-2.28-6.75-1.45-10,1.79a8.39,8.39,0,0,0-.75.82,10.12,10.12,0,0,0-2.23,4.74c0,.11,0,.23,0,.3a4.88,4.88,0,0,0,1.23,4.1,4.79,4.79,0,0,0,1.19.83,3.31,3.31,0,0,1,.56.29,1.28,1.28,0,0,1,.23.19,4,4,0,0,1-.79,1.16,3.78,3.78,0,0,1-1.08.74h0A6.94,6.94,0,0,1,38,17.5a7.32,7.32,0,0,1-2.05-5.19,11.13,11.13,0,0,1,.11-1.49,12.58,12.58,0,0,1,2-5,13.17,13.17,0,0,1,1.68-2C44-.45,50.16-1.27,53.44,2S56,11.46,51.69,15.71Z"
      />
      <path
        fill="#d06200"
        id="Shape-4"
        data-name="Shape"
        d="M30.16,46.79a9.59,9.59,0,0,1-1.34,1,12.11,12.11,0,0,1-13.51-.19,11.72,11.72,0,0,1-1.31-1c.08,0,.15,0,.26,0a13.27,13.27,0,0,0,6.94-1.79,2.69,2.69,0,0,1,3.14.11A9.13,9.13,0,0,0,30.16,46.79Z"
      />
      <path
        fill="#ffc542"
        d="M26.09,15.19A3.84,3.84,0,1,0,29.93,19,3.83,3.83,0,0,0,26.09,15.19Zm0,5.63A1.79,1.79,0,1,1,27.88,19,1.76,1.76,0,0,1,26.09,20.82ZM35.87,29H16.65a1.24,1.24,0,0,1-1.23-1.23h0a1.24,1.24,0,0,1,1.23-1.23H35.87a1.24,1.24,0,0,1,1.23,1.23h0A1.22,1.22,0,0,1,35.87,29ZM26,32.31a3.85,3.85,0,1,0,3.84,3.85A3.84,3.84,0,0,0,26,32.31ZM26,38a1.79,1.79,0,1,1,1.79-1.79A1.81,1.81,0,0,1,26,38Zm14-19.11a3.84,3.84,0,0,1-4.34-6.19c.11-.11.24-.19.36-.29a7.26,7.26,0,0,0,2,5.14A7,7,0,0,0,39.93,18.84Zm-1.3-7.28a4.87,4.87,0,0,0,1.23,4.07,4.79,4.79,0,0,0,1.19.83,3.31,3.31,0,0,1,.56.29,1.06,1.06,0,0,1,.2.17,3.79,3.79,0,0,0-3.18-5.36Z"
      />
    </svg>
  );
};
