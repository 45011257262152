import { FC, FormEvent, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { IconLogoDagcoin2 } from "../icon/IconLogoDagcoin2";
import { IconClose } from "../icon/IconClose";
import { IconArrow } from "../icon/IconArrow";
import { Heading } from "../heading/Heading";
import { Button } from "../button/Button";
import { Modal } from "../modal/Modal";
import Field from "../field/Field";
import { Layout } from "../layout/Layout";
import { Link } from "../link/Link";

import styles from "./migration-notifier.module.scss";

export interface MigrationNotifierProps {
  onLinkClick(password: string): Promise<void>;
  isFloating?: boolean;
  isUserVerified: boolean;
  accountEmail?: string | null;
}

export const MigrationNotifier: FC<MigrationNotifierProps> = ({
  isFloating = true,
  onLinkClick,
  accountEmail,
  isUserVerified,
}) => {
  const { t, i18n } = useTranslation();
  const [isNotifierOpen, setIsNotifierOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleModalClose() {
    setPassword("");
    setIsModalOpen(false);
  }

  async function handleModalSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    setIsLoading(true);

    try {
      await onLinkClick(password);

      setPassword("");
    } catch (err) {
      console.warn("Failed to submit grow modal", err);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div
      className={classNames(styles.wrap, {
        [styles["wrap--open"]]: isNotifierOpen,
        [styles["wrap--floating"]]: isFloating,
      })}
    >
      <div className={styles["inner-wrap"]}>
        <div className={classNames(styles.header, { [styles["header--open"]]: isNotifierOpen })}>
          <IconLogoDagcoin2 className={styles["dagcoin-icon"]} />
          <button
            className={classNames(styles["toggle-notification"], {
              [styles["toggle-notification--open"]]: isNotifierOpen,
            })}
            onClick={() => setIsNotifierOpen(!isNotifierOpen)}
          >
            <IconClose
              className={classNames(styles["close-icon"], {
                [styles["close-icon--open"]]: isNotifierOpen,
              })}
            />
            <IconArrow
              fill="#ff871c"
              className={classNames(styles["expand-icon"], {
                [styles["expand-icon--open"]]: isNotifierOpen,
              })}
            />
          </button>
          <Heading className={classNames(styles.title, { [styles["title--open"]]: isNotifierOpen })} level={4} white>
            {t("Your dagcoins have moved!")}
          </Heading>
        </div>
        <div className={classNames(styles.content, { [styles["content--open"]]: isNotifierOpen })}>
          <p className={classNames(styles.text, styles["text--first"])}>
            {t(
              "Important news regarding your dagcoin balance. All your coins have securely moved from SF to Dagcoin Grow",
            )}
          </p>
          <p className={styles.text}>
            {t(
              "To get the access to your Dagcoin Grow account you have to link SF and Dagcoin Grow once, but after that you can easily access your coins directly from Dagcoin Grow.",
            )}
          </p>

          {accountEmail /*&& isUserVerified*/ && (
            <p className={styles.text}> {t("Your linked Grow account email is {{email}}", { email: accountEmail })}</p>
          )}

          {/*!isUserVerified && (
            <p className={styles.text}> {t("You must complete the verification process to link your account.")}</p>
          )*/}

          {/*isUserVerified ? (
            <Button color="ORANGE" borderRadius="SMALL" fontSize={16} onClick={() => setIsModalOpen(true)}>
              {accountEmail ? t("Log In to Dagcoin Grow") : t("Link Dagcoin Grow")}
            </Button>
          ) : (
            <Link
              color="ORANGE"
              borderRadius="SMALL"
              fontSize={16}
              to=""
              externalUrl={`${process.env.REACT_APP_SUCCESSFACTORY_SERVER_PROXY}/${i18n.language}/member/my-profile/verify-account`}
            >
              {t("Go to verification page")}
            </Link>
          )*/}

          {
            <Button color="ORANGE" borderRadius="SMALL" fontSize={16} onClick={() => setIsModalOpen(true)}>
              {accountEmail ? t("Log In to Dagcoin Grow") : t("Link Dagcoin Grow")}
            </Button>
          }
        </div>
      </div>

      <Modal isOpen={isModalOpen} close={handleModalClose}>
        <form onSubmit={handleModalSubmit} className={styles["password-modal"]}>
          <h2>{t("Transaction password")}</h2>
          <p>{t("Please enter your Transaction password to continue")}</p>

          {hasError && <p className={styles.error}>{t("Your transaction password was not correct")}</p>}

          <Field type="password" label={t("Transaction password")} defaultValue={password} onChange={setPassword} />

          <Layout gutter="MEDIUM">
            <Button type="button" color="GREY" borderRadius="SMALL" onClick={handleModalClose}>
              {t("Cancel")}
            </Button>
            <Button type="submit" color="ORANGE" borderRadius="SMALL" isLoading={isLoading}>
              {t("Continue")}
            </Button>
          </Layout>
        </form>
      </Modal>
    </div>
  );
};
