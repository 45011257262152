import React from "react";

export interface IconArrowProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconArrow: React.FC<IconArrowProps> = ({
  hasTitle,
  className,
  fill = "#fff",
  width = 20,
  height = 37,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.14 37.22"
    >
      {hasTitle && <title>IconArrow</title>}
      <path
        fill={fill}
        d="M18.58,37.22a1.53,1.53,0,0,1-1.07-.44L.44,19.71a1.5,1.5,0,0,1,0-2.13L17.58.44A1.5,1.5,0,1,1,19.7,2.56L3.62,18.64l16,16a1.51,1.51,0,0,1,0,2.12A1.53,1.53,0,0,1,18.58,37.22Z"
      />
    </svg>
  );
};
