export interface IconShieldSuccessHollowProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

function IconShieldSuccessHollow({ hasTitle, className, width = 48, height = 48 }: IconShieldSuccessHollowProps) {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      {hasTitle && <title>IconShieldSuccessHollow</title>}
      <path d="M41.211 10.593c.526 6.848.789 12.638.789 17.375 0 3.97-2.843 8.34-7.345 12.257C30.828 43.555 26.358 46 24 46c-2.36 0-6.832-2.42-10.652-5.715C8.842 36.4 6 32.047 6 28.065c0-4.754.264-10.576.79-17.47a5 5 0 013.439-4.373C19.296 3.272 23.399 2 24 2c.61 0 4.723 1.275 13.774 4.222a5 5 0 013.437 4.371zm-1.994.153a3 3 0 00-2.063-2.623C29.117 5.507 24.256 4 24 4c-.247 0-5.119 1.51-13.153 4.123a3 3 0 00-2.063 2.625C8.261 17.594 8 23.368 8 28.065c0 3.263 2.547 7.163 6.654 10.706C18.141 41.78 22.247 44 24 44c1.746 0 5.85-2.246 9.343-5.284C37.45 35.143 40 31.222 40 27.968c0-4.68-.26-10.421-.783-17.222zM23 25.586l7.293-7.293c.943-.943 2.357.471 1.414 1.414l-8 8c-.187.187-.44.308-.707.311a1.012 1.012 0 01-.707-.31l-5-5c-.943-.944.471-2.358 1.414-1.415L23 25.586z"></path>
    </svg>
  );
}

export default IconShieldSuccessHollow;
