import React, { ReactNode } from "react";
import classNames from "classnames";

import { Breakpoints } from "../../services/constants";

import styles from "./layout.module.scss";

export enum LayoutGutterSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export interface LayoutProps {
  wrap?: keyof typeof Breakpoints | boolean;
  stretch?: keyof typeof Breakpoints | boolean;
  gutter?: keyof typeof LayoutGutterSize;
  withSideSpace?: boolean;
  className?: string;
  children?: ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children, wrap, stretch, gutter, withSideSpace, className }) => {
  // class names for the 'wrap' prop
  const wrapClassNames = classNames({
    [styles.wrap]: typeof wrap === "boolean" && wrap,
    [styles["layout--mobile"]]: wrap === "MOBILE",
    [styles["layout--tablet-portrait-up"]]: wrap === "TABLET_PORTRAIT_UP",
    [styles["layout--tablet-landscape-up"]]: wrap === "TABLET_LANDSCAPE_UP",
    [styles["layout--desktop-up"]]: wrap === "DESKTOP_UP",
    [styles["layout--tablet-portrait-max"]]: wrap === "TABLET_PORTRAIT_MAX",
    [styles["layout--tablet-landscape-max"]]: wrap === "TABLET_LANDSCAPE_MAX",
  });

  // class names for the 'stretch' prop
  const stretchClassNames = classNames({
    [styles.stretch]: typeof stretch === "boolean",
    [styles["stretch--mobile"]]: stretch === "MOBILE",
    [styles["stretch--portrait-up"]]: stretch === "TABLET_PORTRAIT_UP",
    [styles["stretch--landscape-up"]]: stretch === "TABLET_LANDSCAPE_UP",
    [styles["stretch--desktop-up"]]: stretch === "DESKTOP_UP",
    [styles["stretch--portrait-max"]]: stretch === "TABLET_PORTRAIT_MAX",
    [styles["stretch--landscape-max"]]: stretch === "TABLET_LANDSCAPE_MAX",
  });

  // class names for the 'stretch' prop
  const gutterClassNames = classNames({
    [styles["gutter--small"]]: gutter === "SMALL",
    [styles["gutter--medium"]]: gutter === "MEDIUM",
    [styles["gutter--semi-large"]]: gutter === "SEMI_LARGE",
    [styles["gutter--large"]]: gutter === "LARGE",
    [styles["gutter--extra-large"]]: gutter === "EXTRA_LARGE",
  });

  return (
    <div
      className={classNames(
        styles.layout,
        wrapClassNames,
        stretchClassNames,
        gutterClassNames,
        {
          [styles.space]: withSideSpace,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
