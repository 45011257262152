import React from "react";

import { Breakpoints } from "../../services/constants";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";

import styles from "./stats-listing.module.scss";

export enum PanelGap {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LAREGE",
}

type PickKey<T, K extends keyof T> = Extract<keyof T, K>;

type PickedBreakpointKeys = PickKey<typeof Breakpoints, "TABLET_PORTRAIT_UP" | "TABLET_LANDSCAPE_UP" | "DESKTOP_UP">;

export interface StatsListingProps {
  stats: { value: string | number; label: string }[];
  gap?: keyof typeof PanelGap;
  horizontal?: PickedBreakpointKeys;
  width?: number;
}

export const StatsListing: React.FC<StatsListingProps> = ({ stats, gap, horizontal, width }) => {
  // get window dimensions
  const { isTabletPortraitOrBigger, isTabletLandscapeOrBigger, isDesktopUp } = useWindowDimensionsContext();

  // determin on what screen size to apply css breakpoints
  const getGridRange = (gridRange: keyof typeof Breakpoints, count: number) => {
    switch (gridRange) {
      case "TABLET_PORTRAIT_UP":
        return isTabletPortraitOrBigger ? `repeat(${count}, 1fr)` : undefined;

      case "TABLET_LANDSCAPE_UP":
        return isTabletLandscapeOrBigger ? `repeat(${count}, 1fr)` : undefined;

      case "DESKTOP_UP":
        return isDesktopUp ? `repeat(${count}, 1fr)` : undefined;

      default:
        return `repeat(${count}, 1fr)`;
    }
  };

  return (
    <div
      className={styles.wrap}
      style={{
        gridTemplateColumns: horizontal ? getGridRange(horizontal, stats.length) : `repeat(${stats.length}, 1fr)`,
        gap: gap && handlePanelGap(gap),
        width: width,
      }}
    >
      {stats.map((stat, index) => (
        <div key={index} className={styles.panel}>
          <div className={styles.value}>{stat.value}</div>
          <div className={styles.label}>{stat.label}</div>
        </div>
      ))}
    </div>
  );
};

function handlePanelGap(gap: keyof typeof PanelGap) {
  switch (gap) {
    case "SMALL":
      return "10px";
    case "MEDIUM":
      return "20px";
    case "LARGE":
      return "40px";
    default:
      return "unset";
  }
}
