export interface IconEyeProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

function IconEye({ hasTitle, className, fill = "#7789AD", width = 25, height = 16 }: IconEyeProps) {
  return (
    <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 16">
      {hasTitle && <title>IconEye</title>}
      <path
        fill={fill}
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M235.755 270.87c3.123-3.903 7.052-5.87 11.748-5.87s8.623 1.968 11.744 5.872a3.39 3.39 0 01.002 4.258c-3.112 3.903-7.04 5.87-11.746 5.87-4.705 0-8.635-1.966-11.75-5.868a3.39 3.39 0 01.002-4.262zm.826.61a2.421 2.421 0 00-.002 3.044c2.924 3.66 6.552 5.476 10.924 5.476s7.999-1.816 10.919-5.478a2.421 2.421 0 00-.002-3.041c-2.928-3.664-6.554-5.481-10.917-5.481-4.363 0-7.99 1.816-10.922 5.48zm12.322-2.603a1.933 1.933 0 00-.359 1.123c0 1.105.933 2 2.084 2 .423 0 .827-.121 1.169-.344.306-.2.726-.038.802.31.074.338.112.684.112 1.034 0 2.761-2.332 5-5.208 5-2.877 0-5.209-2.239-5.209-5s2.332-5 5.209-5c.365 0 .725.036 1.077.107a.495.495 0 01.323.77zm-1.4.123c-2.302 0-4.167 1.79-4.167 4s1.865 4 4.167 4c2.3 0 4.166-1.79 4.166-4 0-.057 0-.113-.003-.17-.33.112-.68.17-1.038.17-1.726 0-3.125-1.343-3.125-3 0-.344.06-.68.176-.996a4.426 4.426 0 00-.176-.004z"
        transform="translate(-1035 -455) translate(80 90) translate(720 100)"
      ></path>
    </svg>
  );
}

export default IconEye;
