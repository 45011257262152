import classNames from "classnames";
import React, { useState, useRef } from "react";

import { Button } from "../button/Button";
import IconArrowTriangle from "../icon/IconArrowTriangle";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { isFirstPeriodDiscounted } from "../../services/isFirstPeriodDiscounted";
import { CurrencyCode, SubscriptionType } from "../../graphql/schema";
import Price from "../price/Price";

import styles from "./productSelector.module.scss";

export interface ProductSelectorOption {
  name: string;
  value: string;
  fullPrice: string;
  fullPriceNext?: string | null;
  discountedPrice?: string | null;
  discountedPriceNext?: string | null;
  currencyCode: keyof typeof CurrencyCode;
  comingSoon?: boolean;
}

export interface ProductSelectorProps {
  className?: string;
  options: ProductSelectorOption[];
  value: ProductSelectorOption["value"];
  onSelect: (value: ProductSelectorOption["value"]) => void;
  displayPerMonth?: boolean;
}

export const ProductSelector: React.FC<ProductSelectorProps> = ({
  className,
  options,
  value,
  onSelect,
  displayPerMonth,
}) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(ref, () => setIsSelectorOpen(false));

  const handleOnSelectEvent = (value: string) => {
    onSelect(value);
    setIsSelectorOpen(false);
  };

  const selectedProduct = options.find((p) => p.value === value) || options[0];

  const mappedProducts = options.map((p) => (
    <Button
      className={styles["selected-item-button--selector-item"]}
      color="DARK_BLUE"
      onClick={() => handleOnSelectEvent(p.value)}
      key={p.name}
    >
      <span className={styles["selected-item--name"]}>{p.name}</span>
      <p className={classNames(styles["selected-item--price"], "notranslate")}>
        <Price
          price={getCurrentPrice(p)}
          currency={p.currencyCode}
          period={displayPerMonth ? SubscriptionType.MONTHLY : undefined}
          hideSubUnits
          hideSign={p?.comingSoon}
          comingSoon={p?.comingSoon}
        />
        {isFirstPeriodDiscounted(p) && "*"}
      </p>
    </Button>
  ));

  return (
    <>
      <div className={classNames(styles["selector-wrapper"], className)} ref={ref}>
        <Button
          className={styles["selected-item-button"]}
          color="DARK_BLUE"
          onClick={() => setIsSelectorOpen(!isSelectorOpen)}
        >
          <span className={styles["selected-item--name"]}>{selectedProduct.name}</span>
          <p className={classNames(styles["selected-item--price"], "notranslate")}>
            <Price
              price={getCurrentPrice(selectedProduct)}
              currency={selectedProduct.currencyCode}
              period={displayPerMonth ? SubscriptionType.MONTHLY : undefined}
              hideSubUnits
              hideSign={selectedProduct?.comingSoon}
              comingSoon={selectedProduct?.comingSoon}
            />
            {isFirstPeriodDiscounted(selectedProduct) && "*"}
            <IconArrowTriangle
              className={classNames(styles["arrow-down"], { [styles["arrow-down--reverse"]]: isSelectorOpen })}
            />
          </p>
        </Button>
        <div className={classNames(styles.selector, { [styles["selector--open"]]: isSelectorOpen })}>
          {mappedProducts}
        </div>
      </div>
    </>
  );
};

function getCurrentPrice(params: { fullPrice: string; discountedPrice?: string | null }) {
  return params.discountedPrice || params.fullPrice;
}
