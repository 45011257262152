export enum Height {
  EXTRA_SMALL = "EXTRA_SMALL",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  AUTO = "AUTO",
}

export enum Shape {
  RECT = "RECT",
  ROUND = "ROUND",
}

export enum Color {
  BLUE = "BLUE",
  LIGHT_BLUE = "LIGHT_BLUE",
  LIGHT_BLUE_2 = "LIGHT_BLUE_2",
  DARK_BLUE = "DARK_BLUE",
  DARK_BLUE_2 = "DARK_BLUE_2",
  GREY = "GREY",
  ORANGE = "ORANGE",
  YELLOW = "YELLOW",
  GREEN = "GREEN",
  LIGHT_RED = "LIGHT_RED",
  WHITE = "WHITE",
  LIGHT_GREY = "LIGHT_GREY",
}

export enum FontWeight {
  LIGHT = "LIGHT",
  NORMAL = "NORMAL",
  MEDIUM = "MEDIUM",
  SEMI_BOLD = "SEMI_BOLD",
  BOLD = "BOLD",
}

export enum BorderRadius {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
}
