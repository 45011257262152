export interface IconLinkProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconLink: React.FC<IconLinkProps> = ({ hasTitle, className, fill, width = 48, height = 48 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 13" className={className}>
      {hasTitle && <title>IconLink</title>}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1851 2.00702C9.12934 0.951213 7.41753 0.951213 6.36172 2.00702L5.22502 3.14373C5.02526 3.34348 4.70139 3.34348 4.50163 3.14373C4.30187 2.94397 4.30187 2.62009 4.50163 2.42034L5.63833 1.28363C7.09366 -0.171692 9.45321 -0.171694 10.9085 1.28363C12.3639 2.73896 12.3639 5.09851 10.9085 6.55384L9.77183 7.69054C9.57208 7.8903 9.2482 7.8903 9.04845 7.69054C8.84869 7.49078 8.84869 7.16691 9.04845 6.96715L10.1851 5.83045C11.241 4.77464 11.241 3.06283 10.1851 2.00702ZM7.80858 4.38361C8.00834 4.58337 8.00834 4.90724 7.80858 5.107L4.91503 8.00056C4.71527 8.20032 4.3914 8.20032 4.19164 8.00056C3.99188 7.8008 3.99188 7.47693 4.19164 7.27717L7.0852 4.38361C7.28495 4.18386 7.60883 4.18386 7.80858 4.38361ZM5.63831 10.3772C4.5825 11.433 2.87069 11.433 1.81488 10.3772C0.759074 9.32138 0.759074 7.60957 1.81488 6.55376L2.95159 5.41706C3.15135 5.2173 3.15135 4.89343 2.95159 4.69367C2.75183 4.49391 2.42796 4.49391 2.2282 4.69367L1.09149 5.83037C-0.363831 7.2857 -0.363832 9.64525 1.09149 11.1006C2.54682 12.5559 4.90637 12.5559 6.3617 11.1006L7.4984 9.96387C7.69816 9.76412 7.69816 9.44024 7.4984 9.24049C7.29864 9.04073 6.97477 9.04073 6.77501 9.24049L5.63831 10.3772Z"
        fill={fill}
      />
    </svg>
  );
};
