import classNames from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ViewSection } from "../../../components/view-section/ViewSection";
import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { Heading } from "../../../components/heading/Heading";
import { SubTitle } from "../../../components/sub-title/SubTitle";
import { TextAccent } from "../../../components/text-accent/TextAccent";
import awenturesImage from "./assets/awenturesImage.png";

import styles from "./product-membors-featured-section.module.scss";

export interface ProductMemborsFeaturedSectionProps {
  className?: string;
}

export const ProductMemborsFeaturedSection: FC<ProductMemborsFeaturedSectionProps> = ({ className }) => {
  const [t] = useTranslation();

  return (
    <ViewSection reverse="LARGE_SCREEN" image={<AsyncImg src={awenturesImage} />} gap="GAP_40" gutter={100}>
      <Heading className={styles.title} gutter={8} level={4}>
        {t("Featured in Membors.com")}
      </Heading>
      <SubTitle withBottomLine>{t("Awentures.com")}</SubTitle>

      <div className={styles.content}>
        <strong>{t("Awentures is the best-kept secret in travel")}</strong>

        <p>
          {t(
            "Dive into Awentures, your ultimate gateway to unparalleled travel experiences. Explore our vast database of resorts and hotels, many of which you'll recognize from popular travel sites, but here, they come with up to a staggering 75% discount!",
          )}
        </p>
        <p>
          {t(
            "Beyond accommodations, embark on majestic cruises, secure rental cars, engage in thrilling activities and book flights.",
          )}
        </p>

        <div className={styles["pricing-note"]}>
          <p>{t("Regular price: 399€ per year")}</p>
          <p
            className={styles["highlighted"]}
            color="ORANGE"
            dangerouslySetInnerHTML={{ __html: t("Included for <strong>Free</strong> with Membors.com membership") }}
          />
        </div>
      </div>
    </ViewSection>
  );
};
