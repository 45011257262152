import { useTranslation } from "react-i18next";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { FC, ReactNode } from "react";
import { useReactiveVar } from "@apollo/client";

import IconSignOut from "../../components/icon/IconSignOut";
import { Navbar, NavBarLink } from "../../components/navbar/Navbar";
import { Titlebar, TitlebarType } from "../../components/titlebar/Titlebar";
// import { DigitalProductsType } from "../../graphql/schema";
import { HiddenNavbarPathnames, Routes } from "../../services/constants";
import { EmailConfirmationView } from "../email-confirmation-view/EmailConfirmationView";
import NotFoundView from "../not-found-view/NotFoundView";
// import BankCodeAccessView from "./bank-code-access-view/BankCodeAccessView";
import BecomeAffiliateView from "./become-affiliate-view/BecomeAffiliateView";
import DigitalProductsView from "./digital-products-view/DigitalProductsView";
import PaymentMethodsView from "./payment-methods-view/PaymentMethodsView";
import ProfileView from "./profile-view/ProfileView";
import GrowLinkingAccountView from "./grow-linking-account-view/GrowLinkingAccountView";
import SubscriptionListView from "./subscriptions-view/SubscriptionListView";
import { InvoicesListView } from "./invoices-list-view/InvoicesListView";
import { GiftCardView } from "../gift-card-view/GiftCardView";
import { DriveForFiveView } from "./drive-for-five-view/DriveForFiveView";
import { DriveForFiveMembershipView } from "./drive-for-five-view/drive-for-five-membership-view/DriveForFiveMembershipView";
import { isUserFromUs } from "../../services/isUserFromUs";
import { useAccountViewQuery } from "../../graphql/schema";
import { shouldRedirectToBecomeAffiliateViewVar } from "../../cache/shouldRedirectToBecomeAffiliateView";
import { NewsListView } from "./news/newsListView";

export interface AccountViewMenuItem {
  icon: ReactNode;
  label: string;
  to: string | Routes;
  isDisabled?: boolean;
  isExternal?: boolean;
}
export interface AccountViewProps {
  accountMenuItems: AccountViewMenuItem[];
}

export const AccountView: FC<AccountViewProps> = ({ accountMenuItems }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const query = useAccountViewQuery();
  const isUsCountry = isUserFromUs(query.data?.me);
  const shouldRedirectToBecomeAffiliateView = useReactiveVar(shouldRedirectToBecomeAffiliateViewVar);

  const viewWithPrimaryTitleBar = pathname.startsWith(`${Routes.SUBSCRIPTION_LIST}/details`);

  const viewWithTertiaryTitleBar = pathname.includes("/account/email-confirmation");

  const viewWithHiddenNavbar = Object.values(HiddenNavbarPathnames).some((v) => v === pathname || pathname.includes(v));

  const getTitleFromPath = (path: string) => {
    switch (path) {
      case Routes.PRODUCTS:
        return t("Digital products");
      case Routes.NEWS_LIST:
        return t("News");
      case Routes.INVOICES_LIST:
        return t("Invoices");
      case Routes.SUBSCRIPTION_LIST:
        return t("Subscriptions");
      case Routes.PROFILE:
        return t("My Profile");
      case Routes.AFFILIATE:
        return t("Become an affiliate");
      case Routes.DRIVE_FOR_FIVE:
        return t("Drive for Five");
    }
  };

  function isRouteEnabled(route: string) {
    return !!accountMenuItems.find((item) => item.to === route && item.isDisabled !== true);
  }

  const renderTopBar = () => {
    if (viewWithPrimaryTitleBar || viewWithHiddenNavbar) {
      return;
    }

    if (viewWithTertiaryTitleBar) {
      return <Titlebar type={TitlebarType.TERTIARY} imageUrl="/images/emailConfirmation.png" />;
    }

    return (
      <Navbar title={t("My account").toUpperCase()} caption={getTitleFromPath(pathname)}>
        {accountMenuItems.map((item, index) => (
          <NavBarLink
            key={index}
            to={item.to}
            title={item.label}
            icon={item.icon}
            isDisabled={item.isDisabled}
            external={item.isExternal}
          />
        ))}

        <NavBarLink to="/logout" title={t("Log Out")} icon={<IconSignOut />} />
      </Navbar>
    );
  };

  if (isUsCountry && pathname === Routes.GIFT_CARD) {
    console.log(isUsCountry && pathname === Routes.GIFT_CARD);

    return <Redirect to="/" />;
  }

  return (
    <>
      {shouldRedirectToBecomeAffiliateView && <Redirect to={Routes.AFFILIATE} />}
      {renderTopBar()}
      <Switch>
        {isRouteEnabled(Routes.PRODUCTS) && <Route exact path={Routes.PRODUCTS} component={DigitalProductsView} />}
        {isRouteEnabled(Routes.NEWS_LIST) && <Route exact path={Routes.NEWS_LIST} component={NewsListView} />}
        {isRouteEnabled(Routes.DRIVE_FOR_FIVE) && (
          <Route exact path={Routes.DRIVE_FOR_FIVE} component={DriveForFiveView} />
        )}
        {isRouteEnabled(Routes.DRIVE_FOR_FIVE) && (
          <Route exact path={`${Routes.DRIVE_FOR_FIVE}/:id`} component={DriveForFiveMembershipView} />
        )}
        {isRouteEnabled(Routes.INVOICES_LIST) && (
          <Route exact path={Routes.INVOICES_LIST} component={InvoicesListView} />
        )}
        {isRouteEnabled(Routes.SUBSCRIPTION_LIST) && (
          <Route exact path={Routes.SUBSCRIPTION_LIST} component={SubscriptionListView} />
        )}
        {isRouteEnabled(Routes.PROFILE) && <Route exact path={Routes.PROFILE} component={ProfileView} />}

        <Route exact path={Routes.GROW_LINKING} component={GrowLinkingAccountView} />

        {isRouteEnabled(Routes.AFFILIATE) && <Route exact path={Routes.AFFILIATE} component={BecomeAffiliateView} />}
        {isRouteEnabled(Routes.PAYMENT_METHODS) && (
          <Route path={Routes.PAYMENT_METHODS} component={PaymentMethodsView} />
        )}
        {isRouteEnabled(Routes.PAYMENT_METHODS) && <Route path={Routes.GIFT_CARD} component={GiftCardView} />}
        {/* <Route
          exact
          path={`${Routes.ACCESS}/${DigitalProductsType.FOREX.toLowerCase()}`}
          component={BankCodeAccessView}
        /> */}
        {/* <Route exact path={`${Routes.ACCESS}/:productName(bank-connect|bank-beyond)`} component={BankCodeAccessView} /> */}
        <Route exact path={Routes.EMAIL_CONFIRMATION} component={EmailConfirmationView} />
        <Route component={NotFoundView} />
      </Switch>
    </>
  );
};
