import React from "react";
import classNames from "classnames";

import { ServiceProductCode } from "../../graphql/schema";
import { ServiceInfo } from "../../services/constants";
import BusinessFeeIcon from "./assets/SF_course.png";
import SfFlameIcon from "./assets/SF_Flame_Icon.svg";
import ForexInsidersIcon from "./assets/Forex_insiders.svg";
import ForexProIcon from "./assets/Forex_pro.svg";
import TheViewIcon from "./assets/The_view.svg";
import MemborsIcon from "./assets/MemborsIcon.svg";

export interface ServiceIconProps {
  productCode: keyof typeof ServiceProductCode;
  className?: string;
  forexLogoClassName?: string;
  bankLogoClassName?: string;
  businessFeeLogoClassName?: string;
  width?: number;
  height?: number;
}

export const ServiceIcon: React.FC<ServiceIconProps> = ({
  productCode,
  className,
  forexLogoClassName,
  bankLogoClassName,
  businessFeeLogoClassName,
  width,
  height,
}) => {
  const logos: Record<ServiceProductCode, string> = {
    // [DigitalProductsType.DAGPAY]: "/images/Dagpay.svg",
    // [DigitalProductsType.BANK_FOREX]: "/images/Forex_insiders.svg",
    // SF_TRAINING: "/images/SF_course.png",
    FOREX: ForexInsidersIcon,
    FOREX_PRO: ForexProIcon,
    BANK_BASIC: SfFlameIcon,
    BANK_FULL: SfFlameIcon,
    BUSINESS_FEE_BASIC: BusinessFeeIcon,
    BUSINESS_FEE_PRO: BusinessFeeIcon,
    VIEW_BASIC: TheViewIcon,
    VIEW_STANDARD: TheViewIcon,
    VIEW_SUPER: TheViewIcon,
    VIEW_ULTRA: TheViewIcon,
    VIEW_ULTRA_PLUS: TheViewIcon,
    MEMBERSHIP_BASIC: MemborsIcon,
    MEMBERSHIP_PRO: MemborsIcon,
  };

  const serviceInfo = ServiceInfo[productCode];

  // custom classNames for each logo
  const customClassNames = [
    productCode.startsWith("FOREX") && forexLogoClassName,
    productCode.startsWith("BUSINESS_FEE") && businessFeeLogoClassName,
    productCode.startsWith("BANK") && bankLogoClassName,
  ];

  return (
    <img
      className={classNames(className, customClassNames)}
      src={logos[productCode]}
      alt={`${serviceInfo.title} logo`}
      width={width}
      height={height}
    />
  );
};
