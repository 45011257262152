import React, { useLayoutEffect, useRef } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { IconGift } from "../icon/IconGift";
import { Tooltip } from "../tooltip/Tooltip";
import { Heading } from "../heading/Heading";
import { TextAccent } from "../text-accent/TextAccent";
import { IconUser2 } from "../icon/IconUser2";
import { DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT } from "../../services/constants";
import IconPercentage from "../icon/IconPercentage";

import styles from "./monthly-subscriptions-tag.module.scss";

export enum MonthStatus {
  PAST = "#1B1F3B",
  FREE = "#3DD598",
  CURRENT = "#FF871C",
  UPCOMING = "#7789AD",
}

export enum SubscriptionStatusColor {
  GREEN = "#3DD598",
  ORANGE = "#FF871C",
  DISCOUNT = "#ffc542",
  GREY = "#CFD0DF",
}

export interface Subscription {
  month: number;
  year: number;
  freeExtension: boolean;
  discountedExtension: boolean;
  current: boolean;
  records: {
    id: string;
    referral: { firstName?: string | null; lastName?: string | null };
    products: { name: string }[];
  }[];
}

export interface MonthlySubscriptionsTagProps {
  productName: string;
  subscriptions: Subscription[];
}

export const MonthlySubscriptionsTag: React.FC<MonthlySubscriptionsTagProps> = ({ productName, subscriptions }) => {
  const { t } = useTranslation();

  const wrapRef = useRef<HTMLDivElement>(null);

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  // center the scroll area so that the current mont is in spotlight
  useLayoutEffect(() => {
    if (!wrapRef.current) {
      return;
    }

    wrapRef.current.scrollLeft = (wrapRef.current.scrollWidth - wrapRef.current.clientWidth) / 2;
  }, [subscriptions]);

  // current month as number

  // handle user icon color
  const handleSlotColor = (isFree: boolean, isDiscounted: boolean, isCurrent: boolean) => {
    if (isCurrent) {
      return SubscriptionStatusColor.ORANGE;
    }

    if (isFree) {
      return SubscriptionStatusColor.GREEN;
    }

    if (isDiscounted) {
      return SubscriptionStatusColor.DISCOUNT;
    }

    return SubscriptionStatusColor.GREY;
  };

  const currentMonthIndex = subscriptions.findIndex((row) => row.current);

  return (
    <div className={styles.wrap} ref={wrapRef}>
      {subscriptions.map((data, index) => {
        const translatedMonth = months[data.month - 1];
        const isFree = data?.freeExtension ?? false;
        const isDiscounted = data?.discountedExtension ?? false;
        const usersCount = (data?.records ?? []).length;
        const isPast = index < currentMonthIndex;
        const isFuture = index > currentMonthIndex;

        return (
          <div className={styles["tooltip-wrap"]} key={`${data.year}-${data.month}`}>
            <Tooltip
              className={styles.tooltip}
              arrowClassName={styles["tooltip-arrow"]}
              openOnHover
              disabled={!data || data.current}
              reference={
                <div
                  className={classNames(styles.content, {
                    [styles["content--free-month"]]: isFree && !data.current,
                    [styles["content--discounted-month"]]: isDiscounted && !isFree && !data.current,
                    [styles["content--current-month"]]: data.current,
                  })}
                >
                  {isFree && <IconGift fill="#3DD598" className={styles["gift-icon"]} />}
                  {isDiscounted && !isFree && (
                    <IconPercentage
                      fill={SubscriptionStatusColor.DISCOUNT}
                      width={20}
                      height={20}
                      className={styles["gift-icon"]}
                    />
                  )}
                  <IconUser2
                    className={classNames(styles["subscription-icon"])}
                    width={20}
                    height={20}
                    fill={handleSlotColor(isFree, isDiscounted, data.current)}
                  />
                  <div
                    className={classNames(styles.month, {
                      [styles["month--past"]]: isPast,
                      [styles["month--current"]]: data.current,
                      [styles["month--future"]]: isFuture,
                    })}
                  >
                    {translatedMonth.slice(0, 3)}
                    <span className={styles.year}>{data.year}</span>
                  </div>
                  <div
                    className={classNames(styles["subscription-count"], {
                      [styles["subscription-count--past"]]: !isFree && !isDiscounted && isPast,
                      [styles["subscription-count--future"]]: !isFree && !isDiscounted && isFuture,
                      [styles["subscription-count--current"]]: data.current,
                      [styles["subscription-count--free"]]: isFree && !data.current,
                      [styles["subscription-count--discounted"]]: isDiscounted && !data.current,
                    })}
                  >
                    {usersCount}
                  </div>
                </div>
              }
            >
              <div className={styles["tooltip-content"]}>
                {isFree && <IconGift className={styles["tooltip-icon"]} fill="#3DD598" />}
                {!isFree && isDiscounted && (
                  <IconPercentage
                    className={styles["tooltip-icon"]}
                    width={22}
                    height={22}
                    fill={SubscriptionStatusColor.DISCOUNT}
                  />
                )}
                {!isFree && !isDiscounted && (
                  <IconUser2 className={styles["tooltip-icon"]} width={16} height={16} fill="#7789AD" />
                )}
                <Heading className={styles["tooltip-title"]} level={6}>
                  <TextAccent
                    type={isFree ? "GREEN" : isDiscounted ? "YELLOW" : "WHITE"}
                    dangerouslySetInnerHTML={{
                      __html: t("<strong>{{usersCount}} friends</strong> in {{month}}", {
                        usersCount,
                        month: translatedMonth,
                      }),
                    }}
                  />
                </Heading>
                {isFree && (
                  <p className={styles["tooltip-text"]}>
                    {t(
                      "Congratulations! You had more than {{requiredUsersCount}} friends in {{month}} and earned one month for free for yourself! Great job!",
                      { requiredUsersCount: DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT, month: translatedMonth },
                    )}{" "}
                    🥳
                  </p>
                )}

                {isDiscounted && (
                  <p className={styles["tooltip-text"]}>
                    {t(
                      "Congratulations! you have made more than {{requiredUsersCount}} friends with {{productName}} subscription in the {{month}} and earned discount for yourself!",
                      {
                        month: translatedMonth,
                        requiredUsersCount: DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT,
                        productName: getUniqueProductNames(data.records, productName).join(` ${t("or")} `),
                      },
                    )}{" "}
                    🥳
                  </p>
                )}

                {!isFree && !isDiscounted && (
                  <p className={styles["tooltip-text"]}>
                    {t(
                      "In {{month}} you had {{usersCount}} active friends who had {{productName}} active subscription. You need {{requiredUsersCount}} friends in total, to get the product free for yourself!",
                      {
                        month: translatedMonth,
                        usersCount,
                        requiredUsersCount: DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT,
                        productName: getUniqueProductNames(data.records, productName).join(` ${t("or")} `),
                      },
                    )}
                  </p>
                )}
                <br />
                {usersCount > 0 && (
                  <>
                    <p className={styles["subscribers-label"]}>
                      <strong>{t("Your active friends were:")}</strong>
                    </p>
                    {(data?.records ?? []).map(({ id, referral }, index) => (
                      <div className={styles.subscribers} key={id}>
                        {index + 1}. {[referral.firstName, referral.lastName].filter((n) => n).join(" ")}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

function getUniqueProductNames(records: { products: { name: string }[] }[], defaultName: string) {
  const productNames = records.reduce((res, row) => [...res, ...row.products.map((p) => p.name)], [] as string[]);

  if (productNames.length === 0) {
    productNames.push(defaultName);
  }

  return Array.from(new Set(productNames));
}
