const Documents = {
  PRIVACY_POLICY: "SF_Suite_Privacy_Policy.pdf",
  COOKIE_POLICY: "SF_Suite_Cookie_policy.pdf",
  GENERAL_TERMS_AND_CONDITIONS: "SF_Suite_General_Terms.pdf",
  TERMS_OF_USE: "SF_Suite_Terms_of_Use.pdf",
  AFFILIATE_AGREEMENT: "SF_Suite_Affiliate_Agreement.pdf",
};

export function getDocumentUrl(type: keyof typeof Documents, isUsCountry: boolean) {
  const folder = isUsCountry ? "/files/us/" : "/files/";

  return ` ${folder}${Documents[type]}`;
}
