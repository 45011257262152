import React from "react";

export interface IconYellowDotsProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconYellowDots: React.FC<IconYellowDotsProps> = ({ hasTitle, className, width = 52, height = 62 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 52 62" className={className}>
      {hasTitle && <title>IconYellowDots</title>}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-266 -4100)">
          <g transform="translate(0 3611)">
            <g transform="translate(266 489)">
              <circle cx="17.5" cy="17.5" r="17.5" fill="#FFC542"></circle>
              <circle cx="43.5" cy="52.5" r="6.5" stroke="#FFC542" strokeWidth="4" opacity="0.98"></circle>
              <circle cx="12" cy="57" r="5" fill="#FFC542"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
