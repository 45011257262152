import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";

import { Heading } from "../heading/Heading";
import { IconClose2 } from "../icon/IconClose2";
import { IconMenuBurgerThick } from "../icon/IconMenuBurgerThick";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";

import styles from "./navbar.module.scss";

export interface NavbarProps {
  title: string;
  caption?: string;
  children?: ReactNode;
}

export interface NavLinkProps {
  icon: React.ReactNode;
  title: string;
  to: string;
  external?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const Navbar: React.FC<NavbarProps> = ({ title, caption, children }) => {
  const [open, setOpen] = useState(false);
  const { isTabletLandscapeOrBigger } = useWindowDimensionsContext();

  if (!isTabletLandscapeOrBigger) {
    return (
      <>
        <div className={classNames(styles["navbar--mobile"], styles["navbar-background"])}>
          <Heading level={2} className={styles.title} center white>
            {title}
          </Heading>
          {caption && <p className={styles.caption}>{caption}</p>}
          <div className={styles["menu-button"]} onClick={() => setOpen(!open)}>
            <div className={styles["icon-wrap"]}>
              {open ? <IconClose2 className={styles.icon} /> : <IconMenuBurgerThick className={styles.icon} />}
            </div>
          </div>
        </div>
        {open && (
          <div className={classNames(styles["dropdown-wrap"])} onClick={() => setOpen(false)}>
            {children}
          </div>
        )}
      </>
    );
  }

  return <div className={classNames(styles.navbar, styles["navbar-background"])}>{children}</div>;
};

export const NavBarLink: React.FC<NavLinkProps> = ({ icon, title, to, external, isDisabled, onClick }) => {
  if (external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" className={styles.link} onClick={onClick}>
        <span className={styles["link-icon"]}>{icon}</span>
        <div>{title}</div>
      </a>
    );
  }

  if (isDisabled) {
    return (
      <span className={classNames(styles.link, styles["link--disabled"])}>
        <span className={styles["link-icon"]}>{icon}</span>
        <div>{title}</div>
        <div className={styles.decoration} />
      </span>
    );
  }

  return (
    <NavLink to={to} className={styles.link} activeClassName={styles["link--active"]} onClick={onClick}>
      <span className={styles["link-icon"]}>{icon}</span>
      <div>{title}</div>
      <div className={styles.decoration} />
    </NavLink>
  );
};
