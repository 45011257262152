import React from "react";

export interface IconPlayButtonProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconPlayButton: React.FC<IconPlayButtonProps> = ({ hasTitle, className, width = 50, height = 50 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 50 50" className={className}>
      {hasTitle && <title>IconPlayButton</title>}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-486 -600)">
          <g transform="translate(0 80)">
            <g transform="translate(150 140)">
              <g transform="translate(0 377)">
                <g transform="translate(336 3)">
                  <circle cx="25" cy="25" r="25" fill="#FFF"></circle>
                  <path
                    fill="#FF871C"
                    d="M27.87 19.217l7.923 11.094a1.068 1.068 0 01-.87 1.689H19.077a1.068 1.068 0 01-.87-1.69l7.925-11.093a1.068 1.068 0 011.738 0z"
                    transform="rotate(90 27 25)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
