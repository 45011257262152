import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Benefit } from "../../../components/benefit/Benefit";
import IconBankNote from "../../../components/icon/IconBankNote";
import IconPieChart from "../../../components/icon/IconPieChart";
import IconShieldSuccessHollow from "../../../components/icon/IconShieldSuccessHollow";
import { Layout } from "../../../components/layout/Layout";
import { CurrencySign } from "../../../components/price/Price";
import { Section } from "../../../components/section/Section";
import { Separator, SeparatorGutter } from "../../../components/separator/Separator";
import { CurrencyCode } from "../../../graphql/schema";

import styles from "./benefits-section.module.scss";

export interface BenefitsSectionProps {
  minAmount: number;
  maxAmount: number;
  currencyCode: keyof typeof CurrencyCode;
}

export const BenefitsSection: FC<BenefitsSectionProps> = ({ minAmount, maxAmount, currencyCode }) => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <Section gutter={0} className={styles.wrap} center withSpace>
      <Layout className={styles.content} wrap="TABLET_PORTRAIT_MAX">
        <Separator type="SECONDARY" gutter={SeparatorGutter.EXTRA_LARGE} />
        <Benefit
          className={styles.benefit}
          icon={<IconBankNote width={70} height={47} />}
          title={t("From {{minAmount}}{{sign}} to {{maxAmount}}{{sign}}", {
            minAmount,
            maxAmount,
            sign: CurrencySign[currencyCode],
          })}
        >
          {t(
            "You set the value for the gift card. Gift cards can start from {{minAmount}}{{sign}} up to {{maxAmount}}{{sign}} per card.",
            {
              minAmount,
              maxAmount,
              sign: CurrencySign[currencyCode],
            },
          )}
        </Benefit>
        <Benefit
          className={styles.benefit}
          icon={<IconPieChart width={60} height={60} />}
          title={t("Use fully or partly")}
        >
          {t(
            "The gift card can be redeemed to the SF Suite shop credit. So, you can purchase any product from the SF Suite.",
          )}
        </Benefit>
        <Benefit
          className={styles.benefit}
          icon={<IconShieldSuccessHollow width={49} height={60} />}
          title={t("Valid FOREVER")}
        >
          {t(
            "We have decided to remove valid thru dates from SF Suite gift card. This means the gift card you purchase will be valid forever.",
          )}
        </Benefit>
      </Layout>
    </Section>
  );
};
