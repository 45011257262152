import React from "react";

export interface IconLogoDagcoinProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  gradientFrom?: string;
  gradientTo?: string;
  width?: number;
  height?: number;
}

export const IconLogoDagcoin: React.FC<IconLogoDagcoinProps> = ({
  hasTitle,
  className,
  fill,
  gradientFrom,
  gradientTo,
  width = 18,
  height = 23,
}) => {
  // check if icon has fill
  const hasFill = fill ? `-${fill}` : undefined;

  // check if icon has stop-color "from"
  const hasGradientFrom = gradientFrom ? `-${gradientFrom}` : hasFill;

  // check if icon has stop-color "to"
  const hasGradientTo = gradientTo ? `-${gradientTo}` : `${hasFill}`;

  // create icon id
  const iconId = () =>
    hasGradientFrom !== undefined && hasGradientTo !== undefined
      ? `linear-gradient${hasGradientFrom}${hasGradientTo}`
      : undefined;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.52 23"
    >
      {hasTitle && <title>IconLogoDagcoin</title>}
      <defs>
        <linearGradient id={iconId()} x1="8.76" x2="8.76" y2="23" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={gradientFrom} />
          <stop offset="1" stopColor={gradientTo} />
        </linearGradient>
      </defs>
      <path
        id="D-Copy"
        style={{
          fill: gradientFrom !== undefined && gradientTo !== undefined ? `url(#${iconId()})` : fill ? fill : undefined,
        }}
        d="M8.59,23l.85-4a9.25,9.25,0,0,0,3.08-.91,8.71,8.71,0,0,0,5-8,6.05,6.05,0,0,0-.95-3.46,6.51,6.51,0,0,0-2.63-2.36,6,6,0,0,0-1.32-.45L13.37,0H9.51L8.85,3.45H3.2L0,19.18H5.56L4.71,23H8.59Zm-6-7.09L5,15.18,6.59,7.55,4.43,6.83H9.32a3.81,3.81,0,0,1,2.73,1,3.91,3.91,0,0,1,1,2.72,5.31,5.31,0,0,1-.66,2.73,5.1,5.1,0,0,1-1.79,1.9,4.75,4.75,0,0,1-2.54.73Z"
      />
    </svg>
  );
};
