import { Route, Switch, useHistory, useRouteMatch, useLocation, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Layout } from "../../../components/layout/Layout";
import { Loader } from "../../../components/loader/Loader";
import { RedeemGiftCardModal } from "../../../components/redeem-gift-card-modal/RedeemGiftCardModal";
import { Section } from "../../../components/section/Section";
import { usePaymentMethodsViewQuery } from "../../../graphql/schema";
import { TopUpSection } from "./top-up-section/TopUpSection";
import { GiftCardSection } from "./gift-card-section/GiftCardSection";
import { PaymentMethodsSection } from "./payment-methods-section/PaymentMethodsSection";
import { GiftCardHistorySection } from "./gift-card-history-section/GiftCardHistorySection";
import { TopUpModal } from "../../../components/top-up-modal/TopUpModal";
import { AddNewPaymentMethodModal } from "../../../components/add-new-payment-method/AddNewPaymentMethod";
import NotFound from "../../../components/not-found/NotFound";
import { DefaultCurrencyCode } from "../../../services/constants";
import { isUserFromUs } from "../../../services/isUserFromUs";

import styles from "./payment-methods-view.module.scss";

export default function PaymentMethodsView() {
  const [t] = useTranslation();
  const { push } = useHistory();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const query = usePaymentMethodsViewQuery();

  const listGiftCard = query.data?.getGiftCards;
  const savedPaymentSources = query.data?.me.paymentSources;
  const currencyCode = query.data?.me.currency.code ?? DefaultCurrencyCode;
  const isUsCountry = isUserFromUs(query.data?.me);

  // limit access for US users
  const limitedAccessPaths = [`${path}/top-up`, `${path}/redeem-gift-card`];

  if (query.error) {
    return <NotFound>{t("Something went wrong. Please reload the page or contact support")}</NotFound>;
  }

  if (isUsCountry && limitedAccessPaths.includes(pathname)) {
    return <Redirect to={path} />;
  }

  return (
    <Section
      className={classNames(styles.wrap, { [styles["wrap--is-user-from-us"]]: isUsCountry })}
      gutter={0}
      centerSelf
      withSpace
    >
      <Loader cover visibleOnFirstLoad={query} />
      <Section gutter={100} center={!isUsCountry}>
        {isUsCountry ? (
          <div>
            <PaymentMethodsSection paymentSources={savedPaymentSources} />
          </div>
        ) : (
          <Layout className={styles["section-wrap"]} wrap="TABLET_LANDSCAPE_MAX">
            <PaymentMethodsSection paymentSources={savedPaymentSources} />
            <TopUpSection paymentSources={savedPaymentSources} topUpRoute={`${path}/top-up`} />
          </Layout>
        )}
      </Section>

      {!isUsCountry && (
        <GiftCardSection openRedeemModal={() => push(`${path}/redeem-gift-card`)} currencyCode={currencyCode} />
      )}

      {!isUsCountry && <GiftCardHistorySection giftCards={listGiftCard} />}

      <Switch>
        <Route exact path={`${path}/add-new`}>
          <AddNewPaymentMethodModal />
        </Route>
        <Route exact path={`${path}/top-up`}>
          <TopUpModal
            refetchPaymentSources={query.refetch}
            paymentFlow={"PAYMENT_METHODS_PURCHASE_OF_SHOP_CREDIT"}
            currencyCode={currencyCode}
          />
        </Route>
        <Route exact path={`${path}/redeem-gift-card`}>
          <RedeemGiftCardModal refetchQueries={["PaymentMethodsView"]} />
        </Route>
      </Switch>
    </Section>
  );
}
