import { useTranslation } from "react-i18next";

import assertNever from "../services/assertNever";
import { ServicePeriodCode } from "../graphql/schema";

export type PeriodTranslationType = "LONG" | "SHORT" | "NUMERIC";

export function useServicePeriodTranslation() {
  const [t] = useTranslation();

  return (key: keyof typeof ServicePeriodCode, type: PeriodTranslationType = "LONG") => {
    switch (key) {
      case "ONE_MONTH": {
        switch (type) {
          case "LONG":
            return t("per month");
          case "SHORT":
            return t("mo");
          case "NUMERIC":
            return t("1 month");
          default:
            return assertNever(type);
        }
      }

      case "SIX_MONTHS": {
        switch (type) {
          case "LONG":
            return t("per six months");
          case "SHORT":
            return t("6 mo");
          case "NUMERIC":
            return t("6 months");
          default:
            return assertNever(type);
        }
      }

      case "TWELVE_MONTHS": {
        switch (type) {
          case "LONG":
            return t("per year");
          case "SHORT":
            return t("annually");
          case "NUMERIC":
            return t("12 months");
          default:
            return assertNever(type);
        }
      }

      case "LIFETIME":
        return t("One-time purchase");
      default:
        return assertNever(key);
    }
  };
}
