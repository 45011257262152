import React from "react";

export interface IconGift2Props {
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconGift2: React.FC<IconGift2Props> = ({ className, fill, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 30"
    >
      <path
        fill={fill}
        id="Gift"
        d="M8.22,0A4.13,4.13,0,0,0,4.05,4.09,4.05,4.05,0,0,0,5.24,7L3.24,7A3.39,3.39,0,0,0,0,10.42v3.49a2.67,2.67,0,0,0,.26,1.37,2,2,0,0,0,.79.8l.29.13v4.24h0c0,2.39.07,4.39.23,6a3.37,3.37,0,0,0,3.05,3.07c3.47.32,6.41.48,8.84.48s5.39-.16,8.9-.48a3.38,3.38,0,0,0,3.05-3.07c.16-1.61.23-3.61.23-6V20c0-.18,0-.49,0-1V16.2a1.48,1.48,0,0,0,.29-.12,2,2,0,0,0,.79-.8A2.67,2.67,0,0,0,27,13.91V10.43A3.4,3.4,0,0,0,23.75,7l-2-.06A4,4,0,0,0,23,4.09,4.13,4.13,0,0,0,18.78,0a3.86,3.86,0,0,0-3.93,4.13A2.22,2.22,0,0,1,13.5,6.35a2.22,2.22,0,0,1-1.35-2.22A3.86,3.86,0,0,0,8.22,0Zm5.16,8.2h1.47V28.62l-1.39,0-1.32,0V8.2ZM2.69,16.34H10.8V28.58q-2.69-.1-6.05-.41a2,2,0,0,1-1.83-1.85Q2.7,24,2.7,20.45V16.34Zm13.51,0h8.11V19c0,.54,0,.84,0,1v.45h0q0,3.53-.22,5.87a2,2,0,0,1-1.83,1.84c-2.25.21-4.26.35-6.05.42v0h0ZM10.8,8.21V15H2.41a1.5,1.5,0,0,1-.73-.1.66.66,0,0,1-.23-.24,1.5,1.5,0,0,1-.1-.73V10.42a2,2,0,0,1,1.95-2Q6.8,8.24,10.8,8.21Zm12.9.18a2,2,0,0,1,1.95,2v3.48a1.5,1.5,0,0,1-.1.73.57.57,0,0,1-.23.23,1.35,1.35,0,0,1-.73.11H16.2V8.21C18.84,8.24,21.34,8.29,23.7,8.39Zm-15.48-7A2.53,2.53,0,0,1,10.8,4.13a3.63,3.63,0,0,0,1,2.65l-3.56,0A2.77,2.77,0,0,1,5.4,4.09,2.78,2.78,0,0,1,8.22,1.36Zm10.56,0A2.78,2.78,0,0,1,21.6,4.09a2.77,2.77,0,0,1-2.82,2.72l-3.56,0a3.63,3.63,0,0,0,1-2.65A2.53,2.53,0,0,1,18.78,1.36Z"
      />
    </svg>
  );
};
