import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Button } from "../../../components/button/Button";
import { Heading } from "../../../components/heading/Heading";
import { LoginForm } from "../../../components/login-form/LoginForm";
import { Section } from "../../../components/section/Section";

import styles from "./login-section.module.scss";

interface props {
  isRegistrationEnabled: boolean;
  toggleShowLogin: () => void;
}

export const LoginSection: React.FC<props> = ({ toggleShowLogin, isRegistrationEnabled }) => {
  // get translation keys
  const { t } = useTranslation();

  return (
    <>
      <div className={classNames(styles.header, { [styles["header--no-registration"]]: !isRegistrationEnabled })}>
        <Heading className={styles.title} thin level={3} center>
          {t("Login to your account")}
        </Heading>
        {isRegistrationEnabled && (
          <div className={styles.label}>
            {t("Don't have an account?")}
            <Button
              className={styles.link}
              kind="TEXT"
              height="AUTO"
              weight="SEMI_BOLD"
              color="YELLOW"
              onClick={() => toggleShowLogin()}
            >
              {t("Click here")}
            </Button>
          </div>
        )}
      </div>
      <Section gutter={0} withSpace center>
        <LoginForm className={styles.form} kind="SECONDARY" />
      </Section>
    </>
  );
};
