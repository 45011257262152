import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserConsentCode } from "../../graphql/schema";
import { FOREX_TERMS_OF_USE_LINK_URL, FOREX_PRIVACY_POLICY_LINK_URL } from "../../services/constants";
import { Checkbox } from "../checkbox/Checkbox";
import { Link } from "../link/Link";
import { ProductTermsConsentModal } from "../product-terms-consent-modal/ProductTermsConsentModal";
import { Section, SectionGutter } from "../section/Section";
import { TermsAndConditionsConsent } from "../terms-and-conditions/TermsAndConditions";
import { TheViewConsentProps } from "../the-view-consent/TheViewConsent";

export interface ForexConsentModalProps extends Pick<TheViewConsentProps, "isVisible" | "close"> {
  consents: TermsAndConditionsConsent[];
  onAccept: (codes: UserConsentCode) => Promise<void>;
  onReject: (codes: UserConsentCode) => Promise<void>;
}

export const ForexConsentModal: FC<ForexConsentModalProps> = ({ consents, onAccept, onReject, ...props }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isTermAccepted, setIsTermAccepted] = useState(false);
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  return (
    <ProductTermsConsentModal
      isTermAccepted={isTermAccepted && isPrivacyPolicyAccepted}
      isVisible={props.isVisible}
      isLoading={isLoading}
      handleAccess={handleAccess}
      onAccept={onAccept}
      onReject={onReject}
      close={props.close}
    >
      <Section gutter={SectionGutter.MEDIUM}>
        <div
          dangerouslySetInnerHTML={{
            __html: t("Prior proceeding further, please, read and accept our Terms of Use and Privacy Policy"),
          }}
        />
      </Section>
      <Checkbox
        checked={isTermAccepted}
        onChange={(e) => setIsTermAccepted(!isTermAccepted)}
        gutter="MEDIUM"
        label={
          <>
            {t("I have read and agree to the")}{" "}
            <Link
              to={"#"}
              externalUrl={FOREX_TERMS_OF_USE_LINK_URL}
              color="LIGHT_GREY"
              kind="TEXT"
              weight="SEMI_BOLD"
              height="AUTO"
            >
              {t("Terms of Use")}
            </Link>
          </>
        }
      />
      <Checkbox
        checked={isPrivacyPolicyAccepted}
        onChange={(e) => setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted)}
        gutter="SEMI_LARGE"
        label={
          <>
            {t("I have read and agree to the")}{" "}
            <Link
              to={"#"}
              externalUrl={FOREX_PRIVACY_POLICY_LINK_URL}
              color="LIGHT_GREY"
              kind="TEXT"
              weight="SEMI_BOLD"
              height="AUTO"
            >
              {t("Privacy Policy")}
            </Link>
          </>
        }
      />
    </ProductTermsConsentModal>
  );

  async function handleAccess(granted: boolean) {
    setIsLoading(true);

    if (granted) {
      await onAccept(UserConsentCode.FXTOU);
      await onAccept(UserConsentCode.FXPP);
    } else {
      await onReject(UserConsentCode.FXTOU);
      await onReject(UserConsentCode.FXPP);
    }

    setIsLoading(false);
  }
};
