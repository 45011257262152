import { useTranslation } from "react-i18next";

import { useSubscriptionsListViewQuery } from "../../../graphql/schema";
import NotFound from "../../../components/not-found/NotFound";
import { SubscriptionListItem } from "../../../components/subscription-list-item/SubscriptionListItem";
import { Section } from "../../../components/section/Section";
import { Loader } from "../../../components/loader/Loader";

import styles from "./subscriptionListView.module.scss";

export default function SubscriptionListView() {
  const [t] = useTranslation();
  const query = useSubscriptionsListViewQuery();

  const subscriptionList = query.data?.me.serviceSubscriptions || [];
  const hasSubscriptions = query.loading || subscriptionList.length > 0;

  if (query.error) {
    return <NotFound>{t("Something went wrong. Please reload the page or contact support")}</NotFound>;
  }

  if (!hasSubscriptions) {
    return <NotFound>{t("You have no subscriptions")}</NotFound>;
  }

  return (
    <Section gutter={100} withSpace className={styles.subscriptions}>
      <Loader cover visibleOnFirstLoad={query} />

      <ul>
        {subscriptionList &&
          subscriptionList.map((subscription) => (
            <SubscriptionListItem
              key={subscription.id}
              subscription={{
                ...subscription,
                name: subscription.products.map((p) => p.name).join(" + "),
              }}
              refetchSubscriptions={query.refetch}
            />
          ))}
      </ul>
    </Section>
  );
}
