export interface IconPercentageProps {
  hasTitle?: boolean;
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
}

function IconPercentage({ hasTitle, fill, width = 30, height = 30, className }: IconPercentageProps) {
  return (
    <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      {hasTitle && <title>IconPercentage</title>}
      <path
        fill={fill}
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M14.711 27.076a.646.646 0 01.578 0l2.94 1.466c.646.323 1.43.067 1.763-.571l1.526-2.935a.653.653 0 01.468-.343l3.245-.557a1.318 1.318 0 001.083-1.488l-.478-3.293a.663.663 0 01.179-.556l2.31-2.383a1.316 1.316 0 000-1.832l-2.31-2.383a.663.663 0 01-.18-.556l.48-3.293a1.318 1.318 0 00-1.084-1.488l-3.245-.557a.653.653 0 01-.468-.343l-1.526-2.935a1.322 1.322 0 00-1.763-.571l-2.94 1.466a.646.646 0 01-.578 0l-2.94-1.466a1.322 1.322 0 00-1.763.571L8.482 5.964a.653.653 0 01-.468.343l-3.245.557a1.318 1.318 0 00-1.083 1.488l.478 3.293a.663.663 0 01-.179.556l-2.31 2.383a1.316 1.316 0 000 1.832l2.31 2.383a.663.663 0 01.18.556l-.48 3.293a1.318 1.318 0 001.084 1.488l3.245.557c.2.035.373.161.468.343l1.526 2.935a1.322 1.322 0 001.763.571l2.94-1.466zm-2.363 2.648a2.62 2.62 0 01-3.494-1.14l-1.377-2.646-2.927-.502a2.635 2.635 0 01-2.155-2.98l.432-2.969L.744 17.34a2.644 2.644 0 010-3.678l2.083-2.148-.432-2.97A2.635 2.635 0 014.55 5.564l2.927-.502 1.377-2.646a2.62 2.62 0 013.494-1.14L15 2.599l2.652-1.323a2.62 2.62 0 013.494 1.14l1.377 2.646 2.927.502a2.635 2.635 0 012.155 2.98l-.432 2.969 2.083 2.148a2.644 2.644 0 010 3.678l-2.083 2.148.432 2.97a2.635 2.635 0 01-2.155 2.979l-2.927.502-1.377 2.646a2.62 2.62 0 01-3.494 1.14L15 28.401l-2.652 1.323zm-3.027-9.253L19.756 9.927c.615-.622 1.537.31.923.932L10.244 21.403c-.615.621-1.537-.31-.923-.932zm1.114-6.289c-1.441 0-2.609-1.18-2.609-2.636 0-1.456 1.168-2.636 2.609-2.636 1.44 0 2.608 1.18 2.608 2.636 0 1.456-1.167 2.636-2.608 2.636zm0-1.318c.72 0 1.304-.59 1.304-1.318 0-.728-.584-1.318-1.304-1.318-.72 0-1.305.59-1.305 1.318 0 .728.584 1.318 1.305 1.318zm9.13 9.226c-1.44 0-2.608-1.18-2.608-2.636 0-1.456 1.167-2.636 2.608-2.636s2.609 1.18 2.609 2.636c0 1.456-1.168 2.636-2.609 2.636zm0-1.318c.72 0 1.305-.59 1.305-1.318 0-.728-.584-1.318-1.305-1.318-.72 0-1.304.59-1.304 1.318 0 .728.584 1.318 1.304 1.318z"
        transform="translate(-815 -651) translate(80 90) translate(720 100) translate(15 460)"
      />
    </svg>
  );
}

export default IconPercentage;
