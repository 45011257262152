import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { formatLocaleDate } from "../../services/formatDate";

import styles from "./date-time.module.scss";

export interface DateTimeProps {
  value: Date | string | number;
  month?: "long" | "short";
  showTime?: boolean;
  showSeconds?: boolean;
  wrap?: boolean;
}

export default function DateTime({ value, month = "short", showTime, showSeconds, wrap }: DateTimeProps) {
  const { i18n } = useTranslation();

  const localeStr = formatLocaleDate(value, i18n.language, { month, showTime, showSeconds });

  return <span className={classNames(styles["date-time"], { [styles["date-time--wrap"]]: wrap })}>{localeStr}</span>;
}
