import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useState } from "react";

import { Button } from "../../../components/button/Button";
import Field from "../../../components/field/Field";
import { Loader } from "../../../components/loader/Loader";
import { useGrowAccountAccessLinkMutation, useGrowLinkingAccountViewQuery } from "../../../graphql/schema";
import { Section, SectionGutter } from "../../../components/section/Section";
import { Modal } from "../../../components/modal/Modal";
import { showGraphqlValidationErrors } from "../../../services/showGraphqlValidationErrors";
import { Panel } from "../../../components/panel/Panel";
import { Heading, HeadingGutter } from "../../../components/heading/Heading";

import styles from "./grow.module.scss";

type ConfirmationPasswordSchema = {
  secretPassword: string;
};

export default function GrowLinkingAccountView() {
  const [t] = useTranslation();

  const [isConfirmationModalOpen, setIsconfirmationModalOpen] = useState(false);
  const [confirmationPasswordError, setConfirmationPasswordError] = useState<string | null>(null);
  const { data, error, loading, previousData } = useGrowLinkingAccountViewQuery();
  const [growLinking] = useGrowAccountAccessLinkMutation();

  const {
    register: secretPasswordRegister,
    handleSubmit: handleSecretPasswordSubmit,
    errors: secretPasswordInputErrors,
  } = useSecretPasswordForm();

  // handle change profile info on submit
  const handleGrowLink = handleSecretPasswordSubmit(async ({ secretPassword }) => {
    try {
      const result = await growLinking({
        variables: { secretPassword },
        refetchQueries: "active",
        awaitRefetchQueries: true,
      });

      if (result.data?.growAccountAccessLink) {
        window.location.href = result.data?.growAccountAccessLink;
      }
    } catch (err: any) {
      console.error("Account linking failed", err);

      if (!showGraphqlValidationErrors(t, err) && err instanceof Error) {
        toast.error(err?.message, { autoClose: false });
      }
    }
  });

  const grow = data?.me?.growAccount;

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Section gutter={SectionGutter.LARGE} center>
      <Loader visibleOnFirstLoad={{ loading, previousData }} />

      <form className={styles.form} onSubmit={() => setIsconfirmationModalOpen(true)}>
        <Section gutter={SectionGutter.EXTRA_LARGE} center withSpace>
          <Heading level={5} gutter={HeadingGutter.MEDIUM} center="MOBILE">
            {t("Grow Account")}
          </Heading>
          <Button
            shape="ROUND"
            color="BLUE"
            height="LARGE"
            fontSize={16}
            weight="BOLD"
            width={230}
            type="button"
            onClick={() => setIsconfirmationModalOpen(true)}
          >
            {grow?.linkedAccount ? t("Login Account") : t("Link Account")}
          </Button>
        </Section>
      </form>

      <Modal isOpen={isConfirmationModalOpen} close={() => setIsconfirmationModalOpen(false)}>
        <Panel contentClassName={styles["modal-content"]}>
          <p className={styles["modal-description"]}>{t("Enter secret password to link with grow account")}</p>
          <form className={styles.form} onSubmit={handleGrowLink}>
            <Field
              className={styles["password-field"]}
              label={t("Secret password")}
              type="password"
              name="secretPassword"
              internalRef={secretPasswordRegister}
              isRequired
              onChange={() => setConfirmationPasswordError(null)}
              error={
                secretPasswordInputErrors.secretPassword ||
                (confirmationPasswordError ? { message: confirmationPasswordError, type: "required" } : undefined)
              }
            />
            <Button
              type="submit"
              shape="ROUND"
              color="BLUE"
              height="LARGE"
              fontSize={16}
              weight="BOLD"
              width={230}
              center="BLOCK_AND_MARGIN"
            >
              {t("Submit")}
            </Button>
          </form>
        </Panel>
      </Modal>
    </Section>
  );
}

function useSecretPasswordForm() {
  const [t] = useTranslation();

  const secretPasswordSchema = yup.object().shape({
    secretPassword: yup.string().required(t("Secret password is missing")),
  });

  const { register, handleSubmit, errors } = useForm<ConfirmationPasswordSchema>({
    resolver: yupResolver(secretPasswordSchema),
  });

  return { register, handleSubmit, errors };
}
