import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { makeVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { StripeElementStyle } from "@stripe/stripe-js";
import { IdealBankElement, useElements, useStripe } from "@stripe/react-stripe-js";

import Field, { FieldGutter } from "../field/Field";
import { Section, SectionGutter } from "../section/Section";
import { PaymentFlow } from "../payment-result/PaymentResult";
import { Layout } from "../layout/Layout";

import styles from "./stripe-ideal-form.module.scss";

const stripeCartStyle: StripeElementStyle = {
  base: {
    iconColor: "#0062ff",
    color: "rgb(181, 181, 190)",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "16px",
    lineHeight: "50px",
    padding: "0 20px",

    ":-webkit-autofill": {
      color: "#b5b5be",
    },
  },
  invalid: {
    color: "rgb(252, 90, 90)",
  },
};

export interface IDealFormProps {
  onPaymentSourceHolderNameChange: (name: string) => void;
  className?: string;
  handleIsIdealBankSelected?: (e: boolean) => void;
}

export const IDealForm: React.FC<IDealFormProps> = ({
  onPaymentSourceHolderNameChange,
  className,
  handleIsIdealBankSelected,
}) => {
  const [t] = useTranslation();
  const [hasSelected, setHasSelected] = useState(false);

  // reset all form errors on un-mount
  useEffect(
    () => () => {
      stripeIDealErrorsVar(stripeIDealErrorsDefaultValues);
    },
    [],
  );

  useEffect(() => {
    if (handleIsIdealBankSelected) {
      handleIsIdealBankSelected(hasSelected);
    }
  }, [hasSelected, handleIsIdealBankSelected]);

  const hasError = Object.values(stripeIDealErrorsVar()).some((v) => !!v);

  return (
    <Section gutter={SectionGutter.LARGE} withSpace className={classNames(styles["field-wrap"], className)}>
      {hasError && <p className={styles.error}>{t("Please review selected bank info")}</p>}
      <Layout wrap="TABLET_PORTRAIT_MAX">
        <Field
          className={classNames(styles.field, styles["custom-field"])}
          type="custom"
          gutter={FieldGutter.MEDIUM}
          error={getIDealError("iDealBank")}
        >
          {({ onBlur, onChange, onFocus }) => (
            <IdealBankElement
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={(e) => {
                setHasSelected(true);
                setIDealError("iDealBank", undefined);
                onChange(!e.empty);
              }}
              options={{ style: stripeCartStyle }}
            />
          )}
        </Field>

        <Field
          className={styles.field}
          label={t("Full name")}
          gutter={FieldGutter.MEDIUM}
          error={getIDealError("holderName")}
          isRequired
          onChange={(e) => {
            setIDealError("holderName", undefined);
            onPaymentSourceHolderNameChange(e);
          }}
        />
      </Layout>
    </Section>
  );
};

interface StripeIDealErrorsVar {
  iDealBank?: string;
  holderName?: string;
}

const stripeIDealErrorsDefaultValues: StripeIDealErrorsVar = {
  iDealBank: undefined,
  holderName: undefined,
};

const stripeIDealErrorsVar = makeVar<StripeIDealErrorsVar>(stripeIDealErrorsDefaultValues);

function getIDealError(fieldName: keyof NonNullable<StripeIDealErrorsVar>) {
  const message = stripeIDealErrorsVar()[fieldName];

  if (!message) {
    return undefined;
  }

  return { type: "error", message, isManual: true };
}

function setIDealError(fieldName: keyof NonNullable<StripeIDealErrorsVar>, message?: string) {
  stripeIDealErrorsVar({ ...stripeIDealErrorsVar(), [fieldName]: message });
}

export function useStripeIdealPayment() {
  const stripe = useStripe();
  const elements = useElements();
  const [t] = useTranslation();

  // Make request to stripe to confirm card payment
  return async (props: {
    paymentIntentClientSecret: string;
    paymentId: string;
    paymentFlow: PaymentFlow;
    isIdealBankCardSet: boolean;
    paymentSourceHolderName?: string;
  }) => {
    const { paymentIntentClientSecret, paymentId, paymentSourceHolderName, paymentFlow, isIdealBankCardSet } = props;

    if (!paymentSourceHolderName || !paymentSourceHolderName.trim() || !isIdealBankCardSet) {
      if (!paymentSourceHolderName || !paymentSourceHolderName.trim()) {
        setIDealError("holderName", "Name is required");
      }

      if (!isIdealBankCardSet) {
        setIDealError("iDealBank", "Bank is required");
      }

      throw new Error(t("Please review your Ideal info"));
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted idealBankElement. Elements knows how
    // to find idealBankElement because there can only ever be one of
    // each type of element.
    const idealBankElement = elements.getElement(IdealBankElement);

    if (!idealBankElement) {
      throw new Error(t("Ideal bank element was not found."));
    }

    const { error } = await stripe.confirmIdealPayment(paymentIntentClientSecret, {
      payment_method: {
        ideal: idealBankElement,
        billing_details: {
          name: paymentSourceHolderName,
        },
      },
      return_url: `${process.env.REACT_APP_STRIPE_IDEAL_RETURN_URL}/${paymentFlow}/${paymentId}`,
    });

    // TODO: Handle all errors
    if (error) {
      console.error("Ideal returned error before payment attempt. Message: ", error.message);
      throw new Error(error.message);
    }
  };
}
