import React from "react";

export interface IconRefreshProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconRefresh: React.FC<IconRefreshProps> = ({ hasTitle, className, fill, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56 64"
    >
      {hasTitle && <title>IconRefresh</title>}
      <path fill={fill} d="M48,36A20,20,0,1,1,24,16.4V24L38,12,24,0V8.3A28,28,0,1,0,56,36Z" />
    </svg>
  );
};
