import React from "react";

export interface IconCheckmarkInCircleProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconCheckmarkInCircle: React.FC<IconCheckmarkInCircleProps> = ({
  hasTitle,
  className,
  fill,
  width,
  height,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      {hasTitle && <title>IconCheckmarkInCircle</title>}
      <path
        fill={fill}
        d="M12.3,7.3a1,1,0,0,1,1.4,0,.94.94,0,0,1,.1,1.3l-.1.1-4,4a.94.94,0,0,1-1.3.1l-.1-.1-2-2a1,1,0,0,1,0-1.4.94.94,0,0,1,1.3-.1l.1.1L9,10.6Z"
      />
      <path
        opacity={0.5}
        fill={fill}
        d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,10,18Z"
      />
    </svg>
  );
};
