import React, { useState } from "react";
import classNames from "classnames";

import { Button, ButtonProps } from "../button/Button";

export interface DropdownProps extends ButtonProps {
  button: React.ReactNode;
  className?: string;
  buttonClassName?: string;
  openClassName?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  children,
  button,
  className,
  buttonClassName,
  openClassName,
  ...props
}) => {
  // check if dropdown is open
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className={className}>
      <Button
        className={classNames(buttonClassName, isDropdownOpen && openClassName)}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        {...props}
      >
        {button}
      </Button>
      {isDropdownOpen && children}
    </div>
  );
};
