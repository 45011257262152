import cookie from "react-cookies";

import i18n from "../services/i18n";

const developmentLocales = ["cimode"];

const availableLocales = (i18n?.options?.supportedLngs || [])
  // filter out languages that are meant for helping with translating site
  .filter((locale) =>
    process.env.REACT_APP_CROWDIN_TRANSLATION_MODE === "true" ? true : !developmentLocales.includes(locale),
  );

export function useGetLocales() {
  return {
    locales: availableLocales.map((locale) => ({ name: sanitizeLocaleName(locale), code: locale })),
    activeLocale: { name: sanitizeLocaleName(i18n.language), code: i18n.language },
    setLocale: async (localeCode: string) => {
      await i18n.changeLanguage(localeCode);
      cookie.save("locale", localeCode, { path: "/" });
    },
  };
}

function sanitizeLocaleName(lang: string) {
  return (
    lang
      // return first part of locale name. eg "es-ES" becomes es
      .split("-")[0]
      // force same casing to every language
      .toLowerCase()
  );
}
