import React, { ReactNode } from "react";
import classNames from "classnames";

import { Heading } from "../heading/Heading";

import styles from "./container.module.scss";

export enum ContainerWidth {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export interface ContainerProps {
  size?: ContainerWidth | undefined;
  icon?: React.ReactNode;
  secondary?: boolean;
  grow?: boolean;
  secondaryTitle?: boolean;
  center?: boolean;
  title?: string;
  className?: string;
  children?: ReactNode;
}

export const Container: React.FC<ContainerProps> = ({
  size,
  icon,
  secondary,
  grow,
  secondaryTitle,
  center,
  title,
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles["container--with-title"]]: title,
          [styles["container--secondary"]]: secondary,
          [styles["container--center"]]: center,
          [styles["container--small"]]: size === ContainerWidth.SMALL,
          [styles["container--medium"]]: size === ContainerWidth.MEDIUM,
          [styles["container--large"]]: size === ContainerWidth.LARGE,
          [styles["container--grow"]]: grow,
        },
        className,
      )}
    >
      {icon && icon}
      {title && (
        <Heading
          level={2}
          thin
          center
          className={classNames(styles.title, { [styles["title--secondary"]]: secondaryTitle })}
        >
          {title}
        </Heading>
      )}
      {children}
    </div>
  );
};
