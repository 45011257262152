import classNames from "classnames";
import React from "react";

import styles from "./radio.module.scss";

export enum LabelGap {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
  LARGE = "LARGE",
}

export enum RadioStyle {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
}

export interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode;
  gap?: keyof typeof LabelGap;
  kind?: keyof typeof RadioStyle;
  className?: string;
}

export const Radio: React.FC<RadioProps> = ({ label, gap = "MEDIUM", kind = "PRIMARY", className, ...rest }) => {
  return (
    <label
      className={classNames(
        styles.radio,
        (styles as any)[`radio--${kind}`],
        { [styles["radio--is-checked"]]: rest.checked && kind === "PRIMARY" },
        className,
      )}
    >
      <input type="radio" className={styles.input} {...rest} />
      <span className={(styles as any)[`checkmark--${kind}`]} />
      <span className={classNames(styles.label, (styles as any)[`gap--${gap}`], (styles as any)[`label--${kind}`])}>
        {label}
      </span>
    </label>
  );
};
