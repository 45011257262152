export interface IconListProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconList: React.FC<IconListProps> = ({ hasTitle, className, fill, width = 48, height = 48 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48" className={className}>
      {hasTitle && <title>IconList</title>}
      <path
        fill={fill}
        d="M39.57 15.237a97.778 97.778 0 00-5.104-5.684A101.612 101.612 0 0028.91 4.25a1 1 0 00-.664-.25H24c-4.243 0-8.455.209-12.635.626a3 3 0 00-2.69 2.724C8.224 12.486 8 18.057 8 24.065c0 5.306.175 10.25.526 14.83a5 5 0 004.564 4.601c3.966.336 7.593.504 10.88.504 3.295 0 6.942-.169 10.94-.506a5 5 0 004.564-4.602c.35-4.59.526-9.564.526-14.924 0-1.836-.067-4.547-.201-8.13a1 1 0 00-.23-.601zm2.43 8.73c0 5.41-.177 10.435-.532 15.078a7 7 0 01-6.39 6.442c-4.052.342-7.754.513-11.108.513-3.346 0-7.03-.17-11.05-.51a7 7 0 01-6.388-6.442C6.177 34.415 6 29.42 6 24.065c0-6.064.227-11.693.682-16.89a5 5 0 014.484-4.539C15.412 2.212 19.691 2 24 2h4.246a3 3 0 011.988.754 103.611 103.611 0 015.667 5.405 99.778 99.778 0 015.208 5.801 3 3 0 01.688 1.803c.135 3.606.203 6.34.203 8.205zm-1.552-9.958a1 1 0 11-.002 2L32.999 16a5 5 0 01-4.996-4.999C28.001 8.931 28 7.275 28 3.548a1 1 0 012 0c0 3.726 0 5.381.003 7.452a3 3 0 002.998 3l7.447.009zM15 28a1 1 0 010-2h18a1 1 0 010 2H15zm0-6a1 1 0 010-2h18a1 1 0 010 2H15zm0 12a1 1 0 010-2h18a1 1 0 010 2H15zm0-18a1 1 0 010-2h6.999a1 1 0 110 2H15z"
      ></path>
    </svg>
  );
};
