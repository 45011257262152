import i18n from "i18next";
import LngDetector, { DetectorOptions } from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";

const BUILD_TIMESTAMP = process.env.REACT_APP_CROWDIN_CDN_ENABLED === "true" ? getCachedBuildTimestamp() : null;
const REMOTE_URL = `${process.env.REACT_APP_CROWDIN_CDN_URL}`;
const LOCAL_URL = "/translations";
const detectionOptions: DetectorOptions = {
  // order and from where user language should be detected
  order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path"],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "locale",

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "myDomain",

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
};

const supportedLngs = ["en"];

if (process.env.REACT_APP_CROWDIN_TRANSLATION_MODE === "true") {
  // ach is for crowdin in-context localization feature
  // See https://support.crowdin.com/in-context-localization/
  supportedLngs.push("ach");
}

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(ChainedBackend)
  .use(LngDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // resources,
    debug: false,
    load: "currentOnly",
    supportedLngs,

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,

    // fallback to keys
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    backend: {
      backends: [HttpBackend, HttpBackend],
      backendOptions: [
        {
          loadPath: `${
            process.env.REACT_APP_CROWDIN_CDN_ENABLED === "true" ? REMOTE_URL : LOCAL_URL
          }/{{lng}}/{{ns}}.json${BUILD_TIMESTAMP ? `?timestamp=${BUILD_TIMESTAMP}` : ""}`,
        },
        { loadPath: `${LOCAL_URL}/{{lng}}/{{ns}}.json` },
      ],
    },
    detection: detectionOptions,
  });

export default i18n;

updateManifestData();

export async function updateManifestData() {
  const url = `${process.env.REACT_APP_CROWDIN_CDN_URL?.split("/content")[0]}/manifest.json`;

  if (!("localStorage" in window)) {
    return;
  }

  const oldTimestamp = parseInt(window.localStorage.getItem("crowdin-build-timestamp") ?? "0");
  const timeStart = Date.now();

  try {
    const res = await fetch(url);
    const json = await res.json();

    if (!("timestamp" in json) || json.timestamp === oldTimestamp) {
      return;
    }

    window.localStorage.setItem("crowdin-build-timestamp", json.timestamp);

    const timeEnd = Date.now();

    // reload page with new translations if fetch took less than 3 seconds
    if (timeEnd - timeStart < 10 * 3000) {
      window.location.reload();
    }
  } catch (e) {
    console.error("failed to fetch translation metadata from crowdin");
  }
}

function getCachedBuildTimestamp() {
  if (!("localStorage" in window)) {
    return null;
  }

  const timestamp = window.localStorage.getItem("crowdin-build-timestamp");

  return timestamp ?? null;
}
