import * as React from "react";
import classNames from "classnames";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { formatCountryOptions } from "../../services/formatCountryOptions";
import { RegisterLuckyLooreFormValues } from "../lucky-loore-registration-form/LuckyLooreRegistrationForm";
import { Button, CenterSelf } from "../button/Button";
import Field from "../field/Field";
import { Form } from "../form/Form";
import { PasswordTip } from "../password-tip/PasswordTip";
import { RegisterUserFormValues } from "../registration-form/RegistrationForm";
import { Tooltip } from "../tooltip/Tooltip";
import { BorderRadius, Color, FontWeight, Shape } from "../../services/buttonLinkConstants";
import { Checkbox } from "../checkbox/Checkbox";
import assertNever from "../../services/assertNever";
import { USCountryCodes, USStates } from "../../services/constants";
import { getDocumentUrl } from "../../services/getDocumentUrl";

import styles from "./registrationFormBase.module.scss";

const maxBirthdayDate = new Date();
maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);

export enum RegistrationFormKind {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export interface RegistrationFormCountry {
  id: number;
  code?: string | null;
  name?: string | null;
}

export interface RegistrationFormBaseProps {
  layout?: keyof typeof RegistrationFormKind;
  className?: string;
  agreementMessage?: string;
  agreementMessageClassName?: string;
  submitButtonClassName?: string;
  submitButtonText?: string;
  buttonType?: "button" | "submit" | "reset" | undefined;
  onClick?(): void;
}

export interface RegistrationFormProps extends RegistrationFormBaseProps {
  formError: string;
  handleRegisterUser: (e: React.BaseSyntheticEvent<object, any, any>) => Promise<void>;
  userInputErrors: FieldErrors<RegisterLuckyLooreFormValues | RegisterUserFormValues>;
  register: any;
  countries: RegistrationFormCountry[];
  setUserPasswd: (e: string) => void;
  passwordTipValues: {
    hasEightChars: boolean;
    hasUpperCase: boolean;
    hasLowerCase: boolean;
    hasNumber: boolean;
  };
  setSelectedDate: (e: Date | null) => void;
  selectedDate: Date | null;
  registerUserLoading: boolean;
  isStandaloneRegistration?: boolean;
}

export default function RegistrationFormBase({
  layout = "PRIMARY",
  formError,
  handleRegisterUser,
  userInputErrors,
  register,
  countries,
  setUserPasswd,
  passwordTipValues,
  setSelectedDate,
  selectedDate,
  registerUserLoading,
  isStandaloneRegistration,
  ...props
}: RegistrationFormProps) {
  // access translation keys
  const [t] = useTranslation();
  const [selectedCountryId, setSelectedCountryId] = React.useState<string | null>(null);

  // submit button visuals
  const button = (layout: keyof typeof RegistrationFormKind) => {
    switch (layout) {
      case "PRIMARY":
        return { shape: Shape.RECT, color: Color.YELLOW, borderRadius: BorderRadius.SMALL, weight: FontWeight.MEDIUM };
      case "SECONDARY":
        return { shape: Shape.ROUND, color: Color.BLUE, weight: FontWeight.BOLD, center: CenterSelf.BLOCK_AND_MARGIN };
      default:
        return assertNever(layout);
    }
  };

  const usCountryIds = countries.filter((c) => c.code && USCountryCodes.includes(c.code)).map((c) => c.id);
  const isUsCountry = usCountryIds.includes(Number(selectedCountryId));

  return (
    <>
      {formError && <div>{formError}</div>}
      <Form
        className={classNames((styles as any)[`form--${layout}`], props.className)}
        doubleColumn
        onSubmit={handleRegisterUser}
      >
        <Field
          type="text"
          label={t("First name")}
          name="firstName"
          error={userInputErrors.firstName}
          internalRef={register}
          isRequired
        />
        <Field
          type="text"
          label={t("Last name")}
          name="lastName"
          error={userInputErrors.lastName}
          internalRef={register}
        />
        <Field
          type="text"
          label={t("Email")}
          name="email"
          error={userInputErrors.email}
          internalRef={register}
          isRequired
        />
        <Field
          type="select"
          label={t("Choose country")}
          name="countryId"
          error={userInputErrors.countryId}
          internalRef={register}
          options={formatCountryOptions(countries)}
          onChange={setSelectedCountryId}
        />

        {isUsCountry && (
          <>
            <Field
              type="select"
              label={t("Choose state")}
              name="state"
              error={userInputErrors.state}
              internalRef={register}
              options={USStates.map((state) => ({ label: state, value: state }))}
            />
            <Field type="text" label={t("City")} name="city" error={userInputErrors.city} internalRef={register} />
            <Field
              type="text"
              label={t("Zip Code")}
              name="postcode"
              error={userInputErrors.postcode}
              internalRef={register}
            />
            <Field
              type="text"
              label={t("House number, Street name")}
              name="address"
              error={userInputErrors.address}
              internalRef={register}
            />
          </>
        )}

        {isStandaloneRegistration ? (
          <Field
            type="text"
            label={t("Username")}
            name="username"
            error={"username" in userInputErrors ? userInputErrors.username : undefined}
            internalRef={register}
            isRequired
          />
        ) : null}
        <Tooltip
          className={styles["tooltip-content"]}
          reference={
            <Field
              type="password"
              label={t("Password")}
              name="password"
              error={userInputErrors.password}
              internalRef={register}
              isRequired
              onChange={(v) => setUserPasswd(v)}
            />
          }
        >
          <PasswordTip checked={passwordTipValues.hasEightChars} label="8+" description={t("8+ characters")} />
          <PasswordTip checked={passwordTipValues.hasUpperCase} label="ABC" description={t("Uppercase")} />
          <PasswordTip checked={passwordTipValues.hasLowerCase} label="abc" description={t("Lowercase")} />
          <PasswordTip checked={passwordTipValues.hasNumber} label="123" description={t("Number")} />
        </Tooltip>
        <Field
          type="datepicker"
          label={t("Date of birth")}
          name="dateOfBirth"
          datePickerProps={{
            selected: selectedDate,
            maxDate: maxBirthdayDate,
            showYearDropdown: true,
            yearDropdownItemNumber: 60,
            scrollableYearDropdown: true,
            dateFormat: "dd/MM/yyyy",
          }}
          isRequired
          error={userInputErrors.dateOfBirth}
          onChange={(date) => setSelectedDate(new Date(date))}
        />
        <Checkbox
          className={classNames(
            styles.checkBox,
            (styles as any)[`checkBox--${layout}`],
            props.agreementMessageClassName,
          )}
          checkboxStyle={layout}
          name="termsAndConditions"
          internalRef={register}
          error={userInputErrors.termsAndConditions}
          labelClassName={(styles as any)[`label--${layout}`]}
          label={
            props.agreementMessage ? (
              <span dangerouslySetInnerHTML={{ __html: props.agreementMessage }} />
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: t('I agree to the <a href={{href}} target="_blank">Privacy Policy</a>', {
                    href: getDocumentUrl("PRIVACY_POLICY", isUsCountry),
                  }),
                }}
              />
            )
          }
        />
        <Button
          className={classNames((styles as any)[`button--${layout}`], props.submitButtonClassName)}
          shape={button(layout).shape}
          color={button(layout).color}
          borderRadius={button(layout).borderRadius}
          weight={button(layout).weight}
          fontSize={18}
          width={250}
          type={props.buttonType || "submit"}
          disabled={registerUserLoading}
          center={button(layout).center}
        >
          {props.submitButtonText || t("Get access")}
        </Button>
      </Form>
    </>
  );
}
