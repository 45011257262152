export interface IconSignOutProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

function IconSignOut({ hasTitle, className, width = 48, height = 48 }: IconSignOutProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41.97 40"
    >
      {hasTitle && <title>IconSignOut</title>}
      <path d="M39.58,19l0-.06-7.22-7.23a1,1,0,0,1,1.42-1.42l7.21,7.24a3.49,3.49,0,0,1,0,4.95l-7.23,7.23a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.41l0,0,7.24-7.23.06-.06H15a1,1,0,0,1,0-2ZM26,29a1,1,0,0,1,2,0s0,0,0,.06c0,.6-.14,2-.33,4.21a7,7,0,0,1-6.39,6.37q-3.57.31-7.15.3-3.93,0-7.27-.31A7,7,0,0,1,.48,33.3Q0,27.63,0,20.07T.49,6.61A7,7,0,0,1,7,.21Q9.94,0,14.12,0t7,.2a7,7,0,0,1,6.54,6.39c.2,2.3.31,3.75.35,4.38a1,1,0,0,1-2,.11c0-.6-.15-2.05-.34-4.32A5,5,0,0,0,21,2.2Q18.17,2,14.12,2c-2.74,0-5.07.07-7,.21A5,5,0,0,0,2.48,6.78Q2,12.47,2,20.07t.47,13.06A5,5,0,0,0,7,37.7q3.24.3,7.08.3t7-.29a5,5,0,0,0,4.57-4.55c.19-2.2.3-3.58.34-4.15Z" />
    </svg>
  );
}

export default IconSignOut;
