import React, { ReactNode } from "react";
import classNames from "classnames";

import { AsyncImg } from "../async-img/AsyncImg";

import styles from "./content-section.module.scss";

export enum ContentSectionGap {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export interface ContentSectionProps {
  gap?: keyof typeof ContentSectionGap | null;
  imageUrl?: string | React.ReactNode;
  className?: string;
  imageClassName?: string;
  wrapClassName?: string;
  contentClassName?: string;
  reverse?: boolean;
  centerText?: boolean;
  hasSideSpace?: boolean;
  imageSize?: number;
  children?: ReactNode;
}

export const ContentSection: React.FC<ContentSectionProps> = ({
  children,
  gap,
  className,
  imageClassName,
  wrapClassName,
  contentClassName,
  imageUrl,
  reverse,
  centerText,
  hasSideSpace = true,
  imageSize,
}) => {
  const defaultClassNames = classNames(
    styles.illustration,
    {
      [styles["gap--small"]]: gap === "SMALL" && !reverse,
      [styles["gap--small-reverse"]]: gap === "SMALL" && reverse,
      [styles["gap--medium"]]: gap === "MEDIUM" && !reverse,
      [styles["gap--medium-reverse"]]: gap === "MEDIUM" && reverse,
      [styles["gap--large"]]: gap === "LARGE" && !reverse,
      [styles["gap--large-reverse"]]: gap === "LARGE" && reverse,
      [styles["gap--extra-large"]]: gap === "EXTRA_LARGE" && !reverse,
      [styles["gap--extra-large-reverse"]]: gap === "EXTRA_LARGE" && reverse,
    },
    imageClassName,
  );

  return (
    <div
      className={classNames(
        {
          [styles["content-section--reverse"]]: reverse,
          [styles["content-section--center-text"]]: centerText,
          [styles["content-section--side-space"]]: hasSideSpace,
        },
        className,
      )}
    >
      <div className={classNames(styles.wrap, wrapClassName)}>
        {imageUrl && typeof imageUrl === "string" ? (
          <AsyncImg src={imageUrl} className={defaultClassNames} style={{ minWidth: `${imageSize}%` }} alt="" />
        ) : (
          imageUrl && <div className={defaultClassNames}>{imageUrl}</div>
        )}

        <div className={classNames(styles.main, contentClassName)}>{children}</div>
      </div>
    </div>
  );
};
