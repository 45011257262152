import React from "react";
import { useTranslation } from "react-i18next";

import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { Heading } from "../../../components/heading/Heading";
import { ViewSection } from "../../../components/view-section/ViewSection";
import discoverMemborsImage from "./assets/discoverMemborsImage.png";
import { SubTitle } from "../../../components/sub-title/SubTitle";
import { CustomAddShopCart } from "../banner-section/ProductMemborsBannerSection";
import { HeroSectionOption } from "../../../components/hero-section/HeroSection";
import { ServiceOfferAvailability, ServicePeriodCode, ServiceProductCode } from "../../../graphql/schema";
import { IconTag } from "../../../components/icon/IconTag";
import { CurrencySign, formatPrice } from "../../../components/price/Price";
import { Separator } from "../../../components/separator/Separator";

import styles from "./product-membors-intro-section.module.scss";

export interface ProductMemborsIntroSectionProps {
  productCode: keyof typeof ServiceProductCode;
  product?: HeroSectionOption & {
    availability?: ServiceOfferAvailability;
    upgradableSubscriptions?: { id?: string }[];
    comingSoon?: boolean;
    period?: { code?: ServicePeriodCode };
  };
}

export const ProductMemborsIntroSection: React.FC<ProductMemborsIntroSectionProps> = ({ productCode, product }) => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <ViewSection
      withBackground
      image={<AsyncImg src={discoverMemborsImage} className={styles.image} />}
      gap="GAP_40"
      gutter={160}
    >
      <Heading className={styles.title} gutter={8} level={4}>
        {t("Discover Membors.com")}
      </Heading>
      <SubTitle withBottomLine>{t("Passport to premium experiences")}</SubTitle>
      <div className={styles.content}>
        <p>
          {t(
            "Membors is an exclusive membership club that provides access to a diverse range of premium products and experiences, from travel discounts to educational courses, books, and software. ",
          )}
        </p>

        <p>
          {t(
            "It was created to consolidate the costs of multiple services into one affordable annual fee, allowing members to enjoy a variety of offerings without the expense of individual subscriptions, ultimately saving them money.",
          )}
        </p>
        {product && !product.comingSoon && (
          <div className={styles["cart-wrapper"]}>
            <CustomAddShopCart
              productCode={productCode}
              {...product}
              activeSubscriptionId={product?.upgradableSubscriptions?.[0]?.id}
              periodCode={product?.period?.code}
              fullPrice={product?.fullPriceNext ?? product?.fullPrice}
              discountedPrice={product?.fullPriceNext !== product?.fullPrice ? product?.fullPrice : null}
              className={styles["cart-button"]}
              labelClassName={styles["cart-button-label"]}
            />

            {product?.fullPriceNext !== product?.fullPrice &&
              product.availability === ServiceOfferAvailability.AVAILABLE_FOR_PURCHASE && (
                <span className={styles["discount-label"]}>
                  <IconTag />
                  {t("Save {{price}} now", {
                    price: `${formatPrice(
                      Number(product.fullPriceNext) - Number(product.fullPrice),
                      product.currencyCode,
                      true,
                    )}${CurrencySign[product.currencyCode]}`,
                  })}
                </span>
              )}
          </div>
        )}
      </div>

      <Separator className={styles["bottom-separator"]} type="SECONDARY" position="BOTTOM" />
    </ViewSection>
  );
};
