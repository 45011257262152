import React from "react";

export interface IconUser2Props {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconUser2: React.FC<IconUser2Props> = ({ hasTitle, className, fill, width = 15, height = 15 }) => (
  <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
    {hasTitle && <title>IconUser2</title>}
    <path
      fill={fill}
      fillRule="evenodd"
      d="M11.322 10.725A4.994 4.994 0 017.5 12.5a4.994 4.994 0 01-3.822-1.775.925.925 0 01-.09-1.044C4.308 8.387 5.79 7.5 7.5 7.5s3.19.887 3.913 2.181a.925.925 0 01-.091 1.044zM7.5 2.5a2.031 2.031 0 110 4.062 2.031 2.031 0 010-4.062zm0-2.5C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36 7.5-7.5C15 3.36 11.64 0 7.5 0z"
    />
  </svg>
);
