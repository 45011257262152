import { Option } from "../components/field/Field";
import { Country } from "../graphql/schema";

export function formatCountryOptions(countries: Country[]): Option[] {
  const options: Option[] = [];
  countries.forEach((c) => {
    if (c.name && c.id) {
      options.push({ label: c.name, value: c.id });
    }
  });

  return options;
}
