import React, { useEffect, useState } from "react";
import { useTranslation, TFunction } from "react-i18next";
import { toast } from "react-toastify";

import { Button } from "../../../../components/button/Button";
import { Heading, HeadingGutter } from "../../../../components/heading/Heading";
import { Modal, ModalKind } from "../../../../components/modal/Modal";
import { PanelWidth } from "../../../../components/panel/Panel";
import { ChangePlan } from "./change-plan/ChangePlan";
import { ChoosePeriod } from "./choose-period/ChoosePeriod";
import { Upgrade } from "./upgrade/Upgrade";
import { CancelSubscription } from "./cancel-subscription/CancelSubscription";
import { UpgradeSuccessful } from "./upgrade-successful/UpgradeSuccessful";
import {
  useCancelSubscriptionMutation,
  useDiscontinueServiceSubscriptionMutation,
  useContinueServiceSubscriptionMutation,
  ServiceSubscriptionStatus,
  ServiceProductCode,
  ServicePeriodCode,
  useUpgradeServiceSubscriptionMutation,
  CurrencyCode,
  PaymentMethod,
  useEditSubscriptionUpgradeOfferLazyQuery,
} from "../../../../graphql/schema";
import assertNever from "../../../../services/assertNever";
import { ContinueSubscription } from "./continue-subscription/ContinueSubscription";
import { getTitle } from "../../../../components/subscription-list-item/SubscriptionListItem";
import { formatLocaleDate } from "../../../../services/formatDate";
import { PAYMENT_METHODS_ORDER } from "../../../../components/payment-methods-list/PaymentMethodsList";

import styles from "./edit-subscription.module.scss";

export interface EditSubscriptionProps {
  subscription: {
    periodCode: ServicePeriodCode;
    status: ServiceSubscriptionStatus;
    productCodes: ServiceProductCode[];
    upgradeOffers: UpgradeOffer[];
    isLegacy: boolean;
    id: string;
    dateStart: string;
    billingAmount?: string | null;
    dateEnd?: string | null;
    billingDate?: string | null;
    name: string;
  };
  refetchSubscriptions(): void;
}

export interface UpgradeOffer {
  id: string;
  currency: { sign: string };
  discount: string;
  discountedPrice?: string | null;
  fullPrice: string;
  labelText?: string | null;
  period: { code: ServicePeriodCode };

  products: {
    code: ServiceProductCode;
    name: string;
  }[];
}

export interface UpgradeSubscriptionOffer {
  id: string;
  difference: string;
  differenceFee: string;
  differenceTax: string;
  differenceTotal: string;
  productCodes: ServiceProductCode[];
  offerId: string;
  offerPeriod: ServicePeriodCode;
  offerFullPrice: string;
  currencyCode: keyof typeof CurrencyCode;
  offerDiscountPrice?: string | null;
}

export enum EditSubscriptionState {
  CHANGE_PLAN = "CHANGE_PLAN",
  CHOOSE_PERIOD = "CHOOSE_PERIOD",
  UPGRADE = "UPGRADE",
  UPGRADE_SUCCESS = "UPGRADE_SUCCESS",
  DISCONTINUE = "DISCONTINUE",
  CONTINUE = "CONTINUE",
}

interface ModalCaptionProps {
  t: TFunction;
  caption: keyof typeof EditSubscriptionState;
  serviceSubscriptionTitle?: string;
  endDate?: string;
  upgradeOffer?: UpgradeSubscriptionOffer;
}

interface ModalHeaderProps {
  title: keyof typeof EditSubscriptionState;
  caption: keyof typeof EditSubscriptionState;
  serviceSubscriptionTitle: string;
  endDate: string | undefined;
  upgradeOffer: UpgradeSubscriptionOffer | undefined;
}

export const EditSubscription: React.FC<EditSubscriptionProps> = ({ subscription, refetchSubscriptions }) => {
  // check if subscription modal is open
  const [isEditSubscriptionModalOpen, setIsEditSubscriptionModalOpen] = useState(false);
  const [refetchSubscriptionsOnClose, setRefetchSubscriptionsOnClose] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disableModalClose, setDisableModalClose] = useState(false);

  /* Queries and mutations */
  const [cancelSubscriptions] = useCancelSubscriptionMutation();
  const [getUpgradeOffer] = useUpgradeServiceSubscriptionMutation();
  const [updateUpgradeOffer] = useEditSubscriptionUpgradeOfferLazyQuery();
  const [continueServiceSubscription] = useContinueServiceSubscriptionMutation();
  const [discontinueServiceSubscription] = useDiscontinueServiceSubscriptionMutation();

  // choose what modal content to render
  const [modalContent, setModalContent] = useState<keyof typeof EditSubscriptionState>("CHANGE_PLAN");
  const [upgradeOffer, setUpgradeOffer] = useState<UpgradeSubscriptionOffer | undefined>(undefined);

  // access translation keys
  const { t } = useTranslation();

  function closeModal() {
    if (isLoading) {
      return;
    }
    setModalContent("CHANGE_PLAN");
    setUpgradeOffer(undefined);
    setIsEditSubscriptionModalOpen(false);
  }

  /* Filter out upgrades into two different categories */
  const periodUpgrades = subscription.upgradeOffers.filter(
    (upgrade) => upgrade.period.code !== subscription.periodCode,
  );

  const productUpgrades = subscription.upgradeOffers.filter(
    (upgrade) => upgrade.period.code === subscription.periodCode,
  );

  useEffect(() => {
    if (!isEditSubscriptionModalOpen && refetchSubscriptionsOnClose) {
      refetchSubscriptions();
    }
  }, [isEditSubscriptionModalOpen, refetchSubscriptionsOnClose, refetchSubscriptions]);

  /* Handle Discontinue subscription */
  async function handleDiscontinueServiceSubscription() {
    setIsLoading(true);
    try {
      if (subscription.isLegacy) {
        await cancelSubscriptions({
          variables: { subscriptionId: parseInt(subscription.id, 10) },
          refetchQueries: ["SubscriptionsListView"],
        });
      } else {
        await discontinueServiceSubscription({
          variables: { subscriptionId: subscription.id },
          refetchQueries: ["SubscriptionsListView"],
        });
      }

      toast.success(t("Subscription cancelled successfully"));
      closeModal();
    } catch (err) {
      console.error("Canceling subscription was unsuccessful", { err });
      toast.error(t("Canceling subscription was unsuccessful"));
      closeModal();
    }
    setIsLoading(false);
  }

  /* Handle continue subscription */
  async function handleContinueServiceSubscription() {
    if (subscription.isLegacy) {
      console.error("Continuing subscription not allowed on legacy subscription");
      return;
    }

    setIsLoading(true);

    try {
      await continueServiceSubscription({
        variables: { subscriptionId: subscription.id },
        refetchQueries: ["SubscriptionsListView"],
      });

      toast.success(t("Subscription continued successfully"));
      closeModal();
    } catch (err) {
      console.error("Continuing subscription was unsuccessful", { err });
      toast.error(t("Continuing subscription was unsuccessful"));
      closeModal();
    }

    setIsLoading(false);
  }

  /* Get offer for subscription upgrade from API */
  async function handleGetUpgradeOffer(props: {
    upgradeId?: string;
    offerId?: string;
    productCode?: ServiceProductCode[];
    paymentMethod: PaymentMethod;
  }) {
    if (!props.offerId || !props.productCode) {
      console.error("Finding subscription upgrade was unsuccessful");
      toast.error(t("Finding subscription upgrade was unsuccessful"));
      closeModal();
      return;
    }

    setIsLoading(true);

    try {
      const res = props.upgradeId
        ? await updateUpgradeOffer({ variables: { id: props.upgradeId, paymentMethod: props.paymentMethod } }).then(
            (res) => res.data?.me.serviceSubscriptionUpgrade,
          )
        : await getUpgradeOffer({
            variables: {
              offerId: props.offerId,
              subscriptionId: subscription.id,
              paymentMethod: props.paymentMethod,
            },
          }).then((res) => res.data?.upgradeServiceSubscription);

      if (isEditSubscriptionModalOpen) {
        setUpgradeOffer(
          res
            ? {
                ...res,
                offerId: props.offerId,
                offerFullPrice: res.offer.fullPrice,
                productCodes: props.productCode,
                offerPeriod: res.offer.period.code,
                offerDiscountPrice: res.offer.discountedPrice,
                currencyCode: res.currency.code,
              }
            : undefined,
        );
      }
    } catch (err) {
      console.error("Upgrading subscription was unsuccessful", { err });
      toast.error(t("Upgrading subscription was unsuccessful"));
      closeModal();
    }

    setModalContent("UPGRADE");
    setIsLoading(false);
  }

  /* Upgrade subscription */
  async function onUpgradeSuccess() {
    setRefetchSubscriptionsOnClose(true);
    setModalContent("UPGRADE_SUCCESS");
  }

  return (
    <>
      {/* <Button
        className={styles.button}
        color="YELLOW"
        height="SMALL"
        borderRadius="SMALL"
        weight="BOLD"
        fontSize={14}
        onClick={() => setIsEditSubscriptionModalOpen(true)}
      >
        {t("Edit")}
      </Button> */}

      <Modal
        kind={ModalKind.SECONDARY}
        panelProps={{
          imagePath: "../../../images/illustrationSwitchPlan.png",
          width: PanelWidth.SMALL,
        }}
        isOpen={isEditSubscriptionModalOpen}
        close={() => (disableModalClose ? undefined : closeModal())}
        onReturn={
          modalContent === "UPGRADE"
            ? () => {
                setModalContent("CHANGE_PLAN");
              }
            : undefined
        }
      >
        <ModalHeader
          title={modalContent}
          caption={modalContent}
          serviceSubscriptionTitle={subscription.name}
          endDate={subscription.dateEnd ? subscription.dateEnd : undefined}
          upgradeOffer={upgradeOffer}
        />

        {/* change plan */}

        {modalContent === "CHANGE_PLAN" && (
          <ChangePlan
            subscription={subscription}
            onChangePlan={() => setModalContent("CHOOSE_PERIOD")}
            onDiscontinueSubscription={() => setModalContent("DISCONTINUE")}
            onContinueSubscription={() => setModalContent("CONTINUE")}
            onClose={() => setIsEditSubscriptionModalOpen(false)}
            hasPeriodUpgrades={periodUpgrades.length > 0}
            isModalLoading={isLoading}
            productUpgrades={productUpgrades}
            handleUpgradeOffer={async (offerId: string | undefined, productCode: ServiceProductCode[] | undefined) => {
              return handleGetUpgradeOffer({ offerId, productCode, paymentMethod: PAYMENT_METHODS_ORDER[0] });
            }}
          />
        )}

        {/* choose period */}

        {modalContent === "CHOOSE_PERIOD" && periodUpgrades.length > 0 && (
          <ChoosePeriod
            onSubmit={async () => undefined}
            onContinue={async (offerId: string | undefined, productCode: ServiceProductCode[] | undefined) =>
              handleGetUpgradeOffer({ offerId, productCode, paymentMethod: PAYMENT_METHODS_ORDER[0] })
            }
            upgradeOffers={periodUpgrades}
          />
        )}

        {/* upgrade */}

        {modalContent === "UPGRADE" && upgradeOffer && (
          <Upgrade
            upgradeOffer={{
              id: upgradeOffer.id,
              difference: upgradeOffer.difference,
              differenceFee: upgradeOffer.differenceFee,
              differenceTax: upgradeOffer.differenceTax,
              differenceTotal: upgradeOffer.differenceTotal,
              currency: { code: upgradeOffer.currencyCode },
              offer: {
                products: upgradeOffer.productCodes.map((code) => ({ code })),
                period: { code: upgradeOffer.offerPeriod },
                fullPrice: upgradeOffer.offerFullPrice,
                discountPrice: upgradeOffer.offerDiscountPrice,
              },
            }}
            isLoading={isLoading}
            onClose={closeModal}
            handleDisableModalClose={setDisableModalClose}
            // Display next view
            onUpgradeSuccess={onUpgradeSuccess}
            onPaymentMethodChange={(paymentMethod) => {
              console.log("payment method changed", paymentMethod);
              handleGetUpgradeOffer({
                paymentMethod,
                upgradeId: upgradeOffer.id,
                offerId: upgradeOffer.offerId,
                productCode: upgradeOffer.productCodes,
              });
            }}
          />
        )}

        {/* discontinue subscription */}

        {modalContent === "DISCONTINUE" && (
          <CancelSubscription
            onSuccess={handleDiscontinueServiceSubscription}
            onCancel={() => setModalContent("CHANGE_PLAN")}
          />
        )}

        {/* Continue subscription */}
        {modalContent === "CONTINUE" && (
          <ContinueSubscription
            onSuccess={handleContinueServiceSubscription}
            onCancel={() => setModalContent("CHANGE_PLAN")}
          />
        )}

        {/* upgrade successful */}

        {modalContent === "UPGRADE_SUCCESS" && (
          <UpgradeSuccessful
            onClose={() => {
              setModalContent("CHANGE_PLAN");
              setIsEditSubscriptionModalOpen(false);
            }}
          />
        )}
      </Modal>
    </>
  );
};

const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  caption,
  serviceSubscriptionTitle,
  endDate,
  upgradeOffer,
}) => {
  // access translation keys
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const formattedEndDate = endDate ? formatLocaleDate(endDate, i18n.language) : undefined;

  return (
    <>
      <Heading className={styles.title} level={3} gutter={HeadingGutter.MEDIUM}>
        {renderModalTitle(t, title)}
      </Heading>
      {renderModalCaption({ t, caption, serviceSubscriptionTitle, endDate: formattedEndDate, upgradeOffer }) !== "" && (
        <p
          className={styles.caption}
          dangerouslySetInnerHTML={{
            __html: renderModalCaption({
              t,
              caption,
              serviceSubscriptionTitle,
              endDate: formattedEndDate,
              upgradeOffer,
            }),
          }}
        />
      )}
    </>
  );

  function renderModalTitle(t: TFunction, title: keyof typeof EditSubscriptionState) {
    switch (title) {
      case "CHANGE_PLAN":
        return t("Edit subscription");
      case "CHOOSE_PERIOD":
        return t("Switch plan to save more!");
      case "UPGRADE":
        return t("Confirm the upgrade");
      case "UPGRADE_SUCCESS":
        return t("Thank you!");
      case "DISCONTINUE":
        return t("Are you sure you want to cancel?");
      case "CONTINUE":
        return t("Are you sure you want to continue?");
      default:
        return assertNever(title);
    }
  }

  function renderModalCaption({ caption, t, endDate, serviceSubscriptionTitle, upgradeOffer }: ModalCaptionProps) {
    const upgradedProduct = upgradeOffer
      ? upgradeOffer?.productCodes.length > 1
        ? serviceSubscriptionTitle
        : getTitle(upgradeOffer?.productCodes)
      : "";

    const period = upgradeOffer
      ? upgradeOffer?.productCodes.length > 1
        ? getTitle(upgradeOffer?.productCodes)
        : getServicePeriodText(upgradeOffer.offerPeriod)
      : "";

    switch (caption) {
      case "CHANGE_PLAN":
        return t("Choose one of the following options to edit your {{serviceSubscriptionTitle}} subscription.", {
          serviceSubscriptionTitle,
        });
      case "CHOOSE_PERIOD":
        return t(
          `It’s always smartest to choose longest billing period, because when you choose 6 month period, you’ll only pay for 5 (and get 1 month for free). But when you choose 12 month period, you’ll only pay for 9 months and get <strong>3 months absolutely for free!</strong>`,
        );
      case "UPGRADE":
        return "";
      case "UPGRADE_SUCCESS":
        return t(
          "Your {{upgradedProduct}} subscription plan has been successfully changed to {{period}}. You can always see and manage your subscriptions from “Subscriptions” tab on your account.",
          { upgradedProduct, period },
        );
      case "DISCONTINUE":
        return t(
          "Are you 100% sure you want to cancel your active {{serviceSubscriptionTitle}} subscription? You’ll lose access to the {{serviceSubscriptionTitle}} from {{endDate}}.",
          { serviceSubscriptionTitle, endDate },
        );
      case "CONTINUE":
        return t("Are you 100% sure you want to continue your {{serviceSubscriptionTitle}} subscription?", {
          serviceSubscriptionTitle,
        });
      default:
        return assertNever(caption);
    }
  }

  function getServicePeriodText(period: ServicePeriodCode) {
    switch (period) {
      case ServicePeriodCode.ONE_MONTH:
        return t("one month");
      case ServicePeriodCode.SIX_MONTHS:
        return t("6 months");
      case ServicePeriodCode.TWELVE_MONTHS:
        return t("12 months");
      case ServicePeriodCode.LIFETIME:
        return "";
      default:
        assertNever(period);
    }
  }
};
