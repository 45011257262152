import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ServiceOfferAvailability, ServicePeriodCode, ServiceProductCode } from "../../graphql/schema";
import { AddServicesToCartButton } from "../add-services-to-cart-button/AddServicesToCartButton";
import { isFirstPeriodDiscounted } from "../../services/isFirstPeriodDiscounted";
import { useServicePeriodTranslation } from "../../hooks/useServicePeriodTranslation";
import { ServiceIcon } from "../service-icon/ServiceIcon";
import { Layout } from "../layout/Layout";
import { Link } from "../link/Link";
import { CurrencySign, CurrencyCode } from "../price/Price";

import styles from "./suggestion.module.scss";

export interface SuggestionProps {
  name: string;
  to?: string;
  productCodes: (keyof typeof ServiceProductCode)[];
  periodCode: keyof typeof ServicePeriodCode;
  availability?: ServiceOfferAvailability | null;
  activeSubscriptionId?: string | null | undefined;
  fullPrice?: string;
  fullPriceNext?: string | null;
  discountedPrice?: string | null;
  discountedPriceNext?: string | null;
  currencyCode?: keyof typeof CurrencyCode;
}

export const Suggestion: React.FC<SuggestionProps> = ({
  name,
  to,
  productCodes,
  periodCode,
  fullPrice,
  fullPriceNext,
  discountedPrice,
  discountedPriceNext,
  currencyCode,
  availability,
  activeSubscriptionId,
}) => {
  const [t] = useTranslation();
  const translatePeriod = useServicePeriodTranslation();

  const period = translatePeriod(periodCode, "SHORT");
  const isDisclaimerVisible = fullPrice
    ? isFirstPeriodDiscounted({
        fullPrice,
        fullPriceNext,
        discountedPrice,
        discountedPriceNext,
      })
    : false;

  return (
    <Layout className={styles.wrap} wrap="TABLET_PORTRAIT_MAX">
      <div className={styles.section}>
        <span className={styles["icon-wrap"]}>
          {productCodes.length > 0 && <ServiceIcon productCode={productCodes[0]} className={styles["icon"]} />}
        </span>
        <span>
          <span
            className={styles.name}
            dangerouslySetInnerHTML={{
              __html: to
                ? t("Would you like to learn more about <strong>{{product}}?</strong>", { product: name })
                : t("Would you like to add <strong>{{product}}</strong> to your cart?", { product: name }),
            }}
          />
          {isDisclaimerVisible && (
            <span className={styles.disclaimer}>
              {t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                price: discountedPriceNext || fullPriceNext,
                currencySign: currencyCode ? CurrencySign[currencyCode] : "",
                period,
              })}
            </span>
          )}
        </span>
      </div>
      {to ? (
        <Link to={to} color="DARK_BLUE" borderRadius="SMALL" weight="BOLD">
          {t("View product")}
        </Link>
      ) : (
        <div className={styles["button-wrap"]}>
          <div className={styles["price-wrap"]}>
            {fullPrice && currencyCode && (
              <span className={classNames(styles.price, { [styles["price--old"]]: discountedPrice })}>
                {fullPrice}
                {CurrencySign[currencyCode]} / {period}
              </span>
            )}
            {discountedPrice && currencyCode && (
              <span className={styles.price}>
                {discountedPrice}
                {CurrencySign[currencyCode]} / {period}
                {isDisclaimerVisible && "*"}
              </span>
            )}
          </div>

          <AddServicesToCartButton
            productCodes={productCodes}
            periodCode={periodCode}
            availability={availability || null}
            activeSubscriptionId={activeSubscriptionId || null}
          />
        </div>
      )}
    </Layout>
  );
};
