import classNames from "classnames";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Benefits } from "../../components/benefits/Benefits";
import { Button } from "../../components/button/Button";
import { Container } from "../../components/container/Container";
import Field from "../../components/field/Field";
import { Form } from "../../components/form/Form";
import { Loader } from "../../components/loader/Loader";
import { Panel, PanelWidth } from "../../components/panel/Panel";
import { Separator, SeparatorGutter } from "../../components/separator/Separator";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { RequestInviteMutationVariables, useCountryQuery, useRequestInviteMutation } from "../../graphql/schema";
import { formatCountryOptions } from "../../services/formatCountryOptions";
import NotFoundView from "../not-found-view/NotFoundView";
import { USCountryCodes, USStates } from "../../services/constants";
import { tracker } from "../../libs/trackers";

import styles from "./requestInviteView.module.scss";

export const RequestInviteView: React.FC = () => {
  const [t] = useTranslation();

  const RequestInviteSchema = yup.object().shape({
    firstName: yup.string().required(t("First name is missing")),
    lastName: yup.string().required(t("Last name is missing")),
    phone: yup.string().required(t("Phone number is missing")),
    email: yup.string().required(t("Email is missing")).email(t("Not a valid email")),
    countryId: yup.string().required(t("Country is missing")),
    state: yup
      .string()
      .trim()
      .when("countryId", (countryId, schema) =>
        usCountryIds.includes(Number(countryId)) ? schema.required(t("State is missing")) : schema,
      ),
    city: yup
      .string()
      .trim()
      .when("countryId", (countryId, schema) =>
        usCountryIds.includes(Number(countryId)) ? schema.required(t("City is missing")).min(2).max(50) : schema,
      ),
  });

  const [invitationSuccessful, setInvitationSuccessful] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = React.useState<string | null>(null);

  // get window dimensions
  const { isMobile } = useWindowDimensionsContext();

  const { register, errors, handleSubmit } = useForm<RequestInviteMutationVariables>({
    resolver: yupResolver(RequestInviteSchema),
  });
  const { push } = useHistory();

  const [requestInvite, { loading: requestInviteLoading }] = useRequestInviteMutation();

  const { data: country, loading: isCountryLoading, error: countryError } = useCountryQuery();

  if (isCountryLoading || !country?.country.countries || requestInviteLoading) {
    return <Loader />;
  }

  if (countryError) {
    return <NotFoundView text={t("Something went wrong")} />;
  }

  const countries = country.country.countries ?? [];
  const usCountryIds = countries.filter((c) => c.code && USCountryCodes.includes(c.code)).map((c) => c.id);
  const isUsCountry = usCountryIds.includes(Number(selectedCountryId));

  const handleRequestInvite = handleSubmit(async ({ firstName, lastName, phone, email, countryId, state, city }) => {
    try {
      const result = await requestInvite({
        variables: {
          firstName,
          lastName,
          phone,
          email,
          state,
          city,
          countryId: parseInt(countryId.toString(), 10),
        },
      });

      if (result.data?.requestInvite) {
        tracker.trackEvent("requestInvite", { firstName, lastName, phone, email, country: countryId });

        setInvitationSuccessful(true);
      }
    } catch (e) {
      toast.error(t("Something get wrong, please try again"));
    }
  });

  return (
    <Container secondary>
      {invitationSuccessful ? (
        <Panel
          panelStyle={isMobile ? "SECONDARY" : "PRIMARY"}
          width={PanelWidth.MEDIUM}
          imagePath="/images/requestInviteIllustration.png"
          title={t("Thank you!")}
          caption={t(
            "We received your request and have sent it to somebody from your country. They will try to contact you as soon as possible, and meanwhile, you can still shop around!",
          )}
        >
          <Button
            className={classNames(styles.button, styles["button--success"])}
            type="submit"
            color="BLUE"
            borderRadius="MEDIUM"
            fontSize={16}
            weight="MEDIUM"
            stretch="MOBILE"
            onClick={() => push("/")}
          >
            {t("Back to shopping")}
          </Button>
        </Panel>
      ) : (
        <Panel
          panelStyle={isMobile ? "SECONDARY" : "PRIMARY"}
          width={PanelWidth.LARGE}
          imagePath="/images/requestInviteIllustration.png"
          title={t("Request an invite from the community...")}
          caption={
            <>
              <span className={styles.highlight}>
                <strong>{t("Important")}!</strong>{" "}
                {t(
                  "If someone already introduced you to SF, then please go back to that person to receive an Invitation Code.",
                )}{" "}
              </span>
              {t(
                "If you don’t have the invitation yet, but would like to join our community, you can request access. After requesting invitation, somebody from our community within your country will contact you and might share his invitation with you… ",
              )}
            </>
          }
        >
          <>
            <Form doubleColumn onSubmit={handleRequestInvite}>
              <Field
                label={t("First name")}
                name="firstName"
                internalRef={register}
                error={errors.firstName}
                isRequired
              />
              <Field label={t("Last name")} name="lastName" internalRef={register} error={errors.lastName} isRequired />
              <Field label={t("Email")} name="email" internalRef={register} error={errors.email} isRequired />
              <Field label={t("Phone")} name="phone" internalRef={register} error={errors.phone} />
              <Field
                label={t("Choose country")}
                name="countryId"
                type="select"
                internalRef={register}
                options={formatCountryOptions(countries)}
                error={errors.countryId}
                isRequired
                onChange={setSelectedCountryId}
              />
              {isUsCountry && (
                <>
                  <Field
                    type="select"
                    label={t("Choose state")}
                    name="state"
                    error={errors.state}
                    internalRef={register}
                    options={USStates.map((state) => ({ label: state, value: state }))}
                  />
                  <Field label={t("City")} name="city" internalRef={register} error={errors.city} />
                </>
              )}

              <Button
                className={styles.button}
                type="submit"
                color="BLUE"
                borderRadius="MEDIUM"
                fontSize={16}
                weight="MEDIUM"
                stretch
                disabled={requestInviteLoading}
              >
                {t("Request an invite")}
              </Button>
            </Form>
            <Separator gutter={SeparatorGutter.MEDIUM} />
            <Benefits />
          </>
        </Panel>
      )}
    </Container>
  );
};
