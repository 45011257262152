import classNames from "classnames";
import React from "react";

import { Breakpoints } from "../../services/constants";

import styles from "./heading.module.scss";

export enum HeadingGutter {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export interface HeadingProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  level: number;
  gutter?: HeadingGutter | number | false;
  thin?: boolean;
  white?: boolean;
  center?: boolean | keyof typeof Breakpoints;
  className?: string;
}

export const Heading: React.FC<HeadingProps> = ({
  level,
  gutter = HeadingGutter.MEDIUM,
  thin,
  white,
  center,
  className,
  children,
  ...rest
}) => {
  if (level > 6) {
    throw new Error("only levels 1-6 are accepted");
  }

  const levelClassName = `${styles[`h${level}` as keyof typeof styles]}`;

  type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

  const CustomHeading = `h${level}` as HeadingTag;

  const customGutter = typeof gutter === "number" ? gutter : undefined;

  return (
    <CustomHeading
      style={{ marginBottom: customGutter }}
      className={classNames(
        levelClassName,
        gutter === HeadingGutter.SMALL && styles["header--gutter-small"],
        gutter === HeadingGutter.MEDIUM && styles["header--gutter-medium"],
        gutter === HeadingGutter.SEMI_LARGE && styles["header--gutter-semi-large"],
        gutter === HeadingGutter.LARGE && styles["header--gutter-large"],
        gutter === HeadingGutter.EXTRA_LARGE && styles["header--gutter-extra-large"],
        thin && styles["header--thin"],
        white && styles["header--white"],
        center === true && styles.center,
        (styles as any)[`center--${center}`],
        className,
      )}
      {...rest}
    >
      {children}
    </CustomHeading>
  );
};
