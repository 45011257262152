import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button } from "../../components/button/Button";
import { Container } from "../../components/container/Container";
import Field, { FieldGutter } from "../../components/field/Field";
import { Form } from "../../components/form/Form";
import { Panel, PanelWidth } from "../../components/panel/Panel";
import { Separator, SeparatorGutter } from "../../components/separator/Separator";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { useForgottenLoginMutationMutation } from "../../graphql/schema";
import { Routes } from "../../services/constants";

import styles from "./forgotPasswordView.module.scss";

interface ValidateCodeFormValues {
  email: string;
  username: string;
}

export default function ForgotPasswordView() {
  const [t] = useTranslation();

  const { isMobile } = useWindowDimensionsContext();

  const validateCodeValidationSchema: yup.SchemaOf<ValidateCodeFormValues> = yup.object().shape({
    email: yup.string().required(t("Email is missing")),
    username: yup.string().required(t("Username is missing")),
  });

  const {
    register,
    handleSubmit,
    errors: userInputErrors,
  } = useForm<ValidateCodeFormValues>({
    resolver: yupResolver(validateCodeValidationSchema),
  });

  const [forgottenLoginMutation] = useForgottenLoginMutationMutation();

  const { push } = useHistory();

  const handleForgotPassword = handleSubmit(async ({ email, username }) => {
    try {
      const result = await forgottenLoginMutation({
        variables: { data: { email, username } },
        refetchQueries: ["AppAuthenticationStatus"],
      });

      if (!result || !result.data) {
        toast.error(t("Something went wrong, please try again"));

        return;
      }

      if (result.data.forgottenLogin.result.type === "SUCCESS") {
        toast.success(t("A link to reset your password has been sent to your e-mail."));
        push("/");

        return;
      }

      if (result.data.forgottenLogin.result.type === "VALIDATION_ERROR") {
        toast.error(t("Invalid username or email"));

        return;
      }
    } catch (e) {
      toast.error("error");
    }
  });

  return (
    <Container secondary>
      <Panel
        panelStyle={isMobile ? "SECONDARY" : "PRIMARY"}
        width={PanelWidth.MEDIUM}
        imagePath="/images/loginIllustration.png"
        title={t("Forgot your password?")}
        caption={t(
          "No problem! Just provide your username and email and we will send an email with instructions on how to restore the login password.",
        )}
      >
        <Form onSubmit={handleForgotPassword}>
          <Field
            type="text"
            label={t("Email")}
            name="email"
            internalRef={register}
            isRequired
            error={userInputErrors.email}
            gutter={FieldGutter.MEDIUM}
          />
          <Field
            type="text"
            label={t("Username")}
            name="username"
            internalRef={register}
            isRequired
            error={userInputErrors.username}
            gutter={FieldGutter.MEDIUM}
          />
          <Button
            className={styles["restore-password-button"]}
            color="YELLOW"
            borderRadius="SMALL"
            stretch="MOBILE"
            fontSize={16}
            weight="MEDIUM"
            gutter="LARGE"
          >
            {t("Restore password")}
          </Button>
        </Form>
        <Separator gutter={SeparatorGutter.MEDIUM} />
        <Link to={Routes.LOGIN} className={styles["login-link"]}>
          {t("Back to login")}
        </Link>
      </Panel>
    </Container>
  );
}
