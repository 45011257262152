import { useStripeCreditCardFormRegisterSourceMutation } from "../graphql/schema";

interface RegisterStripePaymentSourceProps {
  signature: string;
}

export function useRegisterPaymentSource(): [(props: RegisterStripePaymentSourceProps) => Promise<void>] {
  const [registerStripePaymentSource] = useStripeCreditCardFormRegisterSourceMutation();

  const setValue = async (props: RegisterStripePaymentSourceProps) => {
    // Register new card with given signature
    await registerStripePaymentSource({
      variables: {
        setupIntentClientSecret: props.signature,
      },
      refetchQueries: ["PaymentSources"],
    })
      // .then((res) => setStripeSourceId(res.data?.registerStripePaymentSource.id))
      .catch((e) => {
        console.error("Failed to save payment method for future use. Message: ", e.message);
        throw new Error("Failed to save payment method for future use.");
      });
  };

  return [setValue];
}
