import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { Button } from "../button/Button";
import Field, { FieldGutter } from "../field/Field";
import { Modal, ModalKind } from "../modal/Modal";
import { PanelWidth } from "../panel/Panel";
import { useRedeemGiftCardMutation } from "../../graphql/schema";

import styles from "./redeem-gift-card-modal.module.scss";

type GiftCardFormValues = {
  cardCode: string;
};

export interface RedeemGiftCardModalProps {
  refetchQueries: string[];
}

export const RedeemGiftCardModal: React.FC<RedeemGiftCardModalProps> = ({ refetchQueries }) => {
  // translation keys
  const { t } = useTranslation();
  const [redeemGiftCard] = useRedeemGiftCardMutation();
  const [cardCode, setCardCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { goBack } = useHistory();

  const giftCardCodeSchema = yup.object().shape({
    cardCode: yup.string(),
  });

  const { register, errors, setError, reset } = useForm<GiftCardFormValues>({
    resolver: yupResolver(giftCardCodeSchema),
  });

  const handleRedeemGiftCard = async () => {
    reset({ cardCode: "" });

    if (cardCode.length < 1) {
      setError("cardCode", { type: "notMatch", message: t("Card code is missing") || t("Invalid value") });
      return;
    }

    setIsLoading(true);

    const options = {
      variables: {
        cardCode,
      },
      refetchQueries: [...refetchQueries, "PaymentSources"],
    };

    try {
      await redeemGiftCard(options);

      toast.success(t("Gift card redeemed successfully"));
      goBack();
    } catch (error) {
      setError("cardCode", { type: "notMatch", message: t("Invalid value") });
    }
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={true}
      kind={ModalKind.SECONDARY}
      close={goBack}
      panelProps={{
        imagePath: "../../../../images/giftCardIllustration.png",
        title: t("Yay! Received a gift card?!"),
        width: PanelWidth.SMALL,
        caption: t(
          "To use a gift card, you have to first redeem it. After redeeming the gift card, the gift card value is added to your Shop Credit where you can use it for future purchases.",
        ),
      }}
    >
      <div className={styles["modal-field-wrap"]}>
        <Field
          label={t("Gift card code")}
          gutter={FieldGutter.MEDIUM}
          onChange={(e) => setCardCode(e)}
          internalRef={register}
          name={"cardCode"}
          isRequired
          error={errors.cardCode}
        />
        <Button
          color="BLUE"
          borderRadius="SMALL"
          fontSize={18}
          weight="MEDIUM"
          center="BLOCK_AND_MARGIN"
          width={280}
          onClick={handleRedeemGiftCard}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t("Redeem gift card")}
        </Button>
      </div>
    </Modal>
  );
};
