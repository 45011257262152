import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";

import Price, { CurrencyCode } from "../../../price/Price";
import { Layout } from "../../../layout/Layout";
import { isLoggedInVar } from "../../../../cache/isLoggedIn";

import styles from "./cart-totals.module.scss";

export interface CartTotalsProps {
  shippingCountryId?: number | string | null;
  taxPrice?: string | null;
  totalPrice?: string | null;
  totalFee?: string | null;
  currencyCode?: keyof typeof CurrencyCode;
  className?: string;
}

export const CartTotals: React.FC<CartTotalsProps> = ({ taxPrice, totalFee, totalPrice, currencyCode, className }) => {
  const [t] = useTranslation();

  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return (
    <div className={classNames(styles.totals, className)}>
      {taxPrice && (
        <Layout gutter="SMALL">
          {t("Taxes")}
          <Price price={taxPrice} currency={currencyCode} />
        </Layout>
      )}

      {totalFee && (
        <Layout gutter="SMALL">
          {t("Fee")}
          <Price price={totalFee} currency={currencyCode} />
        </Layout>
      )}

      {totalPrice && (
        <Layout>
          {t("Total").toUpperCase()}
          <strong>
            <Price price={totalPrice} currency={currencyCode} />
          </strong>
        </Layout>
      )}

      {!taxPrice && !isLoggedIn && (
        <p style={{ fontSize: 14, textAlign: "right", marginTop: 30 }}>
          {t("Additional taxes may be applied. Please log in to see the details")}
        </p>
      )}
    </div>
  );
};
