import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./columns.module.scss";

export interface ColumnsProps {
  className?: string;
  minWidth?: string | number;
  gap?: string | number;
  debug?: boolean;
  children?: ReactNode;
}

/**
 * @see https://web.dev/one-line-layouts/#03.-sidebar-says:-grid-template-columns:-minmax(lessmingreater-lessmaxgreater)-...)
 */
export const Columns: React.FC<ColumnsProps> = ({ children, className, minWidth = 0, gap = 0, debug = false }) => {
  const columnsStyle: React.CSSProperties = {
    gridTemplateColumns: `repeat(auto-fit, minmax(${numberToPx(minWidth)}, 1fr))`,
    gridGap: numberToPx(gap),
  };

  return (
    <div
      className={classNames(
        styles.columns,
        { [styles.debug]: debug && process.env.NODE_ENV === "development" },
        className,
      )}
      style={columnsStyle}
    >
      {children}
    </div>
  );
};

function numberToPx(value: string | number) {
  if (typeof value === "number") {
    return `${value}px`;
  }

  return value;
}
