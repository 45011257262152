import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { PaymentResult } from "../../components/payment-result/PaymentResult";
import { Routes } from "../../services/constants";
import { CartUpSellView } from "../cart-upsell/CartUpsellView";
import { CartView } from "../cart-view/CartView";
import { OrderCompleteView } from "../order-complete-view/OrderCompleteView";
import { PaymentFailedView } from "../payment-failed-view/PaymentFailedView";

export default function CheckOutView() {
  return (
    <Switch>
      <Route path={`${Routes.CHECKOUT_RESULT}/:flow/:id`} component={PaymentResult} />
      <Route exact path={Routes.CHECKOUT_SUCCESS} component={OrderCompleteView} />
      <Route exact path={Routes.CHECKOUT_FAILED} component={PaymentFailedView} />
      <Route path={Routes.CHECKOUT_CART_UP_SELL} component={CartUpSellView} />
      <Route path={Routes.CHECKOUT_CART} component={CartView} />
      <Redirect exact from="/checkout" to={Routes.CHECKOUT_CART} />
    </Switch>
  );
}
