import React from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "../../../components/heading/Heading";
import { Loader } from "../../../components/loader/Loader";
import { Section, SectionGutter } from "../../../components/section/Section";
import { SharePanel } from "../../../components/share-panel/SharePanel";
import { IconSend } from "../../../components/icon/IconSend";
import { MembershipLinkPanel } from "../../../components/membership-link-panel/MembershipLinkPanel";
import { ServiceIcon } from "../../../components/service-icon/ServiceIcon";
import {
  DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT,
  FOREX_DISCOUNT_MEMBERSHIP_PRICE,
  Routes,
} from "../../../services/constants";
import { StatsListing } from "../../../components/stats-listing/StatsListing";
import { ReferralsSubscriptionStatus, useDriveForFiveViewQuery } from "../../../graphql/schema";
import NotFound from "../../../components/not-found/NotFound";

import styles from "./drive-for-five-view.module.scss";

export const DriveForFiveView: React.FC = () => {
  const { t } = useTranslation();
  const { loading, data, previousData, error } = useDriveForFiveViewQuery();

  const username = data?.me.info?.username ?? "";
  const inviteLink = data?.me.info?.inviteLink;
  const subscriptions = (data?.me.serviceSubscriptions ?? []).filter(
    ({ referralsSubscriptionsForCurrentMonth }) => !!referralsSubscriptionsForCurrentMonth, // hide all subscriptions that have no referrals at all
  );

  // values for the 'Stats' section
  const stats = [
    {
      value: data?.me.referralsStats.registrations ?? 0,
      label: t("Total visitors"),
    },
    {
      value: data?.me.referralsStats.customers ?? 0,
      label: t("Sign ups"),
    },
    {
      value: data?.me.referralsStats.active ?? 0,
      label: t("Active friends"),
    },
  ];

  if (error) {
    return <NotFound>{t("Something went wrong. Please reload the page or contact support")}</NotFound>;
  }

  return (
    <>
      <Loader cover visibleOnFirstLoad={{ loading, previousData, data }} />
      <Section className={styles["intro-wrap"]} gutter={SectionGutter.LARGE} withSpace centerSelf>
        <Heading className={styles.title} level={3} center>
          {t("INVITE {{usersCount}} FRIENDS & GET YOUR MEMBERSHIP FOR FREE OR GET A DISCOUNT!", {
            usersCount: DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT,
          })}
        </Heading>
        <p className={styles.paragraph}>
          {t(
            "If you enjoy and benefit from SF Suite products, why not invite your friends to become Forex Insider members?",
          )}
        </p>
        <p className={styles.paragraph}>
          {t(
            "If you are an active Forex Insiders member, you will receive a one-month free membership when you sign up four new users with an active membership.",
          )}
        </p>
        <p
          className={styles.paragraph}
          dangerouslySetInnerHTML={{
            __html: t(
              "And if you are an active Forex insiders <strong>Pro member</strong>, you will receive either <strong>a free membership or a {{price}}{{currency}} discount*</strong> when you renew your membership.",
              { price: FOREX_DISCOUNT_MEMBERSHIP_PRICE, currency: data?.me.currency.sign },
            ),
          }}
        />
        <p className={styles.paragraph}>
          {t(
            "Access your account today, share your invite link with your friends, or post it on your Facebook, Instagram, or other social media.",
          )}
        </p>

        <p className={styles["small-paragraph"]}>
          {t(
            "* The free membership or discount depends on the {{usersCount}} new users you have signed up and the type of their active membership - Regular or Pro",
            {
              usersCount: DRIVE_FOR_FIVE_REQUIRED_SUBSCRIPTIONS_COUNT,
            },
          )}
        </p>
      </Section>
      {/* share on media section */}
      {inviteLink && (
        <SharePanel
          color="ORANGE_GRADIENT"
          icon={
            <Section gutter={10}>
              <IconSend width={50} height={38} fill="#fff" opacity={0.5} />
            </Section>
          }
          title={t("{{username}}, this is your private invite link!", { username })}
          gutter={130}
          linkUrl={inviteLink}
        />
      )}
      {/* Stats section */}
      <Section gutter={SectionGutter.SEMI_LARGE} center withSpace>
        <StatsListing stats={stats} horizontal="TABLET_PORTRAIT_UP" width={780} gap="LARGE" />
      </Section>
      {/* membership links */}
      <Section gutter={150} withSpace center>
        {subscriptions.map((subscription) => (
          <MembershipLinkPanel
            key={subscription.id}
            activeBadgesCount={getBadgesCount(
              ReferralsSubscriptionStatus.ACTIVE,
              subscription.referralsSubscriptionsForCurrentMonth,
            )}
            pendingBadgesCount={getBadgesCount(
              ReferralsSubscriptionStatus.PENDING,
              subscription.referralsSubscriptionsForCurrentMonth,
            )}
            to={`${Routes.DRIVE_FOR_FIVE}/${subscription.id}`}
            logo={
              <>
                {subscription.products.map(({ code }) => (
                  <ServiceIcon key={code} productCode={code} />
                ))}
              </>
            }
            isFree={!!subscription.referralsSubscriptionsForCurrentMonth?.freeExtension}
            name={getProductName(subscription.products)}
          >
            {subscription.referralsStatsForCurrentMonth &&
              t(
                "In total you have invited {{visitorsCount}} unique visitors, who have created {{signUpsCount}} sign-ups and {{activeBadgesCount}} of them have a current active subscription. Click here to see it in detail.",
                {
                  visitorsCount: subscription.referralsStatsForCurrentMonth.registrations,
                  signUpsCount: subscription.referralsStatsForCurrentMonth.customers,
                  activeBadgesCount: subscription.referralsStatsForCurrentMonth.active,
                },
              )}
          </MembershipLinkPanel>
        ))}
      </Section>
    </>
  );
};

export function getProductName(products: { name: string }[] = []) {
  return products.map((p) => p.name).join(" + ");
}

function getBadgesCount(
  requiredStatus: ReferralsSubscriptionStatus,
  subscriptions?: { records: { status: ReferralsSubscriptionStatus }[] } | null,
) {
  if (!subscriptions) {
    return 0;
  }

  return subscriptions.records.filter(({ status }) => status === requiredStatus).length;
}
