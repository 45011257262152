import React, { ReactNode } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Button } from "../button/Button";
import IconArrowTriangle from "../icon/IconArrowTriangle";
import { IconTrashbin } from "../icon/IconTrashbin";
import { Layout } from "../layout/Layout";

import styles from "./payment-option.module.scss";

export interface PaymentOptionProps {
  label: string;
  icon?: ReactNode;
  isCardExpired?: boolean;
  dragAndDropEnabled?: boolean;
  caption?: string;
  expirationDate?: string;
  className?: string;
  children?: ReactNode;
  onDelete?(): void;
}

export const PaymentOption: React.FC<PaymentOptionProps> = ({
  children,
  label,
  icon,
  isCardExpired,
  dragAndDropEnabled,
  caption,
  expirationDate,
  className,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.wrap, className)}>
      <div className={styles.content}>
        {dragAndDropEnabled && (
          <div className={styles["drag-and-drop-wrap"]}>
            <IconArrowTriangle className={styles["drag-and-drop-arrow--top"]} height={10} />
            <IconArrowTriangle className={styles["drag-and-drop-arrow--bottom"]} height={10} />
          </div>
        )}
        {icon && <div className={styles["icon-wrap"]}>{icon}</div>}
        <div className={styles.label}>
          <div>{label}</div>
          <Layout wrap="MOBILE" className={styles["label-content"]}>
            {caption && <span className={styles.caption}>{caption}</span>}
            {expirationDate && (
              <span
                className={classNames(styles["expiration-date"], {
                  [styles["expiration-date--expired"]]: isCardExpired,
                })}
              >
                {expirationDate} {isCardExpired && t("Expired")}
              </span>
            )}
          </Layout>
        </div>
      </div>
      {onDelete && (
        <Button className={styles["delete-button"]} shape="RECT" kind="SOLID" borderRadius="MEDIUM" onClick={onDelete}>
          <IconTrashbin className={styles["icon-trashbin"]} fill="#fff" width={11} height={14} />
        </Button>
      )}
      {children}
    </div>
  );
};
