import React from "react";
import { useTranslation } from "react-i18next";

import IconBankNote from "../icon/IconBankNote";
import IconCheck from "../icon/IconCheck";
import IconPercentage from "../icon/IconPercentage";
import IconSafe from "../icon/IconSafe";

import styles from "./benefits.module.scss";

export const Benefits: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className={styles["benefits-wrap"]}>
      <div className={styles.benefit}>
        <IconPercentage className={styles["benefit-icon"]} />
        <span>{t("Get special deals")}</span>
      </div>

      <div className={styles.benefit}>
        <IconCheck className={styles["benefit-icon"]} />
        <span>{t("Global community events")}</span>
      </div>

      <div className={styles.benefit}>
        <IconSafe className={styles["benefit-icon"]} />
        <span>{t("Exclusive products")}</span>
      </div>

      <div className={styles.benefit}>
        <IconBankNote className={styles["benefit-icon"]} />
        <span>{t("Become an affiliate")}</span>
      </div>
    </div>
  );
};
