import React from "react";
import { useTranslation, TFunction } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  useInvoicesListViewQuery,
  PaymentStatus,
  PaymentMethod,
  PaymentSourceUnion,
  PaymentEntityType,
} from "../../../graphql/schema";
import { StatusIcon, StatusIconStatus } from "../../../components/status-icon/StatusIcon";
import { Section } from "../../../components/section/Section";
import { Loader } from "../../../components/loader/Loader";
import NotFound from "../../../components/not-found/NotFound";
import { StatusLabel, StatusLabelColor } from "../../../components/status-label/StatusLabel";
import assertNever from "../../../services/assertNever";
import Price from "../../../components/price/Price";
import DateTime from "../../../components/date-time/DateTime";
import { IconCloud } from "../../../components/icon/IconCloud";

import styles from "./invoices-list-view.module.scss";

const ITEMS_PER_PAGE = 20;

export const InvoicesListView: React.FC = () => {
  // accestt translation keys
  const [t] = useTranslation();

  // number of invoices loaded on start

  // fetch invoice query
  const { data, fetchMore, error, loading, previousData } = useInvoicesListViewQuery({
    variables: { offset: 0, limit: ITEMS_PER_PAGE },
  });

  const invoiceList = data?.me.serviceSubscriptionInvoices.rows || [];
  const hasInvoices = loading || invoiceList.length > 0;
  const totalRows = data?.me.serviceSubscriptionInvoices.totalRows || 0;

  if (error) {
    return <NotFound>{t("Something went wrong. Please reload the page or contact support")}</NotFound>;
  }

  if (!hasInvoices) {
    return <NotFound>{t("You have no invoices")}</NotFound>;
  }

  return (
    <Section gutter={100} withSpace className={styles.wrap}>
      <Loader cover visibleOnFirstLoad={{ loading, previousData, data }} />

      <InfiniteScroll
        dataLength={invoiceList.length}
        next={() => {
          fetchMore({ variables: { offset: invoiceList.length } });
        }}
        hasMore={!!data && data.me.serviceSubscriptionInvoices.rows.length < totalRows}
        loader={<div>Loading...</div>}
      >
        {data?.me.serviceSubscriptionInvoices.rows.map((invoice) => (
          <div key={invoice.id} className={styles["term-grid"]}>
            <StatusIcon className={styles.icon} status={getStatusIconStatus(invoice.status)} />

            <div>
              <h2 className={styles.title}>{t("Order {{orderNo}}", { orderNo: invoice.orderNo })}</h2>

              <p
                className={styles["title-secondary"]}
                title={getServiceNameTranslation(
                  t,
                  invoice.paymentType,
                  invoice.products.reduce((res, row) => [...res, ...row.productNames], [] as string[]),
                )}
              >
                {getServiceNameTranslation(
                  t,
                  invoice.paymentType,
                  invoice.products.reduce((res, row) => [...res, ...row.productNames], [] as string[]),
                )}
              </p>
            </div>

            <div>
              <DateTime value={invoice.createdDate} />
            </div>

            <div>
              <strong className={styles.title}>
                {invoice.currency && invoice.amount && (
                  <Price price={invoice.amount} currency={invoice.currency.code} />
                )}
              </strong>
              {invoice.paymentMethod &&
                getPaymentMethodTranslation(
                  t,
                  invoice.paymentMethod,
                  invoice.paymentMethodName,
                  invoice.paymentSource?.info,
                )}
            </div>

            <div>
              <StatusLabel color={getStatusLabelColor(invoice.status)}>
                {getStatusIconTranslation(t, invoice.status)}
              </StatusLabel>
            </div>
            {invoice.status === PaymentStatus.SUCCESS && (
              <a
                href={`${process.env.REACT_APP_SUCCESSFACTORY_SERVER_PROXY}/pdf/invoice/${invoice.id}`}
                className={styles.pdf}
                target="_blank"
                rel="noreferrer"
              >
                <IconCloud fill="#ff871c" />
              </a>
            )}
          </div>
        ))}
      </InfiniteScroll>
    </Section>
  );
};

function getStatusIconStatus(status: PaymentStatus): StatusIconStatus {
  switch (status) {
    case PaymentStatus.SUCCESS:
      return StatusIconStatus.SUCCESS;

    case PaymentStatus.INVALID:
    case PaymentStatus.UNRESOLVED:
      return StatusIconStatus.ERROR;

    case PaymentStatus.NEW:
    case PaymentStatus.CANCELLED:
    case PaymentStatus.REFUNDED:
    case PaymentStatus.PENDING:
    case PaymentStatus.PROCESSED:
      return StatusIconStatus.CANCEL;

    default:
      return assertNever(status);
  }
}

function getStatusLabelColor(status: PaymentStatus): StatusLabelColor {
  switch (status) {
    case PaymentStatus.SUCCESS:
      return StatusLabelColor.GREEN;

    case PaymentStatus.INVALID:
    case PaymentStatus.UNRESOLVED:
      return StatusLabelColor.GRAY;

    case PaymentStatus.NEW:
    case PaymentStatus.CANCELLED:
    case PaymentStatus.REFUNDED:
    case PaymentStatus.PENDING:
    case PaymentStatus.PROCESSED:
      return StatusLabelColor.GRAY;

    default:
      return assertNever(status);
  }
}

function getStatusIconTranslation(t: (key: string) => string, status: PaymentStatus) {
  switch (status) {
    case PaymentStatus.CANCELLED:
      return t("Cancelled");

    case PaymentStatus.NEW:
    case PaymentStatus.PENDING:
    case PaymentStatus.PROCESSED:
      return t("Payment pending");

    case PaymentStatus.INVALID:
    case PaymentStatus.UNRESOLVED:
      return t("Payment failed");

    case PaymentStatus.SUCCESS:
      return t("Paid");

    case PaymentStatus.REFUNDED:
      return t("Refunded");
    default:
      return assertNever(status);
  }
}

function getServiceNameTranslation(t: TFunction, paymentType?: PaymentEntityType | null, productNames: string[] = []) {
  if (productNames.length > 0) {
    const isSingular = productNames.length === 1;

    // renewal
    if (paymentType === PaymentEntityType.PURCHASE_OF_SERVICE_SUBSCRIPTION_RENEWAL) {
      return isSingular
        ? t("{{service}}", { service: productNames[0] })
        : t("{{productCount}} products ({{productNames}})", {
            productCount: productNames.length,
            productNames: productNames.join(", "),
          });
    }

    // upgrade
    if (paymentType === PaymentEntityType.PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE) {
      return isSingular
        ? t("{{service}}", { service: productNames[0] })
        : t("{{productCount}} products ({{productNames}})", {
            productCount: productNames.length,
            productNames: productNames.join(", "),
          });
    }

    return isSingular
      ? productNames[0]
      : t("{{productCount}} products ({{productNames}})", {
          productCount: productNames.length,
          productNames: productNames.join(", "),
        });
  }

  if (paymentType === PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS) {
    return t("Cart purchase");
  }
  if (paymentType === PaymentEntityType.PURCHASE_OF_SHOP_CREDIT) {
    return t("Shop credit Top Up");
  }
  if (paymentType === PaymentEntityType.PURCHASE_OF_GIFT_CARD) {
    return t("Gift card");
  }

  return "";
}

function getPaymentMethodTranslation(
  t: TFunction,
  paymentMethod: PaymentMethod,
  paymentMethodName?: string | null,
  paymentSourceInfo?: { __typename: PaymentSourceUnion["__typename"]; last4?: string | null } | null,
) {
  // translations for new system
  if (paymentMethod === PaymentMethod.BANK_CARD) {
    switch (paymentSourceInfo?.__typename) {
      case "PaymentSourceCreditCard":
        return paymentSourceInfo.last4
          ? t("by Card ending {{cardNumber}}", { cardNumber: paymentSourceInfo.last4 })
          : t("by Card");

      case "PaymentSourceShopCredit":
        return t("by Shop Credit");

      default:
        return t("by {{serviceName}}", { serviceName: paymentMethodName });
    }
  }

  if (paymentMethodName) {
    return t("by {{serviceName}}", { serviceName: paymentMethodName });
  }

  // translations for old system
  let serviceName;

  switch (paymentMethod) {
    case PaymentMethod.ACTIVATION_CODE:
      serviceName = t("Activation Code");
      break;

    case PaymentMethod.BANK_WIRE:
      serviceName = t("Gift Card");
      break;

    default:
      serviceName = paymentMethod.toLowerCase().replace(/_/g, " ");
      serviceName = `${serviceName[0].toUpperCase()}${serviceName.slice(1)}`;
      break;
  }

  return serviceName ? t("by {{serviceName}}", { serviceName }) : "";
}
