export type LocalStorageKey = "installedAppRegistrationId" | "localCartItems" | "upsellIds" | "crowdin-build-timestamp";

export const setItem = (key: LocalStorageKey, value: string) => {
  window.localStorage.setItem(key, value);
};

export const getItem = (key: LocalStorageKey) => {
  return window.localStorage.getItem(key);
};

export const removeItem = (key: LocalStorageKey) => {
  if (!getItem(key)) {
    return false;
  }

  window.localStorage.removeItem(key);

  return getItem(key) ? true : false;
};
