import { getCookie } from "../../../services/getCookie";
import { ConsentValues, CookieConsentCategory, TrackerEventParams, TrackerEventType } from "../types";

export abstract class BaseTracker {
  protected isInitialized = false;

  constructor(protected consentCategory: CookieConsentCategory) {
    if (!this.isConsentGranted()) {
      return;
    }

    this.isInitialized = this.initialize();
  }

  /**
   * Method for tracker setup. Returns true if tracker was initialized
   */
  protected abstract initialize(): boolean;

  /**
   * Method for tracker teardown.
   */
  protected abstract destroy(): void;

  /**
   * Link user with tracker
   */
  public abstract identify(userId: string | number, params?: TrackerEventParams): void;

  /**
   * Unlink user with tracker
   */
  public abstract unIdentify(): void;

  /**
   * Track any event
   */
  public abstract trackEvent(event: TrackerEventType, params?: TrackerEventParams): void;

  public abstract trackError(error: Error, from?: string): void;

  protected isConsentGranted() {
    if (this.consentCategory === CookieConsentCategory.FUNCTIONAL) {
      return true;
    }

    const cookie = getCookie<ConsentValues | null>("sf_cookie_consent", null);

    return !!cookie && cookie[this.consentCategory] === true;
  }

  public reload() {
    this.destroy();

    if (this.isConsentGranted()) {
      this.isInitialized = this.initialize();
    }
  }
}
