import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./separator.module.scss";

export enum SeparatorGutter {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export enum SeparatorType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum SeparatorPosition {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

export interface SeparatorProps {
  gutter?: SeparatorGutter;
  type?: keyof typeof SeparatorType;
  position?: keyof typeof SeparatorPosition;
  onBackground?: boolean;
  className?: string;
  children?: ReactNode;
}

export const Separator: React.FC<SeparatorProps> = ({
  gutter,
  type = "PRIMARY",
  position = "TOP",
  onBackground,
  className,
  children,
}) => {
  if (type === "SECONDARY") {
    return (
      <div
        className={classNames(
          styles["separator--secondary"],
          styles.position,
          {
            [styles["position--top"]]: position === "TOP",
            [styles["position--bottom"]]: position === "BOTTOM",
            [styles["position--on-background"]]: onBackground,
          },
          className,
        )}
      />
    );
  }

  return (
    <div
      className={classNames(
        styles.separator,
        {
          [styles["separator--gutter-small"]]: gutter === SeparatorGutter.SMALL,
          [styles["separator--gutter-medium"]]: gutter === SeparatorGutter.MEDIUM,
          [styles["separator--gutter-semi-large"]]: gutter === SeparatorGutter.SEMI_LARGE,
          [styles["separator--gutter-large"]]: gutter === SeparatorGutter.LARGE,
          [styles["separator--gutter-extra-large"]]: gutter === SeparatorGutter.EXTRA_LARGE,
          [styles["separator--has-children"]]: children,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
