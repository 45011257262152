import { TFunction } from "react-i18next";

import { ValidationErrorType } from "../graphql/schema";

const translations: { [K in ValidationErrorType]?: string } = {
  RESOURCE_DOES_NOT_EXIST: "Requested resource does not exist",
  NOT_ENOUGH_FUNDS: "Not enough funds",
};

export function translateValidationError(t: TFunction, error: any) {
  const type: keyof typeof translations | null = error?.type ?? null;

  if (type && type in translations) {
    return t(translations[type] || "Something went wrong");
  }

  return error?.message || t("Something went wrong");
}
