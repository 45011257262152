import React from "react";
import classNames from "classnames";

import IconArrowTriangle from "../icon/IconArrowTriangle";

import styles from "./play-button.module.scss";

export interface PlayButtonProps {
  center?: boolean;
  onClick?(): void;
}

export const PlayButton: React.FC<PlayButtonProps> = ({ center, onClick }) => {
  return (
    <button className={classNames(styles.button, { [styles["button--center"]]: center })} onClick={onClick}>
      <IconArrowTriangle className={styles.icon} />
    </button>
  );
};
