import { FC, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Button } from "../button/Button";
import Field from "../field/Field";
import { IconExpand } from "../icon/IconExpand";
import { Form } from "../form/Form";
import { CurrencyCode, CurrencySign } from "../price/Price";

import styles from "./discount-coupon.module.scss";

export interface DiscountCouponData {
  id: string;
  percent?: number | null;
  value?: {
    amount: string;
    currency: {
      code: keyof typeof CurrencyCode;
    };
  } | null;
}
export interface DiscountCouponProps {
  discountCouponData?: DiscountCouponData | null;
  onAdd(value: string): Promise<void>;
  onRemove(id: string): Promise<void>;
  className?: string;
}

export const DiscountCoupon: FC<DiscountCouponProps> = ({ discountCouponData, onRemove, onAdd, className }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currencySign = discountCouponData?.value?.currency.code
    ? CurrencySign[discountCouponData?.value?.currency.code]
    : "";

  const discount = discountCouponData?.percent
    ? `${discountCouponData.percent}%`
    : `${discountCouponData?.value?.amount} ${currencySign}`;

  async function handleSubmit() {
    setIsSubmitting(true);

    return onAdd(value).finally(() => {
      setIsSubmitting(false);
      setValue("");
    });
  }

  async function handleRemove() {
    setIsSubmitting(false);
    setValue("");

    if (!discountCouponData) {
      return;
    }

    onRemove(discountCouponData.id);
  }

  if (discountCouponData) {
    return (
      <div className={classNames(styles.wrap, styles["wrap-with-discount"])}>
        {t("-{{discount}} discount applied", { discount })}{" "}
        <Button className={styles["button-close"]} onClick={handleRemove}>
          <IconExpand width={16} height={16} className={styles["close-icon"]} />
        </Button>
      </div>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        className={classNames(styles.wrap, className)}
        label={t("Coupon code")}
        defaultValue={value}
        onChange={setValue}
        submitButton={
          <Button
            className={styles.button}
            type="submit"
            height="SMALL"
            borderRadius="SMALL"
            color="ORANGE"
            disabled={!value}
            isLoading={isSubmitting}
          >
            {t("Apply")}
          </Button>
        }
      />
    </Form>
  );
};
