import React, { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import classNames from "classnames";

import styles from "./mobile-sub-menu.module.scss";

export interface MobileSubMenuProps {
  children?: ReactNode;
}

export interface MobileSubMenuItemProps extends LinkProps {
  title: string;
  handleClick(): void;
  isDisabled?: boolean;
}

export const MobileSubMenu: React.FC<MobileSubMenuProps> = ({ children }) => (
  <ul className={styles["menu-wrap"]}>{children}</ul>
);

export const MobileSubMenuItem: React.FC<MobileSubMenuItemProps> = ({ handleClick, title, isDisabled, ...rest }) => (
  <li className={styles["menu-item"]}>
    {isDisabled ? (
      <span className={classNames(styles.link, styles["link--disabled"])}>{title}</span>
    ) : (
      <Link {...rest} className={styles.link} onClick={handleClick}>
        {title}
      </Link>
    )}
  </li>
);
