import React, { ReactNode } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ServiceProductCode, ServicePeriodCode, CurrencyCode, ServiceOfferAvailability } from "../../graphql/schema";
import { AsyncImg } from "../async-img/AsyncImg";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";
import { VideoButton } from "../video-button/VideoButton";
import { ProductSelector } from "../product-selector/ProductSelector";
import { AddServicesToCartButton } from "../add-services-to-cart-button/AddServicesToCartButton";
import { isFirstPeriodDiscounted } from "../../services/isFirstPeriodDiscounted";
import { useServicePeriodTranslation } from "../../hooks/useServicePeriodTranslation";
import { CurrencySign } from "../price/Price";

import styles from "./hero-section.module.scss";

export enum HeroSectionGap {
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
}

export interface HeroSectionOption {
  name: string;
  value: string;
  fullPrice: string;
  fullPriceNext?: string | null;
  discountedPrice?: string | null;
  discountedPriceNext?: string | null;
  currencyCode: keyof typeof CurrencyCode;
}

export interface HeroSectionProps {
  bgUrl: string;
  bgPositionY?: string;
  options?: HeroSectionOption[];
  selectedOptionValue?: string;
  onOptionChange?: (value: any) => any;
  gap?: keyof typeof HeroSectionGap;
  illustrationUrl?: string;
  illustrationWidth?: string;
  illustrationBottomOffset?: string;
  illustrationClassName?: string;
  marginBottom?: string;
  videoId?: string;
  videoTitle?: string;
  videoLength?: string;
  className?: string;
  reverseLayoutInMobile?: boolean;
  reverseButtons?: boolean;
  productCode: keyof typeof ServiceProductCode;
  periodCode: keyof typeof ServicePeriodCode;
  availability: keyof typeof ServiceOfferAvailability | null | undefined;
  activeSubscriptionId: string | null | undefined;
  children?: ReactNode;
}

export const HeroSection: React.FC<HeroSectionProps> = ({
  children,
  bgUrl,
  bgPositionY,
  options = [],
  selectedOptionValue,
  onOptionChange,
  gap,
  illustrationUrl,
  illustrationWidth,
  illustrationBottomOffset,
  illustrationClassName,
  marginBottom,
  videoId,
  videoTitle,
  videoLength,
  className,
  reverseLayoutInMobile,
  reverseButtons,
  productCode,
  periodCode,
  availability,
  activeSubscriptionId,
}) => {
  const illustrationStyles = useCalculatedIllustrationStyles({
    illustrationBottomOffset,
    bgPositionY,
    illustrationWidth,
    marginBottom,
  });
  const { t } = useTranslation();
  const getPeriodName = useServicePeriodTranslation();

  const selectedOptionInfo = options.find((o) => o.value === selectedOptionValue);

  return (
    <div
      className={classNames(styles.hero, { [styles["hero--reverse-layout"]]: reverseLayoutInMobile }, className)}
      style={illustrationStyles.hero}
    >
      <AsyncImg className={styles.bg} src={bgUrl} style={illustrationStyles.bg} />
      <div
        className={classNames(styles.content, { [styles["content--reverse-layout"]]: reverseLayoutInMobile })}
        style={illustrationStyles.content}
      >
        {illustrationUrl && (
          <AsyncImg
            className={classNames(styles.illustration, illustrationClassName)}
            src={illustrationUrl}
            style={illustrationStyles.illustration}
          />
        )}

        <div
          className={classNames(styles.text, {
            [styles["text--reverse-content"]]: reverseLayoutInMobile,
            [styles["text--gap-medium"]]: gap === "MEDIUM",
            [styles["text--gap-semi-large"]]: gap === "SEMI_LARGE",
          })}
          style={illustrationStyles.text}
        >
          {children}
          <div
            className={classNames(styles.cta, styles["cta--row"], {
              [styles["cta--reverse-layout"]]: reverseLayoutInMobile,
              [styles["cta--reverse-buttons"]]: reverseButtons,
            })}
          >
            <div className={styles["button-wrap"]}>
              {options.length > 1 && selectedOptionValue && onOptionChange && (
                <ProductSelector
                  options={options}
                  value={selectedOptionValue}
                  onSelect={onOptionChange}
                  displayPerMonth={true}
                />
              )}

              <AddServicesToCartButton
                productCodes={[productCode]}
                periodCode={periodCode}
                availability={availability}
                activeSubscriptionId={activeSubscriptionId}
                serviceCode={productCode}
              />

              {videoId && <VideoButton videoId={videoId} videoTitle={videoTitle} videoLength={videoLength} />}
            </div>

            {selectedOptionInfo && isFirstPeriodDiscounted(selectedOptionInfo) && (
              <p className={styles["disclaimer"]}>
                {t("*Just for new {{product}} users. {{price}}{{currencySign}} / {{period}} from the next period on", {
                  product: selectedOptionInfo.name,
                  price: selectedOptionInfo.fullPriceNext,
                  currencySign: CurrencySign[selectedOptionInfo.currencyCode],
                  period: getPeriodName(ServicePeriodCode[periodCode], "SHORT"),
                })}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function useCalculatedIllustrationStyles({
  illustrationBottomOffset,
  bgPositionY = "top",
  illustrationWidth,
  marginBottom = "0px",
}: Pick<HeroSectionProps, "illustrationBottomOffset" | "bgPositionY" | "illustrationWidth" | "marginBottom">) {
  const { isTabletLandscapeOrBigger } = useWindowDimensionsContext();

  const calculatedStyles = {
    hero: undefined as undefined | React.CSSProperties,
    bg: undefined as undefined | React.CSSProperties,
    illustration: undefined as undefined | React.CSSProperties,
    content: undefined as undefined | React.CSSProperties,
    text: undefined as undefined | React.CSSProperties,
  };

  if (!illustrationWidth || !illustrationBottomOffset) {
    return calculatedStyles;
  }

  // apply illustration related styles only when on bigger screen
  if (isTabletLandscapeOrBigger) {
    calculatedStyles.hero = {
      marginBottom: `calc(-${illustrationBottomOffset} + ${marginBottom})`,
      background: `linear-gradient(#1b1f3b calc(100% - ${illustrationBottomOffset}), transparent calc(100% - ${illustrationBottomOffset}))`,
    };

    calculatedStyles.bg = {
      height: `calc(100% - ${illustrationBottomOffset})`,
      objectPosition: `center ${bgPositionY}`,
    };

    calculatedStyles.illustration = {
      minWidth: illustrationWidth,
    };

    calculatedStyles.text = {
      marginRight: illustrationWidth,
      maxWidth: `calc(100% - ${illustrationWidth})`,
    };

    calculatedStyles.content = {
      paddingBottom: illustrationBottomOffset,
    };
  }

  return calculatedStyles;
}
