import { FC, ReactNode } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./cookie-consent-details.module.scss";

export interface CookieConsentDetailsCookie {
  name: string;
  provider: string;
  retentionPeriod: string;
  purpose: string;
  needCookieConsent: boolean;
}

export interface CookieConsentDetailsProps {
  children: ReactNode;
  cookies: CookieConsentDetailsCookie[];
}

export const CookieConsentDetails: FC<CookieConsentDetailsProps> = (props) => {
  const [t] = useTranslation();

  return (
    <div>
      {props.children}
      {props.cookies.map((cookie, index) => (
        <div key={index}>
          <h3 className={styles.title}>
            {t("Provider")}: {cookie.provider}
          </h3>
          <div className={styles["grid-wrap"]}>
            <p className={classNames(styles["grid-title"], styles["grid-name"])}>{t("Name")}</p>
            <p className={classNames(styles["grid-title"], styles["grid-period"])}>{t("Retention period")}</p>
            <p className={classNames(styles["grid-title"], styles["grid-purpose"])}>{t("Purpose")}</p>
            <p className={classNames(styles["grid-title"], styles["grid-mandatory"])}>{t("Need cookie consent?")}</p>
            <p className={styles["grid-name-data"]}>{cookie.name}</p>
            <p className={styles["grid-period-data"]}>{cookie.retentionPeriod}</p>
            <p className={styles["grid-purpose-data"]}>{cookie.purpose}</p>
            <p className={styles["grid-mandatory-data"]}>{cookie.needCookieConsent ? t("Yes") : t("No")}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
