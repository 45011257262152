import React, { ReactNode } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ServiceProductCode, ServicePeriodCode, CurrencyCode, ServiceOfferAvailability } from "../../graphql/schema";
import { ProductBadge } from "../product-badge/ProductBadge";
import { AddServicesToCartButton } from "../add-services-to-cart-button/AddServicesToCartButton";
import { useServicePeriodTranslation } from "../../hooks/useServicePeriodTranslation";
import { isFirstPeriodDiscounted } from "../../services/isFirstPeriodDiscounted";
import assertNever from "../../services/assertNever";
import Price, { CurrencySign } from "../price/Price";

import styles from "./sign-up-panel.module.scss";

export interface SignUpPanelProduct {
  price: string;
}

export interface SignUpPanelProps {
  productCode: keyof typeof ServiceProductCode;
  periodCode: keyof typeof ServicePeriodCode;
  availability: keyof typeof ServiceOfferAvailability | null | undefined;
  activeSubscriptionId: string | null | undefined;
  title?: React.ReactNode;
  isMostPopular?: boolean;
  price?: string;
  priceNext?: string | null;
  currencyCode?: keyof typeof CurrencyCode;
  className?: string;
  children?: ReactNode;
}

export const SignUpPanel: React.FC<SignUpPanelProps> = ({
  children,
  productCode,
  periodCode,
  availability,
  title,
  isMostPopular,
  price,
  priceNext,
  currencyCode,
  className,
  activeSubscriptionId,
}) => {
  const [t] = useTranslation();
  const getPeriodName = useServicePeriodTranslation();

  return (
    <div className={classNames(styles.wrap, className)}>
      {isMostPopular && (
        <ProductBadge color="GREEN" className={styles["is-popular"]}>
          {t("Most popular")}
        </ProductBadge>
      )}
      <div className={styles.title}>{title}</div>

      {price && (
        <>
          <p className={styles.price}>
            <strong className="notranslate">
              <Price price={getPrice(price, periodCode)} currency={currencyCode} />
            </strong>{" "}
            {t("per month")}*
          </p>

          <p className={styles["price-additional"]}>
            {priceNext && isFirstPeriodDiscounted({ fullPrice: price, fullPriceNext: priceNext }) && (
              <>
                {t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                  price: getPrice(priceNext, periodCode),
                  currencySign: currencyCode ? CurrencySign[currencyCode] : "",
                  period: getPeriodName(ServicePeriodCode[periodCode], "SHORT"),
                })}
                <br />
              </>
            )}
            {t("*Price does not include taxes")}
          </p>
        </>
      )}

      {children}

      <AddServicesToCartButton
        className={styles["cart-button"]}
        productCodes={[productCode]}
        periodCode={periodCode}
        availability={availability}
        kind="YELLOW"
        stretch="MOBILE"
        activeSubscriptionId={activeSubscriptionId}
        serviceCode={productCode}
      />
    </div>
  );

  // display correct price per month
  function getPrice(price: string, period: keyof typeof ServicePeriodCode) {
    switch (period) {
      case "ONE_MONTH":
        return price;

      case "SIX_MONTHS":
        return (parseInt(price) / 6).toFixed(2);

      case "TWELVE_MONTHS":
        return (parseInt(price) / 12).toFixed(2);

      case "LIFETIME":
        return "";

      default:
        return assertNever(period);
    }
  }
};
