import React from "react";
import classNames from "classnames";

import { Breakpoints, Gutters } from "../../services/constants";
import { BorderRadius, Color, FontWeight, Height, Shape } from "../../services/buttonLinkConstants";
import { Loader, LoaderSize } from "../loader/Loader";

import styles from "./button.module.scss";

export enum ButtonIconGutter {
  SMALL = 10,
  MEDIUM = 20,
}

export enum ButtonKind {
  SOLID = "SOLID",
  HOLLOW = "HOLLOW",
  TEXT = "TEXT",
}

export enum CenterSelf {
  WITH_MARGIN = "WITH_MARGIN",
  ALIGN_SELF = "ALIGN_SELF",
  BLOCK_AND_MARGIN = "BLOCK_AND_MARGIN",
}

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  iconGutter?: ButtonIconGutter;
  gutter?: keyof typeof Gutters;
  height?: keyof typeof Height;
  shape?: keyof typeof Shape;
  color?: keyof typeof Color;
  textColor?: keyof typeof Color;
  kind?: keyof typeof ButtonKind;
  center?: keyof typeof CenterSelf;
  weight?: keyof typeof FontWeight;
  borderRadius?: keyof typeof BorderRadius;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  stretch?: boolean | keyof typeof Breakpoints;
  isLoading?: boolean;
  className?: string;
  iconHeight?: number | "auto";
  fontSize?: number | "inherit";
  width?: number;
  inlineStyles?: {};
}

export const Button: React.FC<ButtonProps> = ({
  children,
  iconGutter = ButtonIconGutter.MEDIUM,
  gutter,
  height = "MEDIUM",
  shape = "RECT",
  color,
  textColor,
  kind = "SOLID",
  center,
  weight,
  borderRadius,
  iconRight,
  iconLeft,
  stretch,
  isLoading,
  className,
  iconHeight,
  fontSize,
  width,
  inlineStyles,
  ...rest
}) => {
  // return correct font-size
  const getFontSize = typeof fontSize === "number" ? `${fontSize}px` : fontSize === "inherit" ? "inherit" : undefined;

  return (
    <button
      style={{ fontSize: getFontSize, width: `${width}px`, ...inlineStyles }}
      className={classNames(
        styles.button,
        // @ts-ignore remove me once typescript 4.2 is installed
        styles[`center--${center}`],
        // @ts-ignore remove me once typescript 4.2 is installed
        styles[`gutter--${gutter}`],
        // @ts-ignore remove me once typescript 4.2 is installed
        styles[`height--${height}`],
        // @ts-ignore remove me once typescript 4.2 is installed
        styles[`shape--${shape}`],
        // @ts-ignore remove me once typescript 4.2 is installed
        styles[`color--${color}`],
        // @ts-ignore remove me once typescript 4.2 is installed
        styles[`text-color--${textColor}`],
        // @ts-ignore remove me once typescript 4.2 is installed
        styles[`kind--${kind}`],
        // @ts-ignore remove me once typescript 4.2 is installed
        styles[`weight--${weight}`],
        // @ts-ignore remove me once typescript 4.2 is installed
        styles[`radius--${borderRadius}`],
        // @ts-ignore remove me once typescript 4.2 is installed
        styles[`stretch--${stretch}`],
        {
          [styles.stretch]: typeof stretch === "boolean",
          [styles["button--is-loading"]]: isLoading,
          [styles["button--text-button-loading"]]: isLoading && kind === "TEXT",
          [styles["button--has-icon"]]: iconRight !== undefined || iconLeft !== undefined,
        },
        className,
      )}
      {...rest}
    >
      <Loader size={LoaderSize.SMALL} visible={!!isLoading} />

      {iconLeft && (
        <span
          style={{ marginRight: `${iconGutter}px`, height: "auto" ? "auto" : `${iconHeight}px` }}
          className={styles.icon}
        >
          {iconLeft}
        </span>
      )}

      {children}
      {iconRight && (
        <span
          style={{ height: "auto" ? "auto" : `${iconHeight}px`, marginLeft: `${iconGutter}px` }}
          className={styles.icon}
        >
          {iconRight}
        </span>
      )}
    </button>
  );
};
