import React from "react";
import { useTranslation } from "react-i18next";

import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { TextAccent } from "../../../components/text-accent/TextAccent";
import { Heading } from "../../../components/heading/Heading";
import { ViewSection } from "../../../components/view-section/ViewSection";
import IntroImage from "../assets/intro.jpg";

import styles from "./intro-section.module.scss";

export const IntroSection: React.FC = () => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <ViewSection image={<AsyncImg src={IntroImage} />} reverse="ALL_SCREENS" gap="GAP_40" gutter={40}>
      <Heading className={styles.title} level={3}>
        <TextAccent dangerouslySetInnerHTML={{ __html: t("What is <strong>The View?</strong>") }} />
      </Heading>
      <p>
        {t(
          "The View is a new and groundbreaking virtual reality platform that brings together most innovative technology with the most extensive catalogue of experiences and contents.",
        )}
      </p>
      <br />
      <p>
        {t(
          "You will have a window into a reality without limits. From leisure, education, communication to shopping and sales, all in a virtual, interactive, instantaneous and real space.",
        )}
      </p>
    </ViewSection>
  );
};
