import React from "react";

export interface IconCheckAllProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconCheckAll: React.FC<IconCheckAllProps> = ({ hasTitle, className, fill, width = 55, height = 30 }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 55 30"
    >
      {hasTitle && <title>IconCheckAll</title>}
      <path
        fill={fill}
        id="Check-All"
        d="M52.65.4a1.39,1.39,0,0,1,2,0,1.37,1.37,0,0,1,0,1.93L27.1,29.6a1.39,1.39,0,0,1-2,0l-1.27-1.26a1.35,1.35,0,0,1,0-1.93,1.38,1.38,0,0,1,1.94,0l.31.3Zm-9,0a1.37,1.37,0,0,1,0,1.93L16.1,29.6a1.39,1.39,0,0,1-2,0L.4,16A1.36,1.36,0,0,1,.4,14a1.38,1.38,0,0,1,2,0L15.13,26.71,41.65.4A1.39,1.39,0,0,1,43.6.4ZM13.35,14l4.11,4.08a1.34,1.34,0,0,1,0,1.92,1.36,1.36,0,0,1-1.94,0L11.4,16a1.36,1.36,0,0,1,0-1.92A1.38,1.38,0,0,1,13.35,14Z"
      />
    </svg>
  );
};
