import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Layout } from "../../layout/Layout";
import { Button, ButtonIconGutter } from "../../button/Button";
import { IconWallet2 } from "../../icon/IconWallet2";
import { IconCloud } from "../../icon/IconCloud";
import { tracker } from "../../../libs/trackers";

import styles from "./redemption-form.module.scss";

export enum GiftCardStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface RedemptionFormProps {
  status: keyof typeof GiftCardStatus;
  code: string;
  cardUser: string | undefined | null;
  id: string;
  onUse(): void;
  onClose(): void;
}

export const RedemptionForm: React.FC<RedemptionFormProps> = ({ status, code, cardUser, id, onUse, onClose }) => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.grid}>
        <div className={classNames(styles.row, styles.label)}>{t("Redeem code:")}</div>
        <div className={classNames(styles.row, styles.data, { [styles["code--redeemed"]]: status === "INACTIVE" })}>
          {code}
        </div>
        <div className={classNames(styles.row, styles.label)}>{t("Status:")}</div>
        <div className={classNames(styles.row, styles.data)}>{status === "ACTIVE" ? t("Active") : t("Redeemed")}</div>
        <div className={classNames(styles.row, styles.label)}>{t("Used by:")}</div>
        <div className={classNames(styles.row, styles.data)}>{cardUser !== undefined ? cardUser : t("Not used")}</div>
      </div>
      {status === "ACTIVE" && cardUser === null && (
        <Layout wrap={"MOBILE"}>
          <Button
            type="button"
            className={styles["option-button"]}
            color="YELLOW"
            borderRadius="MEDIUM"
            iconLeft={<IconWallet2 />}
            gutter="LARGE"
            weight="MEDIUM"
            iconGutter={ButtonIconGutter.SMALL}
            stretch
            onClick={onUse}
          >
            {t("Use as my Shop credits")}
          </Button>
          <Button
            className={styles["option-button"]}
            color="YELLOW"
            borderRadius="MEDIUM"
            weight="MEDIUM"
            iconLeft={<IconCloud />}
            gutter="LARGE"
            iconGutter={ButtonIconGutter.SMALL}
            stretch
            onClick={() => {
              tracker.trackEvent("click", { name: "Resources - Download Downloads", description: `gift card` });

              window.open(`${process.env.REACT_APP_SUCCESSFACTORY_SERVER_PROXY}/pdf/gift-card/${id}`);
            }}
          >
            {t("Download as PDF")}
          </Button>
        </Layout>
      )}
      <Button
        className={styles["close-button"]}
        color="BLUE"
        borderRadius="SMALL"
        weight="MEDIUM"
        stretch="MOBILE"
        center="BLOCK_AND_MARGIN"
        width={280}
        fontSize={18}
        onClick={onClose}
      >
        {t("Close")}
      </Button>
    </div>
  );
};
