import React from "react";
import classNames from "classnames";

import styles from "./text-accent.module.scss";

export enum TextAccentType {
  ORANGE = "ORANGE",
  YELLOW = "YELLOW",
  GREEN = "GREEN",
  LIGHT_BLUE = "LIGHT_BLUE",
  WHITE = "WHITE",
}

export interface TextAccentProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  type?: keyof typeof TextAccentType;
  wrap?: boolean;
  bold?: boolean;
  className?: string;
}

export const TextAccent: React.FC<TextAccentProps> = ({
  children,
  type = "ORANGE",
  wrap,
  bold,
  className,
  ...rest
}) => {
  return (
    <span
      className={classNames(
        styles.accent,
        {
          [styles["accent--orange"]]: TextAccentType[type] === TextAccentType.ORANGE,
          [styles["accent--yellow"]]: TextAccentType[type] === TextAccentType.YELLOW,
          [styles["accent--green"]]: TextAccentType[type] === TextAccentType.GREEN,
          [styles["accent--light-blue"]]: TextAccentType[type] === TextAccentType.LIGHT_BLUE,
          [styles["accent--wrap"]]: wrap,
          [styles["accent--bold"]]: bold,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </span>
  );
};
