import { ServiceProductCode, ServicePeriodCode, useAddServiceCartItemMutation } from "../graphql/schema";

export interface UseAddServiceToCartService {
  productCode: ServiceProductCode;
  periodCode: ServicePeriodCode;
}

export function useAddServicesToCart() {
  const [addServiceCartItem] = useAddServiceCartItemMutation({
    awaitRefetchQueries: true,
  });

  return async (services: UseAddServiceToCartService[]) => {
    // add cart items in sequence to avoid overwrite issues
    for (const { productCode, periodCode } of services) {
      try {
        await addServiceCartItem({
          variables: { periodCode, productCode },
          refetchQueries: "active",
          awaitRefetchQueries: true,
        });
      } catch (e) {
        console.warn("adding service subscription returned error", e);
      }
    }
  };
}
