import React, { ReactNode, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { useAddServicesToCart } from "../../hooks/useAddServicesToCart";
import { ServiceInfo } from "../../services/constants";
import {
  AddToCartButton,
  AddToCartButtonKind,
  AddToCartButtonProps,
  AddToCartButtonStyleProps,
} from "../add-to-cart-button/AddToCartButton";
import {
  ServiceProductCode,
  ServicePeriodCode,
  ServiceOfferAvailability,
  useAddServicesToCartButtonServiceSubscriptionsLazyQuery,
  useAddServicesToCartButtonCreateSubscriptionUpgradeMutation,
  useAddServicesToCartButtonGetSubscriptionUpgradeLazyQuery,
  PaymentMethod,
} from "../../graphql/schema";
import { Modal, ModalKind } from "../modal/Modal";
import { PanelWidth } from "../panel/Panel";
import { Heading, HeadingGutter } from "../heading/Heading";
import { Upgrade } from "../../views/account-view/subscriptions-view/edit-subscription/upgrade/Upgrade";
import { Loader } from "../loader/Loader";
import { PAYMENT_METHODS_ORDER } from "../payment-methods-list/PaymentMethodsList";

export interface AddServicesToCartButtonProps
  extends AddToCartButtonStyleProps,
    Pick<AddToCartButtonProps, "serviceCode"> {
  productCodes: (keyof typeof ServiceProductCode)[];
  externalUrl?: string | false;
  periodCode: keyof typeof ServicePeriodCode;
  kind?: keyof typeof AddToCartButtonKind;
  availability: keyof typeof ServiceOfferAvailability | null | undefined;
  activeSubscriptionId: string | null | undefined;
  className?: string;
}

export const AddServicesToCartButton: React.FC<AddServicesToCartButtonProps> = ({
  productCodes,
  periodCode,
  kind,
  availability,
  className,
  activeSubscriptionId,
  children,
  ...props
}) => {
  const client = useApolloClient();
  const [t] = useTranslation();
  const handleAddToCartClick = useAddServicesToCart();

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PAYMENT_METHODS_ORDER[0]);
  const subscriptionUpgrade = useGetSubscriptionUpgrade(productCodes, periodCode, activeSubscriptionId);

  const serviceInfo = productCodes.length === 1 ? ServiceInfo[productCodes[0]] : undefined;
  const serviceProductCodes = productCodes.map((code) => ServiceProductCode[code]);

  // update offer info on payment method change
  useEffect(() => {
    if (!isUpgradeModalOpen) {
      return;
    }

    subscriptionUpgrade.update(selectedPaymentMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpgradeModalOpen, selectedPaymentMethod]);

  if (productCodes.length === 0) {
    return null;
  }

  const onHandleUpgrade = async () => {
    setIsUpgradeModalOpen(true);
    subscriptionUpgrade.query(selectedPaymentMethod);
  };

  const handleModalClose = () => {
    if (isUpgrading) {
      return;
    }

    setIsUpgradeModalOpen(false);
  };

  const handleUpgradeSuccess = async () => {
    setIsUpgrading(false);

    client.reFetchObservableQueries().then(() => {
      setIsUpgradeModalOpen(false);
      toast.success(t("Product upgraded successfully"), { autoClose: false });
    });
  };

  return (
    <>
      <AddToCartButton
        productAccessPath={serviceInfo?.accessUrl}
        onAddToCartClick={() =>
          handleAddToCartClick(
            serviceProductCodes.map((productCode) => ({ productCode, periodCode: ServicePeriodCode[periodCode] })),
          )
        }
        availability={availability || null}
        kind={kind}
        className={className}
        labelClassName={props.labelClassName}
        iconSize={props.iconSize}
        iconGutter={props.iconGutter}
        height={props.height}
        stretch={props.stretch}
        hideCartIcon={props.hideCartIcon}
        width={props.width}
        onHandleUpgradeModal={onHandleUpgrade}
        externalUrl={props.externalUrl}
        serviceCode={props.serviceCode}
        children={children}
      />
      <Modal
        kind={ModalKind.SECONDARY}
        close={handleModalClose}
        isOpen={isUpgradeModalOpen}
        panelProps={{
          imagePath: "../../../images/illustrationSwitchPlan.png",
          width: PanelWidth.SMALL,
        }}
        loading={subscriptionUpgrade.loading}
      >
        <>
          <Heading level={3} gutter={HeadingGutter.MEDIUM}>
            {t("Confirm the upgrade")}
          </Heading>
        </>

        {subscriptionUpgrade.error && <p>Something went wrong</p>}

        {subscriptionUpgrade.data && subscriptionUpgrade.hasUpgrade ? (
          <Upgrade
            handleDisableModalClose={(e) => setIsUpgrading(e)}
            onClose={handleModalClose}
            onUpgradeSuccess={handleUpgradeSuccess}
            upgradeOffer={subscriptionUpgrade.data}
            onPaymentMethodChange={setSelectedPaymentMethod}
            isLoading={subscriptionUpgrade.loading}
          />
        ) : subscriptionUpgrade.loading ? (
          <Loader />
        ) : (
          <p>{t("No upgrade found")}</p>
        )}
      </Modal>
    </>
  );
};

function useGetSubscriptionUpgrade(
  productCodes: (keyof typeof ServiceProductCode)[],
  periodCode: keyof typeof ServicePeriodCode,
  activeSubscriptionId?: string | null,
) {
  const [getActiveSubscriptions, subscriptionsQuery] = useAddServicesToCartButtonServiceSubscriptionsLazyQuery();
  const [getSubscriptionUpgrade, subscriptionUpgrade] = useAddServicesToCartButtonGetSubscriptionUpgradeLazyQuery();
  const [createSubscriptionUpgrade, createdSubscriptionUpgrade] =
    useAddServicesToCartButtonCreateSubscriptionUpgradeMutation();
  const [upgradeId, setUpgradeId] = useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);

  const upgradeOffer = (subscriptionsQuery.data?.me.serviceSubscriptions[0]?.upgradeOffers || []).find(
    ({ products, period }) =>
      products.map((p) => p.code).every((code) => productCodes.includes(code)) && period.code === periodCode,
  );

  const error = createdSubscriptionUpgrade.error || subscriptionsQuery.error || subscriptionUpgrade.error;
  const hasCheckedForUpgradeOffer = subscriptionsQuery.called && !subscriptionsQuery.loading;
  const hasCheckedForUpgradeSubscription = createdSubscriptionUpgrade.called && !createdSubscriptionUpgrade.loading;
  const isFinishedLoading =
    !error && ((hasCheckedForUpgradeOffer && !upgradeOffer) || hasCheckedForUpgradeSubscription);
  const serviceSubscriptionUpgrade = subscriptionUpgrade.data?.me.serviceSubscriptionUpgrade;

  // get subscription upgrade once offer was found
  useEffect(() => {
    if (upgradeOffer && activeSubscriptionId && paymentMethod) {
      createSubscriptionUpgrade({
        variables: {
          subscriptionId: activeSubscriptionId,
          offerId: upgradeOffer.id,
        },
      }).then((res) => {
        setUpgradeId(res.data?.upgradeServiceSubscription.id ?? null);
        if (res.data?.upgradeServiceSubscription.id) {
          getSubscriptionUpgrade({ variables: { id: res.data?.upgradeServiceSubscription.id, paymentMethod } });
        }
      });
    }
  }, [createSubscriptionUpgrade, upgradeOffer, activeSubscriptionId, paymentMethod, getSubscriptionUpgrade]);

  return {
    query: (paymentMethod: PaymentMethod) => {
      if (!activeSubscriptionId) {
        throw new Error("activeSubscriptionId was not provided");
      }

      setPaymentMethod(paymentMethod);

      if (subscriptionsQuery.called) {
        subscriptionsQuery.refetch({ activeSubscriptionId });
        return;
      }

      getActiveSubscriptions({ variables: { activeSubscriptionId } });
    },
    update: (paymentMethod: PaymentMethod) => {
      if (!upgradeId) {
        return;
      }

      getSubscriptionUpgrade({ variables: { id: upgradeId, paymentMethod } });
    },
    error,
    loading: !isFinishedLoading || subscriptionUpgrade.loading,
    data: serviceSubscriptionUpgrade,
    hasUpgrade: isFinishedLoading ? !!serviceSubscriptionUpgrade : false,
  };
}
