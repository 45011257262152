import React from "react";

export interface IconClose2Props {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconClose2: React.FC<IconClose2Props> = ({
  hasTitle,
  className,
  fill = "#fff",
  width = 17,
  height = 17,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 17" className={className}>
      {hasTitle && <title>IconClose2</title>}
      <path stroke={fill} strokeLinecap="round" strokeWidth="3" d="M2 2l13 13M2 15L15 2"></path>
    </svg>
  );
};
