import React from "react";

export interface IconGiftCardProps {
  hasTitle?: boolean;
  disabled?: boolean;
  ribbonClassName?: string;
  className?: string;
  width?: number;
  height?: number;
}

export const IconGiftCard: React.FC<IconGiftCardProps> = ({
  hasTitle,
  disabled,
  ribbonClassName,
  className,
  width = 44,
  height = 30,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 30"
    >
      {hasTitle && <title>IconGiftCard</title>}
      <path
        id="Path"
        fill={disabled ? "#7789AD" : "#ffc542"}
        d="M38.59,30H5.41C2.42,30,0,27.92,0,25.35V4.68C0,2.11,2.42,0,5.41,0H38.59C41.58,0,44,2.11,44,4.68V25.35C44,27.92,41.58,30,38.59,30Z"
      />
      <polygon
        className={ribbonClassName}
        fill={disabled ? "#292F48" : "#292f48"}
        points="44 8.53 14.75 8.53 14.75 0 12.47 0 12.47 8.53 0 8.53 0 10.82 12.47 10.82 12.47 30 14.75 30 14.75 10.82 44 10.82 44 8.53"
      />
      <path
        fill={disabled ? "#1B1F3B" : "#ff871c"}
        d="M11.29,7.85S5.45-.39,2.67,4.78s8.62,5.11,8.62,5.11v-2Zm3.75,0s5.84-8.24,8.62-3.07S15,9.89,15,9.89v-2Zm-1.89,3.69S10.08,17.2,9.09,19a.46.46,0,0,1-.73.1L5.36,16a.46.46,0,0,1,0-.64l6-5.82Zm0,0S16.23,17.2,17.22,19a.46.46,0,0,0,.73.1L21,16a.45.45,0,0,0,0-.64L15,9.54Z"
      />
      <path
        fill={disabled ? "#374066" : "#ff9d45"}
        d="M20,6.58A.85.85,0,0,0,19,6a20.69,20.69,0,0,0-2.79,1.21,1.68,1.68,0,0,0-1.63-1.29H12.62A1.67,1.67,0,0,0,11,7.6v0A26.82,26.82,0,0,0,7.35,6a.85.85,0,0,0-1.06.57.86.86,0,0,0,.58,1.07,28.45,28.45,0,0,1,3.54,1.63.86.86,0,0,0,.4.11l.14,0V10.6a1.67,1.67,0,0,0,1.67,1.67h1.94a1.68,1.68,0,0,0,1.68-1.67V9.12a24.54,24.54,0,0,1,3.22-1.47A.85.85,0,0,0,20,6.58Z"
      />
    </svg>
  );
};
