import React from "react";

export interface IconWallet2Props {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconWallet2: React.FC<IconWallet2Props> = ({
  hasTitle,
  className,
  fill = "#fff",
  width = 24,
  height = 20,
}) => {
  return (
    <svg
      className={className}
      fill={fill}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 20"
    >
      {hasTitle && <title>IconWallet2</title>}
      <path d="M19.83,11.58a1,1,0,1,1-1-1.05A1,1,0,0,1,19.83,11.58ZM24,10v3.16a1.57,1.57,0,0,1-1.13,1.51c0,.38-.08.94-.16,1.71a3.68,3.68,0,0,1-3.38,3.3c-3,.21-5.6.32-7.82.32s-4.88-.11-7.94-.33A3.66,3.66,0,0,1,.19,16.32C.06,14.88,0,13.3,0,11.58s.06-3.3.19-4.75A3.67,3.67,0,0,1,3.24,3.54c0-.23,0-.54.09-.94A2.62,2.62,0,0,1,5.74.27Q9.36,0,12,0c1.66,0,3.41.08,5.26.24a2.61,2.61,0,0,1,2.37,2.33c0,.41.08.73.1,1a3.66,3.66,0,0,1,3,3.24c.08.77.13,1.32.16,1.71A1.57,1.57,0,0,1,24,10ZM4.37,2.72c0,.29-.06.52-.08.72,2.73-.19,5.13-.28,7.17-.28s4.46.09,7.2.28c0-.21-.05-.45-.08-.75a1.57,1.57,0,0,0-1.42-1.4c-1.82-.16-3.55-.24-5.17-.24s-3.79.09-6.18.27A1.56,1.56,0,0,0,4.37,2.72Zm17.3,13.55c.07-.67.12-1.17.15-1.53H18.26a2.62,2.62,0,0,1-2.61-2.63V11.05a2.62,2.62,0,0,1,2.61-2.63h3.56c0-.37-.08-.87-.15-1.53a2.62,2.62,0,0,0-2.39-2.36h-.1q-4.47-.32-7.72-.32c-2.2,0-4.8.11-7.82.32a2.63,2.63,0,0,0-2.41,2.4C1.11,8.34,1,9.89,1,11.58s.07,3.24.19,4.65a2.61,2.61,0,0,0,2.41,2.39c3,.22,5.67.33,7.87.33s4.77-.11,7.75-.32A2.62,2.62,0,0,0,21.67,16.27ZM23,10a.53.53,0,0,0-.5-.52H22.4l-.1,0h-4a1.57,1.57,0,0,0-1.56,1.58v1.06a1.56,1.56,0,0,0,1.56,1.57h4.17a.52.52,0,0,0,.53-.52Z" />
    </svg>
  );
};
