import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./sub-title.module.scss";
export interface SubTitleProps {
  withBottomLine?: boolean;
  className?: string;
  children?: ReactNode;
}

export const SubTitle: React.FC<SubTitleProps> = ({ withBottomLine, className, children }) => {
  return <p className={classNames(styles.wrap, className, { [styles["bottom-line"]]: withBottomLine })}>{children}</p>;
};
