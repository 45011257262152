import { Label } from "../label/Label";
import { Radio } from "../radio/Radio";
import RadioGroup from "../radio-group/RadioGroup";

export type SubscriptionPeriodOption<T extends string> = {
  value: T;
  label: string;
  discountLabel?: string | null;
};

export interface SubscriptionPeriodProps<T extends string> {
  name: string;
  options: SubscriptionPeriodOption<T>[];
  selectedValue: T;
  onChange: (value: T) => any;
}

export function SubscriptionPeriod<T extends string>({
  options,
  name,
  selectedValue,
  onChange,
}: SubscriptionPeriodProps<T>) {
  // subscription period radio group

  return (
    <RadioGroup name={name} onChange={() => {}}>
      {options.map((option) => {
        return (
          <div key={option.value}>
            <Radio
              kind="SECONDARY"
              gap="SMALL"
              value={option.value}
              label={
                option.label && (
                  <>
                    {option.label}
                    {option.discountLabel && <Label>{option.discountLabel}</Label>}
                  </>
                )
              }
              checked={selectedValue === option.value}
              onChange={() => {
                onChange(option.value);
              }}
            />
          </div>
        );
      })}
    </RadioGroup>
  );
}
