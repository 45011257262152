import React from "react";
import { useTranslation } from "react-i18next";

import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { Heading } from "../../../components/heading/Heading";
import { TextAccent } from "../../../components/text-accent/TextAccent";
import { ViewSection } from "../../../components/view-section/ViewSection";
import ExperienceImage from "../assets/experience.jpg";

import styles from "./experience-section.module.scss";

export const ExperienceSection: React.FC = () => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <ViewSection
      sectionClassName={styles.section}
      imageWrapClassName={styles.image}
      image={<AsyncImg src={ExperienceImage} />}
      withBackground
      reverse="SMALL_SCREEN"
      gutter={80}
      gap="GAP_40"
    >
      <Heading className={styles.title} level={3}>
        <TextAccent
          dangerouslySetInnerHTML={{
            __html: t("Imagine really <strong>traveling the world</strong> without leaving the comfort of your home."),
          }}
        />
      </Heading>
      <p>
        {t(
          "Travel the world, visit new places or return to your favourite spots, enjoy new experiences, learn from the best, connect with other people, do business or buy a property...",
        )}
      </p>
      <br />
      <p>
        {t(
          "All this is possible from anywhere, at any time, and live it as if you were there. Discover a new world without barriers and an unlimited reality with The View.",
        )}
      </p>
    </ViewSection>
  );
};
