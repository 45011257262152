import { useReactiveVar } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router-dom";

import { shouldRedirectToBecomeAffiliateViewVar } from "../../cache/shouldRedirectToBecomeAffiliateView";
import { Heading } from "../../components/heading/Heading";
import { Link } from "../../components/link/Link";
import { PaymentFlow } from "../../components/payment-result/PaymentResult";
import { Section } from "../../components/section/Section";
import { Titlebar, TitlebarType } from "../../components/titlebar/Titlebar";
import assertNever from "../../services/assertNever";
import { Routes } from "../../services/constants";

import styles from "./order-complete-view.module.scss";

export interface OrderCompleteViewProps {
  className?: string;
}

export const OrderCompleteView: React.FC<OrderCompleteViewProps> = () => {
  const { t } = useTranslation();
  const shouldRedirectToBecomeAffiliateView = useReactiveVar(shouldRedirectToBecomeAffiliateViewVar);

  const flow = new URLSearchParams(useLocation().search).get("flow") as PaymentFlow;

  function handleRoute(flow: PaymentFlow) {
    // Upsell routes are most likely already handled but for any case if not, handle them here also.
    switch (flow) {
      case "CART_PURCHASE_OF_SHOP_CREDIT":
        return Routes.CHECKOUT_CART;
      case "PAYMENT_METHODS_PURCHASE_OF_GIFT_CARD":
      case "PAYMENT_METHODS_PURCHASE_OF_SHOP_CREDIT":
        return Routes.PAYMENT_METHODS;
      case "SUBSCRIPTION_PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE":
      case "SUBSCRIPTION_PURCHASE_OF_SHOP_CREDIT":
        return Routes.SUBSCRIPTION_LIST;
      case "CART_PURCHASE_OF_SERVICE_PRODUCTS":
      case "UPSELL_PURCHASE_OF_SERVICE_PRODUCTS":
      case "UPSELL_PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE":
      case "UPSELL_PURCHASE_OF_SHOP_CREDIT":
        return Routes.CHECKOUT_CART_UP_SELL;
      case "DEFAULT":
        return Routes.PRODUCTS;
      default:
        assertNever(flow);
    }
  }

  if (shouldRedirectToBecomeAffiliateView) {
    return <Redirect to={Routes.AFFILIATE} />;
  }

  return (
    <>
      <Titlebar imageUrl="/images/orderCompleteIllustration.png" type={TitlebarType.QUINARY} />
      <Section gutter={0} withSpace center centerText>
        <Heading className={styles.title} level={2}>
          {t("Thank you for your order!")}
        </Heading>
        <p className={styles.message}>
          {t(
            "We have successfully received your order. You can find the summary of your order in the “My Invoices” page. To access the purchased products go to “My Account” page.",
          )}
        </p>
        <Link
          to={flow ? handleRoute(flow) : Routes.PRODUCTS}
          color="BLUE"
          shape="ROUND"
          gutter="MEDIUM"
          width={250}
          weight="BOLD"
        >
          {flow ? t("Go back") : t("Go to My Account")}
        </Link>
        <Link to={Routes.INVOICES_LIST} color="YELLOW" shape="ROUND" width={250} weight="BOLD" gutter="LARGE">
          {t("View invoices")}
        </Link>
      </Section>
    </>
  );
};
