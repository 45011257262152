import React from "react";

export interface IconPadlockProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconPadlock: React.FC<IconPadlockProps> = ({ hasTitle, className, width = 11, height = 15 }) => (
  <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 15">
    {hasTitle && <title>IconPadlock</title>}
    <path
      fillRule="evenodd"
      d="M7.944 6.563H3.056v-2.5c0-1.379 1.097-2.5 2.444-2.5s2.444 1.121 2.444 2.5v2.5zM5.5 12.03c-.675 0-1.222-.56-1.222-1.25S4.825 9.53 5.5 9.53c.675 0 1.222.56 1.222 1.25s-.547 1.25-1.222 1.25zm3.972-5.469v-2.5C9.472 1.82 7.694 0 5.5 0S1.528 1.819 1.528 4.063v2.5C.684 6.563 0 7.263 0 8.124v5.313C0 14.3.684 15 1.528 15h7.944c.844 0 1.528-.7 1.528-1.563V8.125c0-.863-.684-1.563-1.528-1.563z"
    ></path>
  </svg>
);
