import React from "react";

export interface IconCheckmarkThinProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconCheckmarkThin: React.FC<IconCheckmarkThinProps> = ({
  hasTitle,
  className,
  fill = "#fff",
  width = 22,
  height = 15,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 15"
    >
      {hasTitle && <title>IconCheckmarkThin</title>}
      <path
        fill={fill}
        id="icon-check-mark-thin"
        d="M7.57,13.34,20.82.21a.68.68,0,0,1,1,1L8.05,14.79a.66.66,0,0,1-.71.17A.93.93,0,0,1,7,14.77l-.44-.47L.21,8a.68.68,0,0,1,1-1l6.39,6.32Z"
      />
    </svg>
  );
};
