import { BaseTracker } from "./src/BaseTracker";
import { DataDogTracker } from "./src/DataDogTracker";
import { GoogleTagManagerTracker } from "./src/GoogleTagManagerTracker";
import { MixPanelTracker } from "./src/MixPanelTracker";
import { TrackerEventParams, TrackerEventType } from "./types";
export * from "./src/useCookieConsent";
export * from "./types";

const DEBUG = false;

const trackerInstances: BaseTracker[] = [];

export const tracker = {
  initialize() {
    log("initialize");

    // Google Tag Manager
    trackerInstances.push(new GoogleTagManagerTracker());

    // MixPanel
    trackerInstances.push(new MixPanelTracker());

    // DataDog
    trackerInstances.push(new DataDogTracker());
  },

  reload() {
    log("reload");
    trackerInstances.forEach((tracker) => {
      tracker.reload();
    });
  },

  identify(userId: string | number, params?: TrackerEventParams) {
    log("identify", { userId, params });

    trackerInstances.forEach((tracker) => {
      tracker.identify(userId, params);
    });
  },

  unIdentify() {
    log("unIdentify");

    trackerInstances.forEach((tracker) => {
      tracker.unIdentify();
    });
  },

  trackEvent(event: TrackerEventType, params: TrackerEventParams) {
    log("trackEvent", { event, params });

    trackerInstances.forEach((tracker) => {
      tracker.trackEvent(event, params);
    });
  },

  trackError(error: Error, from?: string) {
    log("trackError", { error, from });

    trackerInstances.forEach((tracker) => {
      tracker.trackError(error, from);
    });
  },
};

function log(event: string, params?: unknown) {
  if (!DEBUG) {
    return;
  }

  console.log(`[TRACKER]: ${event}`, params);
}
