import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  PaymentEntityType,
  PaymentStatus,
  useCartViewCloneCartMutation,
  usePaymentStatusQuery,
} from "../../graphql/schema";
import { Routes } from "../../services/constants";
import { Loader } from "../loader/Loader";

import styles from "./payment-result.module.scss";

interface PaymentResultParams {
  id: string;
  flow: PaymentFlow;
}

export type PaymentFlow =
  | `UPSELL_${PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS}`
  | `UPSELL_${PaymentEntityType.PURCHASE_OF_SHOP_CREDIT}`
  | `UPSELL_${PaymentEntityType.PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE}`
  | `CART_${PaymentEntityType.PURCHASE_OF_SHOP_CREDIT}`
  | `CART_${PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS}`
  | `SUBSCRIPTION_${PaymentEntityType.PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE}`
  | `SUBSCRIPTION_${PaymentEntityType.PURCHASE_OF_SHOP_CREDIT}`
  | `PAYMENT_METHODS_${PaymentEntityType.PURCHASE_OF_SHOP_CREDIT}`
  | `PAYMENT_METHODS_${PaymentEntityType.PURCHASE_OF_GIFT_CARD}`
  | "DEFAULT";

export const PaymentResult: React.FC = () => {
  const { t } = useTranslation();
  // poll the paymentStatus
  const { id, flow } = useParams<PaymentResultParams>();
  const [isPolling, setIsPolling] = useState(false);
  const history = useHistory();

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | undefined>();
  const [isCloningCart, setIsCloningCart] = useState<boolean | undefined>();
  const [cloneCart] = useCartViewCloneCartMutation();
  const [isPaymentRejectedByUser, setIsPaymentRejectedByUser] = useState<boolean | undefined>();

  const {
    data: paymentStatusData,
    startPolling,
    stopPolling,
  } = usePaymentStatusQuery({
    variables: { paymentId: id },
  });

  const urlQuery = new URLSearchParams(history.location.search);

  const isRejected = urlQuery.get("rejected");

  useEffect(() => {
    if (!isPolling) {
      setIsPolling(true);
      startPolling(500);
    }
  }, [paymentStatusData, isPolling, setIsPolling, startPolling]);

  // Check if payment status has changed to success or invalid
  // Status Null means error.
  useEffect(() => {
    if (isPolling && paymentStatusData) {
      if (paymentStatusData.payments.fetch?.status === PaymentStatus.SUCCESS) {
        stopPolling();
        setPaymentStatus(PaymentStatus.SUCCESS);
      }

      if (
        !paymentStatusData.payments.fetch ||
        paymentStatusData.payments.fetch.status === PaymentStatus.INVALID ||
        paymentStatusData.payments.fetch.status === PaymentStatus.CANCELLED
      ) {
        stopPolling();
        setPaymentStatus(PaymentStatus.INVALID);
      }

      if (paymentStatusData.payments.fetch?.status === PaymentStatus.PENDING && isRejected) {
        stopPolling();
        setIsPaymentRejectedByUser(true);
      }
    }
  }, [paymentStatusData, isPolling, stopPolling, isRejected]);

  useEffect(() => {
    if (isCloningCart === false) {
    }
  }, [isCloningCart]);

  async function handleCloningCart(entityId: string) {
    await cloneCart({
      variables: {
        cartId: entityId,
      },
    });
  }

  if (isPaymentRejectedByUser) {
    if (flow === "CART_PURCHASE_OF_SERVICE_PRODUCTS" && paymentStatusData?.payments.fetch?.entityId) {
      if (!isCloningCart) {
        setIsCloningCart(true);

        handleCloningCart(`${paymentStatusData.payments.fetch?.entityId}`)
          .catch(() => {
            console.error("Cloning cart failed, CartId: ", paymentStatusData.payments.fetch?.entityId);
          })
          .finally(() => {
            return <Redirect to={`${Routes.CHECKOUT_FAILED}?flow=${flow}`} />;
          });
      }
    }

    return <Redirect to={`${Routes.CHECKOUT_FAILED}?flow=${flow}`} />;
  }

  if (!id) {
    // Something went wrong, user cant be redirected to correct route, send to invoices
    return <Redirect to={Routes.INVOICES_LIST} />;
  }

  if (paymentStatus === PaymentStatus.SUCCESS) {
    // All cases where flow directs user to upsell
    if (
      [
        "CART_PURCHASE_OF_SERVICE_PRODUCTS",
        "UPSELL_PURCHASE_OF_SERVICE_PRODUCTS",
        "UPSELL_PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE",
        "UPSELL_PURCHASE_OF_SHOP_CREDIT",
      ].includes(flow)
    ) {
      return <Redirect to={Routes.CHECKOUT_CART_UP_SELL} />;
    }

    return <Redirect to={`${Routes.CHECKOUT_SUCCESS}?flow=${flow}`} />;
  }

  if (paymentStatus === PaymentStatus.INVALID) {
    if (
      [
        "UPSELL_PURCHASE_OF_SERVICE_PRODUCTS",
        "UPSELL_PURCHASE_OF_SERVICE_SUBSCRIPTION_UPGRADE",
        "UPSELL_PURCHASE_OF_SHOP_CREDIT",
      ].includes(flow)
    ) {
      toast.error(t("Payment was unsuccessful"));

      return <Redirect to={Routes.CHECKOUT_CART_UP_SELL} />;
    }

    if (flow === "CART_PURCHASE_OF_SERVICE_PRODUCTS" && paymentStatusData?.payments.fetch?.entityId) {
      if (!isCloningCart) {
        setIsCloningCart(true);

        handleCloningCart(`${paymentStatusData?.payments.fetch?.entityId}`);
      }
    }

    return <Redirect to={`${Routes.CHECKOUT_FAILED}?flow=${flow}`} />;
  }

  return (
    <div className={styles.loading}>
      <Loader cover={false} className={styles.loadingIndicator} />
      <p className={styles.loadingText}>{t("Please wait...")} </p>
      <p className={styles.loadingText}>{t("It may take up to a few minutes to process your payment.")}</p>
    </div>
  );
};
