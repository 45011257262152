import React from "react";

interface IconShieldSuccessProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
  opacity?: number;
}

export const IconShieldSuccess: React.FC<IconShieldSuccessProps> = ({
  hasTitle,
  className,
  fill = "#3dd598",
  width,
  height,
  opacity,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      opacity={opacity}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 25"
    >
      {hasTitle && <title>IconShieldSuccess</title>}
      <path
        id="Fill-303"
        fill={fill}
        fillRule="evenodd"
        d="M13.21,11.4,9.47,15.08a1.05,1.05,0,0,1-.74.31A1.1,1.1,0,0,1,8,15.08L5.83,13a1,1,0,0,1,0-1.48,1.09,1.09,0,0,1,1.5,0l1.4,1.39,3-2.95a1.09,1.09,0,0,1,1.5,0A1,1,0,0,1,13.21,11.4Zm4.33-7.62L10.21.17a1.55,1.55,0,0,0-1.42,0L1.46,3.78A2.6,2.6,0,0,0,0,6.12V14.5a9.36,9.36,0,0,0,4.89,8.2l3.84,2.1a1.57,1.57,0,0,0,1.54,0l3.84-2.1A9.36,9.36,0,0,0,19,14.5V6.12a2.6,2.6,0,0,0-1.46-2.34Z"
      />
    </svg>
  );
};
