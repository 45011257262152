import React from "react";

export interface IconLogoForexInsidersProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconLogoForexInsiders: React.FC<IconLogoForexInsidersProps> = ({
  hasTitle,
  className,
  width = 34,
  height = 24,
  fill,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 87 61"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {hasTitle && <title>IconLogoForexInsiders</title>}
      <defs>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-1">
          <stop stopColor={fill || "#29B935"} offset="0%" />
          <stop stopColor={fill || "#1A9D25"} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="32.3339479%" y2="0%" id="linearGradient-2">
          <stop stopColor={fill || "#27A031"} offset="0%" />
          <stop stopColor={fill || "#35C040"} offset="60.1555453%" />
          <stop stopColor={fill || "#80FF8A"} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-3">
          <stop stopColor={fill || "#29B935"} offset="0%" />
          <stop stopColor={fill || "#1A9D25"} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="32.3342858%" y2="0%" id="linearGradient-4">
          <stop stopColor={fill || "#27A031"} offset="0%" />
          <stop stopColor={fill || "#35C040"} offset="60.1555453%" />
          <stop stopColor={fill || "#80FF8A"} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-5">
          <stop stopColor={fill || "#29B935"} offset="0%" />
          <stop stopColor={fill || "#1A9D25"} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-6">
          <stop stopColor={fill || "#29B935"} offset="0%" />
          <stop stopColor={fill || "#1A9D25"} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="32.3311053%" y2="0%" id="linearGradient-7">
          <stop stopColor={fill || "#27A031"} offset="0%" />
          <stop stopColor={fill || "#35C040"} offset="60.1555453%" />
          <stop stopColor={fill || "#80FF8A"} offset="100%" />
        </linearGradient>
        <linearGradient x1="100%" y1="62.3881969%" x2="22.3049883%" y2="50%" id="linearGradient-8">
          <stop stopColor={fill || "#238E2C"} offset="0%" />
          <stop stopColor={fill || "#29B935"} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="32.9546051%" x2="13.216778%" y2="91.4209886%" id="linearGradient-9">
          <stop stopColor={fill || "#3ED64A"} offset="0%" />
          <stop stopColor={fill || "#3ED64A"} stopOpacity="0.986478005" offset="55.0765878%" />
          <stop stopColor={fill || "#3ED64A"} stopOpacity="0" offset="100%" />
        </linearGradient>
      </defs>
      <g id="SF-2020-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Candle-2" transform="translate(34.562378, 22.554999)">
          <rect
            id="Rectangle"
            fill={fill || "#29B935"}
            x="2.95017888"
            y="0.103592896"
            width="1.88821596"
            height="37.6384382"
            rx="0.503524256"
          />
          <path
            d="M1.30831222,12.6364498 L6.49573251,12.6364498 C7.21517868,12.6364498 7.80404474,13.2253597 7.80404474,13.9442316 L7.80404474,31.8752201 C7.80404474,32.594092 7.21455089,33.1830019 6.49573251,33.1830019 L1.30831222,33.1830019 C0.588866058,33.1830019 0,32.5947198 0,31.8752201 L0,13.9442316 C0,13.2247318 0.588238269,12.6364498 1.30831222,12.6364498"
            id="Fill-24"
            fill={fill || "url(#linearGradient-1)"}
          />
          <path
            d="M7.80341695,18.3937034 L7.80341695,13.9442316 C7.80341695,13.2247318 7.2139231,12.6364498 6.49573251,12.6364498 L1.30768443,12.6364498 C0.589493847,12.6364498 0,13.2259875 0,13.9442316 L0,25.7644949 C0.574426911,21.6873297 3.9004529,18.9813577 7.80341695,18.3937034"
            id="Fill-40"
            fill={fill || "url(#linearGradient-2)"}
          />
        </g>
        <g id="Candle-1" transform="translate(24.726181, 17.915795)">
          <rect
            id="Rectangle-Copy"
            fill={fill || "#29B935"}
            x="2.96765352"
            y="0.0851973087"
            width="1.88821596"
            height="26.8126667"
            rx="0.503524256"
          />
          <path
            d="M1.30768443,4.88518982 L6.49573251,4.88518982 C7.21517868,4.88518982 7.80341695,5.47353469 7.80341695,6.19316 L7.80341695,19.8391068 C7.80341695,20.5592344 7.21517868,21.1475165 6.49573251,21.1475165 L1.30768443,21.1475165 C0.588238269,21.1475165 0,20.5592344 0,19.8391068 L0,6.19316 C0,5.47353469 0.588238269,4.88518982 1.30768443,4.88518982"
            id="Fill-25"
            fill={fill || "url(#linearGradient-3)"}
          />
          <path
            d="M7.80341695,10.6425063 L7.80341695,6.19316 C7.80341695,5.47353469 7.2139231,4.88518982 6.49573251,4.88518982 L1.30768443,4.88518982 C0.589493847,4.88518982 0,5.47472758 0,6.19316 L0,18.0133605 C0.574426911,13.9361953 3.9004529,11.2302861 7.80341695,10.6425063"
            id="Fill-41"
            fill={fill || "url(#linearGradient-4)"}
          />
        </g>
        <g id="Candle-4" transform="translate(54.236029, 17.915795)">
          <rect
            id="Rectangle-Copy"
            fill={fill || "#29B935"}
            x="3.03985507"
            y="0.0851973087"
            width="1.88821596"
            height="26.8126667"
            rx="0.503524256"
          />
          <path
            d="M1.30831222,4.88518982 L6.49573251,4.88518982 C7.21517868,4.88518982 7.80404474,5.47353469 7.80404474,6.19316 L7.80404474,19.8391068 C7.80404474,20.5592344 7.21517868,21.1475165 6.49573251,21.1475165 L1.30831222,21.1475165 C0.588238269,21.1475165 2.27373675e-13,20.5592344 2.27373675e-13,19.8391068 L2.27373675e-13,6.19316 C2.27373675e-13,5.47353469 0.588238269,4.88518982 1.30831222,4.88518982"
            id="Fill-29"
            fill={fill || "url(#linearGradient-5)"}
          />
          <path
            d="M7.80341695,10.6425063 L7.80341695,6.19316 C7.80341695,5.47353469 7.2139231,4.88518982 6.49573251,4.88518982 L1.30768443,4.88518982 C0.589493847,4.88518982 2.27373675e-13,5.47472758 2.27373675e-13,6.19316 L2.27373675e-13,18.0133605 C0.574426911,13.9361953 3.90108068,11.2302861 7.80341695,10.6425063"
            id="Fill-42"
            fill={fill || "url(#linearGradient-4)"}
          />
        </g>
        <g id="Candle-3" transform="translate(44.399204, 22.554999)">
          <rect
            id="Rectangle-Copy"
            fill={fill || "#29B935"}
            x="3.05795751"
            y="0.103592896"
            width="1.88821596"
            height="37.6384382"
            rx="0.503524256"
          />
          <path
            d="M1.30768443,12.6364498 L6.49573251,12.6364498 C7.21517868,12.6364498 7.80341695,13.2253597 7.80341695,13.9442316 L7.80341695,31.8752201 C7.80341695,32.594092 7.21455089,33.1830019 6.49573251,33.1830019 L1.30768443,33.1830019 C0.588866058,33.1830019 0,32.5947198 0,31.8752201 L0,13.9442316 C0,13.2247318 0.588238269,12.6364498 1.30768443,12.6364498"
            id="Fill-28"
            fill={fill || "url(#linearGradient-6)"}
          />
          <path
            d="M7.80404474,18.3937034 L7.80404474,13.9442316 C7.80404474,13.2247318 7.2139231,12.6364498 6.49573251,12.6364498 L1.30831222,12.6364498 C0.590121636,12.6364498 0,13.2259875 0,13.9442316 L0,25.7644949 C0.574426911,21.6873297 3.90108068,18.9813577 7.80404474,18.3937034"
            id="Fill-43"
            fill={fill || "url(#linearGradient-7)"}
          />
        </g>
        <g id="Left-horn" transform="translate(0.082229, 0.093856)">
          <rect
            id="Rectangle-Copy"
            fill={fill || "#29B935"}
            x="17.7928824"
            y="18.2847793"
            width="1.88821596"
            height="12.5881064"
            rx="0.503524256"
          />
          <path
            d="M16.0143647,20.9965274 L21.4033052,20.9965274 C22.067506,20.9965274 22.6105434,21.5398565 22.6105434,22.2038555 L22.6105434,27.7404877 C22.6105434,28.4044868 22.0668782,28.9478158 21.4033052,28.9478158 L16.0143647,28.9478158 C15.3501639,28.9478158 14.8064987,28.4048007 14.8064987,27.7404877 L14.8064987,22.2038555 C14.8064987,21.5395425 15.3501639,20.9965274 16.0143647,20.9965274"
            id="Fill-31"
            fill={fill || "#29B935"}
          />
          <path
            d="M15.7406487,20.9965274 C3.5100639,20.9965274 2.48865124,7.82666842 15.8894347,1.24977522 C17.1073453,0.65163612 16.6390147,-0.235621335 15.665314,0.0579546525 C-6.140936,6.6346595 -4.13954475,28.2014447 15.2202116,28.2014447 L17.795402,28.2014447 L16.8932692,20.9965274 L15.7406487,20.9965274"
            id="Fill-32"
            fill={fill || "#29B935"}
          />
          <path
            d="M15.2202116,28.9478158 L21.4033052,28.9478158 C22.067506,28.9478158 22.6105434,28.4048007 22.6105434,27.7404877 L22.6105434,25.0191337 C14.4856985,25.6958779 2.16157318,25.4645204 2.50371817,15.6259578 L0.020184991,15.6259578 C-0.337026935,22.237382 5.03998563,28.9478158 15.2202116,28.9478158 Z"
            id="Fill-33"
            fill={fill || "url(#linearGradient-8)"}
          />
          <path
            d="M6.5447958,15.6259578 C5.13917629,11.22056 8.03705019,5.10399599 15.8894347,1.24977522 C16.4261942,0.986398132 16.635248,0.666076342 16.6182977,0.416197721 C10.0949424,2.74308251 3.41401218,7.36665318 3.57660953,15.6259578 L6.5447958,15.6259578"
            id="Fill-34"
            fill={fill || "url(#linearGradient-9)"}
          />
        </g>
        <g
          id="Right-horn"
          transform="translate(75.402757, 15.577227) scale(-1, 1) translate(-75.402757, -15.577227) translate(64.073462, 0.093856)"
        >
          <rect
            id="Rectangle-Copy"
            fill={fill || "#29B935"}
            x="17.74923"
            y="18.2847793"
            width="1.88821596"
            height="12.5881064"
            rx="0.503524256"
          />
          <path
            d="M16.0143647,20.9965274 L21.4033052,20.9965274 C22.067506,20.9965274 22.6105434,21.5398565 22.6105434,22.2038555 L22.6105434,27.7404877 C22.6105434,28.4044868 22.0668782,28.9478158 21.4033052,28.9478158 L16.0143647,28.9478158 C15.3501639,28.9478158 14.8064987,28.4048007 14.8064987,27.7404877 L14.8064987,22.2038555 C14.8064987,21.5395425 15.3501639,20.9965274 16.0143647,20.9965274"
            id="Fill-31"
            fill={fill || "#29B935"}
          />
          <path
            d="M15.7406487,20.9965274 C3.5100639,20.9965274 2.48865124,7.82666842 15.8894347,1.24977522 C17.1073453,0.65163612 16.6390147,-0.235621335 15.665314,0.0579546525 C-6.140936,6.6346595 -4.13954475,28.2014447 15.2202116,28.2014447 L17.795402,28.2014447 L16.8932692,20.9965274 L15.7406487,20.9965274"
            id="Fill-32"
            fill={fill || "#29B935"}
          />
          <path
            d="M15.2202116,28.9478158 L21.4033052,28.9478158 C22.067506,28.9478158 22.6105434,28.4048007 22.6105434,27.7404877 L22.6105434,25.0191337 C14.4856985,25.6958779 2.16157318,25.4645204 2.50371817,15.6259578 L0.020184991,15.6259578 C-0.337026935,22.237382 5.03998563,28.9478158 15.2202116,28.9478158 Z"
            id="Fill-33"
            fill={fill || "url(#linearGradient-8)"}
          />
          <path
            d="M6.5447958,15.6259578 C5.13917629,11.22056 8.03705019,5.10399599 15.8894347,1.24977522 C16.4261942,0.986398132 16.635248,0.666076342 16.6182977,0.416197721 C10.0949424,2.74308251 3.41401218,7.36665318 3.57660953,15.6259578 L6.5447958,15.6259578"
            id="Fill-34"
            fill={fill || "url(#linearGradient-9)"}
          />
        </g>
      </g>
    </svg>
  );
};
