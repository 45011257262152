import * as React from "react";

import { Radio } from "../radio/Radio";

interface Props<T extends HTMLInputElement["value"]> {
  onChange(value: T): void;
  children?: React.ReactNode;
  disabled?: boolean;
  defaultSelected?: string | null;
  name?: string;
  className?: string;
}

export default function RadioGroup<T extends HTMLInputElement["value"]>(props: Props<T>) {
  const { onChange, children, disabled = false, defaultSelected, name, className } = props;

  return (
    <div className={className}>
      {React.Children.map(children, (child) => {
        if (!child) {
          return null;
        }

        const childElement: JSX.Element = child as JSX.Element;

        if (childElement.props.isChecked === undefined) {
          return childElement;
        }

        const { value, ...rest } = childElement.props;

        return (
          <Radio
            name={name || "radioOption"}
            value={value as T}
            disabled={disabled}
            checked={value === defaultSelected}
            onChange={(e) => onChange(e.currentTarget.value as unknown as T)}
            {...rest}
          />
        );
      })}
    </div>
  );
}
