import { ApolloError } from "@apollo/client";
import { TFunction } from "i18next";
import { UseFormMethods } from "react-hook-form";
import { toast } from "react-toastify";

import { ValidationResultInfoFragment } from "../graphql/schema";
import { translateValidationError } from "./translateValidationError";

interface HandleFormErrorsProps<T> {
  // support old error response
  errorMap?: { [K in keyof T]: { name: string; customError?: string } }[];
  result?: ValidationResultInfoFragment | undefined;
  // new error response
  error?: ApolloError | undefined;
  t: TFunction;
  setError: UseFormMethods["setError"];
}

export function handleFormErrors<T>({ errorMap, setError, result, error, t }: HandleFormErrorsProps<T>) {
  // handle old SF errors
  if (errorMap && errorMap.length > 0 && result) {
    errorMap.forEach((error) => {
      const fieldKey = Object.keys(error)[0] as keyof T & string;

      const errorData = error[fieldKey];
      const validationError = result && result.errors && result.errors[0] && result.errors[0].errors[0];

      if (result && result.errors.length > 0 && error[fieldKey].name && result.errors[0].parameter === errorData.name) {
        const predefinedError = validationError ? translateValidationError(t, validationError) : null;

        setError(fieldKey, {
          type: "notMatch",
          message: errorData.customError ? errorData.customError : predefinedError || t("Invalid value"),
        });
      }
    });

    return;
  }

  // handle new SF errors
  if (error) {
    error.graphQLErrors.forEach((error) => {
      const additionalData = error.extensions;

      // handle user input errors
      if (
        additionalData &&
        additionalData.code === "BAD_USER_INPUT" &&
        additionalData.invalidArgs &&
        additionalData.invalidArgs.length > 0
      ) {
        additionalData.invalidArgs.forEach((invalidArg: any) => {
          const invalidArgName = typeof invalidArg === "string" ? invalidArg : invalidArg?.parameter;
          const errorMessage = typeof invalidArg === "string" ? error.message : invalidArg?.message;

          setError(invalidArgName, { type: "notMatch", message: errorMessage });
          if (invalidArgName === "username") {
            // PASSWORD - BAD USER INPUT WHEN USERNAME OR PASSWORD IS WRONG
            setError("password", { type: "notMatch", message: errorMessage });
          }
        });

        return;
      }
    });

    // show general error
    toast.error(error.message ? error.message.replace("GraphQL error:", "") : t("Something went wrong"), {
      autoClose: 5000,
    });

    return;
  }
}
