import { useEffect, FC } from "react";
import { useLocation } from "react-router-dom";

/**
 * scroll to top on url change
 * @see https://reactrouter.com/web/guides/scroll-restoration
 */
export const ScrollToTop: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
