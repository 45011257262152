import classNames from "classnames";
import React, { useEffect, useRef } from "react";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import styles from "./menu.module.scss";

export enum MenuDirection {
  BOTTOM = "BOTTOM",
}

export interface MenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  direction?: MenuDirection;
  children?: React.ReactNode;
}

export const Menu: React.FC<MenuProps> = ({ open, setOpen, direction, children }) => {
  const node = useRef(null);
  useOnClickOutside(node, () => setOpen(false));

  useEffect(() => {
    if (open && !document.body.classList.contains("has-modal")) {
      document.body.classList.add("has-modal");
    }

    return () => {
      document.body.classList.remove("has-modal");
    };
  }, [open]);

  return (
    <>
      <div
        className={classNames(styles.overlay, {
          [styles.fadeIn]: open,
          [styles.fadeOut]: !open,
          [styles["direction--bottom"]]: direction !== MenuDirection.BOTTOM,
        })}
      ></div>
      <div
        ref={node}
        className={classNames({
          [styles.open]: open,
          [styles.closed]: !open,
          [styles["direction--bottom"]]: direction === MenuDirection.BOTTOM,
        })}
      >
        {children}
      </div>
    </>
  );
};
