import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useReactiveVar } from "@apollo/client";

import {
  ServiceProductCode,
  ServicePeriodCode,
  useCartViewQuery,
  useCartViewCloneCartMutation,
  useCartViewRemoveItemMutation,
  useCartViewUpdateItemPeriodMutation,
  useUpdateServiceCartItemQuantityMutation,
  PaymentEntityType,
  useCartViewFinalizeCartMutation,
  PaymentMethod,
  useCartViewApplyServiceCartDiscountCodeMutation,
  useCartViewClearServiceCartDiscountCodeMutation,
} from "../../graphql/schema";
import { Link } from "../../components/link/Link";
import { Button } from "../../components/button/Button";
import { Container, ContainerWidth } from "../../components/container/Container";
import { Heading, HeadingGutter } from "../../components/heading/Heading";
import IconTrashbin2 from "../../components/icon/IconTrashbin2";
import { Loader } from "../../components/loader/Loader";
import Price, { CurrencySign } from "../../components/price/Price";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { CartTotals } from "../../components/cart/children/cart-totals/CartTotals";
import { CartProduct as Product } from "../../components/cart-product/CartProduct";
import { Section, SectionGutter } from "../../components/section/Section";
import { SubscriptionPeriod } from "../../components/subscription-period/SubscriptionPeriod";
import { IconCheckmarkThin } from "../../components/icon/IconCheckmarkThin";
import { FrequentlyBought } from "../../components/frequently-bought/FrequentlyBought";
import { Suggestion } from "../../components/suggestion/Suggestion";
import { Badge } from "../../components/badge/Badge";
import { IconLabel } from "../../components/icon/IconLabel";
import { VerificationSection } from "./verification-section/VerificationSection";
import { DefaultCurrencyCode, Routes, SfFeeProductCodes } from "../../services/constants";
import { useServicePeriodTranslation } from "../../hooks/useServicePeriodTranslation";
import Field from "../../components/field/Field";
import { IconGift } from "../../components/icon/IconGift";
import { RedeemGiftCardModal } from "../../components/redeem-gift-card-modal/RedeemGiftCardModal";
import { showGraphqlValidationErrors } from "../../services/showGraphqlValidationErrors";
import { Separator, SeparatorGutter } from "../../components/separator/Separator";
import Quantity from "../../components/quantity/Quantity";
import { Titlebar, TitlebarType } from "../../components/titlebar/Titlebar";
import { useHandelPayment } from "../../hooks/useHandlePayment";
import {
  ADD_NEW_BANK_CARD_ID,
  PaymentMethodsList,
  PAYMENT_METHODS_ORDER,
} from "../../components/payment-methods-list/PaymentMethodsList";
import { Checkbox } from "../../components/checkbox/Checkbox";
import { DiscountCoupon } from "../../components/discount-coupon/DiscountCoupon";
import { Layout } from "../../components/layout/Layout";
import { isFirstPeriodDiscounted } from "../../services/isFirstPeriodDiscounted";
import { isLoggedInVar } from "../../cache/isLoggedIn";
import { getDocumentUrl } from "../../services/getDocumentUrl";
import { isUserFromUs } from "../../services/isUserFromUs";
import { tracker } from "../../libs/trackers";
import { shouldRedirectToBecomeAffiliateViewVar } from "../../cache/shouldRedirectToBecomeAffiliateView";

import styles from "./cartView.module.scss";

// const productNotAvailableReason = "Toode pole saadaval";
const productNotAvailableReason = undefined;

export const CartView: React.FC = () => {
  const { isMobile, isDesktopUp } = useWindowDimensionsContext();

  const { push } = useHistory();
  const { path } = useRouteMatch();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const [t] = useTranslation();
  const translatePeriod = useServicePeriodTranslation();
  const [paymentLimitError, setPaymentLimitError] = useState<string | undefined>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PAYMENT_METHODS_ORDER[0]);
  const [selectedPaymentMethodSourceId, setSelectedPaymentMethodSourceId] = useState<string | null>(null);
  const [saveCardForLaterUse, setSaveCardForLaterUse] = useState<boolean>(false);
  const handlePayment = useHandelPayment({
    selectedPaymentMethod: { id: selectedPaymentMethodSourceId, type: selectedPaymentMethod },
    onSuccess: handleOnPaymentSuccess,
    onError: handleOnPaymentFailed,
    onUnknownResponse: handlePaymentUnknownState,
    useCardForLaterUse: saveCardForLaterUse,
  });
  const [finalizeCart] = useCartViewFinalizeCartMutation();
  const [isIdealBankSelected, setIsIdealBankSelected] = useState(false);

  // coupon code mutations
  const [applyServiceCartDiscountCode] = useCartViewApplyServiceCartDiscountCodeMutation({
    refetchQueries: ["CartView"],
  });
  const [clearServiceCartDiscountCode] = useCartViewClearServiceCartDiscountCodeMutation({
    refetchQueries: ["CartView"],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateCartItemPeriod] = useCartViewUpdateItemPeriodMutation({
    refetchQueries: ["CartView"],
  });
  const [removeCartItem] = useCartViewRemoveItemMutation({ refetchQueries: ["CartView"], awaitRefetchQueries: true });
  const [cloneCart] = useCartViewCloneCartMutation();
  const [updateItemQuantity] = useUpdateServiceCartItemQuantityMutation();

  const { data, loading, error, refetch } = useCartViewQuery({
    variables: {
      periodCode: ServicePeriodCode.ONE_MONTH,
      paymentMethod: selectedPaymentMethod,
    },
  });

  const [isRemovingCartItem, setIsRemovingCartItem] = useState(false);
  // Disables quantity component when quantity is being changes on API side
  const [isUpdatingQuantity, setIsUpdatingQuantity] = useState(false);

  const [creditCardHolderName, setCreditCardHolderName] = useState<string>();
  const { register, trigger, userInputErrors } = useCartFormValidation();

  // return sorted list so services wont change order if period is updated
  const { comboDiscount, totalPrice, totalFee, totalTax, discountApplied } = data?.me.serviceCart ?? {};
  const cartItems = [...(data?.me.serviceCart?.items ?? [])].sort((a, b) => (a.product.code > b.product.code ? 1 : -1));
  const suggestionsList = data?.me.serviceOfferRecommendations ?? [];
  const currencyCode = data?.me.serviceCart?.currency?.code ?? DefaultCurrencyCode;
  const isUsCountry = isUserFromUs({ currency: { code: currencyCode } });
  const productsCount = cartItems.length;
  const cartId = data?.me.serviceCart?.id;

  function getServicePeriodOptions(offers: { period: { code: string }; labelText?: string | null }[]) {
    return offers.map((offer) => ({
      label: translatePeriod(offer.period.code as ServicePeriodCode, "NUMERIC"),
      value: offer.period.code as ServicePeriodCode,
      discountLabel: offer.labelText,
    }));
  }

  function handleOnPaymentSuccess() {
    push(Routes.CHECKOUT_CART_UP_SELL);
    setIsSubmitting(false);
  }

  useEffect(() => {
    tracker.trackEvent("VIEW_CART", {
      total: totalPrice,
      products: productsCount,
      cartId: cartId,
    });
  }, [cartId, productsCount, totalPrice]);

  function handleOnPaymentFailed() {
    cloneCart({ variables: { cartId: data?.me.serviceCart?.id || "" } }).finally(() => {
      if (selectedPaymentMethod === PaymentMethod.SHOP_CREDIT) {
        toast.error(t("Shop credit has insufficient funds"));
      } else {
        toast.error(t("Something went wrong"));
      }
      setIsSubmitting(false);
    });
  }

  function handlePaymentUnknownState() {
    // something unknown went wrong. reload view. Payment might have succeeded, might not.
    setIsSubmitting(false);

    toast.error(t("Something went wrong"));
    refetch();
  }

  async function handleRemoveCartItem(productCode: ServiceProductCode) {
    if (!isRemovingCartItem) {
      setIsRemovingCartItem(true);
      try {
        await removeCartItem({ variables: { productCode } }).then(() => {
          setIsRemovingCartItem(false);
        });
      } catch (err) {
        toast.error(t("Removing cart item failed. Please try again later"));
        console.error("Removing cart item failed. ProductCode: ", productCode);
        setIsRemovingCartItem(false);
      }
    }
  }

  /**
   * 1. finalize cart
   * 2. create new payment
   * 3. if creditCardPayment, then use stipe to make payment, if not skip this step
   * 4. Poll if payment is success
   */
  async function handleCheckoutPressed() {
    const cartId = data?.me.serviceCart?.id || "";
    const finalizationHash = data?.me.serviceCart?.finalizationHash || "";
    let isCartFinalized = false;

    setIsSubmitting(true);

    // validate form input values
    if (!(await trigger())) {
      setIsSubmitting(false);
      return;
    }

    try {
      // handle finalize cart
      const cartRes = await finalizeCart({
        variables: { finalizationHash: finalizationHash, paymentMethod: selectedPaymentMethod },
      });

      if (cartRes.errors || !cartRes.data?.finalizeCart) {
        console.error("Finalize cart failed", cartRes.errors);
        throw new Error(t("Something went wrong. Payment was not made"));
      }

      isCartFinalized = true;

      await handlePayment({
        creditCardHolderName,
        paymentVariables: {
          entityType: PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS,
          currencyCode,
        },
        selectedPaymentSource: {
          id: selectedPaymentMethodSourceId === ADD_NEW_BANK_CARD_ID ? null : selectedPaymentMethodSourceId,
          paymentMethod: selectedPaymentMethod,
        },
        isIDealBankSelected: isIdealBankSelected,
        totalPrice: data?.me.serviceCart?.totalPrice || undefined,
        errorMessage: t("Something went wrong"),
        entityId: cartRes?.data?.finalizeCart.id,
        paymentFlow: "CART_PURCHASE_OF_SERVICE_PRODUCTS",
      });

      // set redirect to affiliate view flag to true if is not yet affiliate
      if (
        cartItems.map((i) => i.product.code).some((code) => SfFeeProductCodes.includes(code)) &&
        data?.viewer?.isActive === "N"
      ) {
        shouldRedirectToBecomeAffiliateViewVar(true);
      }
    } catch (e) {
      // TODO: improve error handling code
      if (!showGraphqlValidationErrors(t, e) && e instanceof Error) {
        toast.error(e.message, { autoClose: false });
      }

      // on failure re-create cart since finalized cart cannot be used anymore
      if (isCartFinalized) {
        await cloneCart({ variables: { cartId } });
      }

      setIsSubmitting(false);

      refetch();

      return;
    }
  }

  async function handleItemQuantityChange(quantity: number, productCode: ServiceProductCode) {
    setIsUpdatingQuantity(true);

    try {
      await updateItemQuantity({
        variables: {
          productCode,
          quantity,
        },
      });

      await refetch();
    } catch (err) {
      if (err instanceof Error) {
        console.error("Could not update item quantity. Message: ", err.message);
        toast.error(t("Could not update item quantity"));
      }
    }
    setIsUpdatingQuantity(false);
  }

  // condition for rendering automatic payment confirmation checkbox
  const renderAutomaticPaymentConfirmation = data?.me.serviceCart?.items.some(
    (item) => item.product.name !== "The View",
  );

  async function handleAddDiscountCoupon(code: string) {
    try {
      await applyServiceCartDiscountCode({ variables: { code } });
    } catch (err) {
      if (!showGraphqlValidationErrors(t, err) && err instanceof Error) {
        toast.error(err.message, { autoClose: false });
      }
    }
  }

  async function handleRemoveDiscountCoupon(id: string) {
    await clearServiceCartDiscountCode();
  }

  if (error) {
    return (
      <Container size={ContainerWidth.LARGE}>
        <div className={styles["empty-cart"]}>{t("We encountered error. Please reload the page")}</div>
      </Container>
    );
  }

  if (cartItems.length <= 0 && !loading) {
    return (
      <CartHeadings>
        <Container size={ContainerWidth.LARGE}>
          <div className={styles["empty-cart"]}>{t("You have no items in your cart")}</div>
        </Container>
      </CartHeadings>
    );
  }

  const serviceCart = data?.me?.serviceCart || null;

  // render discount coupon
  const renderDiscountCoupon = () => (
    <DiscountCoupon
      discountCouponData={discountApplied}
      onAdd={handleAddDiscountCoupon}
      onRemove={handleRemoveDiscountCoupon}
      className={classNames({ [styles["discount-coupon"]]: !isDesktopUp && discountApplied })}
    />
  );

  return (
    <CartHeadings>
      <Loader visible={loading} />
      {isMobile ? (
        <Section gutter={0} withSpace>
          <Section gutter={SectionGutter.MEDIUM}>
            {cartItems.map((item, key) => {
              const selectedServiceOffer = getSelectedServiceOffer(item.product, serviceCart?.items);

              return (
                <Product
                  key={key}
                  productCode={item.product.code}
                  name={item.product.name}
                  discountedPrice={selectedServiceOffer?.discountedPrice}
                  discountedPriceNext={selectedServiceOffer?.discountedPriceNext}
                  fullPrice={selectedServiceOffer?.fullPrice || ""}
                  fullPriceNext={selectedServiceOffer?.fullPriceNext}
                  currencyCode={selectedServiceOffer?.currency.code}
                  hasSuggestion={false}
                  productNotAvailableReason={productNotAvailableReason}
                  priceDifference={0}
                  options={getServicePeriodOptions(item.product.offers)}
                  onChange={(periodCode) => {
                    updateCartItemPeriod({ variables: { productCode: item.product.code, periodCode } });
                  }}
                  selectedValue={getSelectedServicePeriod(item.product.code, serviceCart?.items)}
                  isQuantityDisables={isUpdatingQuantity || !item.product.isQuantifiable}
                  onRemove={() => removeCartItem({ variables: { productCode: item.product.code } })}
                  isRemoving={isRemovingCartItem}
                  quantity={item.quantity || 1}
                  onQuantityUpdate={(quantity) => handleItemQuantityChange(quantity, item.product.code)}
                />
              );
            })}

            {/* discount coupon */}
            {renderDiscountCoupon()}

            {comboDiscount && serviceCart && (
              <>
                <Separator gutter={SeparatorGutter.MEDIUM} />
                <Badge icon={<IconLabel />} label={t("Combo discount:")} badgeGutter="MEDIUM">
                  <Price isNegative price={comboDiscount} currency={serviceCart.currency?.code} />
                </Badge>
                <Separator />
              </>
            )}
          </Section>
          {serviceCart && (
            <CartTotals
              className={styles.totals}
              shippingCountryId={serviceCart.taxCountryId}
              taxPrice={isLoggedIn ? serviceCart.totalTax : undefined}
              totalFee={serviceCart.totalFee}
              totalPrice={serviceCart.totalPrice}
              currencyCode={serviceCart.currency?.code}
            />
          )}
        </Section>
      ) : (
        <Container size={ContainerWidth.LARGE}>
          <table className={styles.table}>
            <thead className={styles["has-border"]}>
              <tr>
                <th>{t("Product")}</th>
                <th>{t("Subscription period")}</th>
                <th>{t("Quantity")}</th>
                <th colSpan={2}>{t("Total")}</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item, key) => {
                const selectedServiceOffer = getSelectedServiceOffer(item.product, serviceCart?.items);
                const selectedServicePeriod = getSelectedServicePeriod(item.product.code, serviceCart?.items);

                const isDisclaimerVisible = selectedServiceOffer
                  ? isFirstPeriodDiscounted(selectedServiceOffer)
                  : false;

                return (
                  <tr key={key} className={styles["has-border"]}>
                    <td>
                      <Product
                        discountedPrice={selectedServiceOffer?.discountedPrice}
                        discountedPriceNext={selectedServiceOffer?.discountedPriceNext}
                        fullPrice={selectedServiceOffer?.fullPrice || ""}
                        fullPriceNext={selectedServiceOffer?.fullPriceNext}
                        currencyCode={selectedServiceOffer?.currency.code}
                        productCode={item.product.code}
                        name={item.product.name}
                        label={item.product.label}
                        productNotAvailableReason={productNotAvailableReason}
                        options={getServicePeriodOptions(item.product.offers)}
                        onChange={(periodCode) => {
                          updateCartItemPeriod({ variables: { productCode: item.product.code, periodCode } });
                        }}
                        selectedValue={selectedServicePeriod}
                      />
                    </td>
                    <td className={styles.period}>
                      <SubscriptionPeriod
                        name={item.product.name}
                        options={getServicePeriodOptions(item.product.offers)}
                        onChange={(periodCode) => {
                          updateCartItemPeriod({ variables: { productCode: item.product.code, periodCode } });
                        }}
                        selectedValue={selectedServicePeriod}
                      />
                      {isDisclaimerVisible && selectedServiceOffer && (
                        <div className={styles["price-disclaimer"]}>
                          {t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                            price: selectedServiceOffer.discountedPriceNext || selectedServiceOffer.fullPriceNext,
                            currencySign: selectedServiceOffer.currency
                              ? CurrencySign[selectedServiceOffer.currency.code]
                              : "",
                            period: translatePeriod(selectedServicePeriod, "SHORT"),
                          })}
                        </div>
                      )}

                      {productNotAvailableReason && (
                        <div className={styles["price-warning"]}>{productNotAvailableReason}</div>
                      )}
                    </td>
                    <td>
                      <div className={styles["quantity-wrap"]}>
                        <Quantity
                          name={"Quantity"}
                          amount={item.quantity || 1}
                          disabled={isUpdatingQuantity || !item.product.isQuantifiable}
                          onUpdate={(quantity) => handleItemQuantityChange(quantity, item.product.code)}
                          isVertical={!isDesktopUp}
                        />
                      </div>
                    </td>
                    <td className={styles["total-price"]}>
                      {selectedServiceOffer && (
                        <Price
                          price={
                            parseFloat(selectedServiceOffer?.discountedPrice || selectedServiceOffer.fullPrice) *
                            (item.quantity || 1)
                          }
                          currency={selectedServiceOffer.currency.code}
                        />
                      )}
                      {isDisclaimerVisible && "*"}
                    </td>
                    <td className={styles["loader-wrapper"]}>
                      <Button
                        kind="TEXT"
                        height="AUTO"
                        // eslint-disable-next-line
                        onClick={() => handleRemoveCartItem(item.product.code)}
                        type="button"
                        center="BLOCK_AND_MARGIN"
                      >
                        <IconTrashbin2 className={styles["remove-product-icon"]} />
                      </Button>
                    </td>
                  </tr>
                );
              })}

              {comboDiscount && (
                <tr className={styles["total-row"]}>
                  {/* discount coupon */}
                  <td>{isDesktopUp && renderDiscountCoupon()}</td>

                  <td className={styles["has-border"]} colSpan={2}>
                    <Layout className={styles.discount} wrap="TABLET_LANDSCAPE_MAX">
                      {/* discount coupon */}
                      {!isDesktopUp && renderDiscountCoupon()}

                      <Badge icon={<IconLabel />} label={t("Combo discount:")}>
                        <Price isNegative price={comboDiscount} currency={serviceCart?.currency?.code} />
                      </Badge>
                    </Layout>
                  </td>
                  <td className={styles["has-border"]} />
                </tr>
              )}

              {!isDesktopUp && !comboDiscount && (
                <tr className={styles["total-row"]}>
                  <td />
                  <td className={styles["has-border"]} colSpan={3}>
                    {renderDiscountCoupon()}
                  </td>
                </tr>
              )}

              {totalFee && (
                <tr className={styles["total-row"]}>
                  <td />
                  <td className={styles["has-border"]}>{t("Fee")}</td>
                  <td className={styles["has-border"]} colSpan={2}>
                    <Price price={totalFee} currency={serviceCart?.currency?.code} />
                  </td>
                </tr>
              )}

              {isLoggedIn && totalTax && (
                <tr className={styles["total-row"]}>
                  <td>
                    {/* this is lower */}
                    {isDesktopUp && !comboDiscount && renderDiscountCoupon()}
                  </td>
                  <td className={styles["has-border"]}>{t("Taxes")}</td>
                  <td className={styles["has-border"]} colSpan={2}>
                    <Price price={totalTax} currency={serviceCart?.currency?.code} />
                  </td>
                </tr>
              )}

              {/*!isDesktopUp && !isLoggedIn && !comboDiscount && (
                <tr className={styles["total-row"]}>
                  <td />
                  <td className={styles["has-border"]} colSpan={3}>
                    {renderDiscountCoupon()}
                  </td>
                </tr>
              )*/}

              <tr className={styles["total-row"]}>
                <td>{isDesktopUp && (!isLoggedIn || !totalTax) && !comboDiscount && renderDiscountCoupon()}</td>
                <td>{t("Total").toUpperCase()}</td>
                <td colSpan={2}>
                  <strong>
                    <Price price={totalPrice ?? 0} currency={serviceCart?.currency?.code} />
                  </strong>
                </td>
              </tr>
              {/*!isLoggedIn && (
                <tr className={styles["total-row"]}>
                  <td colSpan={3} style={{ textAlign: "right", fontSize: 14 }}>
                    {t("Additional taxes may be applied. Please log in to see the details")}
                  </td>
                  <td />
                </tr>
              )*/}
            </tbody>
          </table>
        </Container>
      )}

      {/* frequently bought together */}

      {suggestionsList.length > 0 && (
        <FrequentlyBought gutter={0}>
          {suggestionsList.map((suggestion) => (
            <Suggestion
              key={suggestion.id}
              productCodes={suggestion.products.map(({ code }) => code)}
              periodCode={suggestion.period.code}
              name={suggestion.products.map(({ name }) => name).join(", ")}
              fullPrice={suggestion.fullPrice}
              fullPriceNext={suggestion.fullPriceNext}
              discountedPrice={suggestion.discountedPrice}
              discountedPriceNext={suggestion.discountedPriceNext}
              currencyCode={suggestion.currency.code}
              availability={suggestion.availability}
              activeSubscriptionId={suggestion.upgradableSubscriptions[0]?.id}
            />
          ))}
        </FrequentlyBought>
      )}

      {/* login / register / payment details */}

      {isLoggedIn ? (
        <>
          <Heading className={styles.title} level={3} thin gutter={HeadingGutter.LARGE} center>
            {t("Payment details")}
          </Heading>

          <Section gutter={0} className={styles["payment-option-wrap"]} withSpace centerSelf>
            <PaymentMethodsList
              disabled={parseFloat(totalPrice || "0") === 0}
              selectedPaymentMethod={selectedPaymentMethod}
              selectedPaymentMethodSourceId={selectedPaymentMethodSourceId}
              onPaymentMethodChange={setSelectedPaymentMethod}
              onPaymentMethodSourceIdChange={setSelectedPaymentMethodSourceId}
              onOwnerNameChange={setCreditCardHolderName}
              amount={totalPrice ? parseFloat(totalPrice) : 0}
              currencyCode={currencyCode}
              handleIsIdealBankSelected={setIsIdealBankSelected}
              getSaveForLaterUseStatus={setSaveCardForLaterUse}
              onErrorStateChange={({ hasLimitError, max, sign }) => {
                setPaymentLimitError(
                  hasLimitError
                    ? t(
                        "Total amount should not be larger than {{limit}}{{sign}} for selected payment method. Please split your {{purchaseType}} into smaller payments.",
                        {
                          limit: max,
                          sign,
                          purchaseType: t("purchase"),
                        },
                      )
                    : undefined,
                );
              }}
              topUpPath={`${path}/top-up`}
            />

            <div className={styles["error-message"]}>{paymentLimitError}</div>

            {!isUsCountry && parseFloat(totalPrice || "0") !== 0 && (
              <div className={styles["gift-card-button-wrap"]}>
                <Link
                  className={styles["gift-card-button"]}
                  to={`${path}/redeem-gift-card`}
                  color="WHITE"
                  weight="BOLD"
                  height="EXTRA_SMALL"
                  shape="ROUND"
                  fontSize={12}
                >
                  <IconGift className={styles["gift-card-icon"]} /> {t("Have a gift card?")}
                </Link>
              </div>
            )}

            <div className={styles["terms-wrap"]}>
              <Field
                className={styles.checkbox}
                type="checkbox"
                name="termsAndConditions"
                checkboxStyle="SECONDARY"
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('I agree to the <a href={{href}} target="_blank">Terms and Conditions</a>', {
                        href: getDocumentUrl("GENERAL_TERMS_AND_CONDITIONS", isUsCountry),
                      }),
                    }}
                  />
                }
                error={userInputErrors.termsAndConditions}
                isRequired
                internalRef={register}
              />
              {renderAutomaticPaymentConfirmation && (
                <>
                  <Checkbox
                    name="recurringPayment"
                    className={styles.checkbox}
                    checkboxStyle="SECONDARY"
                    label={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("I authorise SF Suite to apply automatic debit for my recurring subscription."),
                        }}
                      />
                    }
                    error={userInputErrors.recurringPayment}
                    internalRef={register}
                  />
                  <p className={styles["terms-description"]}>
                    {t(
                      "You authorise us to automatically charge your selected payment method on the billing due date for your selected subscription. Any discounts will be deducted from the payment. You will receive a reminder email of your next billing due date. You can cancel your subscription at any time.",
                    )}{" "}
                    {!isUsCountry && (
                      <span>
                        {t(
                          "Since you pay for a 1 month/ 6 months/ 12 months depending on your selected subscription, you won’t receive any restitution when you cancel.",
                        )}
                      </span>
                    )}
                  </p>
                </>
              )}
            </div>
          </Section>

          <Section gutter={100} withSpace>
            <Button
              className={styles["checkout-button"]}
              type="submit"
              shape="ROUND"
              color="BLUE"
              height="LARGE"
              center="BLOCK_AND_MARGIN"
              width={340}
              fontSize={16}
              weight="BOLD"
              disabled={isSubmitting || !!paymentLimitError}
              onClick={handleCheckoutPressed}
              stretch="MOBILE"
            >
              <div className={styles["button-text-wrap"]}>
                {t("Place order")}
                <div className={styles["button-total-sum"]}>
                  <Price price={totalPrice || ""} currency={serviceCart?.currency?.code} />
                </div>
              </div>
              <IconCheckmarkThin className={styles.icon} />
            </Button>
          </Section>

          <Switch>
            <Route exact path={`${path}/redeem-gift-card`}>
              <RedeemGiftCardModal refetchQueries={["PaymentSources", "PaymentMethodsList"]} />
            </Route>
          </Switch>
        </>
      ) : (
        <>
          <VerificationSection />
          <Container className={styles["disclaimer-wrap"]} size={ContainerWidth.SMALL} center>
            <p className={styles["disclaimer-message"]}>
              {t(
                "If you are not a SF Suite member yet but would like to join our community and buy products, you can request access. After requesting an invitation, somebody from our community within your country will contact you and might share his invitation with you…",
              )}
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: t(`Click here to <a href={{link}}>request invite<a/>.`, { link: Routes.REQUEST_INVITE }),
              }}
            />
          </Container>
        </>
      )}
    </CartHeadings>
  );
};

const CartHeadings: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [t] = useTranslation();

  // check window dimensions
  const { isTabletLandscapeOrBigger } = useWindowDimensionsContext();

  return (
    <>
      <Titlebar
        className={styles.titlebar}
        type={TitlebarType.QUATERNARY}
        backUrl={{ to: "/", title: t("Continue shopping?") }}
        formatUrlLabel="UNSET"
        height={isTabletLandscapeOrBigger ? "MEDIUM" : "SMALL"}
      />
      <Heading level={2} thin center className={styles.title}>
        {t("Your cart")}
      </Heading>
      {children}
    </>
  );
};

function getSelectedServicePeriod(
  productCode: ServiceProductCode,
  cartItems: { product: { code: ServiceProductCode }; period: { code: ServicePeriodCode } }[] = [],
): ServicePeriodCode {
  const selectedService = cartItems.find((item) => item.product.code === productCode);

  if (!selectedService) {
    return ServicePeriodCode.ONE_MONTH;
  }

  return selectedService.period.code;
}

function getSelectedServiceOffer<
  T extends { code: ServiceProductCode; offers: { period: { code: ServicePeriodCode } }[] },
  P extends { product: { code: ServiceProductCode }; period: { code: ServicePeriodCode } },
>(service: T, cartItems: P[] = []) {
  const selectedPeriodCode = getSelectedServicePeriod(service.code, cartItems);
  const offer = service?.offers.find((off) => off.period.code === selectedPeriodCode);

  return offer as T["offers"][0] | undefined;
}

interface ValidateCodeFormValues {
  termsAndConditions: boolean;
  recurringPayment: boolean;
}

function useCartFormValidation() {
  const [t] = useTranslation();

  const validationSchema: yup.SchemaOf<ValidateCodeFormValues> = yup.object().shape({
    termsAndConditions: yup
      .boolean()
      .oneOf([true], t("Please agree to terms of service"))
      .required(t("Please agree to terms of service")),
    recurringPayment: yup
      .boolean()
      .oneOf([true], t("Please agree to automatic recurring payments"))
      .required(t("Please agree to automatic recurring payments")),
  });

  const {
    register,
    trigger,

    formState: { errors: userInputErrors },
  } = useForm<ValidateCodeFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: { termsAndConditions: false },
    reValidateMode: "onChange",
  });

  return {
    userInputErrors,
    register,
    trigger,
  };
}
