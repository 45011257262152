import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Button } from "../../button/Button";
import { Layout } from "../../layout/Layout";
import { IconLogoDagcoin } from "../../icon/IconLogoDagcoin";
import Price from "../../price/Price";

import styles from "./purchase-confirmation.module.scss";

export interface PurchaseConfirmationProps {
  onClose(): void;
  onConfirm(): Promise<void>;
  priceInDags: number;
  amountAvailableInDags: number;
}

export const PurchaseConfirmation: FC<PurchaseConfirmationProps> = ({
  onClose,
  onConfirm,
  priceInDags,
  amountAvailableInDags,
}) => {
  // access translation keys
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleSubmit() {
    setIsSubmitting(true);
    return onConfirm().finally(() => {
      setIsSubmitting(false);
    });
  }

  return (
    <>
      {priceInDags > amountAvailableInDags && (
        <p className={styles.error}>{t("You don't have enough coins to proceed with the purchase")}</p>
      )}
      <div className={classNames(styles["summary-wrap"], styles["summary-wrap--total"])}>
        {t("Total amount")}: <Price price={priceInDags} currency="DAG" hideSign />{" "}
        <IconLogoDagcoin className={styles["icon--total"]} width={22} height={22} />
      </div>
      <div className={classNames(styles["summary-wrap"], styles["summary-wrap--available-coins"])}>
        {t("Available coins")}: <Price price={amountAvailableInDags} currency="DAG" hideSign />{" "}
        <IconLogoDagcoin className={styles["icon--available-coins"]} width={22} height={22} />
      </div>
      <Layout wrap="MOBILE">
        <Button
          className={styles.button}
          color="LIGHT_BLUE_2"
          fontSize={18}
          borderRadius="SMALL"
          onClick={onClose}
          disabled={isSubmitting}
        >
          {t("Cancel")}
        </Button>
        <Button
          className={styles.button}
          color="BLUE"
          fontSize={18}
          borderRadius="SMALL"
          onClick={handleSubmit}
          disabled={isSubmitting || priceInDags > amountAvailableInDags}
        >
          {t("Confirm")}
        </Button>
      </Layout>
    </>
  );
};
