import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "../../../components/link/Link";
import { Heading, HeadingGutter } from "../../../components/heading/Heading";
import { useWindowDimensionsContext } from "../../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { Routes } from "../../../services/constants";
import mobileBanner from "./assets/mobile_banner.png";
import { AsyncImg } from "../../../components/async-img/AsyncImg";

import styles from "./hero-section.module.scss";

export interface HeroSectionProps {
  isAuthenticated?: boolean;
  className?: string;
  affiliateId?: number;
}

export const HeroSection: React.FC<HeroSectionProps> = ({ isAuthenticated, className, affiliateId }) => {
  const [t] = useTranslation();
  const { isMobile } = useWindowDimensionsContext();

  return (
    <div className={classNames(styles["container-wrap"], className)}>
      {isMobile && <AsyncImg className={styles["mobile-banner"]} src={mobileBanner} />}
      <div className={styles.container}>
        <div className={styles["content-wrap"]}>
          <Heading
            level={1}
            gutter={!isMobile ? HeadingGutter.SEMI_LARGE : HeadingGutter.MEDIUM}
            className={styles.header}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: t("With the Right <strong>Suite of Tools</strong> Success is Simply Inevitable"),
              }}
            />
          </Heading>

          {/* <p className={styles.text}>
            {t(
              "Discover the products & applications that successful people use and join a community of more than 700,0000 members from all around the world.",
            )}
          </p> */}

          {/* {!isAuthenticated && (
            <Link
              className={classNames(styles["button"])}
              to={affiliateId ? `/registration/${affiliateId}` : Routes.REQUEST_INVITE}
              color="WHITE"
              borderRadius="SMALL"
              weight="BOLD"
            >
              {affiliateId ? t("Sign up") : t("Get access Today")}
            </Link>
          )} */}
        </div>

        {/* Maybe we want the vertical SF Suite thing back, designs were missing it*/}
        {/* <div className={styles["vertical-line-wrap"]}>
          <div className={styles["vertical-line-inner-wrap"]}>
            <div className={styles["vertical-line"]} />
            <div className={styles["vertical-line-text"]}>SF Suite</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
