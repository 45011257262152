import { ServiceOfferAvailability } from "../graphql/schema";

interface Service {
  offers: { availability: keyof typeof ServiceOfferAvailability }[];
}

export function getServicesOffers<T extends Service>(services: T[]) {
  return services.reduce(
    (res, { offers }) => [...res, ...offers],
    [] as { availability: keyof typeof ServiceOfferAvailability }[],
  );
}
