import React from "react";

export interface IconTag3Props {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconTag3: React.FC<IconTag3Props> = ({ hasTitle, className, fill, width = 60, height = 60 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
  >
    {hasTitle && <title>IconTag3</title>}
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html: ".cls-2,.cls-3{fill:#fff;}.cls-2{opacity:0.11;}",
        }}
      />
    </defs>
    <path
      fill={fill || "#0062ff"}
      fillRule="evenodd"
      id="path-1"
      d="M147.62,71.42a19,19,0,1,1,19-19A19,19,0,0,1,147.62,71.42ZM185.72,0H108.86A24,24,0,0,0,92,7L7,92a23.84,23.84,0,0,0,0,33.67L74.34,193A23.84,23.84,0,0,0,108,193l85-85A24,24,0,0,0,200,91.14V14.28A14.26,14.26,0,0,0,185.72,0Z"
    />
    <path
      className="cls-2"
      d="M180.43,119.64c-.06.4-.14.79-.21,1.18l-.23.23C180.14,120.58,180.3,120.12,180.43,119.64Zm-95.06,70.3a64.74,64.74,0,0,0,25.36.38l33.71-33.72a51.2,51.2,0,0,1-9.42,2.6c-17.18,2.9-26.27,2.05-47.94-11.64-7.76-4.93-20-10.2-31.13.14a20.75,20.75,0,0,0-6.07,20.87L64.7,183.4A117.25,117.25,0,0,0,85.37,189.94ZM200,16.54V14.91c-5.91,0-14.59,3.87-18.49,7.26-5.29,4.59-7.62,9-10.16,15.63-1.8,4.66-3,9.69-4.69,14.44,0,0,0,.09,0,.14a19,19,0,0,1-10.36,16.93c-.68.77-1.37,1.53-2.09,2.26-8,8.31-18.69,14.74-30.48,15.51C107.38,88.14,91.87,80,76.25,76.17,47.78,69.26,21.55,93,16.82,120a52.13,52.13,0,0,0-.75,6.72,39.61,39.61,0,0,0,.71,8.74l20.86,20.87c-.55-10.2,6.68-20.62,14.85-25.77a33.27,33.27,0,0,1,26.08-3.89c10.36,2.85,19,9.74,29.06,13.42,26.92,9.92,55.94-2.76,70.32-26.32,9.44-15.44,10.92-67.89,11.62-75a39,39,0,0,1,3.58-13.57C194.54,22.41,198,18.62,200,16.54Zm-40.79,9.55a11.71,11.71,0,0,1-.1-2.34c-2.21,2.26-5.36,6.32-9.1,9.75a18.92,18.92,0,0,1,7.77,2.78,42.58,42.58,0,0,0,1-4.8A34.76,34.76,0,0,0,159.21,26.09ZM11,124.16c-.73-5.1-.32-14.42,1.08-19.41A67.2,67.2,0,0,1,36.25,70,42.74,42.74,0,0,1,46.5,64.34C60.14,59.12,74,62.47,87.72,65.93l1.75.44c14.93,3.75,29.78,7.2,44.27-.47a3.07,3.07,0,0,0,.31-.17,19,19,0,0,1-2.44-23.64c-12.47,2.47-25-.53-34.83-11-8-8.54,12-10.91,13-10.92-6.77-1.58-18.19-2-26.32-1.07-1.41.16-2.81.38-4.21.61L9.93,89a87.61,87.61,0,0,0-1.28,12.64,65,65,0,0,0,.6,12.94C9.48,116,10.59,122.58,11,124.16Z"
    />
    <path
      id="Combined-Shape-Copy"
      className="cls-3"
      d="M124.59,81.78l-.12.11-.13.13a18,18,0,0,0-3,3.7A15,15,0,0,0,120,90.94c0,.38-.08,1.12-.14,2.11l0,.56c-.37,6.39-1.26,21.23-4.28,26.18-5.53,9.06-16.7,13.94-27.06,10.11-3.87-1.42-7.2-4.07-11.19-5.17a12.73,12.73,0,0,0-10,1.49c-3.59,2.26-6.7,7.16-5.43,11.57a13.77,13.77,0,0,0,5.65,7.48,30.85,30.85,0,0,1-10.08-8.93c-.2-.26-.3-.38-.76-1.06l-.18-.27c-.1-.15-.21-.31-.33-.51a17,17,0,0,1-2.89-9.77,20.16,20.16,0,0,1,.29-2.58c1.81-10.4,11.9-19.53,22.86-16.86,6,1.46,12,4.61,18.28,4.21,4.54-.29,8.65-2.77,11.72-6a23,23,0,0,0,3.86-5.24c1.24-2.4,1.75-5.22,2.73-7.75a13.35,13.35,0,0,1,3.9-6C118.52,83.11,122.34,81.45,124.59,81.78ZM116.49,122c-3.53,21.1-23.84,30-36.56,27-6.45-1.51-9.45-3.13-10.76-4.05l-.16-.11a6.78,6.78,0,0,1-.7-.59,8,8,0,0,1,.28-11.52c4.3-4,9-1.94,12,0,8.35,5.27,11.84,5.6,18.46,4.49C106.11,136.06,114.14,130.12,116.49,122Zm-8.23-36.9a3.89,3.89,0,0,0,0,.89,14.47,14.47,0,0,1-.17,2.08,18.29,18.29,0,0,1-9.63,13.24c-10.93,5.78-22.4-4.89-33.58-.62A16.65,16.65,0,0,0,61,102.9a25.91,25.91,0,0,0-9.31,13.37,22.88,22.88,0,0,0-.41,7.47c-.14-.61-.57-3.16-.66-3.67a24.65,24.65,0,0,1-.23-5A33.73,33.73,0,0,1,52,105.52a32.67,32.67,0,0,1,27.11-22.2,34.71,34.71,0,0,1,10.13.42c-.39,0-8.07.91-5,4.2,5,5.35,11.86,5.64,18,2.69C104.83,89.42,107,86.46,108.26,85.14Z"
    />
  </svg>
);
