import { FC, ReactNode } from "react";
import classNames from "classnames";

import { useCountdown } from "../../hooks/useCountdown";

import styles from "./countdown.module.scss";

export interface CountdownProps {
  targetDate: string;
  separator?: ReactNode;
  className?: string;
  onCountDownEnd?(): unknown;
}

export interface DateTimeDisplayProps {
  value: number;
  type: string;
}

export const Countdown: FC<CountdownProps> = ({ targetDate, separator = ":", className, onCountDownEnd }) => {
  const { days, hours, minutes, seconds } = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    if (onCountDownEnd) {
      onCountDownEnd();
    }
  }

  return (
    <div className={classNames(styles.wrap, className)}>
      <DateTimeDisplay value={days} type={days === 1 ? "day" : "days"} />
      {separator}
      <DateTimeDisplay value={hours} type={hours === 1 ? "hour" : "hours"} />
      {separator}
      <DateTimeDisplay value={minutes} type={minutes === 1 ? "minute" : "minutes"} />
      {separator}
      <DateTimeDisplay value={seconds} type={seconds === 1 ? "second" : "seconds"} />
    </div>
  );
};

const DateTimeDisplay: FC<DateTimeDisplayProps> = ({ value, type }) => {
  return (
    <div className={styles.countdown}>
      <p>{value}</p>
      <span>{type}</span>
    </div>
  );
};
