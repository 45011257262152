export interface IconSignInProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
}

export const IconSignIn: React.FC<IconSignInProps> = ({ hasTitle, fill, className, width = 48, height = 48 }) => {
  return (
    <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">
      {hasTitle && <title>IconSignIn</title>}

      <path
        d="M15 10.6123L13.7071 11.9052C13.3166 12.2957 13.3166 12.9289 13.7071 13.3194L15 14.6123M14 12.6123L20 12.6123M4 17.6123V7.61228M16 17.6123C16 18.7168 15.1046 19.6123 14 19.6123H10M16 7.61228C16 6.50771 15.1046 5.61228 14 5.61228H10M4.8906 20.206L6.8906 21.5393C8.21971 22.4254 10 21.4726 10 19.8752V5.34931C10 3.75192 8.21971 2.79914 6.8906 3.68521L4.8906 5.01854C4.3342 5.38948 4 6.01394 4 6.68264V18.5419C4 19.2106 4.3342 19.8351 4.8906 20.206Z"
        stroke={fill}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
