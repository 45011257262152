import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ServiceOfferAvailability, ServiceProductCode, useProductMemborsViewQuery } from "../../graphql/schema";
import { useMount } from "../../hooks/useMount";
import { tracker } from "../../libs/trackers";
import { hasAvailableOffers } from "../../services/hasAvailableOffers";
import { notUndefinedOrNull } from "../../services/notUndefinedOrNull";
import { translateProductName } from "../../services/translateProductName";
import NotFoundView from "../not-found-view/NotFoundView";
import { CustomAddShopCart, ProductMemborsBannerSection } from "./banner-section/ProductMemborsBannerSection";
import { ProductMemborsIntroSection } from "./intro-section/ProductMemborsIntroSection";
import { ProductMemborsFeaturedSection } from "./featured-section/ProductMemborsFeaturedSection";
import { ShopLink } from "../home-view/shop-link/ShopLink";
import { CallToActionPanel } from "../../components/call-to-action-panel/CallToActionPanel";
import { IconLogoMembors } from "../../components/icon/IconLogoMembors";
import { AddServicesToCartButton } from "../../components/add-services-to-cart-button/AddServicesToCartButton";

import styles from "./product-membors-view.module.scss";

export const ProductMemborsView: React.FC = () => {
  const { data, loading, called } = useProductMemborsViewQuery({
    variables: { productCodes: [ServiceProductCode.MEMBERSHIP_BASIC, ServiceProductCode.MEMBERSHIP_PRO] },
  });
  const { t } = useTranslation();

  // check what product is selected
  const [selectedMembershipProductCode, setSelectedMembershipProductCode] = useState<ServiceProductCode>(
    ServiceProductCode.MEMBERSHIP_BASIC,
  );

  useMount(() => {
    // TODO: Add trackerEvent to use the tracker
    // tracker.trackEvent("", {
    //   productName: "MEMBERSHIP",
    //   type: "Regular",
    //   url: window.location.href,
    // });
  });

  const products = (data?.services ?? [])
    .map((product) => {
      const offer = product && product.offers.length > 0 ? product?.offers[0] : undefined;

      if (!product || !offer) {
        return null;
      }

      return {
        ...offer,
        name: translateProductName(product, t) ?? product.name,
        value: product.code,
        currencyCode: offer.currency.code,
        comingSoon: product.code === ServiceProductCode.MEMBERSHIP_PRO,
      };
    })
    .filter(notUndefinedOrNull);

  const selectedProduct = products.find((p) => p.value === selectedMembershipProductCode);
  if (!loading && called && !hasAvailableOffers(products)) {
    return (
      <NotFoundView text={t("Product not available, you will be redirected back to homepage after few seconds...")} />
    );
  }

  return (
    <>
      {/* banner section */}
      <ProductMemborsBannerSection
        productCode={selectedMembershipProductCode}
        options={products}
        onOptionChange={setSelectedMembershipProductCode}
      />

      {/* Discover Membership */}
      <ProductMemborsIntroSection productCode={selectedMembershipProductCode} product={selectedProduct} />

      <ProductMemborsFeaturedSection />
      {selectedProduct?.availability === ServiceOfferAvailability.AVAILABLE_FOR_PURCHASE && (
        <CallToActionPanel
          icon={
            <div className={styles["call-to-action-icon"]}>
              <IconLogoMembors />
              <span>Membors</span>
            </div>
          }
          title={t("One Membership, Countless Possibilities")}
          linkTitle={""}
          linkRoute={null}
          button={
            <AddServicesToCartButton
              className={styles["get-started"]}
              labelClassName={styles["get-started-label"]}
              hideCartIcon
              productCodes={[selectedMembershipProductCode]}
              serviceCode={selectedMembershipProductCode}
              periodCode={selectedProduct?.period.code ?? "ONE_MONTH"}
              activeSubscriptionId={selectedProduct?.upgradableSubscriptions?.[0]?.id}
              availability={selectedProduct?.availability}
            >
              {t("Get Started")}
            </AddServicesToCartButton>
          }
        />
      )}
    </>
  );
};
