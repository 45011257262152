import { useState, useEffect } from "react";

// Hook
export function useOnScreen<T extends Element>(ref: React.RefObject<T>, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = window.IntersectionObserver
      ? new IntersectionObserver(
          ([entry]) => {
            // Update our state when observer callback fires
            setIntersecting(entry.isIntersecting);
          },
          {
            rootMargin,
          },
        )
      : setIntersecting(true);
    if (ref.current && observer) {
      observer.observe(ref.current);
    }
    const refCopy = ref.current;

    return () => {
      if (refCopy && observer) {
        observer.unobserve(refCopy);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and un-mount

  return isIntersecting;
}
