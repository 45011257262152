import React from "react";

export interface IconCloseProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

export const IconClose: React.FC<IconCloseProps> = ({ hasTitle, className, fill, width = 24, height = 24 }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {hasTitle && <title>IconClose</title>}
      <path
        fill={fill}
        d="M10.59,12,.31,1.72A1,1,0,0,1,1.72.31L12,10.59,22.28.31a1,1,0,0,1,1.41,1.41L13.41,12,23.69,22.28a1,1,0,0,1-1.41,1.41L12,13.41,1.72,23.69A1,1,0,0,1,.31,22.28Z"
      />
    </svg>
  );
};
